import { Component, OnInit } from '@angular/core';
import { ConfigModule } from '../../../config/config.module';
import { ProductInStore } from '../../../classes/product-in-store';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { CSProductInStoreService } from '../../../services/customersiteservices/csproduct-in-store.service';
import { CSCartService } from '../../../services/customersiteservices/cscart.service';
import { Store } from '../../../classes/store';
import * as $ from 'jquery';
declare var jQuery:any;

@Component({
  selector: 'app-about-store',
  templateUrl: './about-store.component.html',
  styleUrls: ['./about-store.component.css']
})
export class AboutStoreComponent implements OnInit {
//
  url=""
  storeId
  store=new Store()
  storeAddressId
  products:ProductInStore[]=[]
  constructor(private csStoreSer:CSStoreService,
      private csProductInStoreSer:CSProductInStoreService,
      private csCart:CSCartService) {
      this.storeAddressId=csStoreSer.getCurrStoreAddressIdInService();

      let s=csStoreSer.getCurrStoreFromService();
      if(s==null||this.store.StoreAddressId!=this.storeAddressId)
      {
        csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
            data=>{
              csStoreSer.setCurrStoreInService(data);
              //this.store=data;
              csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
              this.storeId=data.StoreId;
              this.store=data
              this.url=ConfigModule.url+"files/Stores/"+this.storeId+"/"
            },
            err=>{
              console.log(err.message)
            }
          )
      }
      else
      {
        this.storeId=s.StoreId
        this.store=s;
        this.url=ConfigModule.url+"files/Stores/"+this.storeId+"/"
      }
    }

  ngOnInit() {

      var $jq = jQuery.noConflict();
      $jq(document).ready(function() {
        $jq('.multiple-items').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3
        });
      });

  }

}
