import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StorsComponent } from './components/customer/stors/stors.component';
import { ShoppingCenterLinksComponent } from './components/customer/shopping-center-links/shopping-center-links.component';
import { ShoppingCenterHomePageComponent } from './components/customer/shopping-center-home-page/shopping-center-home-page.component';
import { ShoppingInProgressComponent } from './components/customer/shopping-in-progress/shopping-in-progress.component';
import { ShoppingHistoryComponent } from './components/customer/shopping-history/shopping-history.component';
import { CustomerProfileSettingsComponent } from './components/customer/customer-profile-settings/customer-profile-settings.component';
import { SendRequestComponent } from './components/customer/send-request/send-request.component';
import { StoreLinksComponent } from './components/customer/store-links/store-links.component';
import { StoreHomePageComponent } from './components/customer/store-home-page/store-home-page.component';
import { AboutStoreComponent } from './components/customer/about-store/about-store.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CusomerLoginComponent } from './components/customer/cusomer-login/cusomer-login.component';
import { FormsModule } from '@angular/forms';
import { StoreProductsComponent } from './components/customer/store-products/store-products.component';
import { ShoppingCartComponent } from './components/customer/shopping-cart/shopping-cart.component';
import { StoreCustomerLoginComponent } from './components/customer/store-customer-login/store-customer-login.component';
import { CustomerAgreementComponent } from './components/customer/customer-agreement/customer-agreement.component';
import { ManagerLoginComponent } from './components/admin/manager-login/manager-login.component';
import { ResailerLoginComponent } from './components/resailer/resailer-login/resailer-login.component';
import { SellerLoginComponent } from './components/seller/seller-login/seller-login.component';
import { PageNotFoundComponent } from './components/others/page-not-found/page-not-found.component';
import { PageInBuildComponent } from './components/customer/page-in-build/page-in-build.component';
import { StorsChartComponent } from './components/customer/stors-chart/stors-chart.component';
import { StoreShoppingHistoryComponent } from './components/customer/store-shopping-history/store-shopping-history.component';
import { StoreProductComponent } from './components/customer/store-product/store-product.component';
import { LoginMessagesComponent } from './components/customer/login-messages/login-messages.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { CustomerCreditsComponent } from './components/customer/customer-credits/customer-credits.component';
import { StoreShippingComponent } from './components/customer/store-shipping/store-shipping.component';
import { StoreCustomerSettingComponent } from './components/customer/store-customer-setting/store-customer-setting.component';
import { StoreBottomPageComponent } from './components/customer/store-bottom-page/store-bottom-page.component';
import { ShoppingCenterBottomLinksComponent } from './components/customer/mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';
import { ShoppingHistoryPopMobileComponent } from './components/customer/mobile-components/shopping-history-pop-mobile/shopping-history-pop-mobile.component';
import { ShoppingHistoryStorePopComponent } from './components/customer/mobile-components/shopping-history-store-pop/shopping-history-store-pop.component';
import { LinksStoreBottomComponent } from './components/customer/mobile-components/links-store-bottom/links-store-bottom.component';
import { AddToCartErrorComponent } from './components/customer/add-to-cart-error/add-to-cart-error.component';
import { PaymentsTracksComponent } from './components/seller/payments-tracks/payments-tracks.component';
import { CustomerConfirmTempCartComponent } from './components/customer/customer-confirm-temp-cart/customer-confirm-temp-cart.component';
import { SafePipe } from './pipes/safe.pipe';
import { PaymentFailedComponent } from './components/customer/payment-failed/payment-failed.component';
import { PaymentSuccsesComponent } from './components/customer/payment-succses/payment-succses.component';
import { StoreCustomerPssComponent } from './components/customer/store-customer-pss/store-customer-pss.component';
import { StoreCustomerBuyingsNotPaidComponent } from './components/customer/store-customer-buyings-not-paid/store-customer-buyings-not-paid.component';
import { DatePipe } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SliderModule } from 'primeng/slider';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { LandingPageComponent } from './components/others/landing-page/landing-page.component';
//כדי שהאתר לא ישמור cache
@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    StorsComponent,
    ShoppingCenterLinksComponent,
    ShoppingCenterHomePageComponent,
    ShoppingInProgressComponent,
    ShoppingHistoryComponent,
    ShoppingHistoryStorePopComponent,
    LinksStoreBottomComponent,
    ShoppingHistoryPopMobileComponent,
    CustomerProfileSettingsComponent,
    SendRequestComponent,
    StoreLinksComponent,
    StoreHomePageComponent,
    AboutStoreComponent,
    CusomerLoginComponent,
    StoreProductsComponent,
    ShoppingCartComponent,
    StoreCustomerLoginComponent,
    CustomerAgreementComponent,
    ManagerLoginComponent,
    ResailerLoginComponent,
    SellerLoginComponent,
    PageNotFoundComponent,
    PageInBuildComponent,
    PaymentsTracksComponent,
    StorsChartComponent,
    StoreShoppingHistoryComponent,
    StoreProductComponent,
    LoginMessagesComponent,
    CustomerCreditsComponent,
    StoreShippingComponent,
    StoreCustomerSettingComponent,
    StoreBottomPageComponent,
    ShoppingCenterBottomLinksComponent,
    AddToCartErrorComponent,
    PaymentsTracksComponent,
    CustomerConfirmTempCartComponent,
    SafePipe,
    PaymentFailedComponent,
    PaymentSuccsesComponent,
    StoreCustomerPssComponent,
    StoreCustomerBuyingsNotPaidComponent,
    LandingPageComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,  
    InputSwitchModule,
    SliderModule,
    NgxImageZoomModule,
    NgxSliderModule
  ],
  providers: [
    MessageService, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
