import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { SystemMessageByCustomer } from 'src/app/classes/system-message-by-customer';
import { CustomerService } from 'src/app/services/customer.service';
import { Router } from '@angular/router';
import { ShoppingCenterLinksComponent } from '../shopping-center-links/shopping-center-links.component';
import { ShoppingCenterBottomLinksComponent } from '../mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';



@Component({
  selector: 'app-send-request',
  templateUrl: './send-request.component.html',
  styleUrls: ['./send-request.component.css']
})
export class SendRequestComponent implements OnInit {
  currMessage: SystemMessageByCustomer = new SystemMessageByCustomer();
  sendRequestClick() {
    this.isClicked = true;
    var x = document.getElementById('div_loading_img') as HTMLElement;
    x.classList.remove('hide')
    //  this.currMessage.AttachedFiles=this.attachedFiles;
    // this.isSend=true;
    this.cusomerSer.sendMessageToSystem(this.currMessage).subscribe(
      data => {
        if (data == true) {
          //  this.isSend=false;
          this.display = true;
          var x = document.getElementById('div_loading_img') as HTMLElement;
          x.classList.add('hide');
          $('.div_send_request_dialog').css('display', 'none');
          $('.div_thanks_dialog').css('display', 'flex');
          setTimeout(() => {
            this.display=false;
            this.router.navigate(['/shopping-center-links/shopping-center-home-page']);
          }, 2500)

        }
        else {
          var x = document.getElementById('div_loading_img') as HTMLElement;
          x.classList.add('hide');
          alert('בעיה בשליחה')
        }

      },
      err => {
        this.isClicked = false;
        var x = document.getElementById('div_loading_img') as HTMLElement;
        x.classList.add('hide')
        console.log(err.message)
      }
    )



  }
  display: boolean = false;
  isClicked: boolean = false;
  constructor(private cusomerSer: CustomerService, private router: Router,
    private centerLinksCom: ShoppingCenterLinksComponent,
    //private centerBtmLinksCom: ShoppingCenterBottomLinksComponent
    ) {
    centerLinksCom.changeDasignCenteralMobileLink( 'sidenav-sendReq-link');
    //centerBtmLinksCom.changeDasignCenteralMobileBtmLink('mobile-sendReq-link');

    this.display = true;
    var customer = sessionStorage.getItem('customer');
    //  if(customer==null){
    //    //הפניה לדף הרשמה
    //    sessionStorage.setItem('pageLink',JSON.stringify('send-request'));
    //    this.router.navigate(['/login'])

    //  }

  }

  ngOnInit() {


  }

  closeDialog(){
    this.centerLinksCom.isShowSendRequest=false
  }

}
