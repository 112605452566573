import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { StoreService } from '../../../services/store.service';
import { UserLogin } from '../../../classes/user-login';
import { AuthService } from '../../../services/auth.service';
import * as $ from 'jquery';
import { Customers } from 'src/app/classes/customers';
import { ConfigModule } from 'src/app/config/config.module';
import { StoreCustomersService } from 'src/app/services/store-customers.service';
import { StoreCustomers } from 'src/app/classes/store-customers';

@Component({
  selector: 'app-cusomer-login',
  templateUrl: './cusomer-login.component.html',
  styleUrls: ['./cusomer-login.component.css']
})
export class CusomerLoginComponent implements OnInit {

  currCustomer: Customers = new Customers();

  displayPhoneExist: boolean = false;
  displayVerifyPassword: boolean = false;
  displaySuccessVerifyPss: boolean = false;

  displayLoginForm: Boolean = true
  displayFirstForm: Boolean = true;//אם להציג את הכנסת טופס פרטים ראשונים
  displaySecondForm: Boolean = false;//אם להציג את הכנסת טופס פרטים אחרונים
  displayEmailForm: Boolean = false;//אם להציג את טופס מייל
  displayPhoneNumberForm: Boolean = false;//אם להציג את הטופס פלאפון
  displayPhoneVerificationForm: Boolean = false;

  //בדיקות תקינות על שדות הקלט
  isFirstFormValid: boolean = true;
  isEmailFormValid: boolean = true;
  isPhoneFormValid: boolean = true;
  isSecondFormValid: boolean = true;
  isAgreementApproved: Boolean = true;
  isUserNameExist: Boolean = false;
  isEmailExist: Boolean = false;
  isPhoneExists: Boolean = false;
  isAnotherPhoneNumbersExist: Boolean = false;
  isHousePhoneNumberExist: Boolean = false;
  isPasswordsMatch: Boolean = true;
  isIncorrectTempCode: Boolean = false;
  isAdded: Boolean = false;

  existCustomer: boolean = true;
  enrollmentFirstStep: boolean = false;
  newCustomerEmail: boolean = false;
  newCustomerPhone: boolean = false;
  newCustomerMoreDetails: boolean = false;
  loginMessage: boolean = false;
  showWellcom: boolean = true;


  isClicked: Boolean = false
  isForgotPss: Boolean = false;
  isEmailExists: Boolean = true;
  isSent: Boolean = false
  isInitpassClicked: Boolean = false

  constructor(private router: Router,
    private customerSer: CustomerService,
    private storeCustomerSer: StoreCustomersService,
    private storeSer: StoreService,
    // private messageSer:MessageService,
    private authSer: AuthService) {
    this.storeId = storeSer.currStoreIdInBuying
  }


  ngOnInit() {
    $(document).ready(function () {
      $('.login_titls a').click(function () {
        $('.login_titls a').toggleClass("current_login_page");
      });
    })
  }

  storeId: number
  pageLink: string = null;
  ptrn: string = ConfigModule.ptrn;
  user: UserLogin = new UserLogin();
  isCustomerExists: Boolean = true;


  // isCustomerExists:Boolean=true;
  login() {
    this.isClicked = true;
    document.getElementById('input_text_user').classList.remove('input_err')
    document.getElementById('input_text_pss').classList.remove('input_err')
    this.authSer.customerLogin(this.user).subscribe(
      data => {
        //
        //sessionStorage.setItem('token',data.access_token);
        this.authSer.setCustomerToken(data.access_token)

        this.customerSer.getCustomerByUserNameAndPassword(this.user).subscribe(
          data => {
            this.customerSer.setCurrCustomerInService(data);

            //
            //בדיקה אם לחץ על זכור אותי
            let cb = (document.getElementById('cb_remember') as HTMLInputElement).checked;
            if (cb == true) {
              //
              localStorage.setItem('customer', JSON.stringify(data));
              localStorage.setItem('customerToken', this.authSer.getCustomerToken())
              //שמירת התאריך של יצירת התוקן כדי לבדוק אחר כך אם הוא פג
              let expireTime = new Date(Date.now())
              //שיניתי ל7 ימים
              new Date(expireTime.setDate(new Date(expireTime).getDate() + 7))
              //new Date(expireTime.setMinutes(new Date(expireTime).getMinutes()+4))

              //expireTime.setDate(expireTime.getDate()+1)
              localStorage.setItem('customerTokenExpireTime', expireTime.toString())
            }


            this.pageLink = sessionStorage.getItem('pageLink');

            if (this.pageLink == null)
              //this.router.navigate(['/shopping-center-links/stors']);
              this.router.navigate(['/']);
            else
              this.router.navigate(['/' + this.pageLink])
          },
          err => {
            console.log(err.message)
          }
        )

      },
      err => {
        this.isClicked = false;
        this.isCustomerExists = false;
        document.getElementById('input_text_user').classList.add('input_err')
        document.getElementById('input_text_pss').classList.add('input_err')
        console.log(err.message)
      }
    )
  }

  //התחברות לנוד
  loginNode() {

    //
    this.isClicked = true;
    document.getElementById('input_text_user').classList.remove('input_err')
    document.getElementById('input_text_pss').classList.remove('input_err')

    this.authSer.nodeJsCustomerLogin(this.user).subscribe(
      data => {
        //

        this.authSer.setCustomerToken(data.token);

        this.customerSer.setCurrCustomerInService(data.customer);



        //בדיקה אם לחץ על זכור אותי
        let cb = (document.getElementById('cb_remember') as HTMLInputElement).checked;
        if (cb == true) {
          //
          localStorage.setItem('customer', JSON.stringify(data.customer));
          localStorage.setItem('customerToken', this.authSer.getCustomerToken())
          //שמירת התאריך של יצירת התוקן כדי לבדוק אחר כך אם הוא פג
          let expireTime = new Date(Date.now())
          //שיניתי ל7 ימים
          new Date(expireTime.setDate(new Date(expireTime).getDate() + 7))
          //new Date(expireTime.setMinutes(new Date(expireTime).getMinutes()+4))

          //expireTime.setDate(expireTime.getDate()+1)
          localStorage.setItem('customerTokenExpireTime', expireTime.toString())
        }


        this.pageLink = sessionStorage.getItem('pageLink');

        if (this.pageLink == null)
          //this.router.navigate(['/shopping-center-links/stors']);
          this.router.navigate(['/']);
        else
          this.router.navigate(['/' + this.pageLink])
      },
      err => {
        //
        //לבדוק את הססטוס של השגיאה
        //400 לא נמצא
        //403 לקוח חסום
        this.isClicked = false;
        this.isCustomerExists = false;
        document.getElementById('input_text_user').classList.add('input_err')
        document.getElementById('input_text_pss').classList.add('input_err')
        console.log(err.message)
      }
    )
  }



  initpass(email: string, name) {

    document.getElementById('input_user_forgot').classList.remove('input_err')
    document.getElementById('input_email_forgot').classList.remove('input_err')
    if (email == '' || name == '') {
      document.getElementById('input_user_forgot').classList.add('input_err')
      document.getElementById('input_email_forgot').classList.add('input_err')
      this.isEmailExists = false;
      this.isInitpassClicked = false;

    }
    else {
      this.isInitpassClicked = true;
      this.customerSer.initPass(email, name).subscribe(
        data => {
          if (data == false) {
            this.isEmailExists = false;
            this.isInitpassClicked = false;
          }
          else {
            this.isInitpassClicked = false
            this.displayLoginForm = true;
            this.existCustomer = true;
            this.isForgotPss = false
            this.isSent = true;
          }
        },
        err => {
          this.isInitpassClicked = false;
          document.getElementById('input_user_forgot').classList.add('input_err')
          document.getElementById('input_email_forgot').classList.add('input_err')
          console.log(err.message)
        }
      )
    }
  }


  nameForgotPss: string = ""
  email: string = "";

  isShowCustomerAgreementDialog: Boolean = false
  goToAgreement() {
    this.isShowCustomerAgreementDialog = true
    //this.router.navigate(['/customer-agreement'])
  }
  cancel() {
    //חזרה לדף הבית - אתר הקניות
    this.router.navigateByUrl('shopping-center-links/shopping-center-home-page');
  }
  showForgotPassword() {
    this.isForgotPss = true;
    this.existCustomer = false;
    this.showWellcom = false;
    this.displayFirstForm = false;
  }


  //הרשמה
  goToAddPhoneForm(confirmPass: string, isFirstFormValid: boolean) {
    //
    //בדיקה אם כל שדות הקלט בטופס הראשוני תקינים
    if (isFirstFormValid == true) {
      ;
      this.isClicked = true;
      //בדיקה אם הסיסמאות תואמות
      if (confirmPass != this.currCustomer.CustomerPassword) {
        this.isPasswordsMatch = false
        this.isClicked = false;
      }
      else {
        this.isPasswordsMatch = true
        //בדיקה אם לחץ על אישור הסכם משתמש
        let cb = (document.getElementById('isApproval') as HTMLInputElement).checked;
        if (cb == false) {
          this.isAgreementApproved = false;
          this.isClicked = false
        }
        else {
          this.customerSer.checkIfCustomerUserNameExists(0, this.currCustomer.UserName).subscribe(
            data => {
              ;
              if (data == true)//כבר קיים משתמש עם שם משתמש זהה
              {
                this.isUserNameExist = true;
                this.isClicked = false
              }
              else//המשך לפרטים הבאים
              {
                this.isClicked = false
                this.displayFirstForm = false;
                this.displayPhoneNumberForm = true;
                this.showWellcom = false;
              }
            },
            err => { console.log(err.message) }
          )

        }
      }
    }
    else {
      this.isFirstFormValid = false;
      //בדיקה אם אישר את הסכם המשתמש
      let cb = (document.getElementById('isApproval') as HTMLInputElement).checked;
      if (cb == false) {
        this.isAgreementApproved = false;
      }
      else
        this.isAgreementApproved = true;

    }

  }
  clickOfAgreementConfirmation() {
    //בדיקה אם אישר את הסכם המשתמש
    let cb = (document.getElementById('isApproval') as HTMLInputElement).checked;
    if (cb == false) {
      this.isAgreementApproved = false;
    }
    else
      this.isAgreementApproved = true;
  }


  cancelEmail() {
    this.currCustomer.Email = null;
    this.displayEmailForm = false;
    this.displayPhoneNumberForm = true;
  }


  addCustomer() {
    ;
    this.isClicked = true;

    this.customerSer.addCustomer(this.currCustomer).subscribe(
      data => {

        ///שמירה בסשן
        this.user.UserName = this.currCustomer.UserName;
        this.user.Password = this.currCustomer.CustomerPassword;;

        this.customerSer.setCurrCustomerInService(data);

        this.currCustomer = new Customers();
        this.isAdded = true;

        this.customerSer.currCustomer = data;//הכנסת הנתונים של הלקוח החדש לסרביס

        // this.messageSer.add({key:'tl',severity:'success', detail:'הנתונים נשמרו בהצלחה'});

        // setTimeout(()=>{
        //   this.router.navigate(['/links-b/customer-settings-b'])
        // },1500);


        //צריך כאן להביא טוקן


        this.authSer.customerLogin(this.user).subscribe(
          data => {
            //
            //sessionStorage.setItem('token',data.access_token);
            this.authSer.setCustomerToken(data.access_token)
            this.showLoginMessage();
          },
          err => {
            console.log(err.message)
          })

      },
      err => { console.log(err.message) }
    )
  }

  addCustomerNode(isSecondFormValid) {
    if (isSecondFormValid == true) {
      this.isClicked = true;
      // בדיקה האם מספר טלפון נוסף ומספר טלפון בבית ואמייל
      //  הם ייוחדיים ולא קיימים כבר במערכת
      this.currCustomer.AnotherPhoneNumber = (this.currCustomer.AnotherPhoneNumber != null && this.currCustomer.AnotherPhoneNumber != '') ? this.currCustomer.AnotherPhoneNumber : null;
      this.currCustomer.HousePhoneNumber = (this.currCustomer.HousePhoneNumber != null && this.currCustomer.HousePhoneNumber != '') ? this.currCustomer.HousePhoneNumber : null;
      this.currCustomer.Email = (this.currCustomer.Email != null && this.currCustomer.Email != '') ? this.currCustomer.Email : null;
      this.customerSer.checkIfPhoneOrMailExists(this.currCustomer.HousePhoneNumber, this.currCustomer.AnotherPhoneNumber, this.currCustomer.Email, this.currCustomer.CustomerId).subscribe(
        data => {
          if (data != 0) {
            if (data == 1) {
              this.isAnotherPhoneNumbersExist = true;
              this.isClicked = false;
            } else if (data == 2) {
              this.isHousePhoneNumberExist = true;
              this.isClicked = false;
            }
            else if (data == 3) {
              this.isEmailExist = true;
              this.isClicked = false;
            }
          }
          else {
            let isPasswordRequired = (document.getElementById('cb_ispasswordrequired') as HTMLInputElement).checked;
            if (isPasswordRequired == true)
              this.currCustomer.IsPasswordRequired = 1;
            else
              this.currCustomer.IsPasswordRequired = 0;
            this.customerSer.addCustomer(this.currCustomer).subscribe(
              data => {
                ///שמירה בסשן
                this.user.UserName = this.currCustomer.UserName;
                this.user.Password = this.currCustomer.CustomerPassword;;
                this.customerSer.setCurrCustomerInService(data.customer);
                this.currCustomer = new Customers();
                this.isAdded = true;
                this.customerSer.currCustomer = data.customer;//הכנסת הנתונים של הלקוח החדש לסרביס
                this.authSer.setCustomerToken(data.token)
                this.showLoginMessage();
              },
              err => { console.log(err.message) }
            )
          }
        },
        err => { console.log(err.message) }
      )

    }
    else {
      this.isSecondFormValid = false;
    }
  }
  addAndSkip() {
    this.isClicked = true;
    this.currCustomer.Street = null;
    this.currCustomer.City = null;
    this.currCustomer.FirstName = null;
    this.currCustomer.PostalCode = null;
    this.currCustomer.LastNameOrCompany = null;
    this.currCustomer.NumHouse = null;
    this.currCustomer.NumApartment = null;
    this.currCustomer.IsPasswordRequired = null;
    this.customerSer.addCustomer(this.currCustomer).subscribe(
      data => {

        this.isAdded = true;
        let pss = this.currCustomer.CustomerPassword;
        ///שמירה בסשן
        this.customerSer.setCurrCustomerInService(data);

        //צריך כאן להביא טוקן
        this.user.UserName = this.currCustomer.UserName;
        this.user.Password = pss;

        this.authSer.customerLogin(this.user).subscribe(
          data => {
            //sessionStorage.setItem('token',data.access_token);
            //
            this.authSer.setCustomerToken(data.access_token)

            this.showLoginMessage();
          },
          err => {
            console.log(err.message)
          })
        // this.messageSer.add({key:'tl',severity:'success', detail:'הנתונים נשמרו בהצלחה'});
        // setTimeout(()=>{
        //   this.router.navigate(['/links-b/customer-settings-b'])
        // },1500);
        this.showLoginMessage();
      },
      err => { console.log(err.message) }
    )
  }
  storeCustomer: StoreCustomers = null;
  savePhone(tempCode) {
    if (tempCode != "") {
      this.isClicked = false;
      //בדיקה האם קוד אימות תקין
      this.customerSer.checkIfTempCodeIsCorrect(this.currCustomer.PhoneNumber, tempCode).subscribe(
        data => {
          if (data == true) {
            //בדיקה האם מספר הטלפון קיים במערכת
            // בטבלת לקוח בחנות ( במקרה שמוכר הכניס את הלקוח)
            this.storeCustomerSer.checkIfPhoneExistsInSystem(this.currCustomer.PhoneNumber).subscribe(
              data => {
                if (!data) {
                  this.displayPhoneVerificationForm = false;
                  this.displaySecondForm = true;
                  this.isClicked = false;
                }
                else {
                  this.storeCustomer = data;
                  this.displayPhoneVerificationForm = false;
                  this.displayPhoneExist = true;
                  this.isClicked = false;
                }
              },
              err => {
                console.log(err.message)
              }
            )
          } else {
            this.isIncorrectTempCode = true;
            this.isClicked = false;
          }
        },
        err => { console.log(err) }
      )

    } else
      this.isIncorrectTempCode = true;

  }

  getPhoneVarification(isValid) {
    if (isValid) {
      this.isClicked = true;
      this.customerSer.checkIfCustomerPhoneNumbersExists(this.currCustomer.CustomerId, this.currCustomer.PhoneNumber, null, null).subscribe(
        data => {
          if (data != false) {
            this.isPhoneExists = true;
            this.isClicked = false;
          }
          else {
            this.customerSer.getTempCodeToPhoneVarification(this.currCustomer.PhoneNumber).subscribe(
              data => {
                this.displayPhoneNumberForm = false;
                this.displayPhoneVerificationForm = true;
                this.isClicked = false;
              },
              err => { console.log(err.message) }
            )
          }
        },
        err => { console.log(err.message) }
      )
    } else
      this.isPhoneFormValid = false;
  }

  setCustomerDetailes() {
    this.currCustomer.FirstName = this.storeCustomer.FirstName;
    this.currCustomer.LastNameOrCompany = this.storeCustomer.LastNameOrCompany;
    this.currCustomer.PhoneNumber = this.storeCustomer.PhoneNumber;
    this.currCustomer.HousePhoneNumber = this.storeCustomer.HousePhoneNumber;
    this.currCustomer.AnotherPhoneNumber = this.storeCustomer.AnotherPhoneNumber;
    this.currCustomer.Email = this.storeCustomer.Email;
    this.currCustomer.City = this.storeCustomer.City;
    this.currCustomer.Street = this.storeCustomer.Street;
    this.currCustomer.NumHouse = this.storeCustomer.NumHouse;
    this.currCustomer.NumApartment = this.storeCustomer.NumApartment;
    this.currCustomer.PostalCode = this.storeCustomer.PostalCode;
    this.currCustomer.Entrance = this.storeCustomer.Entrance;
    this.displayPhoneExist = false;
    this.displaySecondForm = true;
    this.isClicked = false;
  }

  cancelPhone() {
    this.currCustomer.PhoneNumber = null;
    this.currCustomer.HousePhoneNumber = null;
    this.currCustomer.AnotherPhoneNumber = null;
    this.displayPhoneNumberForm = false;
    this.displaySecondForm = true;
  }

  loginLink() {
    this.showWellcom = true;
    this.existCustomer = true;
    this.displayLoginForm = true;
    this.isForgotPss = false;
    this.displayFirstForm = false;
    this.displayEmailForm = false;
    this.displayPhoneNumberForm = false;
    this.displaySecondForm = false;
    this.displayPhoneVerificationForm = false;
  }
  //הרשמת לקוח
  goToEnrollment() {
    this.existCustomer = false;
    this.showWellcom = true;
    this.displayFirstForm = true;
    this.displayLoginForm = false;
    this.displaySecondForm = false;
    this.displayPhoneNumberForm = false;
    this.displayPhoneVerificationForm = false;
    this.isForgotPss = false;
  }

  // פונקציה להצגת הודעת התחברות
  showLoginMessage() {
    this.loginMessage = true;
    setTimeout(() => {
      this.router.navigate(['/shopping-center-links/stors']);
    }, 2500);
  }

  // saveEmail(isEmailFormValid) {
  //   ;
  //   if (isEmailFormValid == true) {
  //     this.isClicked = true;
  //     this.customerSer.checkIfMailExists(this.currCustomer.Email, this.currCustomer.CustomerId).subscribe(
  //       data => {
  //         if (data == true) {
  //           this.isClicked = false;
  //           this.isEmailExist = true;
  //         }
  //         else {
  //           this.displayPhoneNumberForm = true;
  //           this.displayEmailForm = false;
  //           this.isClicked = false;
  //         }
  //       },
  //       err => {
  //         console.log(err.message)
  //       }
  //     )
  //   }

  //   else {
  //     // שדה הקלט אמייל לא חוקי
  //     this.isEmailFormValid = false;
  //   }

  // }
}
