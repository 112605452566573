export class SystemMessageByCustomer {
   constructor(
    public SenderName?:string,
    public SenderPhon?:string,
    public Email?:string,
    public Subject?:string,
    public Content?:string
     )
   {
    //בדיקה עם הלקוח  מחובר למערכת


   }
}
// ופס ובו השדות: שם הפונה (חובה),
// טלפון, כתובת אימייל, נושא הפנייה (חובה),
// תוכן הפנייה (חובה).
