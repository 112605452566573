import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { CustomerService } from 'src/app/services/customer.service';
import { Customers } from 'src/app/classes/customers';
import { Router } from '@angular/router';
import { CSStoreCustomerService } from '../../../services/customersiteservices/csstore-customer.service';
import { AuthService } from '../../../services/auth.service';
import { BuyingService } from 'src/app/services/buying.service';
import { ProductInTempCartService } from 'src/app/services/product-in-temp-cart.service';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';

@Component({
  selector: 'app-shopping-center-links',
  templateUrl: './shopping-center-links.component.html',
  styleUrls: ['./shopping-center-links.component.css']
})
export class ShoppingCenterLinksComponent implements OnInit {
  currCustomer: Customers = null;
  constructor(private customerSer: CustomerService,
    private router: Router,
    private csStoreCustomerService: CSStoreCustomerService,
    private authSer: AuthService,
    private buyingSer: BuyingService,
    private productInTempCartSer: ProductInTempCartService,
    private csCartSer: CSCartService) {

    this.currCustomer = this.customerSer.getCurrCustomerFromService();
  }

  ngOnInit() {
    $('.div_shopping_center_link').click(function () {
      if (window.location.pathname == '/shopping-center-links/shopping-center-home-page') {
        $('.img_line').addClass('hide');
      }
      else {
        $('.img_line').removeClass('hide');
      }
    })
    if (window.location.pathname == '/shopping-center-links/shopping-center-home-page') {
      $('.img_line').addClass('hide');
    }
    else {
      $('.img_line').removeClass('hide');
    }
    //local storage בדיקה אם המשתמש קיים ב
    if (localStorage.getItem('customerToken')) {
      //
      //לבדוק פה קודם אם התוקף של התוקן פג

      let tokenExpireTime: Date = new Date(localStorage.getItem('customerTokenExpireTime'));

      //
      let now = new Date(Date.now());
      if (now > tokenExpireTime) {
        //sessionStorage.clear();
        localStorage.removeItem('customerToken');
        localStorage.removeItem('customerTokenExpireTime')
        localStorage.removeItem('customer')

        //this.sellerSer.setCurrentSeller(null);
      }
      else {

        this.authSer.setCustomerToken(localStorage.getItem('customerToken'))

        this.customerSer.setCurrCustomerInService(JSON.parse(localStorage.getItem('customer')))

        this.currCustomer = this.customerSer.getCurrCustomerFromService();

        //this.router.navigate(['/seller-hp-up-links/hp-side-links/seller-hp'])
      }
    }
  }

  changeDasignCenteralLink(id: string) {
    $('.div_shopping_center_link a').css('color', 'white');
    $('.img_dots').css('opacity', '0');
    $(`#${id} a`).css('color', '#63E3FF');
    $(`#${id} .img_dots`).animate({
      opacity: 1,
    }, 700);
  }

  // שינוי עיצוב ללינק במובייל
  changeDasignCenteralMobileLink(idSidenav: String) {
    $('.sidenav a').removeClass('current_sidenav');
    $(`#${idSidenav}`).addClass('current_sidenav');

  }


  //בדיקה אם המשתמש רשום
  displayIsNotSignIn: boolean = false
  goToPage(url) {
    let customer: Customers = JSON.parse(sessionStorage.getItem('customer'))
    if (customer == null) {
      this.displayIsNotSignIn = true;
      sessionStorage.setItem('pageLink', url)
    }
    else {
      this.router.navigate([url])
    }
  }
  goToLogin() {
    this.displayIsNotSignIn = false;
    //sessionStorage.setItem('pageLink','')
    this.router.navigate(['/login'])
  }
  displayPageInBuild = false

  // הצגה או הסתרה של התפריט הנפתח להתנתקות
  LoginCustomerClick() {
    this.displayDropdownMenue = !this.displayDropdownMenue;
    $(".a_login i").toggleClass("i_arrow_click");
  }
  //  האם להציג אפשרות התנתקות בלקוח מחובר לחנות
  displayDropdownMenue = false;


  signOut() {
    sessionStorage.clear();
    //מחיקת הסטוריית הקניות של לקוח השמורות בסשן
    this.buyingSer.setBuyingsOfCustomerInService(null);
    //מחיקת הקניות השמורות הרשומות בסשן
    this.productInTempCartSer.setTempCartsOfCustomerInService(null);
    this.customerSer.setCurrCustomerInService(null);
    this.currCustomer = null
    //מחיקת הלקוחות של חנות אם קיימים
    this.csStoreCustomerService.exitAllCurrStoreCustomerInService();
    
    // --------- Useless code --------
    //מחיקת כל הסל שיש ללקוח עכשיו בחנות
    // this.csCartSer.removeAllTempCartByStoreIdinService();

    //מחיקת המשתמש מהLOCAL STORAGE
    //אם קיים
    if (localStorage.getItem('customer'))
      localStorage.removeItem('customer')
    if (localStorage.getItem('customerToken'))
      localStorage.removeItem('customerToken')
    if (localStorage.getItem('customerTokenExpireTime'))
      localStorage.removeItem('customerTokenExpireTime')

    this.router.navigate(['/shopping-center-links/shopping-center-home-page'])
  }
  //פתיחת התפריט צד
  openNav() {
    $(".sidenav").addClass('width_sidenav');
  }
  //סגירת התפריט צד
  closeNav() {
    $(".sidenav").removeClass('width_sidenav');
  }

  //
  isShowSendRequest = false
}
