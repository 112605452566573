import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

export class ConfigModule {

  //public static url = 'https://new.ivr.business/';

  public static url = 'https://ivr.business/';
  // public static url = "http://localhost:4300/";

  //public static url="https://admin.ivr.business/";

  public static ptrn = "[^\"#']+";

  public static sellerUrl = "/seller-hp-up-links/hp-side-links/";
  public static adminUrl = "/admin-home-page/hp-side-links/";
  public static sellerToResailerUrl = "/resailer-links/str-links/";
  public static errMss = "401 Unauthorized";
  //rgb(241 203 215) - pink
  public static csCustomColor = "#FBB800";
  public static defaultCsCustomColor = "#FBB800";

}
