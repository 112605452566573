import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { Category } from '../classes/category';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';

@Injectable({
  providedIn: 'root'
})
//export type ResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';
export class CategoryService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService) { }

  url = ConfigModule.url + "api/Category/";

  allCategories: Category[] = null;
  categoriesByStore: Category[] = null;
  // שמירה בסשן את כל הקטגוריות של חנות מסוימת
  // setCategoriesByStoreInService(categories: Category[]) {
  //   this.categoriesByStore = categories;
  //   sessionStorage.setItem('categoriesByStore', JSON.stringify(categories));
  // }
  // שליפה מהסשן את כל הקטגוריות של חנות מסוימת
  getCategoriesByStoreFromService(): Category[] {
    if (this.categoriesByStore == null)
      this.categoriesByStore = JSON.parse(sessionStorage.getItem('categoriesByStore'));
    return this.categoriesByStore;
  }

  getCategoryByStore(storeId: number): Observable<Category[]> {
    // let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    return this.httpClient.get<Category[]>(this.url + "getCategoryByStore/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        // , 'UserId': sellerId + ''
      })
    })
  }

  getFirst10CategoriesByStore(storeId: number): Observable<Category[]> {
    return this.httpClient.get<Category[]>(this.url + "getFirst10CategoriesByStore/" + storeId)
  }

  getCategoryByStoreToAddEditProduct(storeId: number, userId: number): Observable<Category[]> {
    //let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    return this.httpClient.get<Category[]>(this.url + "getCategoryByStoreToAddEditProduct/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': userId + ''
      })
    })
  }


  // קבלת הקטגוריות לחנות מסויימות פונקצייה ללקוחות
  // getCategoriesByStore(storeId:number):Observable<Category[]>
  // {
  //   return this.httpClient.get<Category[]>(this.url+"getCategoriesByStore/"+ storeId);
  // }

  //הוספת תמונה לקטגוריה
  addImageToCategory(image: File, storeId: number): Observable<string> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    const formData: FormData = new FormData();
    formData.append('Image', image, image.name);

    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      }),
      //עבור הנוד
      responseType: 'text' as 'json'
    };

    return this.httpClient.post<string>(this.url + 'addImageToCategory/' + storeId,
      formData,
      options);

  }
  addCategory(cat: Category): Observable<Category> {
    return this.httpClient.post<Category>(this.url + "addCategory", cat,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
      })
  }

  editCategory(c: Category): Observable<Category> {
    return this.httpClient.put<Category>(this.url + "editCategory/" + c.CategoryId, c,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
      });
  }

  deleteCategory(id, storeId, index): Observable<number> {
    return this.httpClient.delete<number>(this.url + "deleteCategory/" + id + "/" + storeId + '/' + index,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
      })
  }
  getCategoryByStoreAddressId(storeSddressId: number): Observable<Category[]> {
    return this.httpClient.get<Category[]>(this.url + "getCategoryByStoreAddressId/" + storeSddressId);
  }

  getCategorybyId(id): Observable<Category> {
    return this.httpClient.get<Category>(this.url + "getCategorybyId/" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
      })
  }

  //בדיקה אם אינדקס של קטגוריה כבר קיים בחנות
  checkIfCategoryIndexExistsInStore(storeId, categoryId, categoryIndex): Observable<boolean> {
    return this.httpClient.get<boolean>(this.url + "checkIfCategoryIndexExistsInStore/" + storeId + "/" + categoryId + "/" + categoryIndex,
      {
        headers: new HttpHeaders({
          "Authorization": "Bearer " + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
      })
  }
}
