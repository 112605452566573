import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { ProductInStore } from '../classes/product-in-store';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ProductFeature } from '../classes/product-feature';
import { Seller } from '../classes/seller';
import { SellerService } from './seller.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer:SellerService) {
    // if(sessionStorage.getItem('product'))
    // this.product=JSON.parse(sessionStorage.getItem('product'))
  }

  url = ConfigModule.url + "api/ProductInStore/";
  product: ProductInStore = null;
  //private
  allProducts: ProductInStore[] = null;

  private productsInCustomerSite: ProductInStore[] = null;

  /*
  //קבלת המוצרים מהסרביס
  getAllProductsOfStoreFromService():ProductInStore[]
  {
    if(this.allProducts==null)
      this.allProducts=JSON.parse(sessionStorage.getItem('products'))

    return this.allProducts;
  }
  //השמה של המוצרים בסרביס
  setProductsOfStoreInService(pl:ProductInStore[])
  {
    this.allProducts=pl;
    sessionStorage.setItem('products',JSON.stringify(pl));
  }
  //הוספת מוצר לרשימת המוצרים בסרביס
  addProductToService(p:ProductInStore)
  {
    if(this.allProducts==null)
    this.allProducts=JSON.parse(sessionStorage.getItem('products'));
    this.allProducts.push(p);
    sessionStorage.setItem('products',JSON.stringify(this.allProducts))
  }
  //מחיקת מוצר מרשימת המוצרים בסרביס
  deleteProductFromProductsInService(prodId:number)
  {
    if(this.allProducts==null)
    this.allProducts=JSON.parse(sessionStorage.getItem('products'));
    let index=this.allProducts.findIndex(x=>x.ProductId==prodId);
    this.allProducts.splice(index,1);
    sessionStorage.setItem('products',JSON.stringify(this.allProducts))
  }
  //עריכת מוצר בסרביס
  editProductInService(prod:ProductInStore)
  {
    if(this.allProducts==null)
    this.allProducts=JSON.parse(sessionStorage.getItem('products'));
    let index=this.allProducts.findIndex(x=>x.ProductId==prod.ProductId);
    this.allProducts[index]=prod;
    sessionStorage.setItem('products',JSON.stringify(this.allProducts))
  }
  //קבלת מוצר מהסרביס לפי ID
  getProductByIdFromService(prodId):ProductInStore
  {
    if(this.allProducts==null)
    this.allProducts=JSON.parse(sessionStorage.getItem('products'));
    let prod=this.allProducts.find(x=>x.ProductId==prodId);
    return prod;
  }
  */


  addProduct(prod: ProductInStore, userId: number): Observable<ProductInStore> {
    return this.httpClient.post<ProductInStore>(this.url + "addProduct", prod,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }
  //העלאת תמונה כל שהיא לחנות
  //הקוד מבטא את המטרה שלשמה העלתה התמונה
  addPicToProduct(image: File, storeId, userId: number): Observable<string> {

    const formData: FormData = new FormData();
    formData.append('Image', image, image.name);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authSer.getSellerToken(),
        UserId: userId + ''
      }),
      responseType: 'text' as 'json'
    };

    return this.httpClient.post<string>(this.url + 'addPicToProduct/' + storeId, formData, httpOptions);
  }

  editProduct(productId: number, prod: ProductInStore, userId: number): Observable<ProductInStore> {
    return this.httpClient.put<ProductInStore>(this.url + "editProduct/" + productId, prod,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }

  deletePics(str: string[], prodId: number, userId: number): Observable<void> {
    return this.httpClient.post<void>(this.url + "deletePics/" + prodId, str,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }

  deleteProduct(prodId: number, storeId: number, userId: number): Observable<void> {
    return this.httpClient.delete<void>(this.url + "deleteProduct/" + prodId + "/" + storeId,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }
  deleteProductsBySKU(prodId: number,sku:number, storeId: number, userId: number): Observable<void> {
    return this.httpClient.delete<void>(this.url + "deleteProductsBySKU/" + prodId + "/" + sku +"/" + storeId,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
    }
  //קבלת מוצר יחיד
  getProductById(idProduct: number, userId: number): Observable<ProductInStore> {
    return this.httpClient.get<ProductInStore>(this.url + "getProductById/" + idProduct,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }


  getProductsByStore(storeId: number, userId: number): Observable<ProductInStore[]> {
    return this.httpClient.get<ProductInStore[]>(this.url + "getProductsByStoreId/" + storeId,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }

  // checkIfProductIndexExistsInStore(storeId: number, productId: number, index: number, userId: number): Observable<number> {
  //   return this.httpClient.get<number>(this.url + "checkIfProductIndexExistsInStore/" + storeId + "/" + productId + "/" + index,
  //     {
  //       headers: new HttpHeaders({
  //         'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
  //         'UserId': userId + ''
  //       })
  //     })
  // }
  checkIfProductIndexExists(storeId: number, productId: number, index: number, userId: number): Observable<number> {
    return this.httpClient.get<number>(this.url + "checkIfProductIndexExists/" + storeId + "/" + productId + "/" + index,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }
  postCheckIfMultiplyIndexesExists(storeId, inedxArr, userId): Observable<any> {
    return this.httpClient.post<any>(this.url + "postCheckIfMultiplyIndexesExists/" + storeId, inedxArr,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }
  postCheckIfMultiplySKUsExists(storeId,SKUArr,userId): Observable<any> {
    return this.httpClient.post<any>(this.url + "postCheckIfMultiplySKUsExists/"+  storeId,SKUArr,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }


  checkIfSkuOrIndexExists(storeId, prodId, sku, index, userId: number): Observable<number> {
    return this.httpClient.get<number>(this.url + "checkIfSkuOrIndexExists/" + storeId + "/" + prodId + "/" + sku + "/" + index,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }

  //מחזיר מוצרים לפי חנות עבור אתר הלקוחות
  getProductsByStoreAddressId(storeId: number): Observable<ProductInStore[]> {
    return this.httpClient.get<ProductInStore[]>(this.url + "getProductsByStoreAddressId/" + storeId);
  }

  //מחזיר מוצרים לפי קטגוריה עבור אתר הלקוחות
  getProductsByCategoryId(catId: number): Observable<ProductInStore[]> {
    return this.httpClient.get<ProductInStore[]>(this.url + "getProductsByCategoryId/" + catId);
  }
  //מחזיר מוצרים לפי תת קטגוריה עבור אתר הלקוחות
  getProductsBySubCategoryId(subCatId): Observable<ProductInStore[]> {
    return this.httpClient.get<ProductInStore[]>(this.url + "getProductsBySubCategoryId/" + subCatId);
  }

  getCurrentStock(storeId, userId): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authSer.getSellerToken(),
        UserId: userId + ''
      })
    };
    // return this.httpClient.get(this.url+"downloadRangeSummary/"+date1+"/"+date2+"/"+storeId,
    //  httpOptions );
    return this.httpClient.get<any>(this.url + "getCurrentStock/" + storeId, httpOptions);
  }



  //קבלת מוצר לפי קוד מוצר ומספר כתובת חנות עבור אתר הלקוחות
  getProductByIdAndStoreAddressId(productId, storeId): Observable<ProductInStore> {
    return this.httpClient.get<ProductInStore>(this.url + "getProductByIdAndStoreAddressId/" + productId + "/" + storeId);
  }


  setProductsForCustomerSiteInService(p: ProductInStore[]) {
    sessionStorage.setItem('p', JSON.stringify(p));
    this.productsInCustomerSite = p;
  }
  getProductsForCustomerSiteFromService(): ProductInStore[] {
    if (this.productsInCustomerSite == null)
      this.productsInCustomerSite = JSON.parse(sessionStorage.getItem('p'));
    return this.productsInCustomerSite;
  }
  addProductsWithFeatures(ps, userId): Observable<any> {
    return this.httpClient.post<any>(this.url + 'addProductsWithFeatures', ps, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authSer.getSellerToken(),
        UserId: userId + ''
      })
    });
  }


     //פונקציה להוספה מרובה של לקוחות בחנות ע"י המוכר
     addProducts(fileToAdd:any,storeId:number,sellerId:number ): Observable<any> {

      const formData: FormData = new FormData();
      formData.append('file', fileToAdd, fileToAdd.name);
      const options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': sellerId + ''
        })
      };
      return this.httpClient.post<any>(this.url + "addProducts/"+ storeId , formData, options)
    }

  editProductsWithFeatures(ps, userId): Observable<any> {
    return this.httpClient.post<any>(this.url + 'editProductsWithFeatures', ps, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authSer.getSellerToken(),
        UserId: userId + ''
      })
    });
  }


  getProductsBySKU(storeId, sku, userId): Observable<ProductInStore[]> {

    return this.httpClient.get<ProductInStore[]>(this.url + 'getProductsBySKU/' + storeId + '/' + sku, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authSer.getSellerToken(),
        UserId: userId + ''
      })
    });
  }

  removeProductWithFeature(prodId: number,userId:number): Observable<void> {
    return this.httpClient.delete<void>(this.url + "deleteFeatureByProductId/" + prodId,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }

  removeFeature(featureName:string,sku:number,storeId:number,userId:number) {
    return this.httpClient.delete<void>(this.url + "deleteFeatureBySKUAndFeatureName/"+sku+"/"+featureName+"/"+storeId,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authSer.getSellerToken(),
          UserId: userId + ''
        })
      });
  }

  uploadProductsFromExcel(fileToUpload: File, storeId: number): Observable<ProductInStore[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    };

    return this.httpClient.post<ProductInStore[]>(this.url + 'uploadProductsFromExcel/' + storeId,
      formData,
      options);

  }
}
