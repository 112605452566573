import { Injectable } from '@angular/core';
import { ConfigModule } from '../config/config.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLogin } from '../classes/user-login';
import { Observable } from 'rxjs';
import { ResailerRepresent } from '../classes/resailer-represent';
import { ResailerService } from './resailer.service';
import { ChangeUserPassword } from '../classes/change-user-password';
import { ManagerService } from './manager.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResailerRepresentService {

  URL = ConfigModule.url + "api/ResailerRepresent/";
  private resailerRepresent: ResailerRepresent = null;

  constructor(private httpClient: HttpClient,
    private resailerSer: ResailerService,
    private managerSer: ManagerService,
    private authSer: AuthService) { }

  setRPInService(rp: ResailerRepresent) {
    sessionStorage.setItem('rp', JSON.stringify(rp));
    this.resailerRepresent = rp;
  }
  getRPFromService() {
    if (this.resailerRepresent == null)
      this.resailerRepresent = JSON.parse(sessionStorage.getItem('rp'))
    return this.resailerRepresent;
  }

  addRpTpService(rp) {

    this.resailerSer.getCurrentResailer().ResailerRepresent.push(rp);
    let r = this.resailerSer.getCurrentResailer();
    this.resailerSer.setCurrentResailer(r);
  }
  editRpInService(rp: ResailerRepresent) {
    let r = this.resailerSer.getCurrentResailer();
    let ind = r.ResailerRepresent.findIndex(x => x.ResailerRepresentId == rp.ResailerRepresentId);
    r.ResailerRepresent[ind] = rp;
    this.resailerSer.setCurrentResailer(r);
  }

  //קבלת משווק ונציג לפי שם משתמש וסיסמא של נציג
  getResailerRepresentByUserNameAndPassword(ul: UserLogin): Observable<any> {
    return this.httpClient.post<any>(this.URL + "getResailerRepresentByUserNameAndPassword", ul, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getResailerToken() }) })
  }
  //בדיקה אם שם משתמש של נציג כבר קיים
  getCheckIfRpUserNameExists(userName, rpId, token: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.URL + "getCheckIfRpUserNameExists/" + userName + "/" + rpId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) })
  }
  //עריכת פרטי נציג של משווק
  editRp(rp: ResailerRepresent, token: string, userId: number): Observable<ResailerRepresent> {

    return this.httpClient.put<ResailerRepresent>(this.URL + "editRp/" + rp.ResailerRepresentId, rp,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token
          , 'UserId': userId + ''
        })
      })
  }
  //הוספת נציג למשווק
  addRp(rp, userId: number): Observable<ResailerRepresent> {
    return this.httpClient.post<ResailerRepresent>(this.URL + "addRp", rp,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getResailerToken(),
          'UserId': userId + ''
        })
      })
  }

  //שינוי סטטוס לנציג
  getEnableDisableRP(id: number, userId: number): Observable<void> {
    return this.httpClient.get<void>(this.URL + "getEnableDisableRP/" + id
      , {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getResailerToken()
          , 'UserId': userId + ''
        })
      })
  }
  //שינוי סטטוס לנציג ע"י המנהל
  changeStatusByAdmin(sellerId: number): Observable<void> {
    return this.httpClient.get<void>(this.URL + "changeStatusByAdmin/" + sellerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }
  // שינוי סטטוס נציג ע"י המנהל בסרביס
  changeResailerRepresentStatusByAdminInService(id: number) {

    let idResailer = this.managerSer.getCurrentResailer();
    var res = this.resailerSer.getCurrResailersFromService();
    var res2 = res.find(x => x.ResailerId == idResailer);
    if (res2 != null) {
      var rp = res2.ResailerRepresent.find(x => x.ResailerRepresentId == id);
      if (rp.IsApprovalToAdmin == true)
        rp.IsApprovalToAdmin = false;
      else
        rp.IsApprovalToAdmin = true;
      this.editRpInService(rp);
    }

  }

  //נציג למנהל
  setRpAdmin(id, userId: number): Observable<void> {
    return this.httpClient.get<void>(this.URL + "setRpAdmin/" + id
      , {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getResailerToken(),
          'UserId': userId + ''
        })
      })
  }

  initpass(email: string, userName: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.URL + "initpass/" + email + "/" + userName)
  }

  //שינוי סיסמת לקוח
  /*
  הפונקציה מקבלת אוביקט מסוג
  ChangeUserPassword
  ומחזירה
  0- במקרה שהסיסמא הישנה שהזין אינה תואמת את הסיסמא הקיימת
  1- הסיסמא שונתה בהצלחה
  2- הסיסמא החדשה תואמת את הסיסמא הישנה - לא תקין
  */
  changeRpPassword(cup: ChangeUserPassword): Observable<any> {
    return this.httpClient.post<any>(this.URL + "changeRpPassword", cup,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getResailerToken()
          , 'UserId': cup.UserId + ''
        })
      });
  }
}
