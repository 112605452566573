import { SubCategory } from './sub-category';

export class Category {
    constructor(public CategoryId :number=0,
        public  CategoryName :string="",
        public  CategoryImage :string="",
        public  StoreId :number=0,
        public  SubCategory:SubCategory[]=null,
        public CategoryIndexInStore:number=null)
        {
    
        }
}
