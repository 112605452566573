import { Component, ElementRef, OnInit } from '@angular/core';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { CSProductInStoreService } from '../../../services/customersiteservices/csproduct-in-store.service';
import { ProductInStore } from '../../../classes/product-in-store';
import { ConfigModule } from '../../../config/config.module';
import { CSCartService } from '../../../services/customersiteservices/cscart.service';
import * as $ from 'jquery';
import { Category } from 'src/app/classes/category';
import { CategoryService } from 'src/app/services/category.service';
import { CSStoreCustomerService } from '../../../services/customersiteservices/csstore-customer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreLinksComponent } from '../store-links/store-links.component';
import { ProductWithFeatures } from 'src/app/classes/product-with-features';
import { ProductDataWithFeature } from 'src/app/classes/product-data-with-feature';
import { StoreService } from 'src/app/services/store.service';
import { TempCart } from 'src/app/classes/temp-cart';
import { ProductInTempCart } from 'src/app/classes/product-in-temp-cart';
import { BuyingService } from 'src/app/services/buying.service';
import { StoreProductsFilter } from 'src/app/classes/filter/store-products-filter';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-store-products',
  templateUrl: './store-products.component.html',
  styleUrls: ['./store-products.component.css']
})
export class StoreProductsComponent implements OnInit {
  displayNotSignIn: boolean = false;
  url = ""
  storeId
  store
  storeAddressId;
  //מספר מוצרים בחנות
  productsNumInStore: number = 0;
  counter: number = 1;
  customColor: string = ''
  //הגבלות מצד החנות
  //האם להציג מוצרים שאזלו מהמלאי
  isDisplayOutOfStockProducts: boolean = true;
  // האם להציג כמות במלאי במוצרים
  isDisplayProductsQuantityInStock: boolean = true;
  //האם קיימים מוצרים עם מאפיינים בחנות
  isExistsProductsWithFeature: number = -1;
  isLoaded: Boolean = false;
  products: ProductInStore[] = [];
  //רשימת המוצרים ממקט  - בחנות בעלת מוצרים עם מאפיינים
  productsBySKU: ProductDataWithFeature[] = [];
  // כמות המוצרים שנייבא בכל טעינה
  numOfProductsPerLoads: number = 20;
  //מספר הטעינות שהתבצעו
  numberOfLoads: number;
  poster = ""
  displayStorePss: boolean = false;

  //משתנים עבור סינון המוצרים
  displayFilters: boolean = false;
  isShowAll: boolean = true;
  tempProducts: ProductInStore[] = [];
  displayAllCategories: boolean = false;
  categories: Category[] = null;
  allCategories: Category[] = null;

  constructor(private csStoreSer: CSStoreService,
    private csProductInStoreSer: CSProductInStoreService,
    private csCart: CSCartService,
    private categorySer: CategoryService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private csCartSer: CSCartService,
    private router: Router,
    private storeLinksCom: StoreLinksComponent,
    private storeSer: StoreService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private buyingSer: BuyingService) {
    storeLinksCom.changeDasignLink('products_link');

    this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
    if (this.storeAddressId == null) {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 2])
      this.storeAddressId = getCurrStoreAddressIdInService
    }

    //שליפה מהסשן את מספר הטעינה
    this.counter = this.csProductInStoreSer.getNumberOfPagesLoaded();

    //בדיקה האם אומתה סיסמת לקוח לחנות
    if (!this.storeSer.getIsStorePassVerifiedInService()) {
      //בדיקה האם קימת סיסמת לקוח לחנות
      this.storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
        data => {
          if (data == true) {//יש סיסמת לקוחות בחנות
            //הצגת הודעה של הזנת סיסמא
            this.displayStorePss = true;
          }
          else {
            //בדיקה האם המשתמש סינן את המוצרים
            if (this.checkIsFilterSelected() == false) {
              //קריאה לפונקצית קבלת המוצרים של החנות מהשרת
              this.getProductsInStore();
              //קבלת פרטי החנות מהשרת או מהסרביס
              this.getStore();
            }
          }
        },
        err => {
          console.log(err);
        }
      )
    } else {
      //בדיקה האם המשתמש סינן את המוצרים
      if (this.checkIsFilterSelected() == false) {
        //קריאה לפונקצית קבלת המוצרים של החנות מהשרת
        this.getProductsInStore();
        //קבלת פרטי החנות מהשרת או מהסרביס
        this.getStore();
      }
    }



    // //קריאה לפונקצית קבלת המוצרים של החנות מהשרת
    // this.getProductsInStore();

  }

  search(txt) {
    if (this.tempProducts != null && this.tempProducts.length != 0)
      this.products = this.tempProducts
    let res: ProductInStore[] = [];
    this.products.forEach(element => {
      if (element.ProductName.includes(txt) || element.SKU.toString().includes(txt))
        res.push(element);
    });
    this.tempProducts = this.products;
    this.products = res;
    // --
    // מה עם חיפוש מהשרת
  }
  // isPasswordCorrect: boolean = true;
  // storePss:string="";
  //   // בדיקה האם הסיסמא שהמשתמש הכניס לחנות תקינה
  //   checkIsStorePssCorrect(isValid) {

  //     if (isValid == true) {
  //       this.storeSer.checkIsStorePssCorrect(this.storeAddressId,this.storePss).subscribe(
  //         data => {
  //           if (data == true) {
  //             // שמירה בסשין את המשתנה של האם סיסמת חנות אומתה
  //            this.storeSer.setIsStorePassVerifiedInService(true);
  //            this.displayStorePss=false;
  //           }

  //           else {
  //             this.isPasswordCorrect = false;
  //           }

  //         },
  //         err => {
  //           console.log(err);
  //         }
  //       )
  //     }
  //   }
  // //חזרה לעמוד של חנויות
  //   goToStores() {
  //     this.displayStorePss=false;
  //     this.router.navigate(['/shopping-center-links/stors']);
  //   }


  // קבלת פרטי החנות מהשרת או מהסרביס
  // ושמירת הגדרות
  getStore() {
    let s = this.csStoreSer.getCurrStoreFromService();
    if (s == null) {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          //this.store=data;
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          this.storeId = data.StoreId;
          // this.categories = this.categorySer.getCategoriesByStoreFromService();
          //בדיקה אם יש לחנות כרזה
          if (data.Poster != null && data.Poster != "")
            this.poster = data.Poster;
          else
            this.poster = data.StoreNameInSite;
          //הגדרת הצבע המותאם אישית
          this.customColor = data.CustomColor
          if (this.customColor != null && this.customColor != '') {
            ConfigModule.csCustomColor = this.customColor;
          }
          else {
            this.customColor = ConfigModule.defaultCsCustomColor;
          }
          this.store = data;

          // בדיקת ההגבלות של החנות לגבי אופן הצגת מוצרים
          if (this.store.DisplayProductQuantityInStock == false)
            this.isDisplayProductsQuantityInStock = false;
          if (this.store.DisplaysFinishedProducts == false)
            this.isDisplayOutOfStockProducts = false;
          this.url = ConfigModule.url + "files/Stores/" + this.storeId + "/"
        },
        err => {
          console.log(err.message)
        }
      )
    }
    else {
      this.storeId = s.StoreId
      this.store = s;
      //בדיקה אם יש לחנות כרזה
      if (this.store.Poster != null && this.store.Poster != "")
        this.poster = this.store.Poster
      else
        this.poster = this.store.StoreNameInSite;
      //הגדרת הצבע המותאם אישית
      this.customColor = this.store.CustomColor
      if (this.customColor != null && this.customColor != '') {
        ConfigModule.csCustomColor = this.customColor;
      }
      else {
        this.customColor = ConfigModule.defaultCsCustomColor;
      }
      // בדיקת ההגבלות של החנות לגבי אופן הצגת מוצרים
      if (this.store.DisplayProductQuantityInStock == false)
        this.isDisplayProductsQuantityInStock = false;
      if (this.store.DisplaysFinishedProducts == false)
        this.isDisplayOutOfStockProducts = false;
      this.url = ConfigModule.url + "files/Stores/" + this.storeId + "/";
    }
  }


  //קבלת מספר המוצרים וייבוא המוצרים מהשרת
  getProductsInStore() {
    //קבלת מספר המוצרים בחנות
    // וטווח מחירים בחנות
    this.csProductInStoreSer.getCountProductsInStore(this.storeAddressId).subscribe(
      data => {
        //קליטת טווח מחירים
        this.filterObj.FromSum = data.minPrice;
        this.filterObj.ToSum = data.maxPrice;
        this.productsNumInStore = data.countAll;
        this.getNumberOfLoads();
        // המשתמש ביצע טעינות נוספות, מקרה קצה בעת רענון הדף
        //↓
        if (this.counter != 1) {
          this.numOfProductsPerLoads = this.counter * 20;
          this.counter = 1;
        }

        if (this.products == null || this.products.length == 0) {
          if (this.counter <= this.numberOfLoads) {

            // שליפת עשרים המוצרים הראשונים מהשרת
            this.csProductInStoreSer.getProductsByStoreAddressIdByloadingNumber(this.storeAddressId, this.counter, this.numOfProductsPerLoads).subscribe(
              data => {
                this.isLoaded = false;
                this.counter = this.csProductInStoreSer.getNumberOfPagesLoaded();//שליפת מספר הטעינות
                this.numOfProductsPerLoads = 20;
                for (let i = 0; i < data.length && this.isExistsProductsWithFeature != 1; i++) {
                  //עדכון המשתנה האם קיים מוצר עם מאפיינים
                  (data[i].ProductFeature.length > 0) ? this.isExistsProductsWithFeature = 1 : this.isExistsProductsWithFeature = 0;
                }

                //במקרה שאין בחנות מוצרים עם מאפיינים
                if (this.isExistsProductsWithFeature != 1)
                  this.products = data;
                else {   //יש בחנות מוצרים עם מאפיינים
                  this.products = [];
                  //קריאה לפונקציה שממינת את המוצרים לפי מקט
                  this.productsBySKU = this.sortProductsBySku(data);
                }
              },
              err => { console.log(err.message) }
            )

            //-------------- בלי משימת המאפיינים-------------
            // this.csProductInStoreSer.getProductsByStoreAddressIdByloadingNumber(this.storeAddressId, this.counter, this.productsNumInStore).subscribe(
            //   data => {
            //     this.products = data;
            //     //שמירה בסשן את המוצרים של החנות שנטענו
            //     this.csProductInStoreSer.setProductsByStoreInService(data);
            //   },
            //   err => { console.log(err.message) }
            // )

          }
        }
        else {
          //עדכון מספר הטעינות שהתבצעו
          if (this.products.length == data)
            this.counter = this.numberOfLoads;
          else
            this.counter = this.products.length / this.numOfProductsPerLoads;
          //עדכון המשתנה האם קיים מוצר עם מאפיינים
          for (let i = 0; i < this.products.length && this.isExistsProductsWithFeature != 1; i++) {
            (this.products[i].ProductFeature.length > 0) ? this.isExistsProductsWithFeature = 1 : this.isExistsProductsWithFeature = 0;
          }
        }
      },
      err => { console.log(err.message) }
    )

  }

  // טעינה נוספת של מוצרים
  loadMore() {
    if (this.displayFilters == true)
      this.loadMoreByFilter();
    else {
      this.counter++;
      //שמירת מספר טעינות/דפים שהמשתמש טען
      this.csProductInStoreSer.setNumberOfPagesLoaded(this.counter);
      if (this.counter <= this.numberOfLoads) {
        this.isLoaded = true;
        this.csProductInStoreSer.getProductsByStoreAddressIdByloadingNumber(this.storeAddressId, this.counter, this.numOfProductsPerLoads).subscribe(
          data => {
            this.isLoaded = false
            if (this.isExistsProductsWithFeature != 1) {
              data.forEach(element => {
                this.products.push(element);
              });
            }
            else {
              //מיון המוצרים שנטענו לפי מקט
              this.productsBySKU = this.sortProductsBySku(data);
            }
          },
          err => { console.log(err.message) }
        )
      }
    }
  }
  // ליצור מערך של כל המונות ולכל תמונה להגדיר האם הגובה אוט הראוך יוצר גדול ולפי זה להוסיף קלאס מתאים!!!!

  // טעינה נוספת של מוצרים בחנות ללא מאפיינים
  // loadMore() {

  //   this.counter++;
  //   if (this.counter <= this.numberOfLoads) {
  //     this.csProductInStoreSer.getProductsByStoreAddressIdByloadingNumber(this.storeAddressId, this.counter, this.productsNumInStore).subscribe(
  //       data => {

  //         data.forEach(element => {
  //           this.products.push(element);
  //         });
  //         //הוספה לסשן את המוצרים החדשים שנטענו
  //         this.csProductInStoreSer.setProductsByStoreInService(this.products);
  //       },
  //       err => { console.log(err.message) }
  //     )
  //   }
  // }

  ngOnInit() {
    var filterObj = this.csProductInStoreSer.getFilterObjFromService();
    $(document).ready(function () {

      // if (filterObj != null && filterObj.CategoriesIds.length > 0) {
      //   //סימון קטגוריות שנבחרו
      //   var checkboxes = document.querySelectorAll('input[type=checkbox]')
      //   for (var i = 0; i < checkboxes.length; i++) {
      //     if (filterObj.CategoriesIds.find(id => id == (parseInt((checkboxes[i] as HTMLInputElement).value))))
      //       (checkboxes[i] as HTMLInputElement).checked = true;
      //   }
      // }

      $(".ui-slider .ui-slider-handle:nth-of-type(2)").append($("#min_value"));
      $(".ui-slider .ui-slider-handle:nth-of-type(3)").append($("#max_value"));
      //$(".ui-slider .ui-slider-handle").after($("<p>Test</p>"));
    })
  }

  ngAfterViewChecked(): void {
    if (this.filterObj != null && this.filterObj.CategoriesIds != null && this.filterObj.CategoriesIds.length > 0) {
      // סימון קטגוריות שנבחרו לסינון
      var checkboxes = document.querySelectorAll('input[type=checkbox]')
      for (var i = 0; i < checkboxes.length; i++) {
        if (this.filterObj.CategoriesIds.find(id => id == (parseInt((checkboxes[i] as HTMLInputElement).value))))
          (checkboxes[i] as HTMLInputElement).checked = true;
      }
    }
  }

  // ---הוספת מוצר לסל---
  isProductAddedToCart: Boolean = false;
  addProductToCart(p: ProductInStore) {
    // var cb=document.getElementById('checkbox_'+p.ProductId) as HTMLInputElement;
    // cb.checked=true;
    var storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    var storeCustomerId = null;
    if (storeCustomer != null) {
      storeCustomerId = storeCustomer.StoreCustomerId;
      //בדיקה שבחנות אין אפשרות לבצע קניות ללקוח שיש לו קניות שלא שולמו או
      //  ששמוגדר בחנות להציג הודעה על קניות שלא שולמו בלי חסימת קנייה וללקוח וזה פעם ראשונה להצגת ההודעה הזו ללקוח
      if (this.store.IsBuyingDisableForCustomerWithUnPaidBuyings == 1 ||
        (this.store.IsBuyingDisableForCustomerWithUnPaidBuyings == 2 && this.csStoreCustomerSer.getIsCustomerSeeBuyingNotPaidInService() != true)) {
        //שליפת קניות שלא שולמו ללקוח בחנות
        this.buyingSer.getUnPaidBuyingsByStoreCustomerId(storeCustomer.StoreCustomerId, this.store.StoreId).subscribe(
          data => {
            // אם יש קניות שלא שולמו מעבר להודעה על הקניות
            if (data != null && data.length > 0) {
              sessionStorage.setItem('storePageLink', 'store-links/' + this.storeAddressId + '/store-products');
              this.router.navigate(['/store-links/' + this.storeAddressId + '/store-customer-buyings-not-paid']);
            } else {
              //הוספה לסל בשרת
              this.csCart.getAddProductToTempCart(this.store.StoreId, p.ProductId, storeCustomerId, 1).subscribe(
                data => {
                  //בדיקה אם המוצר התווסף בשרת
                  if (data["isSucsess"] == true || data["isSucsess"] == 'true') {
                    //עדכון בסרביס של כמות המוצרים בסל
                    this.csCartSer.setTempCartProductsQuantityInService(parseInt(data["content"]));

                    //-------- Unusual code
                    // this.csCart.addProductToTempCartInService(this.store, p, 1);
                    var div_product = document.getElementById('div_product_' + p.ProductId).getElementsByClassName('div_product')[0];
                    div_product.classList.add('selected_border')

                    var add_cart = document.getElementById('add_cart_' + p.ProductId);
                    add_cart.classList.add('hide');

                    var plus_minus = document.getElementById('plus_minus_' + p.ProductId);
                    plus_minus.classList.remove('hide');
                    //אנימציה של הוספה לסל
                    this.storeLinksCom.addToCartAnimate()
                  }
                  else {
                    //הצגת שגיאה
                    alert(data["content"]);
                  }
                },
                err => { console.log(err.message) }
              )
            }
          },
          err => { console.log(err) }
        )
      }
      else {



        //אם הלקוח רשום, קודם יהיה הוספה בשרת
        this.csCart.getAddProductToTempCart(this.store.StoreId, p.ProductId, storeCustomerId, 1).subscribe(
          data => {
            //בדיקה אם המוצר התווסף בשרת
            if (data["isSucsess"] == true || data["isSucsess"] == 'true') {
              //עדכון בסרביס של כמות המוצרים בסל
              this.csCartSer.setTempCartProductsQuantityInService(parseInt(data["content"]));

              //-------- Unusual code
              // this.csCart.addProductToTempCartInService(this.store, p, 1);
              var div_product = document.getElementById('div_product_' + p.ProductId).getElementsByClassName('div_product')[0];
              div_product.classList.add('selected_border')

              var add_cart = document.getElementById('add_cart_' + p.ProductId);
              add_cart.classList.add('hide');

              var plus_minus = document.getElementById('plus_minus_' + p.ProductId);
              plus_minus.classList.remove('hide');
              //אנימציה של הוספה לסל
              this.storeLinksCom.addToCartAnimate()
            }
            else {
              //הצגת שגיאה
              alert(data["content"]);
            }
          },
          err => { console.log(err.message) }
        )
      }
    }
    else {
      //רק הוספה מקומית
      // let res = this.csCart.addProductToTempCartInService(this.store, p, 1);
      // if (res != true) {
      //   alert(res)
      // }
      // else {
      //   var div_product = document.getElementById('div_product_' + p.ProductId).getElementsByClassName('div_product')[0];
      //   div_product.classList.add('selected_border')

      //   var add_cart = document.getElementById('add_cart_' + p.ProductId);
      //   add_cart.classList.add('hide');

      //   var plus_minus = document.getElementById('plus_minus_' + p.ProductId);
      //   plus_minus.classList.remove('hide');
      // }
      alert('רק לקוחות רשומים יכולים לרכוש מוצרים');

    }
    // let res=this.csCart.addProductToTempCart(this.store,p,1,storeCustomerId);
    // if(res==true)
    // {
    //   this.isProductAddedToCart=true;
    //   alert('המוצר התווסף לסל')
    // }
    // else
    // {
    //   alert(res)
    // }
  }

  //הוספת כמות למוצר
  prodIdToAdd: number = 0;

  addQuantityToProd(productId: number) {
    //לחיצה שוב על ה+ לא מאופשרת
    //..
    this.prodIdToAdd = productId
    let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

    if (sc != null) {
      this.csCartSer.getAddQuantityToProductInTempCart(sc.StoreCustomerId, productId).subscribe(
        data => {
          if (data == true || data == 'true') {
            //-------- Unusual code
            // this.csCartSer.getAddQuantityToProductInTempCartInService(this.store.StoreId, productId);
            //שינוי הכמות הנראית לעין
            //בHTML
            let addDiv = document.getElementById('prod_quantity_' + productId);
            addDiv.innerHTML = parseInt(addDiv.innerHTML) + 1 + "";
          }
          else {
            alert(data)
          }
          this.prodIdToAdd = 0;
        },
        err => {
          console.log(err.message)
        }
      )
    }
    else {
      //-------- Unusual code
      // this.csCartSer.getAddQuantityToProductInTempCartInService(this.store.StoreId, productId);
      this.prodIdToAdd = 0;
      //שינוי הכמות בדף HTML
      let addDiv = document.getElementById('prod_quantity_' + productId);
      addDiv.innerHTML = parseInt(addDiv.innerHTML) + 1 + "";
    }
  }
  //הפחתת כמותת ממוצר
  productIdToReduce: number = 0
  reduceQuantityToProd(productId: number) {
    let addDiv = document.getElementById('prod_quantity_' + productId);
    //בדיקה אם הכמות היא יותר מ0 ואז אפשר להוריד וגם להוריד את העיצוב
    if (parseInt(addDiv.innerHTML) > 1) {
      //לחיצה שוב על ה- לא מאופשרת
      //
      this.productIdToReduce = productId;
      let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
      if (sc != null) {
        this.csCartSer.getReduceQuantityFromProductInTempCart(sc.StoreCustomerId, productId).subscribe(
          data => {
            if (data == true || data == 'true') {
              //-------- Unusual code
              // this.csCartSer.getReduceQuantityFromProductInTempCartInService(this.store.StoreId, productId);
              //שינוי הכמות בדף HTML
              addDiv.innerHTML = parseInt(addDiv.innerHTML) - 1 + "";
            }
            else {
              alert(data)
            }
            this.productIdToReduce = 0;
          },
          err => {
            console.log(err.message)
          }
        )
      }
      else {
        //-------- Unusual code
        // this.csCartSer.getReduceQuantityFromProductInTempCartInService(this.store.StoreId, productId);
        this.productIdToReduce = 0;
        //שינוי הכמות בדף HTML
        addDiv.innerHTML = parseInt(addDiv.innerHTML) - 1 + "";
      }
    }
  }

  //הצגת מוצר בודד
  goToSingleProduct(productId: number, sku: number) {
    this.router.navigate(['/store-links/' + this.storeAddressId + '/product/' + productId + "/" + sku])
  }



  getNumberAsNis(num) {
    let dig = (num + "").split('.');
    if (!dig[1])
      return num + '.00'
    else
      if (dig[1] != null && dig[1].length < 2)
        return num + '0';
      else
        return num
  }
  // קבלת מספר הטעינות הנדרשות עבור כל המוצרים בכל טעינה 20 מוצרים
  // ↓
  getNumberOfLoads() {
    //חישוב מספר הפעמים שיצטרכו לטעון את המוצרים מהשרת
    if (this.productsNumInStore % this.numOfProductsPerLoads != 0)
      this.numberOfLoads = this.productsNumInStore / this.numOfProductsPerLoads + 1;
    else
      this.numberOfLoads = this.productsNumInStore / this.numOfProductsPerLoads;
    // ביטול העשרוני כלומר הפיכת המספר לשלם ללא עיגול המספר
    this.numberOfLoads = Math.trunc(this.numberOfLoads * 1) / 1;
  }


  // ------------מאפיינים--------

  // משתנה זמני של מוצר עם מאפיינים
  pf: ProductDataWithFeature;

  // מיון המוצרים לפי מקט, בחנות שיש לה מוצרים עם מאפיינים
  sortProductsBySku(data: ProductInStore[]) {
    let pSKU;
    let prices = [];
    for (let i = 0; i < data.length; i++) {
      pSKU = data[i]['SKU'];
      let prod = this.productsBySKU.find(x => x.SKU == pSKU);
      if (prod == null) {
        this.pf = new ProductDataWithFeature();
        this.pf.SKU = data[i]['SKU'];
        this.pf.Products.push(data[i]);
        this.productsBySKU.push(this.pf);
        this.products.push(data[i]);
      }
      else {
        if (this.pf.SKU != pSKU) // המוצרים שמורים במסד נתונים לא לפי סדר המקטים
          this.pf = this.productsBySKU.find(x => x.SKU == pSKU);
        //הוספת לרשימת מוצרים במערך פרטי מוצר
        this.pf.Products.push(data[i]);
      }
    }
    // מעבר על רשימת פרטי המוצרים עם מאפיינים ומציאת מחיר מינמלי ומקסימלי
    this.productsBySKU.forEach(p => {
      if (p.Products[0].MaxPrice == null) {// במקרה של טעינה נוספת למנוע את החיפוש שוב
        for (let i = 0; i < p.Products.length; i++) {
          prices.push(p.Products[i].Price);
        }
        p.Products[0].MinPrice = Math.min(...prices);
        p.Products[0].MaxPrice = Math.max(...prices);
        prices = [];
      }
    });
    return this.productsBySKU;

  }

  //בדיקה האם קימים למוצר מאפיינים
  // ↓
  isExistsFeatures(p: ProductInStore) {
    //בדיקה אם הלקוח רשום
    var storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    if (storeCustomer == null)
      alert('רק לקוחות רשומים יכולים לרכוש מוצרים');
    else {
      if (p.ProductFeature.length > 0) { //קיימים מאפיינים נוספים למוצר
        this.router.navigate(['/store-links/' + this.storeAddressId + '/product/' + p.ProductId + "/" + p.SKU])
      }
      else {//אין מאפיינים
        //שליחה לפונקצית הוספת מוצר לסל
        this.addProductToCart(p);
      }
    }
  }


  // --
  // אפשרויות סינון על המוצרים
  filterObj: StoreProductsFilter = new StoreProductsFilter();
  value: number = 0;
  highValue: number = 0;
  options: Options = {
    floor: 0,
    ceil: 0
  };
  openFilterOptions() {
    this.displayFilters = !this.displayFilters;
    this.options.floor = this.filterObj.FromSum;
    this.options.ceil = this.filterObj.ToSum;
    //יבוא קטגוריות לחנות מסוימת מהשרת
    if (this.categories == null && this.displayFilters == true) {
      this.categorySer.getFirst10CategoriesByStore(this.storeId).subscribe(
        data => {
          this.categories = data;
        },
        err => { console.log(err.message) }
      )

    }

    if (this.displayFilters == false) {
      this.cancelFiltering();
    }
  }

  cancelFiltering() {
    this.displayFilters = false;
    this.filterObj = new StoreProductsFilter();
    this.csProductInStoreSer.setFilterObjInServise(null);
    this.counter = 1;
    this.products = [];
    this.productsBySKU = [];
    this.isLoaded = true;
    //איפוס אוביקט סינון מהסרביס
    this.csProductInStoreSer.setFilterObjInServise(null);
    this.getProductsInStore();
  }



  getAllCategories() {
    this.categorySer.getCategoryByStore(this.storeId).subscribe(
      data => {
        this.allCategories = data;
        this.displayAllCategories = true;
      },
      err => { console.log(err.message) }
    )
  }

  //--
  getProductsInStoreByFilter() {
    this.csProductInStoreSer.getProductsByFilter(this.storeAddressId, this.counter, this.numOfProductsPerLoads, this.filterObj).subscribe(
      data => {
        this.productsNumInStore = data.countAll;
        //חישוב מספר טעינות
        this.counter = this.csProductInStoreSer.getNumberOfPagesLoaded();//שליפת מספר הטעינות
        this.numOfProductsPerLoads = 20;
        this.getNumberOfLoads();
        //עדכון המשתנה האם קיים מוצר עם מאפיינים
        for (let i = 0; i < data.products.length && this.isExistsProductsWithFeature != 1; i++) {
          (data.products[i].ProductFeature.length > 0) ? this.isExistsProductsWithFeature = 1 : this.isExistsProductsWithFeature = 0;
        }
        //במקרה שאין בחנות מוצרים עם מאפיינים
        if (this.isExistsProductsWithFeature != 1)
          this.products = data.products;
        else {//יש בחנות מוצרים עם מאפיינים
          this.products = [];
          this.productsBySKU = [];
          //קריאה לפונקציה שממינת את המוצרים לפי מקט
          this.productsBySKU = this.sortProductsBySku(data.products);
        }
      },
      err => { console.log(err.message); }
    )
  }

  // --
  // בדיקה האם המשתמש ביצע סינונים בעבר
  checkIsFilterSelected() {
    this.filterObj = this.csProductInStoreSer.getFilterObjFromService();
    if (this.filterObj != null) {
      this.getStore();
      // קבלת קטגוריות לסינון
      this.openFilterOptions();

      //בדיקת מספר מוצרים שנטענו
      // המשתמש ביצע טעינות נוספות, מקרה קצה בעת רענון הדף
      //↓
      if (this.counter != 1) {
        this.numOfProductsPerLoads = this.counter * 20;
        this.counter = 1;
      }
      //קבלת המוצרים לפי סינון
      this.getProductsInStoreByFilter();
      return true
    }
    else {
      this.filterObj = new StoreProductsFilter();
      return false;
    }

  }


  // סנן מוצרים
  filterAll() {
    this.filterObj.CategoriesIds = [];
    //בדיקת מזהי קטגוריות לסינון שנבחרו
    // ↓
    var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
    for (var i = 0; i < checkboxes.length; i++) {
      this.filterObj.CategoriesIds.push(parseInt((checkboxes[i] as HTMLInputElement).value));
    }
    // הסרת כפולים מהמערך
    this.filterObj.CategoriesIds = this.filterObj.CategoriesIds.filter((v, id) => this.filterObj.CategoriesIds.findIndex(categoryId => categoryId == v) === id);
    // this.filterObj.CategoriesIds.splice(this.filterObj.CategoriesIds.indexOf(0), 1);

    //בדיקת סינון לפי מחיר
    // ↓
    if (this.filterObj.isFilterByPrice) {
      if ((document.getElementById('high_to_low_price') as HTMLInputElement).checked) {
        this.filterObj.HighToLowPrice = true; this.filterObj.LowToHighPrice = false;
      } else {
        this.filterObj.HighToLowPrice = false; this.filterObj.LowToHighPrice = true;
      }
    }
    //סינון לפי טווח מחירים


    // שמירת אובייקט הסינון local-storage,session-storage
    this.csProductInStoreSer.setFilterObjInServise(this.filterObj);

    this.counter = 1;
    this.csProductInStoreSer.setNumberOfPagesLoaded(this.counter);
    //קבלת המוצרים לפי סינון
    this.getProductsInStoreByFilter();
  }

  // טעינה נוספת של מוצרים לפי סינונים
  // ↓
  loadMoreByFilter() {
    this.counter++;
    //שמירת מספר טעינות/דפים שהמשתמש טען
    this.csProductInStoreSer.setNumberOfPagesLoaded(this.counter);
    if (this.counter <= this.numberOfLoads) {
      this.isLoaded = true;
      this.csProductInStoreSer.getProductsByFilter(this.storeAddressId, this.counter, this.numOfProductsPerLoads, this.filterObj).subscribe(
        data => {
          this.isLoaded = false
          if (this.isExistsProductsWithFeature != 1) {
            data.forEach(element => {
              this.products.push(element);
            });
          }
          else {
            //מיון המוצרים שנטענו לפי מקט
            this.productsBySKU = this.sortProductsBySku(data);
          }
        },
        err => { console.log(err.message); }
      )
    }
  }

  // onInputFromPriceSilder(event) {
  //   this.priceRange[0] = event.target.value;
  //   debugger;
  // }
  // onInputToPriceSilder(event) {
  //   this.priceRange[1] = event.target.value;
  // }
}



  //קוד לא שימושי
  // data.forEach(p => {
  //   p['Price'] = this.getNumberAsNis(p['Price'])
  // });
  //   let prices = this.products.map(function(element) {
  //     return element.Price;
  //  });
  //   this.rangeValues[0]=Math.min(...prices);
  //   this.rangeValues[1]=Math.max(...prices);

  // addProductToCartFocus(p:ProductInStore)
  // {
  //   var div_product=document.getElementById('div_product_'+p.ProductId);// as HTMLElement;
  //   div_product.setAttribute('style','border: 0.5px solid #FBB800 !important;')
  //   this.csCart.addProductToTempCart(p,1);
  //   alert('המוצר התווסף לסל')
  // }

