import { Category } from './category';

export class SubCategory {
    constructor(public SubCategoryId:number=0,
        public  SubCategoryName:string="",
        public SubCategoryImage:string="",  
        public CategoryId:number=null,
        public CategoryName:string="",
        public Category:Category=null,
        public SubCategoryIndexInStore:number=null,

    ){

        }
}
