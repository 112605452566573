import { ProductInStore } from './product-in-store';

export class ProductsInBuying {
    constructor(public  ProductInBuyingId:number=0,
        public  ProductId:number=0,
        public  BuyingId:number=0,
        public  Quantity:number=0,
        public  ItemPrice:number=0,
        public Sum:number=0.0,
        public ProductInStore:ProductInStore=null
        )
    {

    }
}
