import { Component, OnInit } from '@angular/core';
import { UserLogin } from 'src/app/classes/user-login';
import { Router } from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { ResailerService } from 'src/app/services/resailer.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-manager-login',
  templateUrl: './manager-login.component.html',
  styleUrls: ['./manager-login.component.css']
})
export class ManagerLoginComponent implements OnInit {

  constructor(private router:Router,
    private managerSer:ManagerService,
    private resailerSer:ResailerService,
  private authSer:AuthService) {

    //עבור הדף שגיאה
    sessionStorage.setItem('status','admin')
   }

  ngOnInit() {
  }

  isManagerExists:Boolean=true
  userLogin:UserLogin=new UserLogin();

  isClicked:Boolean=false


  login()
  {
    this.isClicked=true
    this.authSer.managerLogin(this.userLogin).subscribe(
      data =>  {
          //sessionStorage.setItem('token',data.access_token);
          this.authSer.setAdminToken(data.access_token)
          //המנהל קיים במערכת

          this.managerSer.getManagerByManagerUserNameAndPassword(this.userLogin).subscribe(
            data=>{
                  //שמירה בסרביס
                  this.managerSer.setCurrManager(data);

                  this.router.navigateByUrl("/admin-home-page/admin-resailers");

            },
            err=>{console.log(err.message)}
          )

      },
        err=>{
          //המנהל אינו קיים במערכת
          //this.messageService.add({severity:'error', summary:'מצטערים', detail:'אינך יים במערכת'});
          this.isManagerExists=false
          this.isClicked=false
          console.log(err.message)
        }
    );
  }



  loginNode()
  {
    this.isClicked=true
    this.authSer.nodeJsAdminLogin(this.userLogin).subscribe(
      data=>{
        //המנהל קיים במערכת
        this.authSer.setAdminToken(data.token)

        //שמירה בסרביס
        this.managerSer.setCurrManager(data.admin);

        this.router.navigateByUrl("/admin-home-page/admin-resailers");
      },
      err=>{
          //המנהל אינו קיים במערכת
          this.isManagerExists=false
          this.isClicked=false
          console.log(err.message)
      }
    )
  }

}
