import { Component, OnInit } from '@angular/core';
import { StoreLinksComponent } from '../store-links/store-links.component';

@Component({
  selector: 'app-page-in-build',
  templateUrl: './page-in-build.component.html',
  styleUrls: ['./page-in-build.component.css']
})
export class PageInBuildComponent implements OnInit {

  constructor(private storeLinksCom: StoreLinksComponent) {
    storeLinksCom.changeDasignLink('page_in_build');
  }

  ngOnInit() {
  }



}
