export class BuyingDetail {
  constructor(
    public BuyingDetailId: number = null,
    public BuyingId: number = null,
    public DetailDescription: string = null,
    public DetailName: string = null,
    public ProductId: number = null,
    public Quantity: number = null,
    public ItemPrice: number = null,
    public DetailSum: number = null,
  ) {

  }
}
