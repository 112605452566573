import { Customers } from './customers';

export class StoreCustomers {
    constructor(
        public StoreCustomerId: number = 0,
        public StoreId: number = 0,
        public CustomerId: number = 0,

        public FirstName: string = null,
        public LastNameOrCompany: string = null,
        public PhoneNumber: string = null,
        public HousePhoneNumber: string = null,
        public AnotherPhoneNumber: string = null,
        public Email: string = null,
        public City: string = null,
        public Street: string = null,
        public NumHouse: number = null,
        public NumApartment: number = null,
        public PostalCode: string = null,
        public Entrance: string = null,

        public EmailPublications: Boolean = true,
        public PhonePublications: Boolean = true,
        public SmsPublications: Boolean = true,
        public JoinDate: Date = new Date(),
        public IsCustomerEnable: Boolean = true,

        ////מתוך טבלת לקוחות
        // public  customerName:string="",
        // public  phonNumber:string="",
        // public  customerEmail:string="",
        // public  customerAddress:string="",
        public Customers: Customers = null
    ) {

    }
}
