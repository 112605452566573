import { Component, OnInit } from '@angular/core';
import { BuyingService } from '../../../services/buying.service';
import { CSStoreCustomerService } from '../../../services/customersiteservices/csstore-customer.service';
import { StoreCustomers } from '../../../classes/store-customers';
import { Buying } from '../../../classes/buying';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { Store } from '../../../classes/store';
import { StoreLinksComponent } from '../store-links/store-links.component';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigModule } from 'src/app/config/config.module';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store-shopping-history',
  templateUrl: './store-shopping-history.component.html',
  styleUrls: ['./store-shopping-history.component.css']
})
export class StoreShoppingHistoryComponent implements OnInit {

  storeCustomer: StoreCustomers = null;
  buyings: Buying[] = []
  store: Store = null
  storeAddressId;
  buyingIdToShowDetails: number = null;
  displayStorePss: boolean = false;
  displayNotSignInToStore: boolean = false;
  constructor(private buyingSer: BuyingService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private csStoreSer: CSStoreService,
    private storeLinksCom: StoreLinksComponent,
    private authSer: AuthService,
    private activatedRoute: ActivatedRoute,
    private storeSer: StoreService) {

    storeLinksCom.changeDasignLink('store_history_link');

    this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();

    this.storeCustomer = csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId)
    if (this.storeCustomer == null) {
      this.displayNotSignInToStore = true;
    }

    this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
    if (this.storeAddressId == null) {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 2])
      this.storeAddressId = getCurrStoreAddressIdInService
    }
    //בדיקה האם אומתה סיסמת לקוח לחנות
    if (!this.storeSer.getIsStorePassVerifiedInService()) {
      //בדיקה האם קימת סיסמת לקוח לחנות
      this.storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
        data => {
          if (data == true) {//יש סיסמת לקוחות בחנות
            //הצגת הודעה של הזנת סיסמא
            this.displayStorePss = true;
          }
          else {//קבלת הקניות אם המשתמש מחובר
            if (this.storeCustomer != null) {
            this.getShoppingHistory()
           }
          }
        },
        err => {
          console.log(err);
        }
      )
    } else {//קבלת הקניות אם המשתמש מחובר
      if (this.storeCustomer != null) {
        this.getShoppingHistory()
       }

    }





  }

  ngOnInit() {
  }


  getShoppingHistory() {
    this.store = this.csStoreSer.getCurrStoreFromService();
    if (this.store == null) {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          this.store = data;
          //הבאת הקניות
          this.buyingSer.getBuyingsByCustomerIdAndStoreId(this.storeCustomer.StoreCustomerId, this.store.StoreId).subscribe(
            data => {
              //מיון לפי תאריך
              data.sort(function (a, b): any {
                return (new Date(b.BuyingDate).getTime() - new Date(a.BuyingDate).getTime());
              });
              //בדיקה כמה מוצרים וכמה פריטים יש בקנייה
              this.buyings = data;
              let id = 0;

              this.buyings.forEach(b => {
                b.BuyingNumber = ++id;

                b.CountItems = 0;
                b.CountProducts = 0;
                b.ProductsInBuying.forEach(el => {
                  b.CountItems += el.Quantity;
                  b.CountProducts++;
                });
              });
            },
            err => {
              console.log(err.message)
            }
          )

        },
        err => {
          console.log(err.message)
        }
      )
    }
    else {
      //הבאת הקניות
      this.buyingSer.getBuyingsByCustomerIdAndStoreId(this.storeCustomer.StoreCustomerId, this.store.StoreId).subscribe(
        data => {

          //מיון לפי תאריך
          data.sort(function (a, b): any {
            return (new Date(b.BuyingDate).getTime() - new Date(a.BuyingDate).getTime());
          });
          this.buyings = data;

          this.buyings = data;
          let id = 0;
          //בדיקה כמה מוצרים וכמה פריטים יש בקנייה
          this.buyings.forEach(b => {
            b.BuyingNumber = ++id;

            b.CountItems = 0;
            b.CountProducts = 0;
            b.ProductsInBuying.forEach(el => {
              b.CountItems += el.Quantity;
              b.CountProducts++;
            });
          });

        },
        err => {
          console.log(err.message)
        }
      )
    }
  }

  //הצגת פרטי קנייה אחת
  buyingIdToView: number = 0

  changeBuyingIdToView(id: number) {//להוסיף תנאי אם פתחו שורה אחת לפני שסגרו את הקודמת כי אחרת העיצוב של השורה והחץ נשארים
    if (this.buyingIdToView == id) {
      this.buyingIdToView = 0;
      document.getElementById('i_arrow_' + id).classList.remove('i_arrow_hover');
      document.getElementById('tr_' + id).classList.remove('tr_click');
    }
    else {
      this.buyingIdToView = id
      document.getElementById('i_arrow_' + id).classList.add('i_arrow_hover');
      document.getElementById('tr_' + id).classList.add('tr_click');
    }
  }

  ShowBuyingInStorePop(buyingId: number) {
    this.buyingIdToShowDetails = buyingId;
  }

  hideShoppingDetails(result) {
    this.buyingIdToShowDetails = null;
  }
  isDownloadClicked: boolean = false;
  buyingIdToDown: number = 0;
  downloadSummary(buyingId: number) {
    var x = document.getElementById('div_loading_img') as HTMLElement;
    x.classList.remove('hide')
    this.isDownloadClicked = true;
    this.buyingIdToDown = buyingId


    let role = "Customer"
    this.buyingSer.downloadBuyingSummary(buyingId, this.authSer.getCustomerToken(), role).subscribe(
      data => {
        this.buyingIdToDown = 0;
        var x = document.getElementById('div_loading_img') as HTMLElement;
        x.classList.add('hide');
        this.isDownloadClicked = false;
        var FileSaver = require('file-saver');
        var blob = new Blob([data],
          { type: "application/pdf" });
        FileSaver.saveAs(blob, buyingId + "_" + Date.now());
      },
      err => {
        var x = document.getElementById('div_loading_img') as HTMLElement;
        x.classList.add('hide')
        if ((err.message as string).includes(ConfigModule.errMss))
          // this.appComponent.showDialog();
          console.log(err.message)
      }
    )
  }


}
