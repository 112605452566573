import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SystemMessageByCustomer } from 'src/app/classes/system-message-by-customer';
import { CustomerService } from 'src/app/services/customer.service';
import { ShoppingCenterLinksComponent } from '../../../customer/shopping-center-links/shopping-center-links.component';
import * as $ from 'jquery';
import { Buying } from 'src/app/classes/buying';
import { BuyingService } from 'src/app/services/buying.service';
import { SellerService } from 'src/app/services/seller.service';
import { AuthService } from 'src/app/services/auth.service';
import { Seller } from 'src/app/classes/seller';
import { Customers } from 'src/app/classes/customers';
import { ConfigModule } from 'src/app/config/config.module';
@Component({
  selector: 'app-shopping-history-pop-mobile',
  templateUrl: './shopping-history-pop-mobile.component.html',
  styleUrls: ['./shopping-history-pop-mobile.component.css']
})
export class ShoppingHistoryPopMobileComponent implements OnInit {

  @Input() ShoppingHistoryIdByInput: number;
  @Output() hideBuying = new EventEmitter();

  buyingId: number = null;
  isShowShoppingHistory: boolean = true;
  shoppingHistoryId: number;
  buying: Buying = new Buying();
  customer: Customers = new Customers();
  url = ''

  constructor(private buyingSer: BuyingService, private customerSer: CustomerService, private authSer: AuthService,
    ) {



  }
  ngOnInit() {
    this.buyingId = this.ShoppingHistoryIdByInput;
    //שליפת הקניה מהסרביס
    this.customer = this.customerSer.getCurrCustomerFromService();

    this.buyingSer.getBuyingByBuyingId(this.buyingId, this.authSer.getCustomerToken(), this.customer.CustomerId).subscribe(
      data => {
        //
        let b = data;
        let addPayment = 0.0;
        let bd;
        if (b['BuyingDetail'] != null) {
          bd = b['BuyingDetail'].find(x => x['DetailName'] == 'תוספת תשלום')
          bd != null ? addPayment = bd['DetailSum'] : addPayment = 0.0
        }
        b['AddPayment'] = this.getNumberAsNis(addPayment);
        b['BuyingSum'] = this.getNumberAsNis(b['BuyingSum'])

        b['BuyingSumAll'] = b['ShipmentPrice'] != null ? this.getNumberAsNis(b['ShipmentPrice'] + parseFloat(b['BuyingSum']+"")) : this.getNumberAsNis(b['BuyingSum'])

        let sumProducts = 0.0;
        let countItems = 0;
        let countProducts = 0;

        for (let i = 0; i < b['ProductsInBuying'].length; i++) {
          countItems += b['ProductsInBuying'][i]['Quantity'];
          countProducts++;
          sumProducts += parseFloat((b['ProductsInBuying'][i]['Quantity'] * b['ProductsInBuying'][i]['ItemPrice']).toFixed(2))
          b['ProductsInBuying'][i]['ItemPrice'] = this.getNumberAsNis(b['ProductsInBuying'][i]['ItemPrice'])
        }
        b['CountItems'] = countItems
        b['CountProducts'] = countProducts

        if (b['BuyingDetail'] != null)
          for (let i = 0; i < b['BuyingDetail'].length; i++) {
            if (b['BuyingDetail'][i]['DetailName'] != 'תוספת תשלום') {
              if (b['BuyingDetail'][i]['DetailName'] == 'הנחת אחוזים' || b['BuyingDetail'][i]['DetailName'] == 'הנחת סכום')
                sumProducts -= b['BuyingDetail'][i]['DetailSum']
            }
          }
        b['SumProducts'] = this.getNumberAsNis(sumProducts)


        this.url = ConfigModule.url + 'files/Stores/' + b['Store']['StoreId'] + '/' + b['Store']['ImageToView']

        this.buying = b;
      },
      err => {
        console.log(err.message);
      }
    )
  }

  close() {
    this.isShowShoppingHistory = false;
    //ע"י הEventEmmiter
    this.hideBuying.emit(null)
  }

  getNumberAsNis(num) {
    let dig = (num + "").split('.');
    if (!dig[1])
      return num + '.00'
    else
      if (dig[1] != null && dig[1].length < 2)
        return num + '0';
      else
        return num
  }


  isDownloadClicked: Boolean = false
  buyingIdToDown: number = 0;

  downloadSummary() {

    // var x = document.getElementById('div_loading_img') as HTMLElement;
    // x.classList.remove('hide')
    this.isDownloadClicked = true;
    this.buyingIdToDown = this.buying.BuyingId


    let role = "Customer"
    this.buyingSer.downloadBuyingSummary(this.buying.BuyingId, this.authSer.getCustomerToken(), role).subscribe(
      data => {
        this.buyingIdToDown = 0;
        // var x = document.getElementById('div_loading_img') as HTMLElement;
        // x.classList.add('hide');
        this.isDownloadClicked = false;
        var FileSaver = require('file-saver');
        var blob = new Blob([data],
          { type: "application/pdf" });
        FileSaver.saveAs(blob, this.buying.BuyingId + "_" + Date.now());
      },
      err => {
        // x = document.getElementById('div_loading_img') as HTMLElement;
        //x.classList.add('hide')
        // if ((err.message as string).includes(ConfigModule.errMss))
        // this.appComponent.showDialog();
        console.log(err.message)
      }
    )
  }
}

