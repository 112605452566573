let x = 0;
export class Customers {
    constructor(
        public CustomerNumber: number = x++,
        public CustomerId: number = 0,
        public Email: string = "",
        public CustomerPassword: string = "",
        public CustomerConfirmPassword: string = "",
        public FirstName: string = "",
        public LastNameOrCompany: string = "",
        public PhoneNumber: string = "",
        public City: string = "",
        public Street: string = "",
        public NumHouse: number = null,
        public NumApartment: number = null,
        public PostalCode: string = "",
        public CustomerStatus: Boolean = true,
        public UserName: string = "",
        public IsApprovalToAdmin: boolean = true,
        public Entrance: string = null,
        public RegistrationDate: Date = null,
        public HousePhoneNumber: string = "",
        public AnotherPhoneNumber: string = "",
        public IsPasswordRequired: number = null,
        public IsPhoneVarification: number = null,
        public IdentityNumber:string=""

    ) {

    }
}
