import { Injectable } from '@angular/core';
import { StoreCustomers } from '../classes/store-customers';
import { Observable } from 'rxjs';
import { ConfigModule } from '../config/config.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';

@Injectable({
  providedIn: 'root'
})
export class StoreCustomersService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService) { }

  currStoreCustomer: StoreCustomers = null;
  url = ConfigModule.url + "api/StoreCustomer/"

  //בדיקה על לקוח אם הוא קיים בחנות ספציפית
  checkIfCustomerExistsInStore(customerId, storeId): Observable<StoreCustomers> {
    return this.httpClient.get<StoreCustomers>(this.url + "checkIfCustomerExistsInStore/" + customerId + "/" + storeId)
  }
  //בדיקה אם הלקוח קיים בחנות הספציפית ע"י מספר כתובת חנות לאתר הלקוחות
  checkIfCustomerExistsInStoreByStoreAddressId(customerId, storeAddressId): Observable<StoreCustomers> {
    return this.httpClient.get<StoreCustomers>(this.url + "checkIfCustomerExistsInStoreByStoreAddressId/" + customerId + "/" + storeAddressId)
  }
  //פונקציה להוספת לקוח בחנות ע"י המוכר
  addStoreCustomer(storeAddressId: number, sc: StoreCustomers): Observable<StoreCustomers> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.post<StoreCustomers>(this.url + "addStoreCustomer/" + storeAddressId, sc, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }
   //פונקציה להוספה מרובה של לקוחות בחנות ע"י המוכר
   addManyStoreCustomers(storeAddressId: number, fileToAdd:any): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    const formData: FormData = new FormData();
    formData.append('file', fileToAdd, fileToAdd.name);
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    };
    return this.httpClient.post<any>(this.url + "addManyStoreCustomers/" + storeAddressId, formData, options)
  }
  addStoreCustomerByStoreAdressId(sc: StoreCustomers, storeAddressId): Observable<StoreCustomers> {
    return this.httpClient.post<StoreCustomers>(this.url + "addStoreCustomerByStoreAdressId/" + storeAddressId, sc)
  }


  getStoreCustomersByStore(storeId: number): Observable<StoreCustomers[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId
    return this.httpClient.get<StoreCustomers[]>(this.url + "getStoreCustomersByStore/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  getStoreCustomerByStoreCustomerId(storeCustomerId: number): Observable<StoreCustomers> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId
    return this.httpClient.get<StoreCustomers>(this.url + "getStoreCustomerByStoreCustomerId/" + storeCustomerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  // editStoreCustomer() {

  // }

  enableDisableCustomer(custId, storeId): Observable<void> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<void>(this.url + "enableDisableCustomer/" + custId + "/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //פונקציה לעריכת פרטי לקוח בחנות ע"י המוכר
  editStoreCustomerByStoreCustomerId(storeCustomerId, currStoreCustomer: StoreCustomers): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.put<any>(this.url + "editStoreCustomerByStoreCustomerId/" + storeCustomerId, currStoreCustomer, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }


  // פונקציה למחיקת לקוח של חנות ע"י המוכר
  deleteStoreCustomer(storeCustomerId: number): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.delete<any>(this.url + "deleteStoreCustomer/" + storeCustomerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //0 - מאושר
  //1 - טלפון
  //2 - אמייל
  checkIfStoreCustomerPhoneNumbersOrEmailExists(email: string, phone: string, anotherPhoneNumber: string, housePhoneNumber: string, storeId: number, storeCustomerId: number): Observable<number> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId
    return this.httpClient.get<number>(this.url + "checkIfStoreCustomerPhoneNumbersOrEmailExists/" + email + "/" + phone + "/" + anotherPhoneNumber + "/" + housePhoneNumber + "/" + storeCustomerId + "/" + storeId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': sellerId + ''
        })
      })
  }

  postCheckIfManyStoreCustomersPhoneNumbersExists(storeId: number, scArr: StoreCustomers[]): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId
    return this.httpClient.post<any>(this.url + "postCheckIfManyStoreCustomersPhoneNumbersExists/" + storeId,scArr,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': sellerId + '' })
      });
  }

  checkIfPhoneExistsInSystem(phone): Observable<any> {
    return this.httpClient.get<any>(this.url + "checkIfPhoneExistsInSystem/" + phone);
  }

  //לאתר הלקוחות
  setStoreCustomerInService(sc: StoreCustomers) {
    sessionStorage.setItem('storeCustomer', JSON.stringify(sc));
    this.currStoreCustomer = sc;
  }
  getStoreCustomerFromService() {
    if (this.currStoreCustomer == null)
      this.currStoreCustomer = JSON.parse(sessionStorage.getItem('storeCustomer'));
    return this.currStoreCustomer;
  }

  uploadStoreCustomersFromExcel(fileToUpload: File, storeId: number): Observable<StoreCustomers[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    };

    return this.httpClient.post<StoreCustomers[]>(this.url + 'uploadStoreCustomersFromExcel/' + storeId,
      formData,
      options);

  }
}
