import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { Store } from '../classes/store';
import { ShoppingDataToStore } from '../classes/shopping-data-to-store';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {


  constructor(private httpClient: HttpClient,
    private authSer: AuthService) {
  }

  //קוד חנות שאליו נכנס הקונה
  currStoreIdInBuying: number
  //החנות שהקונה נכנס אליה
  currStoreInBuying: Store = null;


  ///קוד חנות שאליו המוכר נכנס
  private currStore: any = null;

  private allStores: Store[] = null;
  // חניות והקנית של המוכר הנוכחי
  private storesAndBuings: Store[] = null;

  getAllStoresFromService(): Store[] {
    if (this.allStores == null && sessionStorage.getItem('stores'))
      this.allStores = JSON.parse(sessionStorage.getItem('stores'))
    return this.allStores;
  }
  setAllStoresInService(s: Store[]) {
    this.allStores = s;
    sessionStorage.setItem('stores', JSON.stringify(s));
  }
  addStoreToStoresInService(s: Store) {
    if (this.allStores == null)
      this.allStores = JSON.parse(sessionStorage.getItem('stores'));
    this.allStores.push(s);
    sessionStorage.setItem('stores', JSON.stringify(this.allStores))
  }
  editStoreInStoresInService(s: Store) {
    if (this.allStores == null)
      this.allStores = JSON.parse(sessionStorage.getItem('stores'));

    let index = this.allStores.findIndex(x => x.StoreId == s.StoreId);
    this.allStores[index] = s;
    sessionStorage.setItem('stores', JSON.stringify(this.allStores))
  }

  getCurrStoreBasicDetailsInService() {
    if (this.currStore == null)
      this.currStore = JSON.parse(sessionStorage.getItem('currStore'))
    return this.currStore;
  }
  setCurrStoreBasicDetailsInService(store) {
    let basic = null;
    if (store != null)
      basic = { "StoreId": store.StoreId, "StoreName": store.StoreName ,"StoreAddressId":store.StoreAddressId }
    this.currStore = basic;
    sessionStorage.setItem('currStore', JSON.stringify(basic));
  }
  getCurrStoreFromService(id: number) {
    if (this.allStores == null)
      this.allStores = JSON.parse(sessionStorage.getItem('stores'))
    return this.allStores.find(x => x.StoreId == id);
  }

  deleteStoreInService(id) {
    if (this.allStores == null)
      this.allStores = JSON.parse(sessionStorage.getItem('stores'))
    let index = this.allStores.findIndex(x => x.StoreId == id);
    this.allStores.splice(index, 1);
    sessionStorage.setItem('stores', JSON.stringify(this.allStores))

  }
  getStoreByStoreIdFromService(storeId): Store {
    if (this.allStores == null)
      this.allStores = JSON.parse(sessionStorage.getItem('stores'));
    return this.allStores.find(x => x.StoreId == storeId);
  }

  url = ConfigModule.url + "api/Store/";

  //קבלת כל החנויות למוכר
  getStoresToSeller(sellerId, token): Observable<Store[]> {

    return this.httpClient.get<Store[]>(this.url + "getStoresBySeller/" + sellerId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token
          , 'UserId': sellerId + ''
        })
      })
  }
  //קבלת חנות לפי מזהה חנות
  getStoreByStoreId(storeId: number,userId): Observable<any> {
    return this.httpClient.get<any>(this.url + "getStoreByStoreId/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken(),
        'UserId': userId + ''
      })
    })
  }

  //הוספת חנות חדשה
  addStore(store: Store, sellerId: number): Observable<Store> {
    return this.httpClient.post<Store>(this.url + "addStore/" + sellerId, store, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
        'UserId': sellerId + ''
      })
    })
  }
  //עריכת חנות
  editStore(store: Store, storeId: number, userId): Observable<Store> {
    return this.httpClient.put<Store>(this.url + "editStore/" + storeId, store,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': userId + ''
        })
      })
  }
  //מחיקת חנות
  deleteStore(id, userId: number): Observable<void> {
    return this.httpClient.delete<void>(this.url + "deleteStore/" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
          'UserId': userId + ''
        })
      })
  }

  //העלאת תמונה כל שהיא לחנות
  //הקוד מבטא את המטרה שלשמה העלתה התמונה
  addPicToStore(image: File, storId, userId: number): Observable<string> {

    const formData: FormData = new FormData();
    formData.append('Image', image, image.name);

    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
        'UserId': userId + ''
      }),
      responseType: 'text' as 'json'
    }
    return this.httpClient.post<string>(this.url + 'addPicToStore/' + storId, formData, options);
  }



  // //קבלת כל החניות והקניות שהתבצעו בהן למוכר
  // getStoresBySeller(sellerId):Observable<Store[]>
  // {
  //    ;
  //     return this.httpClient.post<Store[]>(this.url+"getStoresBySeller",sellerId,{headers:new HttpHeaders({'Authorization':'Bearer '+sessionStorage.getItem('token')})})
  // }


  // //שמירת החניות והקניות של מוכר בסרביס
  // getAllStoresAndBuyingsFromService():Store[]{
  //   if(this.storesAndBuings==null)
  //   this.storesAndBuings=JSON.parse(sessionStorage.getItem('storesAndBuings'))
  //   return this.storesAndBuings;
  // }

  // setAllStoresAndBuyingsInService(s:Store[])
  // {
  //   this.storesAndBuings=s;
  //   sessionStorage.setItem('storesAndBuings',JSON.stringify(s));
  // }

  deletePics(str: string[], storeId: number, userId: number): Observable<void> {
    return this.httpClient.post<void>(this.url + "deletePics/" + storeId, str,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
          'UserId': userId + ''
        })
      })
  }

  getAllActiveStores(): Observable<Store[]> {
    return this.httpClient.get<Store[]>(this.url + "getAllActiveStores")
  }

  getAllStoresInSite(): Observable<Store[]> {
    return this.httpClient.get<Store[]>(this.url + "getAllStoresInSite")
  }

  getStoreAddressIdByStoreNameInSite(name: string): Observable<number> {
    return this.httpClient.get<number>(this.url + "getStoreAddressIdByStoreNameInSite/" + name)
  }

  //קבלת חנות לפי שם חות באתר
  getStoreByStoreNameInSite(nameInSite): Observable<Store> {
    return this.httpClient.get<Store>(this.url + "getStoreByStoreNameInSite/" + nameInSite)
  }
  //בדיקה אם נדרשת סיסמת לקוח לחנות
  checkIsStorePssExists(storeAddressId): Observable<any> {
    return this.httpClient.get<any>(this.url + "checkIsStorePssExists/" + storeAddressId
    )
  }
  //בדיקה אם נדרשת סיסמת לקוח לחנות
  checkIsStorePssCorrect(storeAddressId, storePassword): Observable<any> {
    return this.httpClient.get<any>(this.url + "checkIsStorePssCorrect/" + storeAddressId + "/" + storePassword
    )
  }


  private storeDetails: any = null;
  //פרטים של חנות שגולשים בה עכשיו
  setStoreDetailsForCustomer(obj) {
    sessionStorage.setItem('store', JSON.stringify(obj));
    this.storeDetails = obj;
  }
  getStoreDetailsForCustomer(): any {
    this.storeDetails = sessionStorage.getItem('store');
    this.storeDetails = JSON.parse(this.storeDetails);
    //this.storeDetails=sessionStorage.getItem(JSON.parse('store'));
    return this.storeDetails;
  }

  ///הוספת או עריכת חנות
  private storeToAddOrEdit: Store = null;

  getCurrStoreToAddOrEdit(): Store {
    if (this.storeToAddOrEdit == null)
      this.storeToAddOrEdit = JSON.parse(sessionStorage.getItem('storeToAddOrEdit'))

    return this.storeToAddOrEdit;
  }

  setCurrStoreToAddOrEdit(id: number) {
    if (id == 0) {
      this.storeToAddOrEdit = new Store();
    }
    else {
      this.storeToAddOrEdit = this.getStoreByStoreIdFromService(id);

    }
    sessionStorage.setItem('storeToAddOrEdit', JSON.stringify(this.storeToAddOrEdit))
  }

  //בדיקה אם יש כבר שם חנות באתר כמו שהוזן
  getCheckIfStoreNameInSiteExists(storeId, storeNameinSite: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.url + "getCheckIfStoreNameInSiteExists/" + storeId + "/" + storeNameinSite, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }) })
  }

  //עדכון השדה האם קיים מוצר עם מאפין בחנות
  editIsExistFeaturesInStore(storeId, sellerId: number): Observable<number> {
    return this.httpClient.get<number>(this.url + "editIsExistFeaturesInStore/" + storeId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
          'UserId': sellerId + ''
        })
      })
  }

  editStoresOrderToDisplayInSite(stores: Store[]): Observable<any> {

    return this.httpClient.put<any>(this.url + "editStoresOrderToDisplayInSite", stores, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) });
  }


  convertStore(old_s: Store) {
    let new_s: Store = new Store();
    new_s.StoreId = old_s.StoreId;
    new_s.StoreName = old_s.StoreName;
    new_s.ImageToView = old_s.ImageToView
    new_s.BackgroundImage = old_s.BackgroundImage
    new_s.Poster = old_s.Poster
    new_s.StoreAddress = old_s.StoreAddress
    new_s.PhoneNumber = old_s.PhoneNumber
    new_s.Email = old_s.Email
    new_s.ViewEmail = old_s.ViewEmail
    new_s.IsOpen = old_s.IsOpen
    new_s.OpeningDate = old_s.OpeningDate
    new_s.ClosingDate = old_s.ClosingDate
    new_s.SellerId = old_s.SellerId
    new_s.DeliveryType = old_s.DeliveryType
    new_s.DeliveryTypePrice = old_s.DeliveryTypePrice
    new_s.DeliveryPrice = old_s.DeliveryPrice
    new_s.FreeDelivery = old_s.FreeDelivery
    new_s.LimitationNumBuyingByCustomer = old_s.LimitationNumBuyingByCustomer
    new_s.LimitationSumBuyingByBuying = old_s.LimitationSumBuyingByBuying
    new_s.LimitationSumBuyingByCustomer = old_s.LimitationSumBuyingByCustomer
    new_s.LimitationNumProductByBuying = old_s.LimitationNumProductByBuying
    new_s.LimitationNumProductByCustomer = old_s.LimitationNumProductByCustomer
    new_s.LimitationNumItemsByBuying = old_s.LimitationNumItemsByBuying
    new_s.LimitationNumItemsByCustomer = old_s.LimitationNumItemsByCustomer;
    new_s.LimitationCustomerNotRegisteredBySeller=old_s.LimitationCustomerNotRegisteredBySeller;
    new_s.MinCostToBuy = old_s.MinCostToBuy
    new_s.PaymentForPurchase = old_s.PaymentForPurchase
    new_s.DisplaysFinishedProducts = old_s.DisplaysFinishedProducts
    new_s.ShowContactForum = old_s.ShowContactForum
    new_s.About = old_s.About
    new_s.StoreStatus = old_s.StoreStatus
    new_s.ViewStoreInSite = old_s.ViewStoreInSite
    new_s.StoreCategoryId = old_s.StoreCategoryId
    new_s.DisplayProductQuantityInStock = old_s.DisplayProductQuantityInStock
    new_s.StoreCategory = old_s.StoreCategory;
    new_s.SaveBuyingWithoutPaying = old_s.SaveBuyingWithoutPaying;
    new_s.PaidBuyingIsDelivered = old_s.PaidBuyingIsDelivered;
    new_s.StorePassword = old_s.StorePassword;
    new_s.StoreAddressId = old_s.StoreAddressId;
    new_s.SellerPassword = old_s.SellerPassword;
    new_s.StoreNameInSite = old_s.StoreNameInSite;
    new_s.StoreNameForInvoice = old_s.StoreNameForInvoice;
    new_s.StoreIdentityHF = old_s.StoreIdentityHF;
    new_s.SendAutoSummary = old_s.SendAutoSummary;
    new_s.VatCalculation = old_s.VatCalculation;
    new_s.SendEmailEveryOrder = old_s.SendEmailEveryOrder;
    new_s.AddToEmailStockAndBuyingSummary = old_s.AddToEmailStockAndBuyingSummary;
    new_s.BackDaysNumberToSentSummaryBuying = old_s.BackDaysNumberToSentSummaryBuying;
    new_s.MinProductsInBuying = old_s.MinProductsInBuying;
    new_s.MinItemsInBuying = old_s.MinItemsInBuying;
    new_s.BuyingSavingText = old_s.BuyingSavingText;
    new_s.StoreDescription = old_s.StoreDescription
    new_s.CustomColor = old_s.CustomColor
    new_s.IsBuyingDisableForCustomerWithUnPaidBuyings = old_s.IsBuyingDisableForCustomerWithUnPaidBuyings; 0
    return new_s;
  }


  activeStoresForCustomerSite: Store[] = null
  getAllActiveStoresFromService() {
    return this.activeStoresForCustomerSite;
  }
  setAllActiveStoresInService(sts) {
    this.activeStoresForCustomerSite = sts
  }

  getAllStoreName(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.url + "getAllStoreName")
  }

  //שמירת נתון האם אומתה סיסמת חנות
  // יש עדיפות לשמירת משתנה זה בשרת!!!!!!!!!!!
  setIsStorePassVerifiedInService(isVerified) {
    sessionStorage.setItem('isSV', JSON.stringify(isVerified));
  }

  getIsStorePassVerifiedInService() {
    var isVerified = JSON.parse(sessionStorage.getItem('isSV'))
    return isVerified;
  }

}
