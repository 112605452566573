import { Component, OnInit } from '@angular/core';
import { StoreCustomers } from '../../../classes/store-customers';
import { Customers } from '../../../classes/customers';
import { Store } from '../../../classes/store';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { CSStoreCustomerService } from '../../../services/customersiteservices/csstore-customer.service';
import { CustomerService } from '../../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CSCartService } from '../../../services/customersiteservices/cscart.service';
import { ConfigModule } from 'src/app/config/config.module';
import { TempCart } from '../../../classes/temp-cart';
import { StoreService } from 'src/app/services/store.service';
import { StoreCustomersService } from 'src/app/services/store-customers.service';

@Component({
  selector: 'app-store-customer-login',
  templateUrl: './store-customer-login.component.html',
  styleUrls: ['./store-customer-login.component.css']
})
export class StoreCustomerLoginComponent implements OnInit {

  //storeCustomer:StoreCustomers=new StoreCustomers()
  customer: Customers = null
  storeAddressId = null
  storeBasic
  store: Store = new Store()
  customColor: string = "";
  displayStorePss: boolean = false;
  displayNotSignInToStore: boolean = false


  constructor(private csStoreSer: CSStoreService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private customerSer: CustomerService,
    private router: Router,
    private csCartSer: CSCartService,
    activatedRoute: ActivatedRoute,
    storeSer: StoreService,
    private storeCustomersSer: StoreCustomersService) {
    //החנות הספציפית שאליה נכנס המשתמש

    //
    this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
    if (this.storeAddressId == null) {
      activatedRoute.params.subscribe(
        prm => { this.storeAddressId = prm["storeAddressId"] }
      )
    }
    this.customer = this.customerSer.getCurrCustomerFromService()
    if (this.customer == null) {
      this.displayNotSignInToStore = true;
    }
    this.store = csStoreSer.getCurrStoreFromService();
    if (this.store == null || this.store.StoreAddressId != this.storeAddressId) {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          this.store = data;
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          this.storeAddressId = data.StoreAddressId
          //צבע בבחירה אישית
          this.customColor = this.store.CustomColor
          if (this.customColor != null && this.customColor != '') {
            ConfigModule.csCustomColor = this.customColor;
          }
          else {
            this.customColor = ConfigModule.defaultCsCustomColor;
          }

        },
        err => {
          console.log(err.message)
        })
    }
    else {
      this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();
      this.customColor = this.store.CustomColor
      if (this.customColor != null && this.customColor != '') {
        ConfigModule.csCustomColor = this.customColor;
      }
      else {
        this.customColor = ConfigModule.defaultCsCustomColor;
      }
    }

    //בדיקה האם אומתה סיסמת לקוח לחנות
    if (!storeSer.getIsStorePassVerifiedInService()) {
      //בדיקה האם קימת סיסמת לקוח לחנות
      storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
        data => {
          if (data == true) {//יש סיסמת לקוחות בחנות
            //הצגת הודעה של הזנת סיסמא
            this.displayStorePss = true;
          }
          else {

          }
        },
        err => {
          console.log(err);
        }
      )
    } else {

    }

  }

  ngOnInit() {
  }
  isSuccess = false
  storeCustomerExist: StoreCustomers = null;
  isClicked: Boolean = false
  addCustomerToStore(cb1, cb2, cb3) {
    this.isClicked = true
    //בדיקה האם מספר הטלפון קיים
    // בטבלת לקוח בחנות ז"א במקרה שמוכר הכניס את הלקוח

    this.customer.AnotherPhoneNumber = (this.customer.AnotherPhoneNumber != null && this.customer.AnotherPhoneNumber != '') ? this.customer.AnotherPhoneNumber : null;
    this.customer.HousePhoneNumber = (this.customer.HousePhoneNumber != null && this.customer.HousePhoneNumber != '') ? this.customer.HousePhoneNumber : null;
    this.csStoreCustomerSer.checkIfStoreCustomerPhoneNumbersExists(this.customer.PhoneNumber, this.customer.AnotherPhoneNumber, this.customer.HousePhoneNumber, this.store.StoreId).subscribe(
      data => {
        if (data) {

          //שליחה לעריכת לקוח בחנות:
          // הוספה של המזהה של הלקוח לטבלת לקוח בחנות והנתונים של קבלת הודעות מהמערכת
          this.storeCustomerExist = data
          this.storeCustomerExist.EmailPublications = cb1;
          this.storeCustomerExist.PhonePublications = cb2;
          this.storeCustomerExist.SmsPublications = cb3;
          this.storeCustomerExist.CustomerId = this.customer.CustomerId;
          this.csStoreCustomerSer.editStoreCustomer(this.storeCustomerExist.StoreCustomerId, this.storeCustomerExist).subscribe(
            data => {
              if (data == true) {
                this.csStoreCustomerSer.setCurrStoreCustomerInService(this.storeCustomerExist, this.storeAddressId);
                this.isSuccess = true
                setTimeout(() => {
                  this.isSuccess = false
                  this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
                }, 2000);
              }
            },
            err => {
              console.log(err.message)
            }
          )
        }
        else {//אם הלקוח לא רשום בחנות
          //שליחה להוספת לקוח בחנות
          let customer: Customers = new Customers();
          customer = this.customerSer.getCurrCustomerFromService();
          let storeCust: StoreCustomers = new StoreCustomers(null, null, customer.CustomerId, customer.FirstName,
            customer.LastNameOrCompany, customer.PhoneNumber, customer.HousePhoneNumber, customer.AnotherPhoneNumber,
            customer.Email, customer.City, customer.Street, customer.NumHouse, customer.NumApartment, customer.PostalCode, customer.Entrance);
          storeCust.JoinDate = new Date();
          storeCust.EmailPublications = cb1;
          storeCust.PhonePublications = cb2;
          storeCust.SmsPublications = cb3;
          storeCust.IsCustomerEnable = true;

          this.csStoreCustomerSer.addStoreCustomerByStoreAdressId(storeCust, this.storeAddressId).subscribe(
            data => {
              if (data != null) {
                this.csStoreCustomerSer.setCurrStoreCustomerInService(data, this.storeAddressId);
                this.isSuccess = true
                setTimeout(() => {
                  this.isSuccess = false
                  this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
                }, 2000);
              }

              // --- Useless code ---
              //בדיקה אם יש סל בחנות ספציפית שהוא נכנס אליה ולשמו אותה בשרת
              // let cart = this.csCartSer.getTempCart(this.store.StoreId);
              // if (cart != null && cart.Items > 0)//לבדוק אוךי בכלל יש סל בלי מוצרים
              // {

              //   this.csCartSer.postCompareTempCarts(data.StoreCustomerId, this.store.StoreId, cart).subscribe(
              //     data => {

              //       //localStorage שמירת הסל ב
              //       //רק אם יש יותר 0 מוצרים
              //       if (data.Items > 0)
              //         this.csCartSer.setTempCart(this.store.StoreId, data);

              //       this.isSuccess = true
              //       setTimeout(() => {
              //         this.isSuccess = false
              //         this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
              //       }, 2000);
              //     },
              //     err => {
              //       console.log(err.message)
              //     }
              //   )
              // }
              // else {

              //   this.isSuccess = true
              //   setTimeout(() => {
              //     this.isSuccess = false
              //     this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
              //   }, 2000);
              // }
            },
            err => { console.log(err.message) }
          )
        }
      },
      err => {
        console.log(err.message)
      }
    )


  }

  cancel() {
    this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
  }
}
