import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { TempCart } from '../classes/temp-cart';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';
import { CustomerService } from './customer.service';
@Injectable({
  providedIn: 'root'
})
export class TempCartService {

  url = ConfigModule.url + "api/TempCart/"

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService,
    private customerSer: CustomerService) {

  }
  getAllTempCartByCustomer(customerId: number): Observable<TempCart[]> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;

    return this.httpClient.get<TempCart[]>(this.url + "getAllTempCartByCustomer/" + customerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    })
  }
  //seller
  getAllTempCartsByStore(storeId: number): Observable<TempCart[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<TempCart[]>(this.url + "getAllTempCartsByStore/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //seller,Customer
  getTempCartByStoreCustomerId(storeCustomerId: number, token: string, userId: number): Observable<TempCart> {
    return this.httpClient.get<TempCart>(this.url + "getTempCartByStoreCustomerId/" + storeCustomerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }



  plussProductInTempCart(customerId: number, prodInTempcartId: number): Observable<number> {
    return this.httpClient.get<number>(this.url + "plussProductInTempCart/" + customerId + "/" + prodInTempcartId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getCustomerToken() }) })
  }

  minusProductInTempCart(customerId: number, prodInTempcartId: number): Observable<number> {
    return this.httpClient.get<number>(this.url + "minusProductInTempCart/" + customerId + "/" + prodInTempcartId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getCustomerToken() }) })
  }
  deleteProductInTempCart(customerId: number, prodInTempcartId: number): Observable<number> {
    return this.httpClient.get<number>(this.url + "getDeleteProductInTempCart/" + customerId + "/" + prodInTempcartId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getCustomerToken() }) })
  }
  deleteTempCart(storeCustomerId: number): Observable<any> {
    let userId;
    if (this.customerSer.getCurrCustomerFromService() == null)
      userId = this.sellerSer.getCurrentSeller().SellerId
    else
      userId = this.customerSer.getCurrCustomerFromService().CustomerId;

    return this.httpClient.get<any>(this.url + "getDeleteTempCart/" + storeCustomerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken(),
        'UserId': userId + ''
      })
    })
  }

  //פונקציה לשמירת קנייה
  //עבור אתר הלקוחות ו עבור מוכר
  //כדי להפוך קנייה לנשמרה באתר המוכרים וכדי לסיים קנייה באתר הלקוחות
  //
  // addCartToDB(storeId, storeCustomerId, branchIndex, isDelivery, userId, role,
  //   ): Observable<any> {
  //   //storeId, storeCustomerId, branchIndex, isDelivery
  //   let token
  //   if (role == 'Customer')
  //     token = this.authSer.getCustomerToken()
  //   else if (role == 'Seller')
  //     token = this.authSer.getSellerToken()
  //   return this.httpClient.get<any>(this.url + "addCartToDB/" + storeId + "/" + storeCustomerId + "/" +
  //     branchIndex + "/" + isDelivery+"/"+role, {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer ' + token,
  //       'UserId': userId + ''
  //     })
  //   });
  // }

  addCartToBuying(buying, isDelivery, paymentLoginId, userId, role): Observable<any> {
    let token
    if (role == 'Customer')
      token = this.authSer.getCustomerToken()
    else if (role == 'Seller')
      token = this.authSer.getSellerToken()
    return this.httpClient.post<any>(this.url + "addCartToBuying/" + isDelivery + "/" + paymentLoginId, buying, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'UserId': userId + ''
      })
    });

  }

  //seller,Customer
  // פונקציה לבדיקת תקינות של הסל
  checkIfTempCartIsValid(storeCustomerId, storeId, role, userId) {
    let token
    if (role == 'Customer')
      token = this.authSer.getCustomerToken()
    else if (role == 'Seller')
      token = this.authSer.getSellerToken()
    return this.httpClient.get<any>(this.url + "checkIfTempCartIsValidWithErrorDetails/" + storeCustomerId + "/" + storeId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token,
          'UserId': userId + ''
        })
      });
  }
  getFixErrorsInTempCart(storeCustomerId, storeId, role, userId) {
    let token
    if (role == 'Customer')
      token = this.authSer.getCustomerToken()
    else if (role == 'Seller')
      token = this.authSer.getSellerToken()
    return this.httpClient.get<any>(this.url + "getFixErrorsInTempCart/" + storeCustomerId + "/" + storeId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token,
          'UserId': userId + ''
        })
      });
  }

  updateFixErrorsInTempCart(storeCustomerId, storeId, role, userId) {
    let token
    if (role == 'Customer')
      token = this.authSer.getCustomerToken()
    else if (role == 'Seller')
      token = this.authSer.getSellerToken()
    return this.httpClient.get<any>(this.url + "updateFixErrorsInTempCart/" + storeCustomerId + "/" + storeId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token,
          'UserId': userId + ''
        })
      });
  }

  // פונקציה לעדכון משלוח בקניה
  setIsDeliveryOnPurchase(isDelivary: number, storeCustomerId: number): Observable<any> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    return this.httpClient.get<any>(this.url + "setIsDeliveryOnPurchase/" + isDelivary + "/" + storeCustomerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    })
  }

}
