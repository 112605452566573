import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../../services/store.service';
import { Store } from '../../../classes/store';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { CSProductInStoreService } from '../../../services/customersiteservices/csproduct-in-store.service';
import { CSCartService } from '../../../services/customersiteservices/cscart.service';
import { ConfigModule } from '../../../config/config.module';
import { StoreProductsComponent } from '../store-products/store-products.component';

import { from } from 'rxjs';
import { ShoppingCenterLinksComponent } from '../shopping-center-links/shopping-center-links.component';
declare var jQuery:any;
@Component({
  selector: 'app-store-home-page',
  templateUrl: './store-home-page.component.html',
  styleUrls: ['../store-products/store-products.component.css','./store-home-page.component.css']
})
export class StoreHomePageComponent implements OnInit {

  storeId
  //storeNameInSite:string=""
  storeAddressId
  store:Store=new Store()
  url="";
  displayBackgroundImage:boolean=false;
 // constructor(private csStoreSer:CSStoreService){

    // this.storeNameInSite=csStoreSer.getCurrStoreBasicDetailsInService().StoreNameInSite

    // //החנות הספציפית שאליה נכנס המשתמש
    // this.store=csStoreSer.getCurrStoreFromService();
    // if(this.store==null)
    // {
    //     csStoreSer.getStoreByStoreNameInSite(this.storeNameInSite).subscribe(
    //     data=>{
    //       csStoreSer.setCurrStoreInService(data);
    //       this.store=data;
    //     },
    //     err=>{
    //       console.log(err.message)
    //     }
    //     )
    // }


  //}

  constructor(private csStoreSer:CSStoreService,
    private csProductInStoreSer:CSProductInStoreService,
    private csCart:CSCartService) {


    //החנות הספציפית שאליה נכנס המשתמש
    if(this.csStoreSer.getCurrStoreAddressIdInService()!=null)
    {
      this.storeAddressId=this.csStoreSer.getCurrStoreAddressIdInService();
    }
    else
    {
      let href=window.location.href.split('/')
      let getCurrStoreAddressIdInService=decodeURIComponent(href[href.length-2])
      this.storeAddressId=getCurrStoreAddressIdInService
    }

    let s=this.csStoreSer.getCurrStoreFromService();
    if(s==null||s.StoreAddressId!=this.storeAddressId)
    {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
          data=>{
            this.csStoreSer.setCurrStoreInService(data);
            //this.store=data;
            this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
            this.storeId=data.StoreId;
            this.storeAddressId=data.StoreAddressId
            this.store=data;
            this.url=ConfigModule.url+"files/Stores/"+this.storeId+"/"+this.store.BackgroundImage
          },
          err=>{
            console.log(err.message)
          }
        )
    }
    else
    {
      this.storeId=s.StoreId
      this.store=s;
      this.url=ConfigModule.url+"files/Stores/"+this.storeId+"/"+this.store.BackgroundImage;

    }

     //האם לשמור מקום לתמונה בדף הבית של חנות
    if (this.store.BackgroundImage!="") {
      this.displayBackgroundImage=true;
    }

  }
  ngOnInit() {
    var $jq = jQuery.noConflict();
    // $jq(document).ready(function() {
    //   $jq('.multiple-items').slick({
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 3
    //   });
      $jq(document).ready(function() {
      $jq('.div_products_wrapper').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        // asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        focusOnSelect: true,
        infinite: true,
      });
    });


      //
      // if(this.csStoreSer.getCurrStoreBasicDetailsInService()!=null)
      // {
      //   this.storeAddressId=this.csStoreSer.getCurrStoreBasicDetailsInService().StoreAddressId;
      //   this.storeNameInSite=this.csStoreSer.getCurrStoreBasicDetailsInService().StoreNameInSite
      // }
      // // else
      // // {
      // //   /
      // // }
      // let s=this.csStoreSer.getCurrStoreFromService();
      // if(s==null)
      // {
      //   this.csStoreSer.getStoreByStoreNameInSite(this.storeNameInSite).subscribe(
      //       data=>{
      //         this.csStoreSer.setCurrStoreInService(data);
      //         //this.store=data;
      //         this.csStoreSer.setCurrStoreBasicDetailsInService(data);
      //         this.storeId=data.StoreId;
      //         this.store=data
      //         this.url=ConfigModule.url+"files/Stores/"+this.storeId+"/"+this.store.BackgroundImage
      //       },
      //       err=>{
      //         console.log(err.message)
      //       }
      //     )
      // }
      // else
      // {
      //   this.storeId=s.StoreId
      //   this.store=s;
      //   this.url=ConfigModule.url+"files/Stores/"+this.storeId+"/"+this.store.BackgroundImage
      // }
  }

}
