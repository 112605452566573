import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/classes/store';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { ConfigModule } from 'src/app/config/config.module';
import { CustomerService } from 'src/app/services/customer.service';
import { CategoryService } from 'src/app/services/category.service';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { BuyingService } from 'src/app/services/buying.service';
import * as $ from 'jquery';
import { ShoppingCenterLinksComponent } from '../../../components/customer/shopping-center-links/shopping-center-links.component';
import { ShoppingCenterBottomLinksComponent } from '../mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';

@Component({
  selector: 'app-stors',
  templateUrl: './stors.component.html',
  styleUrls: ['./stors.component.css']
})
export class StorsComponent implements OnInit {

  url: string = ""
  allStores: Store[] = [];
  tempStores: Store[] = [];
  connectedCustomers: number = 0;
  displaySiteInBuild: boolean = false;
  ptrn: string = ConfigModule.ptrn;

  constructor(private storeSer: StoreService
    , private router: Router
    , private customerSer: CustomerService,
    private categorySer: CategoryService,
    private csProductInStoreSer: CSProductInStoreService,
    private csStoreSer: CSStoreService,
    private buyingSer: BuyingService,
    private csCartSer: CSCartService,
    private centerLinksCom: ShoppingCenterLinksComponent,
    //private centerBtmLinksCom: ShoppingCenterBottomLinksComponent
  ) {




    centerLinksCom.changeDasignCenteralLink('stores-link');
    centerLinksCom.changeDasignCenteralMobileLink('sidenav-stores-link');
    //centerBtmLinksCom.changeDasignCenteralMobileBtmLink('mobile-stores-link');

    //בדיקה אם זה פעם ראשונה שהלקוח במערכת ואז
    //להראות לו את ההודעה שא"א לבצע קניות באתר

    if (!sessionStorage.getItem('displaySiteInBuild')) {

      this.displaySiteInBuild = true;

      setTimeout(() => {

        this.displaySiteInBuild = false
        sessionStorage.setItem('displaySiteInBuild', "true");

      }, 4000);

    }

    this.url = ConfigModule.url
    //קבלת מספר הלקוחות המחוברים למערכת
    this.connectedCustomers = customerSer.getCountCustomersFromServiceForCustomerSite();
    if (this.connectedCustomers == null) {
      customerSer.getCountConnectedCustomer().subscribe(
        data => {
          this.connectedCustomers = data;
          customerSer.setCountCustomersInServiceForCustomerSit(data)
        },
        err => { console.log(err.message) }
      )
    }
    this.allStores = storeSer.getAllActiveStoresFromService();

    this.tempStores = this.allStores;
    if (this.allStores == null) {
      storeSer.getAllActiveStores().subscribe(
        data => {
          this.allStores = data;
          this.tempStores = this.allStores;
          storeSer.setAllActiveStoresInService(data)
        },
        err => { console.log(err.message) }
      )
    }
    this.ngOnInit()
  }


  search(txt) {
    if (this.tempStores != null)
      this.allStores = this.tempStores
    let res: Store[] = [];
    this.allStores.forEach(element => {
      if (element.StoreName.includes(txt))
        res.push(element);
    });
    this.tempStores = this.allStores;
    this.allStores = res;
  }
  cancelClicked: boolean = false;
  storeAddressIdChecked: number;
  isPasswordCorrect: boolean = true;
  checkIsStorePssExists(s: Store) {
    this.isPasswordCorrect = true;
    if (this.storeAddressIdChecked != s.StoreAddressId && this.cancelClicked == false) {
      this.storeSer.checkIsStorePssExists(s.StoreAddressId).subscribe(
        data => {
          if (data == true) {//יש סיסמת לקוחות בחנות
            $(`.div_store_pss`).removeClass('display');
            $(`#${s.StoreId} .div_store_pss`).addClass('display');
            this.storeAddressIdChecked = s.StoreAddressId;
          } else {
            this.goToStore(s);
          }
        },
        err => {
          console.log(err);
        }
      )
    }
    this.cancelClicked = false;
  }
  storePss: string = "";

  checkIsStorePssCorrect(isValid, s: Store) {
    if (isValid == true) {
      this.storeSer.checkIsStorePssCorrect(this.storeAddressIdChecked, this.storePss).subscribe(
        data => {
          if (data == true) {

            // שמירה בסשין את המשתנה של האם סיסמת חנות אומתה
            this.storeSer.setIsStorePassVerifiedInService(true);
            this.goToStore(s);
          }

          else {
            this.isPasswordCorrect = false;
          }

        },
        err => {
          console.log(err);
        }
      )
    }
  }

  cancel(s: Store) {
    this.cancelClicked = true;
    $(`#${s.StoreId} .div_store_pss`).removeClass('display');
    this.storeAddressIdChecked = null;
  }

  storeAddressIdSaved: number;
  goToStore(s: Store) {
    //   ;
    // let x= {"Name":s.StoreNameInSite,"StoreAddressId":s.StoreAddressId};
    // this.storeSer.setStoreDetailsForCustomer(x);
    //this.storeDetails=storeSer.getStoreDetailsForCustomer();

    //קבלת קוד החנות שהלקוח נכנס בעבר
    this.storeAddressIdSaved = this.csStoreSer.getCurrStoreAddressIdInService()
    //שמירת כתובת החנות אליה נכנס הלקוח בסשן
    this.csStoreSer.setCurrStoreAddressIdInService(s.StoreAddressId);

    this.csStoreSer.setCurrStoreInService(s);

    //מחיקת הקטגוריות השמורות בסשן
    // this.categorySer.setCategoriesByStoreInService(null);
    //בדיקה אם נכנס לחנות שונה מהחנות שהיה בה פעם קודמת
    if (this.storeAddressIdSaved != s.StoreAddressId) {
      //מחיקת מספר הטעינות שהמשתמש טען
      this.csProductInStoreSer.setNumberOfPagesLoaded(null);
      //עדכון מספר המוצרים שיש בסל הקניות הזמני
      this.csCartSer.setTempCartProductsQuantityInService(null);
    }

    this.router.navigate(['/store-links/' + s.StoreAddressId + '/store-products'])


  }


  ngOnInit() {

  }

  // להצגת תפריט סינון לפי קטגוריות
  displayCategoriesMenue: boolean = false;
  showCategoriesMenue() {
    this.displayCategoriesMenue = !this.displayCategoriesMenue;
    $('.i_arrow').css('transform', 'rotate(180deg)')
  }

}
