import { Component, OnInit } from '@angular/core';
import { UserLogin } from 'src/app/classes/user-login';
import { Router } from '@angular/router';
import { ResailerService } from 'src/app/services/resailer.service';
import { AuthService } from '../../../services/auth.service';
import { ResailerRepresentService } from '../../../services/resailer-represent.service';
import { SellerService } from '../../../services/seller.service';

@Component({
  selector: 'app-resailer-login',
  templateUrl: './resailer-login.component.html',
  styleUrls: ['./resailer-login.component.css']
})
export class ResailerLoginComponent implements OnInit {

  constructor(private router:Router,
    private resailerSer:ResailerService,
    private authSer:AuthService,
    private resailerRepresentSer:ResailerRepresentService,
    private sellerSer:SellerService) {

      //עבור הדף שגיאה
      sessionStorage.setItem('status','resailer')
     }

  ngOnInit() {

  }

  isResailerRepresentBlocked:Boolean=false
  isResailerRepresentExists:Boolean=true
  userLogin:UserLogin=new UserLogin();

  //resailer
  isClick:Boolean=false
  login()
  {
    this.isClick=true
    this.authSer.resailerRepresentLogin(this.userLogin).subscribe(
      data =>  {

        var token= data.access_token;
        this.authSer.setResailerToken(token)
        //sessionStorage.setItem('token',token)

        //this.resailerSer.getResailerByUserNameAndPassword(this.userLogin).subscribe(
          this.resailerRepresentSer.getResailerRepresentByUserNameAndPassword(this.userLogin).subscribe(
          data=>{
                  //בדיקה אם המשווק חסום
                  if(data.ResailerRepresent.ResailerRepresentStatus==false
                  ||data.Resailer.ResailerStatus==0)
                  {
                      this.isResailerRepresentBlocked=true;
                      this.isClick=false;
                      sessionStorage.removeItem('resailerToken')
                  }
                  else
                  {
                      this.authSer.setResailerToken(token)
                      //sessionStorage.setItem('token',token)
                      //this.resailerSer.setCurrentResailer(data);
                      this.resailerRepresentSer.setRPInService(data.ResailerRepresent);
                      this.resailerSer.setCurrentResailer(data.Resailer);
                      //this.sellerSer.setCurrSellersOfResailer(data.Resailer.Seller)
                      //מועבר לדף ניהול משווקים
                      this.router.navigate(['/resailer-links/resailer-sellers']);
                  }
          },
          err=>{console.log(err.message)}
        )


      },
        err=>{
          this.isResailerRepresentExists=false
          this.isClick=false
          console.log(err.message)
        }
    );
  }
  isForgotPss:Boolean=false;
  isEmailExists:Boolean=true;
  isSent:Boolean=false
  isInitpassClicked:Boolean=false
  initpass(email:string,name)
  {
    this.isInitpassClicked=true;
    this.resailerRepresentSer.initpass(email,name).subscribe(
      data=>{
        if(data==false)
        {
          this.isEmailExists=false;
          this.isInitpassClicked=false;
        }
        else
        {
          this.isInitpassClicked=false
          this.showLoginFrm=true;
          this.isEmailExists=true
          this.isForgotPss=false

          this.isSent=true

          setTimeout(() => {
            this.isSent=false
          }, 3000);
        }
      },
      err=>{
          this.isInitpassClicked=false;
        console.log(err.message)
      }
    )
  }
  showLoginFrm:Boolean=true
  showForgotPassword()
  {
    this.isForgotPss=true;
    this.isResailerRepresentExists=true;
    this.showLoginFrm=false
  }

  loginNode()
  {
    this.isClick=true
    this.authSer.nodeJsRPLogin(this.userLogin).subscribe(
      data=>{
        this.authSer.setResailerToken(data.token)
        //sessionStorage.setItem('token',token)
        //this.resailerSer.setCurrentResailer(data);
        this.resailerRepresentSer.setRPInService(data.resailerRepresent);
        this.resailerSer.setCurrentResailer(data.resailer);
        //this.sellerSer.setCurrSellersOfResailer(data.Resailer.Seller)
        //מועבר לדף ניהול משווקים
        this.router.navigate(['/resailer-links/resailer-sellers']);

      },
      err=>{
        this.isResailerRepresentExists=false
        this.isClick=false
        console.log(err.message)
      }
    )
  }

}
