import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store-customer-pss',
  templateUrl: './store-customer-pss.component.html',
  styleUrls: ['./store-customer-pss.component.css']
})
export class StoreCustomerPssComponent implements OnInit {
  // @Input() linkName;
  // urlName:string;

  storeAddressId:number;
  constructor(private router:Router,
  private  storeSer:StoreService,
private csStoreSer: CSStoreService,
activatedRoute:ActivatedRoute ) {
  this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
  if (this.storeAddressId == null) {
    activatedRoute.params.subscribe(
      prm => { this.storeAddressId = prm["storeAddressId"] }
    )
  }
}

ngOnInit() {

// this.urlName=this.linkName;
  }

  isPasswordCorrect: boolean = true;
  storePss: string = "";
  displayStorePss:boolean=true;
  // בדיקה האם הסיסמא שהמשתמש הכניס לחנות תקינה
  checkIsStorePssCorrect(isValid) {
    // this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();

    if (isValid == true) {
      this.storeSer.checkIsStorePssCorrect(this.storeAddressId, this.storePss).subscribe(
        data => {
          if (data == true) {

            // שמירה בסשין את המשתנה של האם סיסמת חנות אומתה
            this.storeSer.setIsStorePassVerifiedInService(true);
             this.displayStorePss = false;
             //רענון העמוד
             let currentUrl = this.router.url;
             this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
               this.router.navigate([currentUrl]);
             });
          }

          else {
             this.isPasswordCorrect = false;
          }

        },
        err => {
          console.log(err);
        }
      )
    }
  }
  //חזרה לעמוד של חנויות
  goToStores() {
     this.displayStorePss = false;
    this.router.navigate(['/shopping-center-links/stors']);
  }


}
