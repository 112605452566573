import { Component, OnInit } from '@angular/core';
import { ShoppingCenterLinksComponent } from '../shopping-center-links/shopping-center-links.component';
import { ShoppingCenterBottomLinksComponent } from '../mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shopping-center-home-page',
  templateUrl: './shopping-center-home-page.component.html',
  styleUrls: ['./shopping-center-home-page.component.css']
})
export class ShoppingCenterHomePageComponent implements OnInit {

  constructor(private centerLinksCom: ShoppingCenterLinksComponent,
    private router: Router
    //private centerBtmLinksCom:ShoppingCenterBottomLinksComponent
  ) {
    centerLinksCom.changeDasignCenteralLink('home-link');
    centerLinksCom.changeDasignCenteralMobileLink('sidenav-home-link');

    // centerBtmLinksCom.changeDasignCenteralMobileBtmLink('moile-home-link');
  }

  ngOnInit() {
  }

  goToSellersSite() {
    window.open('/seller')
  }

  goToLink(url) {
    window.open(url);

  }
  
  displayPageInBuild = false;
}
