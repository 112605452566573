import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Buying } from 'src/app/classes/buying';
import { Customers } from 'src/app/classes/customers';
import { Store } from 'src/app/classes/store';
import { ConfigModule } from 'src/app/config/config.module';
import { AuthService } from 'src/app/services/auth.service';
import { BuyingService } from 'src/app/services/buying.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { TempCartService } from 'src/app/services/temp-cart.service';
import { CustomerConfirmTempCartComponent } from '../customer-confirm-temp-cart/customer-confirm-temp-cart.component';

@Component({
  selector: 'app-payment-succses',
  templateUrl: './payment-succses.component.html',
  styleUrls: ['./payment-succses.component.css']
})
export class PaymentSuccsesComponent implements OnInit {

  buying: Buying = null;
  storeCustomerId: number;

  //נתוני התחברות למערכת
  storeAddressId: number = null;
  customer: Customers = null;
  paymentLoginId: number;
  userLogin: string = null;

  displaySuccsesMsg: boolean = false;
  isLoading: boolean = true;

  constructor(
    private paymentSer: PaymentService,
    private csStoreSer: CSStoreService,
    private router: Router,
    private BuyingSer: BuyingService,
    private tempCartSer: TempCartService,
    private csCartSer: CSCartService,
    private authSer: AuthService,
    private customerSer: CustomerService,
    private activateRoute: ActivatedRoute
  ) {

    activateRoute.params.subscribe(
      prm => this.paymentLoginId = prm['paymentLoginId']
    )

    activateRoute.params.subscribe(
      prm => this.storeCustomerId = prm['storeCustomerId']
    )

    activateRoute.params.subscribe(
      prm => this.userLogin = prm['userLogin']
    )

    //קבלת נתוני הזדהות
    this.paymentSer.getPaymentLogin(this.paymentLoginId, this.storeCustomerId, this.userLogin).subscribe(
      data => {
        if (data != null) {
          this.customer = data.customer;
          this.getPayPageInfo(this.customer.CustomerId, data.token);
        } else {
          //מעבר לדף שגיאת תשלום
          this.router.navigate(['/payment-failed/' + this.paymentLoginId + "/" + this.storeCustomerId + "/" + this.userLogin])
        }
      },
      err => {
        if ((err.message as string).includes(ConfigModule.errMss)) {
          console.log(err.message);
        }
      })


    this.ngOnInit();
  }
  ngOnInit() {
  }

  getPayPageInfo(customerId, token) {
    // קבלת הקנייה מהסרביס
    this.buying = this.BuyingSer.getBuyingFromService();
    this.paymentSer.getPayPageInfo(this.paymentLoginId, this.storeCustomerId, this.buying, customerId, token).subscribe(
      data => {
        if (data == null) {
          this.router.navigate(['/payment-failed/' + this.paymentLoginId + "/" + this.storeCustomerId + "/" + this.userLogin])
        }
        else {
          if (data.savedBuying == true) {
            // הקניה נשמרה ושולמה
            this.buying.CCShovar = data.shovar;
            this.buying.BuyingStatus = 'שולמה';
            this.isLoading = false;
            var x = document.getElementById('img_loading') as HTMLElement;
            x.classList.add('hide')
            this.displaySuccsesMsg = true;
            this.csCartSer.setTempCartProductsQuantityInService(0)//איפוס כמות המוצרים בסל בסשין
            setTimeout(() => {
              this.displaySuccsesMsg = false;
              window.parent.postMessage(
                {
                  event_id: 'payment_message',
                  data: {
                    scId: this.storeCustomerId,
                    ccSovar: this.buying.CCShovar
                  }
                },
                "https://yemot-shopping.co.il/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart" 
              );
            }, 5000);
          } else {
            // הקניה שולמה אך לא נשמרה
            this.buying.CCShovar = data.shovar;
            // הצגת הודעה למשתמש
            alert("הקניה שולמה אך לא נשמרה מס.אישור:" + data.shovar);
          }
        }
      },
      err => {
        if ((err.message as string).includes(ConfigModule.errMss))
          console.log(err.message)
      }
    )
  }
  // check(){
  //   // postMessage('message',"http://localhost:4200/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart/" )
  //   debugger;
  //   window.parent.postMessage(
  //     {
  //       event_id: 'payment_message',
  //       data: {
  //         scId: 55,
  //         ccSovar: '898989'
  //       }
  //     },

  //     "http://localhost:4200/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart/" 
  //   );
  // }

  ///-----------------------ממש קוד לא שימושי--------------
  // getPayPageInfo() {
  //   // קבלת הקנייה מהסרביס
  //   this.buying = this.BuyingSer.getBuyingFromService();
  //   this.paymentSer.getPayPageInfo(this.paymentLoginId, this.storeCustomerId, this.buying).subscribe(
  //     data => {
  //       if (data == null) {
  //         this.router.navigate(['/payment-failed/' + this.paymentLoginId + "/" + this.storeCustomerId + "/" + this.userLogin])
  //       }
  //       else {
  //         if (data.savedBuying == true) {
  //           // הקניה נשמרה ושולמה
  //           this.buying.CCShovar = data.shovar;
  //           this.buying.BuyingStatus = 'שולמה';
  //           this.isLoading = false;
  //           var x = document.getElementById('img_loading') as HTMLElement;
  //           x.classList.add('hide')
  //           this.displaySuccsesMsg = true;
  //           this.csCartSer.setTempCartProductsQuantityInService(0)//איפוס כמות המוצרים בסל בסשין
  //           setTimeout(() => {
  //             this.displaySuccsesMsg = false;
  //           }, 5000);
  //         } else {
  //           // הקניה שולמה אך לא נשמרה
  //           this.buying.CCShovar = data.shovar;
  //           // הצגת הודעה למשתמש
  //           alert("הקניה שולמה אך לא נשמרה מס.אישור:" + data.shovar);
  //         }
  //       }
  //     },
  //     err => {
  //       if ((err.message as string).includes(ConfigModule.errMss))
  //         console.log(err.message)
  //     }
  //   )
  // }


  //חזרה לחנות
  backToStore() {
    window.parent.postMessage(
      {
        event_id: 'payment_message',
        data: {
          scId: this.storeCustomerId,
          ccSovar: this.buying.CCShovar
        }
      },
      "https://yemot-shopping.co.il/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart/"
    );
  }
}
