import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../../config/config.module';
import { Observable } from 'rxjs';
import { StoreCustomers } from '../../classes/store-customers';
import { Store } from '../../classes/store';
import { StoreCustomerItemByStore } from '../../classes/store-customer-item-by-store';
import { AuthService } from '../auth.service';
import { CustomerService } from '../customer.service';
import { Customers } from 'src/app/classes/customers';
import { SellerService } from '../seller.service';

@Injectable({
  providedIn: 'root'
})
export class CSStoreCustomerService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService,
    private customerSer: CustomerService) { }

  url = ConfigModule.url + "api/StoreCustomer/"

  //בדיקה אם לקוח מסוים קיים בחנות מסוימת
  //מחזיר null אם לא מוצא ואת הלקוח של חנות א כן
  checkIfCustomerExistsInStore(customerId, storeId): Observable<StoreCustomers> {
    return this.httpClient.get<StoreCustomers>(this.url + "checkIfCustomerExistsInStore/" + customerId + "/" + storeId);
  }

  // private type MyArrayType = Array<{id: number, text: string}>;

  // const arr: MyArrayType = [
  //     {id: 1, text: 'Sentence 1'},
  //     {id: 2, text: 'Sentence 2'},
  //     {id: 3, text: 'Sentence 3'},
  //     {id: 4, text: 'Sentenc4 '},
  // ];

  //private currStoreCustomers:Array<{storeAddressId: number, storeCustomer: StoreCustomers}>=[]
  private currStoreCustomers: StoreCustomerItemByStore[] = []

  //
  //מחיקה של כל הלקוחות של חניות במקרה של יציאה כללית מהמערכת
  exitAllCurrStoreCustomerInService() {
    this.currStoreCustomers = []
  }

  setCurrStoreCustomerInService(sc: StoreCustomers, storeAddressId: number) {
    this.currStoreCustomers.push(new StoreCustomerItemByStore(storeAddressId, sc))
    sessionStorage.setItem('storeCustomer_' + storeAddressId, JSON.stringify(sc));
  }
  getCurrStoreCustomerFromService(storeAddressId: number): StoreCustomers {
    if (this.currStoreCustomers.find(x => x.StoreAddressId == storeAddressId) == null && sessionStorage.getItem('storeCustomer_' + storeAddressId) != undefined)
      this.currStoreCustomers.push(new StoreCustomerItemByStore(storeAddressId, JSON.parse(sessionStorage.getItem('storeCustomer_' + storeAddressId))))
    // if(this.currStoreCustomers[storeAddressId+""]==null&&JSON.parse(sessionStorage.getItem('storeCustomer_'+storeAddressId))!=null)
    //   this.currStoreCustomers[storeAddressId+""]=JSON.parse(sessionStorage.getItem('storeCustomer_'+storeAddressId))
    if (this.currStoreCustomers.find(x => x.StoreAddressId == storeAddressId))
      return this.currStoreCustomers.find(x => x.StoreAddressId == storeAddressId).StoreCustomer;
    else
      return null
  }
  //יציאה של לקוח מחנות
  exitStoreCustomerInService(sc: StoreCustomers, storeAddressId: number) {
    if (this.currStoreCustomers.find(x => x.StoreAddressId == storeAddressId) == null && sessionStorage.getItem('storeCustomer_' + storeAddressId) != undefined)
      this.currStoreCustomers.push(new StoreCustomerItemByStore(storeAddressId, JSON.parse(sessionStorage.getItem('storeCustomer_' + storeAddressId))))

    sessionStorage.removeItem('storeCustomer_' + storeAddressId);
    let index = this.currStoreCustomers.findIndex(x => x.StoreAddressId == storeAddressId);
    this.currStoreCustomers.splice(index, 1);
  }

  //הוספת לקוח של חנות
  addStoreCustomerByStoreAdressId(storeCust: StoreCustomers, storeAddressId): Observable<StoreCustomers> {
    return this.httpClient.post<StoreCustomers>(this.url + "addStoreCustomerByStoreAdressId/" + storeAddressId, storeCust, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getCustomerToken() }) })
  }

  //עריכת הגדרות לקוח של חנות בשרת
  // ע"י לקוח של חנות
  editStoreCustomer(scId: number, sc: StoreCustomers): Observable<boolean> {
    return this.httpClient.put<boolean>(this.url + "editStoreCustomer/" + scId, sc, {
      headers:
        new HttpHeaders({
          "Authorization": "Bearer " + this.authSer.getCustomerToken()
          , 'UserId': this.customerSer.getCurrCustomerFromService().CustomerId + ''
        })
    })
  }

  //עריכת פרטי לקוח של חנות בשרת
  // ע"י המוכר
  editStoreCustomerByStoreCustomerId(scId: number, sc: StoreCustomers): Observable<any> {
    return this.httpClient.put<any>(this.url + "editStoreCustomerByStoreCustomerId/" + scId, sc, {
      headers:
        new HttpHeaders({
          "Authorization": "Bearer " + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
    })
  }

  // מחיקת לקוח של חנות
  deleteStoreCustomer(scId: number): Observable<any> {
    return this.httpClient.delete<any>(this.url + "deleteStoreCustomer/" + scId, {
      headers:
        new HttpHeaders({
          "Authorization": "Bearer " + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
    })
  }


  //עריכת לקוח של חנות בסרביס
  editStoreCustomerInService(scId: number, scust: StoreCustomers, storeAddressId: number) {
    let sc = this.getCurrStoreCustomerFromService(storeAddressId);
    sc.EmailPublications = scust.EmailPublications;
    sc.SmsPublications = scust.SmsPublications;
    sc.PhonePublications = scust.PhonePublications;

    sessionStorage.setItem('storeCustomer_' + storeAddressId, JSON.stringify(sc));
  }

  checkIfStoreCustomerPhoneNumbersExists( phone: string, anotherPhoneNumber: string, housePhoneNumber: string, storeId: number): Observable<any> {
    return this.httpClient.get<any>(this.url + "checkIfStoreCustomerPhoneNumbersExists/" + phone + "/" + anotherPhoneNumber + "/" + housePhoneNumber + "/"  + storeId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
          , 'UserId': this.customerSer.getCurrCustomerFromService().CustomerId + ''
        })
      })
  }
 //שמירת נתון האם הלקוח ראה את ההודעה של הקניות שלו שלא שולמו
//  ( שימושי רק כשבחנות יש לו אפשרות לרכוש גם עם קניות שלא שולמו )
  setIsCustomerSeeBuyingNotPaidInService(isSeeBuyingNotPaid) {
    sessionStorage.setItem('isCuSee', JSON.stringify(isSeeBuyingNotPaid));
  }

  getIsCustomerSeeBuyingNotPaidInService() { 
    var isSeeBuyingNotPaid = JSON.parse(sessionStorage.getItem('isCuSee'))
    return isSeeBuyingNotPaid;
  }

}
