import { ProductsInBuying } from './products-in-buying';
import { StoreCustomers } from './store-customers';
import { Store } from './store';
import { StoreBranch } from './store-branch';
import { BuyingDetail } from './buying-detail';
let x = 0;
export class Buying {

  constructor(
    public BuyingNumber = x++,
    public BuyingId: number = 0,
    public StoreId: number = 0,
    public StoreCustomerId: number = 0,
    public BuyingStatus: string = "",
    public BuyingSum: number = 0,
    public ShipmentPrice: number = 0,
    public ShipmentCity: string = "",
    public ShipmentStreet: string = "",
    public ShipmentNumHouse: number = 0,
    public ShipmentPostalCode: string = "",
    public ShipmentContactPerson: string = "",
    public ShipmentPhoneNumber: string = "",
    public ShipmentEntrance: string = "",
    public ShipmentNumApartment: number = 0,
    public ShipmentNotes: string = "",
    public SellerPaid = 0,
    public ResailerPaid = 0,
    public BuyingDate: Date = new Date(),
    public ProductsInBuying: ProductsInBuying[] = null,
    public StoreCustomer: StoreCustomers = null,
    public Store: Store = null,
    public StoreBranches: StoreBranch = null,
    //עבור הצגה
    public CountItems: number = 0,
    public CountProducts: number = 0,

    public BuyingDetail: BuyingDetail[] = null,
    public CCShovar: string = null
  ) { }
}
