import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customers } from 'src/app/classes/customers';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import * as $ from 'jquery';


@Component({
  selector: 'app-links-store-bottom',
  templateUrl: './links-store-bottom.component.html',
  styleUrls: ['./links-store-bottom.component.css']
})
export class LinksStoreBottomComponent implements OnInit {

  @Input() linkNumber;
  num;

  storeAddressId
  constructor(private router: Router, private csStoreSer: CSStoreService) {

    this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService()
  }

  ngOnInit() {
    this.num = this.linkNumber
    if (this.num != undefined) {
      $('#link-' + this.num + '-regular').addClass('hide');
      $('#link-' + this.num + '-bold').removeClass('hide');
    }
  }
  //בדיקה אם המשתמש רשום
  displayIsNotSignIn: boolean = false

  goToPage(num) {

    let url = ''
    switch (num) {
      case 1: url = '/store-links/' + this.storeAddressId + '/store-products'; break;//מוצרים
      case 2: url = '/store-links/' + this.storeAddressId + '/store-shopping-history'; break;//הסטוירת קניות
      case 3: url = '/store-links/' + this.storeAddressId + '/shipping'; break;//הגדרות חנות
      case 4: url = '/store-links/' + this.storeAddressId + '/store-customer-setting'; break;//הגדרות לקוח
    }


    let customer: Customers = JSON.parse(sessionStorage.getItem('customer'))
    if (customer == null) {
      this.displayIsNotSignIn = true;
      sessionStorage.setItem('pageLink', url)
    }
    else {

      this.router.navigate([url])
    }
  }

  goToLogin() {
    this.displayIsNotSignIn = false;
    //sessionStorage.setItem('pageLink','')
    this.router.navigate(['/login'])
  }
}
