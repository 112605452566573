import { Buying } from './buying';
import { StoreCategory } from './store-category';

export class Store {
    constructor(
        public StoreId: number = 0,
        public StoreName: string = "",
        public ImageToView: string = "",
        public BackgroundImage: string = "",
        public Poster: string = "",
        public StoreAddress: string = "",
        public PhoneNumber: string = "",
        public Email: string = "",
        public ViewEmail: any = true,
        public IsOpen: Boolean = true,
        public OpeningDate: Date = new Date(),
        public ClosingDate: Date = null,
        public SellerId: number = 0,
        //סוג משלוח
        public DeliveryType: string = "ללא",
        public DeliveryTypePrice: string = "חינם",
        //מחיר משלוח
        public DeliveryPrice: number = null,
        //--10.	משלוח חינם מקנייה בסכום מסוים.
        public FreeDelivery: number = null,
        //--11.	הגבלת מספר הרכישות ללקוח.
        public LimitationNumBuyingByCustomer: number = null,
        //--12.	הגבלת סכום לפי רכישה.
        public LimitationSumBuyingByBuying: number = null,
        //--13.	הגבלת סכום לפי לקוח
        public LimitationSumBuyingByCustomer: number = null,
        //--14.	הגבלת מספר המוצרים לפי רכישה.
        public LimitationNumProductByBuying: number = null,
        //--15.	הגבלת מספר המוצרים לפי לקוח.
        public LimitationNumProductByCustomer: number = null,

        //--16.	הגבלת מספר הפריטים לפי רכישה.
        public LimitationNumItemsByBuying: number = null,

        //--17.	הגבלת מספר הפריטים לפי לקוח.
        public LimitationNumItemsByCustomer: number = null,
       //האם לאפשר כניסה לחנות רק ללקוחות שנרשמו ע"י המוכר
       public LimitationCustomerNotRegisteredBySeller:Boolean=false,

        public MinCostToBuy: number = null,
        public PaymentForPurchase: number = null,

        public DisplaysFinishedProducts: Boolean = true,
        public ShowContactForum: Boolean = true,

        public About: string = "",
        public StoreStatus: Boolean = true,
        public ViewStoreInSite: Boolean = true,
        public StoreCategoryId: number = 1,
        public DisplayProductQuantityInStock: Boolean = true,
        public StoreCategory: StoreCategory = null,

        //האם לאפשר שמירת רכישות ללא תשלום (כן / לא, ברירת מחדל לא).
        public SaveBuyingWithoutPaying: Boolean = false,
        //האם לסמן רכישה ששולמה כסופקה (כן / לא, ברירת מחדל כן).
        public PaidBuyingIsDelivered: Boolean = true,
        public StorePassword: string = "",
        public StoreAddressId: number = null,
        public SellerPassword: string = null,
        public StoreNameInSite: string = "",
        public StoreNameForInvoice: string = null,
        public StoreIdentityHF: string = null,
        public SendAutoSummary: Boolean = false,
        public VatCalculation: Boolean = true,
        public SendEmailEveryOrder: Boolean = true,
        public AddToEmailStockAndBuyingSummary: Boolean = true,
        public BackDaysNumberToSentSummaryBuying: number = 30,
        public MinProductsInBuying: number = null,
        public MinItemsInBuying: number = null,
        public BuyingSavingText: string = null,
        public IsBlockCustomerWithDebt: Boolean = false,
        public IsChecked: boolean = false,
        public StoreDescription: string = null,
        public CustomColor: string = null,
        public IsBuyingDisableForCustomerWithUnPaidBuyings : number = 0,
        //האם קימים מוצרים עם מאפינים בחנות
        public IsExistsProductWithFeature:number=0,
        public IndexToView:number=0) {

    }
}
