import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  status:string=""
  constructor(private router:Router) {

    this.status=sessionStorage.getItem('status');

    // switch (this.status) {
    //   case 'admin':
        
    //     break;
    
    //   default:
    //     break;
    // }
   }

  ngOnInit() {
  }

  back()
  {
    this.router.navigate(['/seller-hp-up-links/hp-side-links/seller-hp'])
  }
}
