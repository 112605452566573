import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { Router } from '@angular/router';
import { Customers } from 'src/app/classes/customers';
import { StoreCustomers } from 'src/app/classes/store-customers';
import { Store } from 'src/app/classes/store';

@Component({
  selector: 'app-login-messages',
  templateUrl: './login-messages.component.html',
  styleUrls: ['./login-messages.component.css']
})
export class LoginMessagesComponent implements OnInit {

  constructor(
    private customerSer:CustomerService,
    private csStoreCustomerSer:CSStoreCustomerService,
    private router:Router) { }

  ngOnInit() {
  }
  displayNotSignInToStore:boolean=true;
  displayNotSignIn:boolean=false;
  customer:Customers=null;
  storeCustomer:StoreCustomers=null;
  displaySignInSuccess:boolean=false;
  storeAddressId:number;
  store:Store=null;
  pageStoreLink:string=null;
   //הרשמה/התחברות משתמש לחנות
   storeLogin()
   {
     //בדיקה אם הלקוח בכלל רשום
     let customer=this.customerSer.getCurrCustomerFromService();
     if(customer==null)
     {
       //alert('יש להרשם/להתחבר למערכת הכללית')
       //האם ברצונך להרשם למערכת הכללית???
       this.displayNotSignIn=true;
     }
     else{
     let storeCustomer=this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId)
       if(storeCustomer!=null)
       {
         alert('אתה כבר מחובר לחנות לחנות')
       }
       else
       {
         //בדיקה אם המשתמש כבר רשום לחנות
         this.csStoreCustomerSer.checkIfCustomerExistsInStore(customer.CustomerId,this.store.StoreId).subscribe(
           data=>{
             if(data==null)
             {
                 //העברה לדף הרשמה לחנות
                 this.router.navigate(['/store-login'])
             }
             else
             {
               //
               this.csStoreCustomerSer.setCurrStoreCustomerInService(data,this.storeAddressId);
               this.customer=this.customerSer.getCurrCustomerFromService();
               this.storeCustomer=this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
               //אתה רשום לחנות
               //alert('התחברת בהצלחה')
               this.displaySignInSuccess=true;
             }
           }
         )

       }
     }
   }

   //מעביר את הלקוח להתחברות כללית למערכת
   goToLoginCustomer()
   {
     //
     //let url=location.href;
     sessionStorage.setItem('pageLink','store-links/'+this.storeAddressId+'/store-home-page')
     this.router.navigate(['/login'])
   }

  }


