import { Component, OnInit } from '@angular/core';
import { StoreProfitsTrack } from 'src/app/classes/store-profits-track';
import { PaymentsTracksService } from 'src/app/services/payments-tracks.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-payments-tracks',
  templateUrl: './payments-tracks.component.html',
  styleUrls: ['./payments-tracks.component.css']
})
export class PaymentsTracksComponent implements OnInit {
  tracks: StoreProfitsTrack[];
  tracksColors: string[] =
    ['transparent linear-gradient(180deg, #FFB941 0%, #FF844D 100%) 0% 0% no-repeat padding-box',
      'transparent linear-gradient(180deg, #FF8AB0 0%, #AA6EFF 100%) 0% 0% no-repeat padding-box',
      'transparent linear-gradient(180deg, #51DCC5 0%, #2F8CCF 49%, #2473D2 100%) 0% 0% no-repeat padding-box',
      'transparent linear-gradient(180deg, #80C48E 0%, #E2E200 100%) 0% 0% no-repeat padding-box',
      'transparent linear-gradient(180deg, #FFCA39 0%, #FF5661 100%) 0% 0% no-repeat padding-box',
    'linear-gradient(rgb(216 38 38) 0%, rgb(99 85 85) 100%) 0% 0% no-repeat padding-box padding-box transparent']
  constructor(private tracksSer: PaymentsTracksService
  ) {

    tracksSer.getAllPaymentsTracks().subscribe(
      data => {
        this.tracks = data
        if (this.tracks.length > 0) {
          setTimeout(() => {
            for (let i = 0; i < this.tracks.length; i++) {
              if(i<this.tracksColors.length)
              $(`#${this.tracks[i].StoreProfitsTrackId}`).css("background", this.tracksColors[i])
            else
              $(`#${this.tracks[i].StoreProfitsTrackId}`).css("background", this.tracksColors[5])

            }
          }, 50);
        }

      },
      err => {
        console.log(err.message)

      }
    )

  }

  ngOnInit() {

  }
}
