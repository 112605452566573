import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ConfigModule } from './config/config.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'shopping-customers';

  constructor(private authSer:AuthService,
    private router:Router)
    {

    }
    url=ConfigModule.url;

    private isShowUnauthorize:Boolean=false;

    showDialog()
    {
     this.isShowUnauthorize=true;
    }
    ok()
    {
      this.isShowUnauthorize=false;
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['/seller'])
    }
}
