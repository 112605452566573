import { Injectable } from '@angular/core';
import { ConfigModule } from '../../config/config.module';
import { Observable } from 'rxjs';
import { Store } from '../../classes/store';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CSStoreService {

  constructor(private httpClient:HttpClient) { }

  url=ConfigModule.url+"api/CsStore/"

  private currStore:Store=null;
  //private storeBasicDetails=null;
  private storeAddressId:number=null

  getStoreByStoreNameInSite(storeNameInSite):Observable<Store>
  {
    return this.httpClient.get<Store>(this.url+"getStoreByStoreNameInSite/"+storeNameInSite);
  }

  getStoreByStoreAddressId(storeAddressId):Observable<Store>
  {
    return this.httpClient.get<Store>(ConfigModule.url+"api/Store/getStoreByStoreAddressId/"+storeAddressId);
  }


  // getCurrStoreBasicDetailsInService()
  // {
  //   if(this.storeBasicDetails==null)
  //   this.storeBasicDetails=JSON.parse(sessionStorage.getItem('currStore'))
  //   return this.storeBasicDetails;
  // }
  // setCurrStoreBasicDetailsInService(store)
  // {
  //   let basic=null;
  //   if(store!=null)
  //   basic={ "StoreAddressId":store.StoreAddressId,"StoreNameInSite":store.StoreNameInSite}
  //   this.storeBasicDetails=basic;
  //   sessionStorage.setItem('currStore',JSON.stringify(basic));
  // }
  getCurrStoreAddressIdInService()
  {
    if(this.storeAddressId==null)
    this.storeAddressId=JSON.parse(sessionStorage.getItem('currStoreAddressId'))
    return this.storeAddressId;
  }
  setCurrStoreAddressIdInService(storeAddressId)
  {
    // ;
    if(storeAddressId!=null)
    this.storeAddressId=storeAddressId;
    sessionStorage.setItem('currStoreAddressId',storeAddressId);
  }

  getCurrStoreFromService()
  {
    return this.currStore;
  }

  setCurrStoreInService(store:Store)
  {
    this.currStore=store
  }
}
