import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { DiscountInStore } from '../classes/discount-in-store';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';
import { CreditCompany } from '../classes/credit-company';
import { PaymentSetting } from '../classes/payment-setting';
import { PaymentParameter } from '../classes/payment-parameter';
import { CustomerService } from './customer.service';
import { PaymentLogin } from '../classes/payment-login';
import { Buying } from '../classes/buying';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService,
    private customerSer: CustomerService
  ) { }

  paymentSettingUrl = ConfigModule.url + "api/PaymentSetting/";

  // ------ paymentSetting -----

  //קבלת הגדרות תשלום/חברות אשראי שהגדיר המוכר לפי קוד חנות מהשרת
  getAllPaymentSettingsByStoreId(storeId: number): Observable<PaymentSetting[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<PaymentSetting[]>(this.paymentSettingUrl + "getAllPaymentSettingsByStoreId/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //קבלת כל ההגדרות תשלום הפעילות לחנות לפי מזהה חנות עבור אתר הלקוחות
  getAllActivePaymentSettingsByStoreId(storeId: number): Observable<PaymentSetting[]> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    return this.httpClient.get<PaymentSetting[]>(this.paymentSettingUrl + "getAllActivePaymentSettingsByStoreId/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    })
  }

  //קבלת הגדרת תשלום לפי מזהה הגדרת תשלום
  getPaymentSettingById(paymentSettingId: number, token, userId): Observable<PaymentSetting> {
    return this.httpClient.get<PaymentSetting>(this.paymentSettingUrl + "getPaymentSettingById/" + paymentSettingId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  //פונקציה להוספת הגדרת תשלום בשרת
  addPaymentSetting(paymentSetting: PaymentSetting): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.post<any>(this.paymentSettingUrl + "addPaymentSetting", paymentSetting, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  // פונקציה לעריכת הגדרת תשלום בשרת
  editPaymentSetting(paymentSetting: PaymentSetting): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.put<any>(this.paymentSettingUrl + "editPaymentSetting", paymentSetting, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  deletePaymentSetting(paymentSettingId: number): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.delete<any>(this.paymentSettingUrl + "deletePaymentSetting/" + paymentSettingId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //פונקציה לשינוי סטטוס הגדרת תשלום
  getChangePaymentSettingStatusById(paymentSettingId: number): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<any>(this.paymentSettingUrl + "getChangePaymentSettingStatusById/" + paymentSettingId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  // קבלת הפרמטרים לחברה לפי מזהה חברה מהשרת
  getParametersByCreditCompanyId(id: number): Observable<PaymentParameter[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<PaymentParameter[]>(this.paymentSettingUrl + "getParametersByCreditCompanyId/" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //פונקציה לקבלת כל חברות האשראי שאפשר לרכוש דרכם באתר
  getAllActiveCreditCompanies(storeId: number): Observable<CreditCompany[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<CreditCompany[]>(this.paymentSettingUrl + "getAllActiveCreditCompanies/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  // פונקציה לקבלת הגדרות אמצעי תשלום לפי מזהה חברה
  getCreditCompanyById(id: number): Observable<CreditCompany> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<CreditCompany>(this.paymentSettingUrl + "getCreditCompanyById/" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }



  // ----- paymentLogin -----
  paymentLoginUrl = ConfigModule.url + "api/PaymentLogin/";

  getPaymentLogin(paymentLoginId: number, storeCustomerId: number, ul: string): Observable<any> {
    return this.httpClient.get<any>(this.paymentLoginUrl + "getPaymentLogin/" + paymentLoginId + "/" + storeCustomerId + "/" + ul);
  }


  //-------  iCount ------

  iCountUrl = ConfigModule.url + "api/ICount/";

  getSellerLogin(cId: string, user: string, pass: string): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<any>(this.iCountUrl + "getSellerLogin/" + cId + "/" + user + "/" + pass, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  getProvider(cId: string, user: string, pass: string): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;
    return this.httpClient.get<any>(this.iCountUrl + "getProvider/" + cId + "/" + user + "/" + pass, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }


  getIcountPayPage(psId: number, scId: number, ul: string, isDelivery: number, buyingId: number): Observable<any> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    return this.httpClient.get<any>(this.iCountUrl + "getIcountPayPage/" + psId + "/" + scId + "/" + ul + "/" + isDelivery + "/" + buyingId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    })
  }

  getPayPageInfo(plId, scId, buying, userId, token): Observable<any> {
    return this.httpClient.post<any>(this.iCountUrl + "getPayPageInfo/" + plId + "/" + scId, buying, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  //קוד לא שימושי------
  // getPayPageInfo(plId, scId, buying): Observable<any> {
  //   return this.httpClient.post<any>(this.iCountUrl + "getPayPageInfo/" + plId + "/" + scId, buying)
  // }


  // -------  nedarimPlus  -------
  nedarimUrl: string = ConfigModule.url + "api/Nedarim/";

  getPayPageNedarimInfo(psId, scId, buying: Buying): Observable<any> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    return this.httpClient.post<any>(this.nedarimUrl + "getPayPageNedarimInfo/" + psId + "/" + scId, buying, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    })
  }

}
