import { Component, OnInit } from '@angular/core';
import  Chart  from 'chart.js';
declare var Chart:any;

@Component({
  selector: 'app-stors-chart',
  templateUrl: './stors-chart.component.html',
  styleUrls: ['./stors-chart.component.css']
})
export class StorsChartComponent implements OnInit {

  constructor() { }

   ngOnInit() {
    
 
     var ctx =(document.getElementById('myChart') as HTMLCanvasElement).getContext('2d');
     var myChart = new Chart(ctx, {
     type: 'line',
     data: {
      position: 'left',
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
        label: 'סכום הרכישות',
        yAxisID: 'A',
        data: [60300,50500,70000,50000,30000,40000,10000,20000],
        //backgroundColor:"rgb(55 142 240 / 12%)",
        borderColor:"rgb(72 166 241)",
        fill:"false",
        borderWidth:2,
        pointBackgroundColor:"rgba(0, 0, 0, 0)",
        pointBorderColor:"rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor:"rgb(72 166 241)",
        pointHoverBorderColor:"rgb(255 255 255)",
        pointHoverBorderWidth:3,
        pointHoverRadius:5
      }, {
        label: 'מספר הרכישות',
        yAxisID: 'B',
        data: [500,600,550,300,400,450,330,500],
       // backgroundColor:"rgb(255 161 67 / 11%)",
        borderColor:"rgb(255 161 67)",
        fill:"false",
        borderWidth:2,
        pointBackgroundColor:"rgba(0, 0, 0, 0)",
        pointBorderColor:"rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor:"rgb(255 161 67)",
        pointHoverBorderColor:"rgb(255 255 255)",
        pointHoverBorderWidth:3,
        pointHoverRadius:5
      }]
    },
    options: {
      scales: {
        xAxes: [{
          gridLines: {
           color: "rgba(0, 0, 0, 0)",
           },}],
        yAxes: [{
          id: 'A',
          type: 'linear',
          position: 'left',
          ticks: {
            max: 100000,
            min: 0
          },

         
        }, {
          id: 'B',
          type: 'linear',
          position: 'right',
          ticks: {
            max: 1000,
            min: 0
          }
        },]
        
      },
    }
     });
   }
 
}
