import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Buying } from 'src/app/classes/buying';
import { Customers } from 'src/app/classes/customers';
import { ConfigModule } from 'src/app/config/config.module';
import { AuthService } from 'src/app/services/auth.service';
import { BuyingService } from 'src/app/services/buying.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-shopping-history-store-pop',
  templateUrl: './shopping-history-store-pop.component.html',
  styleUrls: ['./shopping-history-store-pop.component.css']
})
export class ShoppingHistoryStorePopComponent implements OnInit {

  constructor(private customerSer:CustomerService,
    private authSer:AuthService,
    private buyingSer:BuyingService) { }

  isShowStoreShoppingHistory: boolean = true;
  @Input() buyingIdByInput: number;
  @Output() hideBuyingDetails = new EventEmitter();
  buyingId: number = null;
  url: string = ''
  customer:Customers=new Customers()
  buying:Buying=new Buying()

  ngOnInit() {
    //

    this.buyingId = this.buyingIdByInput;

    //שליפת הקניה מהסרביס
    this.customer = this.customerSer.getCurrCustomerFromService();

    this.buyingSer.getBuyingByBuyingId(this.buyingId, this.authSer.getCustomerToken(), this.customer.CustomerId).subscribe(
      data => {
        //
        let b = data;
        let addPayment = 0.0;
        let bd;
        if (b['BuyingDetail'] != null) {
          bd = b['BuyingDetail'].find(x => x['DetailName'] == 'תוספת תשלום')
          bd != null ? addPayment = bd['DetailSum'] : addPayment = 0.0
        }
        b['AddPayment'] = this.getNumberAsNis(addPayment);
        b['BuyingSum'] = this.getNumberAsNis(b['BuyingSum'])

        b['BuyingSumAll'] = b['ShipmentPrice'] != null ? this.getNumberAsNis(b['ShipmentPrice'] + parseFloat(b['BuyingSum'] + "")) : this.getNumberAsNis(b['BuyingSum'])

        let sumProducts = 0.0;
        let countItems = 0;
        let countProducts = 0;

        for (let i = 0; i < b['ProductsInBuying'].length; i++) {
          countItems += b['ProductsInBuying'][i]['Quantity'];
          countProducts++;
          sumProducts += parseFloat((b['ProductsInBuying'][i]['Quantity'] * b['ProductsInBuying'][i]['ItemPrice']).toFixed(2))
          b['ProductsInBuying'][i]['ItemPrice'] = this.getNumberAsNis(b['ProductsInBuying'][i]['ItemPrice'])
        }
        b['CountItems'] = countItems
        b['CountProducts'] = countProducts

        if (b['BuyingDetail'] != null)
          for (let i = 0; i < b['BuyingDetail'].length; i++) {
            if (b['BuyingDetail'][i]['DetailName'] != 'תוספת תשלום') {
              if (b['BuyingDetail'][i]['DetailName'] == 'הנחת אחוזים' || b['BuyingDetail'][i]['DetailName'] == 'הנחת סכום')
                sumProducts -= b['BuyingDetail'][i]['DetailSum']
            }
          }
        b['SumProducts'] = this.getNumberAsNis(sumProducts)


        this.url = ConfigModule.url + 'files/Stores/' + b['Store']['StoreId'] + '/' + b['Store']['ImageToView']

        this.buying = b;
      },
      err => {
        console.log(err.message);
      }
    )
  }

  // close() {
  //   this.isShowShoppingHistory = false;
  //   //ע"י הEventEmmiter
  //   this.hideBuying.emit(null)
  // }

  getNumberAsNis(num) {
    let dig = (num + "").split('.');
    if (!dig[1])
      return num + '.00'
    else
      if (dig[1] != null && dig[1].length < 2)
        return num + '0';
      else
        return num
  }


  isDownloadClicked: Boolean = false
  buyingIdToDown: number = 0;

  downloadSummary() {


    this.isDownloadClicked = true;
    this.buyingIdToDown = this.buying.BuyingId


    let role = "Customer"
    this.buyingSer.downloadBuyingSummary(this.buying.BuyingId, this.authSer.getCustomerToken(), role).subscribe(
      data => {
        this.buyingIdToDown = 0;
        this.isDownloadClicked = false;
        var FileSaver = require('file-saver');
        var blob = new Blob([data],
          { type: "application/pdf" });
        FileSaver.saveAs(blob, this.buying.BuyingId + "_" + Date.now());
      },
      err => {
        // x = document.getElementById('div_loading_img') as HTMLElement;
        //x.classList.add('hide')
        // if ((err.message as string).includes(ConfigModule.errMss))
        // this.appComponent.showDialog();
        console.log(err.message)
      }
    )
  }
  close() {
    this.isShowStoreShoppingHistory = false;
    //ע"י הEventEmmiter
    this.hideBuyingDetails.emit(null)
  }
}
