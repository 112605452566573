import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/classes/store';
import { ConfigModule } from 'src/app/config/config.module';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.css']
})

export class PaymentFailedComponent implements OnInit {

  displayFailedMsg: boolean = true;

  //נתוני התחברות למערכת
  storeAddressId: number;
  store: Store = null;
  paymentLoginId: number;
  storeCustomerId: number;
  userLogin: string = null;
  paymentSettingId: number;
  token: string = null;

  constructor(private router: Router,
    private paymentSer: PaymentService,
    private csStoreSer: CSStoreService,
    private authSer: AuthService,
    private customerSer: CustomerService,
    private activateRoute: ActivatedRoute,
    private csStoreCustomerSer: CSStoreCustomerService,
    private csProductInStoreSer: CSProductInStoreService) {

    activateRoute.params.subscribe(
      prm => this.paymentLoginId = prm['paymentLoginId']
    )

    activateRoute.params.subscribe(
      prm => this.storeCustomerId = prm['storeCustomerId']
    )

    activateRoute.params.subscribe(
      prm => this.userLogin = prm['userLogin']
    )

    //קבלת נתוני הזדהות
    this.paymentSer.getPaymentLogin(this.paymentLoginId, this.storeCustomerId, this.userLogin).subscribe(
      data => {
        if (data != null) {
          //login payment data
          this.store = data.store;
          this.storeAddressId = this.store.StoreAddressId;
          //הצגת הודעת שגיאה
          setTimeout(() => {
            this.displayFailedMsg = false;
            //מעבר לדף סיכום קנייה
            window.parent.postMessage(
              {
                event_id: 'payment_message',
                data: {
                  scId: this.storeCustomerId,
                  ccSovar: null
                }
              },
              "https://yemot-shopping.co.il/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart"
            );

          }, 3000);

        } else {
          //הצגת הודעת שגיאה
          setTimeout(() => {
            this.displayFailedMsg = false;
            //מעבר לדף הבית
            this.router.navigate(['/shopping-center-links/shopping-center-home-page']);
          }, 3000);
        }
      },
      err => {
        if ((err.message as string).includes(ConfigModule.errMss)) {
          console.log(err.message);
        }
      })

  }

  ngOnInit() {
  }



  backToStore() {
    // מעבר לדף סיכום קנייה
    if (this.token != null) {
      window.parent.postMessage(
        {
          event_id: 'payment_message',
          data: {
            scId: this.storeCustomerId,
            ccSovar: null
          }
        },
        "https://yemot-shopping.co.il/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart" 
      );
    }
    else {
      //מעבר לדף הבית
      this.router.navigate(['/shopping-center-links/shopping-center-home-page']);
    }

  }

}
