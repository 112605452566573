import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Customers } from 'src/app/classes/customers';
import * as $ from 'jquery';

@Component({
  selector: 'app-shopping-center-bottom-links',
  templateUrl: './shopping-center-bottom-links.component.html',
  styleUrls: ['./shopping-center-bottom-links.component.css']
})
export class ShoppingCenterBottomLinksComponent implements OnInit {
  @Input() btmLink: string;
  constructor(private router: Router) {

  }

  ngOnInit() {
 //   שינוי עיצוב ללינק במובייל
    $('.div_bottom_links a').removeClass('change_color')
     $(`#${this.btmLink}`).addClass('change_color');
     $('.div_bottom_links a hr').removeClass('change_border_color')
     $(`#${this.btmLink} hr`).addClass('change_border_color');
  }

  //בדיקה אם המשתמש רשום
  displayIsNotSignIn: boolean = false
  goToPage(url) {
    let customer: Customers = JSON.parse(sessionStorage.getItem('customer'))
    if (customer == null) {
      this.displayIsNotSignIn = true;
      sessionStorage.setItem('pageLink', url)
    }
    else {
      this.router.navigate([url])
    }
  }

  goToLogin() {
    this.displayIsNotSignIn = false;
    //sessionStorage.setItem('pageLink','')
    this.router.navigate(['/login'])
  }

  // שינוי עיצוב ללינק במובייל

  // changeDasignCenteralMobileBtmLink(idBottomnav: string) {
  //   $('.div_bottom_links a').removeClass('change_color')
  //   $(`#${idBottomnav}`).addClass('change_color');
  //   $('.div_bottom_links a hr').removeClass('change_border_color')
  //   $(`#${idBottomnav} hr`).addClass('change_border_color');
  // }

}
