import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Buying } from 'src/app/classes/buying';
import { BuyingDetail } from 'src/app/classes/buying-detail';
import { Customers } from 'src/app/classes/customers';
import { DiscountInStore } from 'src/app/classes/discount-in-store';
import { PaymentLogin } from 'src/app/classes/payment-login';
import { PaymentSetting } from 'src/app/classes/payment-setting';
import { ProductsInBuying } from 'src/app/classes/products-in-buying';
import { Store } from 'src/app/classes/store';
import { StoreBranch } from 'src/app/classes/store-branch';
import { StoreCustomers } from 'src/app/classes/store-customers';
import { TempCart } from 'src/app/classes/temp-cart';
import { ConfigModule } from 'src/app/config/config.module';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { DiscountService } from 'src/app/services/discount.service';
import { PaymentService } from 'src/app/services/payment.service';
import { StoreBranchService } from 'src/app/services/store-branch.service';
import { TempCartService } from 'src/app/services/temp-cart.service';
import { BuyingService } from '../../../services/buying.service';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { StoreLinksComponent } from '../store-links/store-links.component';

@Component({
  selector: 'app-customer-confirm-temp-cart',
  templateUrl: './customer-confirm-temp-cart.component.html',
  styleUrls: ['./customer-confirm-temp-cart.component.css']
})
export class CustomerConfirmTempCartComponent implements OnInit {
  numberOfItems: number = 0;
  url: string = ""
  storeAddressId;
  store: Store;
  cart: TempCart = null;
  currCustomer: Customers = new Customers();
  // cu: Customers = null;
  storeBranches: StoreBranch[] = [];
  isEnableSaveBuyingWithoutPaying: Boolean = false;
  storeCustomer: StoreCustomers;
  totalPayment: number = 0;
  shippingPrice: number = 0
  discountInStore: DiscountInStore[] = [];
  discountSum: { count: number, name: string } = { count: 0, name: '' };
  discountPercentage: { count: number, name: string } = { count: 0, name: '' };
  isShipping: boolean = false;
  branchIndexSelected: number = 1;
  buying: Buying = new Buying();

  savedBuying: Buying;//למחוק
  buyingId: number = 0;
  shippingToExistAddress: Boolean = false;
  isExistUpdatedAddress: boolean = false;


  // סכום ביניים
  tempSum: number = 0;

  // להצגת הודעות---
  displaySaveCart: boolean = false;

  //הגדרות תשלום
  paymentSettings: PaymentSetting[] = [];

  isPaymentInSiteSelected: Boolean = true;
  isSaveAndPayLaterSelected: Boolean = false;
  shipmentContactPerson: string = "";
  shipmentNotes: string = "";
  // להצגת דף תשלום
  displayPayPage: boolean = false;

  // ------------------לעריכת פרטי משלוח מתי מתייחסים לזה?
  // detailsForm
  // isValidateAllFormFields

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private csStoreSer: CSStoreService,
    private csCartSer: CSCartService,
    private authSer: AuthService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private customerSer: CustomerService,
    private sbSer: StoreBranchService,
    private buyingSer: BuyingService,
    private tempCartSer: TempCartService,
    private storeLinksCom: StoreLinksComponent,
    private discountSer: DiscountService,
    private paymentSer: PaymentService) {

    activatedRoute.params.subscribe(
      prm => { this.buyingId = prm['buyingId'] }
    )
    this.buying.BuyingId = this.buyingId;

    this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();
    this.store = csStoreSer.getCurrStoreFromService();
    this.storeCustomer = csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId)
    this.currCustomer = this.customerSer.getCurrCustomerFromService();
    this.buying.ShipmentCity = this.currCustomer.City;
    this.buying.ShipmentStreet = this.currCustomer.Street;
    this.buying.ShipmentPostalCode = this.currCustomer.PostalCode;
    this.buying.ShipmentPhoneNumber = this.currCustomer.PhoneNumber;;
    this.buying.ShipmentNumHouse = this.currCustomer.NumHouse;
    this.buying.ShipmentNumApartment = this.currCustomer.NumApartment;
    this.buying.ShipmentEntrance = this.currCustomer.Entrance;
    this.buying.ShipmentContactPerson = `${this.currCustomer.FirstName} ${this.currCustomer.LastNameOrCompany}`;
    this.buying.ShipmentNotes = this.shipmentNotes

    if (this.storeCustomer != null) {
      if (this.store == null) {
        csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
          data => {

            csStoreSer.setCurrStoreInService(data);
            this.store = data;
            this.url = ConfigModule.url + "files/Stores/" + this.store.StoreId + "/";
            this.isEnableSaveBuyingWithoutPaying = this.store.SaveBuyingWithoutPaying;

            if (this.buyingId == 0) {
              sbSer.getStoreBranchesByStoreId(this.store.StoreId, this.authSer.getCustomerToken(), 'Customer').subscribe(
                data => {
                  this.storeBranches = data
                  //קריאה לפונקציה שמקבלת את הגדרות תשלום של החנות
                  this.getAllActivePaymentSettingsByStoreId();
                  //שליחה לפונ שמביאה את הסל הזמני מהשרת ואת ההנחות אם יש ללקוח בחנות
                  this.getTempCartAndDiscountsByStoreCustomer();
                },
                err => {
                  if ((err.message as string).includes(ConfigModule.errMss))
                    console.log(err.message)
                }
              )
            }
            else {
              //  להציג נתונים   של קנייה שלא שולמה מהסטוריית קניות
              // שליפת הקניייה המתאימה מהסטוריית קניות
              this.buyingSer.getBuyingByBuyingId(this.buyingId, this.authSer.getCustomerToken(), this.currCustomer.CustomerId).subscribe(
                data => {
                  if (data != null) {
                    this.buying = data

                    this.totalPayment = this.buying.BuyingSum + this.buying.ShipmentPrice;
                    this.buying.ProductsInBuying.forEach(element => {
                      this.numberOfItems += element.Quantity;
                    });
                    this.buying.ProductsInBuying.forEach(element => {
                      this.tempSum += element.ItemPrice * element.Quantity;
                    });
                    if (this.buying.ShipmentPrice != null)
                      this.isShipping = true
                    //קריאה לפונקציה שמקבלת את הגדרות תשלום של החנות
                    this.getAllActivePaymentSettingsByStoreId();
                  }
                }, err => { console.log(err.message) }
              )
            }
          },
          err => { console.log(err.message) }
        )
      }
      // במקרה שיש חנות
      else {
        this.url = ConfigModule.url + "Files/Stores/" + this.store.StoreId + "/";
        this.isEnableSaveBuyingWithoutPaying = this.store.SaveBuyingWithoutPaying;
        if (this.buyingId == 0) {
          sbSer.getStoreBranchesByStoreId(this.store.StoreId, this.authSer.getCustomerToken(), 'Customer').subscribe(
            data => {
              this.storeBranches = data
              //קריאה לפונקציה שמקבלת את הגדרות תשלום של החנות
              this.getAllActivePaymentSettingsByStoreId();
              //שליחה לפונ שמביאה את הסל הזמני מהשרת ואת ההנחות אם יש ללקוח בחנות
              this.getTempCartAndDiscountsByStoreCustomer();
            },
            err => {
              if ((err.message as string).includes(ConfigModule.errMss))
                console.log(err.message)
            }
          )
        } else {
          //בדיקה האם להציג נתונים מהסל קניות או נתונים של קנייה שלא שולמה מהסטוריית קניות
          if (this.buyingId != 0) {
            // שליפת הקניייה המתאימה מהסטוריית קניות
            this.buyingSer.getBuyingByBuyingId(this.buyingId, this.authSer.getCustomerToken(), this.currCustomer.CustomerId).subscribe(
              data => {
                if (data != null) {
                  this.buying = data
                  this.totalPayment = this.buying.BuyingSum + this.buying.ShipmentPrice;
                  this.buying.ProductsInBuying.forEach(element => {
                    this.numberOfItems += element.Quantity;
                  });
                  this.buying.ProductsInBuying.forEach(element => {
                    this.tempSum += element.ItemPrice * element.Quantity;
                  });
                  if (this.buying.ShipmentPrice != null)
                    this.isShipping = true
                  //קריאה לפונקציה שמקבלת את הגדרות תשלום של החנות
                  this.getAllActivePaymentSettingsByStoreId();
                }

              }, err => { console.log(err.message) }
            )
          }
        }

      }
      // בדיקה האם יש פרטי משלוח מעודכנים
      if (this.currCustomer.City != "" && this.currCustomer.FirstName != ""
        && this.currCustomer.PostalCode != "" && this.currCustomer.LastNameOrCompany != "" && this.currCustomer.NumHouse != null && this.currCustomer.NumApartment != null
        && this.currCustomer.PhoneNumber != "" && this.currCustomer.Street != "" && this.currCustomer.Entrance != null) {
        this.isExistUpdatedAddress = true;
        this.shippingToExistAddress = true;
      }
    }
  }



  //פונקציה ששולפת את הסל הזמני מהשרת ומטפלת בנושא של ההנחות והמשלוח המתאימים ללקוח בחנות
  getTempCartAndDiscountsByStoreCustomer() {
    //קבלת הסל הזמני מהשרת
    this.tempCartSer.getTempCartByStoreCustomerId(this.storeCustomer.StoreCustomerId, this.authSer.getCustomerToken(), this.storeCustomer.CustomerId).subscribe(
      data => {
        this.cart = data;
        this.cart.ProductInTempCart.forEach(element => {
          this.numberOfItems += element.Quantity;
        });
        //חישוב מחיר סל
        this.cart.ProductInTempCart.forEach(x => {
          this.cart.SumTempCart += x.Quantity * x.ProductInStore.Price;
        });
        //סכום ביניים - רש של המוצרים
        this.tempSum = this.cart.SumTempCart
        // צבירת הסכום הכולל

        this.totalPayment += this.cart.SumTempCart;

        //קבלת ההנחות בחנות והתאמה שלהם לסל הנוכחי
        this.discountSer.getDiscountForStore(this.store.StoreId, this.authSer.getCustomerToken(), 'Customer').subscribe(
          data => {

            this.discountInStore = data;
            let currentDate = new Date()
            let startDate;
            let disExpirationDate;
            for (let i = 0; i < this.discountInStore.length; i++) {
              startDate=null;
              disExpirationDate=null;
              if (this.discountInStore[i].StartDate != null)
                startDate = new Date(this.discountInStore[i].StartDate)

              if (this.discountInStore[i].DiscountExpiration != null)
                disExpirationDate = new Date(this.discountInStore[i].DiscountExpiration)
              if (startDate < currentDate && disExpirationDate > currentDate || (startDate < currentDate && disExpirationDate == null) || (startDate == null && disExpirationDate == null) || (startDate == null && disExpirationDate > currentDate)) {
                if (this.discountInStore[i].DiscountType == 'הנחת אחוזים' && this.discountInStore[i].Discount > this.discountPercentage.count) {
                  if (this.discountInStore[i].PurchasePriceToReceiveDiscount != null && this.cart.SumTempCart > this.discountInStore[i].PurchasePriceToReceiveDiscount) {
                    this.discountPercentage.count = this.discountInStore[i].Discount
                    this.discountPercentage.name = `הנחה של ${this.discountInStore[i].Discount}%בקניה מעל ${this.discountInStore[i].PurchasePriceToReceiveDiscount}₪`
                  }
                  else if (this.discountInStore[i].PurchasePriceToReceiveDiscount == null) {
                    this.discountPercentage.count = this.discountInStore[i].Discount
                    this.discountPercentage.name = `הנחה של ${this.discountInStore[i].Discount}%`
                  }
                }
                else if (this.discountInStore[i].DiscountType == 'הנחת סכום' && this.discountInStore[i].Discount > this.discountSum.count) {
                  if (this.discountInStore[i].PurchasePriceToReceiveDiscount != null && this.cart.SumTempCart > this.discountInStore[i].PurchasePriceToReceiveDiscount) {
                    this.discountSum.count = this.discountInStore[i].Discount
                    this.discountSum.name = `הנחה של ${this.discountInStore[i].Discount}₪בקניה מעל ${this.discountInStore[i].PurchasePriceToReceiveDiscount}₪`
                  }
                  else if (this.discountInStore[i].PurchasePriceToReceiveDiscount == null) {
                    this.discountSum.count = this.discountInStore[i].Discount
                    this.discountSum.name = `הנחה של ${this.discountInStore[i].Discount}₪`;
                  }

                }

              }
            }
            //חישוב תוספת תשלום אם יש, בסכום לתשלום
            if (this.store.PaymentForPurchase != null && this.store.PaymentForPurchase > 0) {
              this.totalPayment += this.store.PaymentForPurchase;

            }
            //חישוב  ההנחה בסכום לתשלום
            this.discountPercentage.count = this.cart.SumTempCart * this.discountPercentage.count / 100;
            this.discountPercentage.count = Math.round(this.discountPercentage.count * 100) / 100
            this.totalPayment -= this.discountSum.count;
            this.totalPayment -= this.discountPercentage.count

            // הכנסת כל הנתונים של הסל לתוך קנייה
            // הכנסת המוצרים
            this.buying.ProductsInBuying = [];
            this.buying.BuyingDetail = [];
            this.cart.ProductInTempCart.forEach(p => {
              let pb = new ProductsInBuying()
              pb.Quantity = p.Quantity;
              pb.ProductInStore = p.ProductInStore

              this.buying.ProductsInBuying.push(pb)
            });

            //  תוספת תשלום
            if (this.store.PaymentForPurchase > 0) {
              let bDetail = new BuyingDetail();
              bDetail.DetailSum = this.store.PaymentForPurchase;
              bDetail.DetailName = "תוספת תשלום"
              this.buying.BuyingDetail.push(bDetail)
            }

            //  הנחת אחוזים
            if (this.discountPercentage.count > 0) {
              let bDetail = new BuyingDetail();
              bDetail.DetailSum = this.discountPercentage.count;
              bDetail.DetailName = this.discountPercentage.name
              this.buying.BuyingDetail.push(bDetail)
            }
            //הנחת סכום
            if (this.discountSum.count > 0) {
              let bDetail = new BuyingDetail();
              bDetail.DetailSum = this.discountSum.count;
              bDetail.DetailName = this.discountSum.name
              this.buying.BuyingDetail.push(bDetail)
            }

          },
          err => {
            console.log(err.message)
          }
        )

        // משלוח---

        // חישוב מחיר משלוח
        if (this.store.DeliveryType != 'ללא') {

          if (this.store.DeliveryTypePrice == 'חינם') {
            this.shippingPrice = 0;
            // this.shippingText = "משלוח חינם"
          } else {
            if (this.store.DeliveryTypePrice == 'מחיר לפי משקל') {
              // this.shippingText = ` בחנות זו המשלוח מחושב לפי משקל המוצרים,
              // ₪${this.store.DeliveryPrice} לכל ק"ג.`

              let cartWeight = 0;
              this.cart.ProductInTempCart.forEach(pt => {
                if (pt.ProductInStore.ProductWeight != null) {
                  cartWeight += pt.Quantity * pt.ProductInStore.ProductWeight;
                }

              });
              this.shippingPrice = this.store.DeliveryPrice * (cartWeight / 1000);
            }
            else {
              if (this.store.DeliveryPrice != null && this.store.DeliveryPrice > 0) {
                this.shippingPrice = this.store.DeliveryPrice;
              }
            }
            if (this.store.FreeDelivery != null && this.cart.SumTempCart > this.store.FreeDelivery) {
              // this.shippingText = `משלוח חינם בקניה מעל ₪${this.store.FreeDelivery}`
              this.shippingPrice = 0
            }
          }
        }
        if (this.store.DeliveryType == 'חובה') {
          this.isShipping = true;
          this.totalPayment += this.shippingPrice;
        }


        // הכנסת מחיר משלוח לקניה
        this.buying.ShipmentPrice = this.shippingPrice

      },
      err => { console.log(err.message) }
    )
  }

  getAllActivePaymentSettingsByStoreId() {
    //קבלת הגדרות תשלום פעילות לחנות לפי מזהה חנות
    this.paymentSer.getAllActivePaymentSettingsByStoreId(this.store.StoreId).subscribe(
      data => {
        this.paymentSettings = data;
        if (this.paymentSettings.length == 0) {
          this.isSaveAndPayLaterSelected = true;
          this.isPaymentInSiteSelected = false;
        }

      },
      err => {
        if ((err.message as string).includes(ConfigModule.errMss)) {
          console.log(err.message);
        }
      })
  }

  //חזרה לחנות
  backToStore() {
    this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products']);
  }
  changeShipping() {
    this.isShipping = !this.isShipping
    if (this.isShipping == true) {
      this.totalPayment += this.shippingPrice
    }
    else {
      this.totalPayment -= this.shippingPrice
    }
  }

  isDelivery: number = 0;
  isLoading: boolean = false;
  isClicked: Boolean = false;
  isValidateAllFormFields: Boolean = true;
  displayErrorSaveCart: boolean = false;

  saveBuying(isValid, ps: PaymentSetting) {

    this.isValidateAllFormFields = true;
    if (isValid == true) {
      this.isClicked = true;
      this.isLoading = true
      this.buying.StoreCustomerId = this.storeCustomer.StoreCustomerId;
      this.buying.StoreCustomer = this.storeCustomer;
      this.buying.StoreId = this.store.StoreId;
      if (this.buying.StoreBranches == null)
        this.buying.StoreBranches = this.storeBranches.find(sb => sb.BranchIndex == this.branchIndexSelected);
      this.buying.BuyingStatus = 'נשמרה';
      if (this.shippingToExistAddress == true) {
        this.buying.ShipmentCity = this.currCustomer.City;
        this.buying.ShipmentStreet = this.currCustomer.Street;
        this.buying.ShipmentPostalCode = this.currCustomer.PostalCode;
        this.buying.ShipmentPhoneNumber = this.currCustomer.PhoneNumber;
        this.buying.ShipmentNumHouse = this.currCustomer.NumHouse;
        this.buying.ShipmentNumApartment = this.currCustomer.NumApartment;
        this.buying.ShipmentEntrance = this.currCustomer.Entrance;
        this.buying.ShipmentContactPerson = `${this.currCustomer.FirstName} ${this.currCustomer.LastNameOrCompany}`
        this.buying.ShipmentNotes = this.shipmentNotes;
      } else {

      }
      //שמירת הסל בשרת
      // להוסיף בםרטי משלוח כניסה והערות למשלוח

      if (this.isShipping)
        this.isDelivery = 1
      else
        this.isDelivery = 0
      //אם יש אפשרות בחנות של שמירת קנייה ללא תשלום
      if (this.isEnableSaveBuyingWithoutPaying == true && this.isSaveAndPayLaterSelected == true && this.isPaymentInSiteSelected == false) {
        this.tempCartSer.addCartToBuying(this.buying, this.isDelivery, null, this.storeCustomer.CustomerId, 'Customer').subscribe(
          data => {
            this.isLoading = false
            this.isClicked = false;
            if (data == 'true' || data == true) {
              this.csCartSer.setTempCartProductsQuantityInService(0)//איפוס כמות המוצרים בסל בסשין
              this.displaySaveCart = true;
              setTimeout(() => {
                this.router.navigate(['/store-links/' + this.storeAddressId + '/store-shopping-history']);
              }, 4500);
            }
            else {
              this.displayErrorSaveCart = true;
              setTimeout(() => {
                this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products']);
              }, 4000);


            }
          },
          err => {
            console.log(err.message)
          }
        )

      }
      else {
        if (this.isPaymentInSiteSelected == true)
          // שמירת נתוני הקנייה בסרביס
          if (this.isDelivery == 0)
            this.buying.ShipmentPrice = null;
        this.buyingSer.setBuyingInService(this.buying);
        this.paymentVia(ps);
      }
    }
    else {
      //ישנם שדות קלט עם ערכים לא חוקיים
      this.isValidateAllFormFields = false;
    }
  }

  //בדיקה דרך איזו חברת אשראי יתבצע התשלום
  paymentVia(ps: PaymentSetting) {
    this.isValidateAllFormFields = true;
    this.isClicked = true;
    this.isPaymentByNedarim = false;

    switch (ps.CreditCompany.CreditCompanyName) {
      case "iCount": {
        this.iCountPayPage(ps);
        break;
      }
      case "נדרים פלוס": {
        this.nedarimPayPage(ps);
        break;
      }
      default: {
        // code block
      }
    }
  }

  //פונקציות תשלום דרך חברות אשראי
  payPageUrl: string = null;
  iframeHeight: number = 700;
  iframeWidth: number = 50;
  displayErrorGetPayPage: boolean = false;

  //סגירת האייפרם
  closeIfram() {
    this.displayPayPage = false;
    this.isClicked = false;
    this.isPaymentByNedarim = false;
    this.isPaymentByNedarimClicked = false;
  }

  //-----iCount-----
  //קריאה לפונקציה של יצירת דף תשלום
  iCountPayPage(ps: PaymentSetting) {
    this.paymentSer.getIcountPayPage(ps.PaymentSettingId, this.storeCustomer.StoreCustomerId, this.currCustomer.UserName, this.isDelivery, this.buyingId).subscribe(
      data => {
        if (data == false || data == 'false') {
          //פרטי ההתחברות של מוכר שגויים
          this.isLoading = false;
          this.displayErrorGetPayPage = true;
          this.isClicked = false;
          setTimeout(() => {
            this.displayErrorGetPayPage = false;
          }, 4000);
        } else {
          this.isLoading = false
          // this.payPageUrl = 'http://localhost:4200/payment-success/32/67/0';
          this.payPageUrl = data["sale_url"];
          this.displayPayPage = true;
        }
      },
      err => {
        if ((err.message as string).includes(ConfigModule.errMss))
          console.log(err.message)
      }
    )
  }


  // ----------- NedarimPlus ------------

  isPaymentByNedarim: boolean = false;
  isPaymentByNedarimClicked: boolean = false;
  shovarByNedarim: string = null;
  currPaymentSetting: PaymentSetting = null;
  nedarimPayPageUrl: string = null;
  isValidateNedarimForm: boolean = true;
  errMessage: string = null;
  numOfPayments: number = 1;
  customer: Customers = new Customers();
  nedarimPayPage(ps: PaymentSetting) {
    this.nedarimPayPageUrl = "https://www.matara.pro/nedarimplus/iframe?language=eb";
    this.isLoading = false;
    this.isPaymentByNedarim = true;
    this.currPaymentSetting = ps;
    //בדיקת מקסימום תשלומים
    if (ps.MaxPayments > this.totalPayment / 10) {
      this.currPaymentSetting.MaxPayments = this.totalPayment / 10;
      //המרת המספר לשלם
      this.currPaymentSetting.MaxPayments = Math.trunc(this.currPaymentSetting.MaxPayments);
    }
    //קליטת פרטי לקוח לפי הלקוח שרשום במערכת
    this.customer.FirstName = this.currCustomer.FirstName;
    this.customer.City = this.currCustomer.City;
    this.customer.Street = this.currCustomer.Street;
    this.customer.Email = this.currCustomer.Email;
    this.customer.PhoneNumber = this.currCustomer.PhoneNumber;

  }

  payByNedarimBtClick(isValid) {

    if (isValid == true) {
      this.isPaymentByNedarimClicked = true;
      this.errMessage = '';
      var callBackUrl = 'https://ivr.business/api/Nedarim/postPayPageNedarimCallBack/' + this.currPaymentSetting.PaymentSettingId + "/" + this.storeCustomer.StoreCustomerId + "/" + this.buyingId;
      this.tempCartSer.setIsDeliveryOnPurchase(this.isDelivery, this.storeCustomer.StoreCustomerId).subscribe(
        data => {

          //העברת הנתונים לאייפרם נדרים
          this.PostNedarim({
            'Name': 'FinishTransaction2',
            'Value': {
              'Mosad': this.currPaymentSetting.Mosad,
              'ApiValid': this.currPaymentSetting.ApiValid,
              'PaymentType': "Ragil",
              'Currency': '1',

              'Zeout': this.customer.IdentityNumber,
              'FirstName': this.customer.FirstName,
              'LastName': '',
              'Street': this.customer.Street,
              'City': this.customer.City,
              'Phone': this.customer.PhoneNumber,
              'Mail': this.customer.Email,

              'Amount': this.totalPayment,
              'Tashlumim': this.numOfPayments,

              'Groupe': '',
              'Comment': '',

              'Param1': '',
              'Param2': '',
              'ForceUpdateMatching': '',

              'CallBack': callBackUrl,
              'Tokef': ''
            }
          });
          this.isLoading = true;
        },
        err => {
          if ((err.message as string).includes(ConfigModule.errMss)) {
            console.log(err.message);
          }
        })

    } else {
      this.isValidateNedarimForm = false;
    }
  }

  PostNedarim(data) {

    var isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === 'IFRAME';
    var iframeWin: HTMLElement = document.getElementById("nedarim_frame");
    var nedarimIframe;
    if (isIFrame(iframeWin) && iframeWin.contentWindow) {
      nedarimIframe = iframeWin.contentWindow;
      nedarimIframe.postMessage(data, "*");
    }
  }

  readPostNedarimMessage: any = (event: any) => {

    switch (event.data.Name) {
      case 'Height': {
        //Here you get the height of iframe | event.data.Value
        // document.getElementById('NedarimFrame').style.height = (parseInt(event.data.Value) + 15) + "px";
        // document.getElementById('WaitNedarimFrame').style.display = 'none';
        break;
      }
      case 'TransactionResponse': {
        console.log(event.data.Value)
        if (event.data.Value.Status == 'Error') {
          this.isPaymentByNedarimClicked = false;
          this.isLoading = false;
          this.errMessage = event.data.Value.Message;
        } else {
          document.getElementById('OkDiv').style.display = 'block';

          this.getPayPageNedarimInfo();
        }
        break;
      }
      default: {
        console.log("Not found");
        break;
      }
    }

  }


  getPayPageNedarimInfo() {

    this.paymentSer.getPayPageNedarimInfo(this.currPaymentSetting.PaymentSettingId, this.storeCustomer.StoreCustomerId, this.buying).subscribe(
      data => {

        if (data.paymentStatus == "Error") {
          this.errMessage = data.message;
          document.getElementById('OkDiv').style.display = 'none';
          this.isLoading = false;
        } else {
          this.isLoading = false;


          if (data.savedBuying == true) {
            this.displaySaveCart = true;
            this.csCartSer.setTempCartProductsQuantityInService(0)//איפוס כמות המוצרים בסל בסשין
            this.shovarByNedarim = data.shovar;
            setTimeout(() => {
              this.router.navigate(['/store-links/' + this.storeAddressId + '/store-shopping-history']);
            }, 4500);
          } else {
            // הצגת הודעה למשתמש
            alert("הקניה שולמה אך לא נשמרה מס.אישור:" + data.shovar);
          }
        }
      },
      err => {
        if ((err.message as string).includes(ConfigModule.errMss)) {
          console.log(err.message);
        }
      })
  }

  // -----------


  backToConfirmTC() {
    this.router.navigate(['/store-links/' + this.storeAddressId + '/cu-confirm-temp-cart/' + 0])
  }

  backToPage() {
    this.displayErrorGetPayPage = false;
  }

  ngOnInit() {
    if (window.addEventListener) {
      window.addEventListener("message", this.readPostNedarimMessage.bind(this), false);
      window.addEventListener("message", this.receiveMessage.bind(this), false);

      // window.addEventListener("message", this.checkGetPostMessage.bind(this), false);
    }

    //  else {
    //   // (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    // }

  }

  // מדף הצלחת תשלום post-message
  receiveMessage: any = (event: any) => {
    if (event.origin == "https://yemot-shopping.co.il" || event.origin == "https://yemot-shopping.co.il/store-links/" + this.storeAddressId + "/cu-confirm-temp-cart") {
      if (event.data.event_id === 'payment_message') {
        if (event.data.data["scId"] == this.storeCustomer.StoreCustomerId) {
          this.displayPayPage = false;
          //מעבר לדף הסטורית קניות
          if (event.data.data["ccSovar"] != null)
            this.router.navigate(['/store-links/' + this.storeAddressId + '/store-shopping-history']);
        } else {
          //מעבר לדף הבית
          this.router.navigate(['/shopping-center-links/shopping-center-home-page']);
        }
      }
    }
  }

  // checkGetPostMessage: any = (event: any) => {
  //   if (event.origin == "http://localhost:4200") {
  //     if (event.data.event_id === 'payment_message') {
  //       if (event.data.data["scId"] == 55) {
  //         this.displayPayPage = false;
  //         //מעבר לדף הסטורית קניות
  //         if (event.data.data["ccSovar"] != null)
  //           this.router.navigate(['/store-links/' + this.storeAddressId + '/store-shopping-history']);
  //       } else {
  //         //מעבר לדף הבית
  //         this.router.navigate(['/shopping-center-links/shopping-center-home-page']);
  //       }
  //     }
  //   }
  // }


}


// לא שימושי
      // this.customerSer.getVerificationCodeByCustomerId(this.currCustomer.CustomerId).subscribe(
      //   data => {
      //     if (data != 'false' && data != "false") {
      //     } else {
      //       alert("שגיאת אימות פנה למוכר");
      //     }
      //   },
      //   err => {
      //     if ((err.message as string).includes(ConfigModule.errMss)) {
      //       console.log(err.message);
      //     }
      //   })
