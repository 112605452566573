import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { Administrator } from '../classes/administrator';
import { Resailer } from '../classes/resailer';
import { Seller } from '../classes/seller';
import { UserLogin } from '../classes/user-login';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  URL=ConfigModule.url+"api/Managers/"

//שמירת המשווק שאליו נכנס המנהל בסרביס
currentResailerId:number;

  // listManager:Array<Manager>=new Array<Manager>();
  private manager:Administrator=null;

  //listResailer:Array<Resailer>=new Array<Resailer>();

  //listSalesePerson:Array<Seller>=new Array<Seller>();

  //1=ManagerYemot  2=Resailer  3=SalesPerson
  FlagUser:number=0;

  constructor(private http:HttpClient,
              private authSer:AuthService) {
   }

   getCurrentResailer():number
   {
     if(this.currentResailerId==null)
     this.currentResailerId=JSON.parse(sessionStorage.getItem('currentResailerId'))
     return this.currentResailerId;
   }
   setCurrentResailer(currentResailerId:number)
   {
     sessionStorage.setItem('currentResailerId',JSON.stringify(currentResailerId));
     this.currentResailerId=currentResailerId;
   }

   getCurrManager():Administrator
   {
    if(this.manager==null)
      this.manager=JSON.parse(sessionStorage.getItem('manager'))
    return this.manager;
   }

   setCurrManager(m:Administrator)
   {
     this.manager=m;
     sessionStorage.setItem('manager',JSON.stringify(m))
   }

  //  checkIfManagerExists(user:UserLogin):Observable<Manager>
  //  {
  //    return this.http.post<Manager>(this.URL+"checkIfManagerExists",user);
  //  }



    // EditDetailesManager(idManager:number,updatedManager:Manager):Observable<Manager[]>
    // {
    //   return this.http.put<Manager[]>(this.URL+"editDetailesManager/"+idManager,updatedManager);
    // }

    getManagerByManagerUserNameAndPassword(ul:UserLogin):Observable<Administrator>
    {
      return this.http.post<Administrator>(this.URL+"getManagerByManagerUserNameAndPassword",ul, { headers:new HttpHeaders({'Authorization':'Bearer '+this.authSer.getAdminToken()})});
    }
}
