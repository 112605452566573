import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { DiscountInStore } from '../classes/discount-in-store';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private customerSer:CustomerService,
    private sellerSer: SellerService) {
  }

  url = ConfigModule.url + "api/DiscountInStore/"
  allDiscounts: DiscountInStore[] = null;
  currDiscount: DiscountInStore = null;

  //לקיחה מהסשן את כל ההנחות לחנות מסוימת
  getAllDiscountsFromService(): DiscountInStore[] {
    if (this.allDiscounts == null && sessionStorage.getItem('allDiscounts'))
      this.allDiscounts = JSON.parse(sessionStorage.getItem('allDiscounts'))
    return this.allDiscounts;
  }
  //שמירה בסשן את כל ההנחות לחנות  מסוימת
  setAllDiscountInService(d: DiscountInStore[]) {
    this.allDiscounts = d;
    sessionStorage.setItem('allDiscounts', JSON.stringify(d));
  }

  getCurrDiscountFromService(discountId: number): DiscountInStore {
    if (this.allDiscounts == null)
      this.allDiscounts = JSON.parse(sessionStorage.getItem('discount'))
    return this.allDiscounts.find(x => x.DiscountInStoreId == discountId);
  }
  setCurrDiscountInService(d: DiscountInStore) {
    this.currDiscount = d;
    sessionStorage.setItem('discount', JSON.stringify(d));
  }
  //קבלת כל ההנחות לחנות מסוימת
  getDiscountForStore(StoreId,token:string,role:string): Observable<DiscountInStore[]> {
    let userId;
    if (role == 'Customer')
      userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    else
      userId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<DiscountInStore[]>(this.url + "getDiscountForStore/" + StoreId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  //הוספת הנחה לחנות
  //,sellerId:number
  addDiscount(discount: DiscountInStore): Observable<DiscountInStore> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    return this.httpClient.post<DiscountInStore>(this.url + "addDiscount", discount, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //עריכת הנחה
  editDiscount(discount: DiscountInStore): Observable<DiscountInStore> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    return this.httpClient.put<DiscountInStore>(this.url + "editDiscount/" + discount.DiscountInStoreId, discount, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  //מחיקת הנחה
  deleteDiscountFromStore(DiscountInStoreId: number, storeId: number): Observable<void> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    return this.httpClient.delete<void>(this.url + "deleteDiscount/" + DiscountInStoreId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  getDiscountById(id): Observable<DiscountInStore> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId;

    return this.httpClient.get<DiscountInStore>(this.url + "getDiscountById/" + id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }
}
