import { Category } from './category';
import { ProductFeature } from './product-feature';
import { SubCategory } from './sub-category';

export class ProductInStore {

  constructor(
    public ProductId: number = 0,
    public SKU: number = null,
    public Price: any = null,
    public ProductName: string = "",
    public ProductDescription: string = "",
    public ProductWeight: number = null,
    public ProductImage: string = "",
    public CategoryId: number = 0,
    public SubCategoryId: number = null,
    public QuantityInStock: number = null,
    public ToSale: any = 1,
    public LimitationBySale: number = null,
    public LimitationByCustomer: number = null,
    public Category: Category = null,
    public StoreId: number = 0,
    public SubCategory: SubCategory = null,
    public MinToBuy: number = null,
    //משתנים מקומיים
    public IsSelectedInTempCart: boolean = false,
    //משתנים עבור מוצרים בעלי מאפינים שונים
    public MinPrice: number = null,
    public MaxPrice: number = null,
    public ProductIndexInStore: number = null,
    public ProductFeature: ProductFeature[] = []
  ) {

    }
}
