import { Component, OnInit } from '@angular/core';
import { Customers } from '../../../classes/customers';
import { Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { ChangeUserPassword } from '../../../classes/change-user-password';
import { AuthService } from '../../../services/auth.service';
import { ShoppingCartComponent } from '../shopping-cart/shopping-cart.component';
import { ShoppingCenterLinksComponent } from '../shopping-center-links/shopping-center-links.component';
import { ShoppingCenterBottomLinksComponent } from '../mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';

@Component({
  selector: 'app-customer-profile-settings',
  templateUrl: './customer-profile-settings.component.html',
  styleUrls: ['./customer-profile-settings.component.css']
})
export class CustomerProfileSettingsComponent implements OnInit {

  currCustomer: Customers = new Customers()
  c: Customers = null;
  constructor(private customerSer: CustomerService,
    private router: Router,
    private authSer: AuthService,
    private centerLinksCom: ShoppingCenterLinksComponent,
    //private centerBtmLinksCom: ShoppingCenterBottomLinksComponent
  ) {

    centerLinksCom.changeDasignCenteralLink('customer-profile-settings-link');
    centerLinksCom.changeDasignCenteralMobileLink('sidenav-cuSettings-link');
    // centerBtmLinksCom.changeDasignCenteralMobileBtmLink('mobile-cuSettings-link');

    this.c = customerSer.getCurrCustomerFromService();
    //הלקוח כבר מחובר
    if (this.c != null) {
      this.currCustomer.City = this.c.City;
      this.currCustomer.Email = this.c.Email;
      this.currCustomer.FirstName = this.c.FirstName;
      this.currCustomer.PostalCode = this.c.PostalCode;
      this.currCustomer.CustomerId = this.c.CustomerId;
      this.currCustomer.LastNameOrCompany = this.c.LastNameOrCompany;
      this.currCustomer.NumHouse = this.c.NumHouse;
      this.currCustomer.NumApartment = this.c.NumApartment;
      this.currCustomer.CustomerPassword = this.c.CustomerPassword;
      this.currCustomer.PhoneNumber = this.c.PhoneNumber;
      this.currCustomer.CustomerStatus = this.c.CustomerStatus;
      this.currCustomer.Street = this.c.Street;
      this.currCustomer.UserName = this.c.UserName;
      this.currCustomer.Entrance = this.c.Entrance;
      this.currCustomer.HousePhoneNumber = this.c.HousePhoneNumber;
      this.currCustomer.AnotherPhoneNumber = this.c.AnotherPhoneNumber;
      this.currCustomer.RegistrationDate = this.c.RegistrationDate;
      this.currCustomer.IsPasswordRequired = this.c.IsPasswordRequired;
    }
    else {
      //שליחה לדף התחברות/הרשמה
      sessionStorage.setItem('pageLink', JSON.stringify('customer-profile-settings'));
      //לבצע הרשמה לפני הצגת דף קניות בביצוע
      this.router.navigate(['/login'])
    }


  }

  isClicked: Boolean = false;
  isValidateAllFormFields: boolean = true;
  isSaved: Boolean = false;
  isSuccessSavedChanges: boolean = false;
  //צריך לבדוק שאין שם משתמש כפול
  //וגם מספר טלפון
  //וגם במספר טלפון נוסף
  //וגם מספר טלפון בבית
  //וגם אמייל כפול
  isPhoneNumberExists: Boolean = false;
  isAnotherPhoneNumberExists: Boolean = false;
  isHousePhoneNumberExists: Boolean = false;
  isUserNameExists: Boolean = false;
  isEmailExists: Boolean = false;
  editCustomer(isFormValid) {
    //שדות הקלט תקינים ואפשר לבצע עדכון שדות
    if (isFormValid == true) {
      //בדיקה אם שינה את הגדרות סיסמה
      let cb = (document.getElementById('is_password_required') as HTMLInputElement).checked;
      if (cb == true)
        this.currCustomer.IsPasswordRequired = 1;
      else
        this.currCustomer.IsPasswordRequired = 0;
      document.getElementById('phone_number').classList.remove('input_err');
      document.getElementById('user_name').classList.remove('input_err');
      this.isClicked = true;
      this.isPhoneNumberExists = false;
      this.isUserNameExists = false;
      this.currCustomer.Email = this.currCustomer.Email ? this.currCustomer.Email : null;
      this.currCustomer.AnotherPhoneNumber = this.currCustomer.AnotherPhoneNumber ? this.currCustomer.AnotherPhoneNumber : null;
      this.currCustomer.HousePhoneNumber = this.currCustomer.HousePhoneNumber ? this.currCustomer.HousePhoneNumber : null;
      this.customerSer.checkIfCustomerUserNameOrPhoneNumbersOrEmailExists(this.currCustomer, this.authSer.getCustomerToken(), "Customer").subscribe(
        data => {
          if (data == 1) {
            this.isPhoneNumberExists = true;
            this.isClicked = false;
            // document.getElementById('phone_number').classList.add('input_err')
          }
          else if (data == 2) {
            this.isAnotherPhoneNumberExists = true;
            this.isClicked = false;
            // document.getElementById('phone_number').classList.add('input_err')
          }
          else if (data == 3) {
            this.isHousePhoneNumberExists = true;
            this.isClicked = false;
            // document.getElementById('phone_number').classList.add('input_err')
          }
          else if (data == 4) {
            this.isUserNameExists = true;
            this.isClicked = false;
            // document.getElementById('user_name').classList.add('input_err')
          }
          else if (data == 5) {
            this.isEmailExists = true;
            this.isClicked = false;
            // document.getElementById('Email').classList.add('input_err')
          }
          else {
            let role = "Customer"
            this.customerSer.editCustomer(this.currCustomer, this.authSer.getCustomerToken(), "Customer").subscribe(
              data => {
                this.customerSer.setCurrCustomerInService(data)
                this.isSaved = true;
                this.isClicked = false;
                this.isSuccessSavedChanges = true;
                setTimeout(() => {
                  this.isSuccessSavedChanges = false;
                }, 2500)
              },
              err => { console.log(err.message) }
            )
          }
        },
        err => { console.log(err.message) }

      )
    }
    //ישנם שדות קלט עם ערכים לא חוקיים
    else {
      this.isValidateAllFormFields = false;
    }


  }

  ngOnInit() {
  }
  showPassword() {

    var input = document.getElementById('txtPass');
    input.removeAttribute('type')
    input.setAttribute('type', 'text')
  }

  hidePassword() {
    //
    var input = document.getElementById('txtPass');
    input.removeAttribute('type')
    input.setAttribute('type', 'password');
  }


  //עבור שינוי סיסמא
  isShowChangePss: Boolean = false;

  //הצגת האפשרות לשינוי סיסמא
  showChangePss() {
    this.PssObj = new ChangeUserPassword()
    this.isShowChangePss = true
  }

  //הסתרת האפשרות של שינוי סיסמא
  hideChangePss() {
    this.isShowChangePss = false;
  }

  //משתנה שמראה אם הסיסמא הישנה שהקיש תקינה
  isOldPassRight: Boolean = true;

  //משתנה שמראה אם לחץ על שמור בשינוי סיסמא
  isChangePasswordClicked: Boolean = false;

  //אם החדשה והאימות זהים
  isNewPssTheSame: Boolean = true;

  //אם הסיסמא הישנה והחדשה זהות
  isNewPssAndOldTheSame: Boolean = true

  //אוביקט ששמור את הסימאות שהמשתמש מקיש
  PssObj: ChangeUserPassword = new ChangeUserPassword()

  //אם שסיסמא שונתה בהצלחה
  isPssWasChangedSuccessfully: Boolean = false;

  // פונקציה לשינוי סיסמא
  changePss() {
    ;
    this.isOldPassRight = true
    this.isNewPssAndOldTheSame = true;
    this.isNewPssTheSame = true;
    this.isChangePasswordClicked = true;

    //בדיקה אם הסיסמא החדשה תואמת לאימות סיסמא
    if (this.PssObj.NewPassword != this.PssObj.VerifyPassword) {
      this.isNewPssTheSame = false;
      this.isChangePasswordClicked = false;
    }
    else {
      this.PssObj.UserId = this.currCustomer.CustomerId;
      this.customerSer.changeCustomerPassword(this.PssObj).subscribe(
        data => {
          if (data == 0)//סיסמא ישנה שגויה
          {
            this.isOldPassRight = false;
          }
          else if (data == 2)//סיסמאות ישנה וחדשה תואמות
          {
            this.isNewPssAndOldTheSame = false;
          }
          else//הצלחה
          {
            //שמירה של הלקוח מחדש בסרביס כדי שלא ישתבש
            this.customerSer.setCurrCustomerInService(data);
            this.currCustomer.CustomerPassword = data.CustomerPassword

            this.isShowChangePss = false;

            this.isPssWasChangedSuccessfully = true;
            setTimeout(() => {
              this.isPssWasChangedSuccessfully = false;
            }, 3000);
          }
          this.isChangePasswordClicked = false;

        },
        err => {
          console.log(err.message)
        }
      )
    }
  }
}
