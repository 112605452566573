import { Component, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '../../../classes/store';
import { StoreService } from '../../../services/store.service';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { CustomerService } from '../../../services/customer.service';
import { CSStoreCustomerService } from '../../../services/customersiteservices/csstore-customer.service';
import { StoreCustomers } from '../../../classes/store-customers';
import { Customers } from '../../../classes/customers';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { HostListener } from '@angular/core';
import { TempCart } from '../../../classes/temp-cart';
import { ConfigModule } from 'src/app/config/config.module';
import { TempCartService } from 'src/app/services/temp-cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';


@Component({
  selector: 'app-store-links',
  templateUrl: './store-links.component.html',
  styleUrls: ['./store-links.component.css']
})
export class StoreLinksComponent implements OnInit {

  invokeAnimation: void;

  quantityProductInCart = 0
  storeCustomer: StoreCustomers = null
  customer: Customers = null
  storeAddressId = null
  store: Store = new Store();
  IsShowSmallCart: Boolean = false;
  IsEmitAnimation: boolean = false;
  url: string = '';
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private csStoreSer: CSStoreService,
    private csProductInStoreSer:CSProductInStoreService,
    private storeSer: StoreService,
    private customerSer: CustomerService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private tempCartSer: TempCartService,
    private authSer: AuthService,
    private csCartSer: CSCartService) {
    this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService()
    if (this.storeAddressId == null) {
      activatedRoute.params.subscribe(
        prm => { this.storeAddressId = prm["storeAddressId"] }
      )
      // this.csStoreSer.setCurrStoreAddressIdInService(this.storeAddressId)

    }
    this.url = ConfigModule.url
    //החנות הספציפית שאליה נכנס המשתמש
    //
    this.store = csStoreSer.getCurrStoreFromService();
    if (this.store == null || this.store.StoreAddressId != this.storeAddressId) {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          this.store = data;
          //--- Unusual code ---
          // this.csCartSer.getTempCart(this.store.StoreId);
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          //
          this.storeAddressId = data.StoreAddressId
          this.storeCustomer = csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

          //פונקציה שבודקת אם הלקוח רשום לחנות ואם הוא עדיין לא מחובר ומחברת אותו
          this.checkIfCustomerLogedIn();
        },
        err => {
          console.log(err.message)
        })
    }
    else {
      //
      this.storeAddressId = this.store.StoreAddressId
      this.storeCustomer = csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
      //פונקציה שבודקת אם הלקוח רשום לחנות ואם הוא עדיין לא מחובר ומחברת אותו
      this.checkIfCustomerLogedIn();
    }

    this.customer = customerSer.getCurrCustomerFromService();
  }
  //שינוי עיצוב בעת גלילת הדף
  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event) {
    $('.div_direction').addClass('div_direction_scroll');
    $('.div_store_links').addClass('div_store_links_scroll');
    $('.div_store_links_container').addClass('div_store_links_container_scroll');
    $('.div_central_links').addClass('div_central_links_scroll');
    $('.centeral_link_click').addClass('centeral_link_click_scroll');
    $('.div_store_title').addClass('div_store_title_scoll')

    if (window.scrollY == 0) {
      $('.div_central_links').removeClass('div_central_links_scroll');
      $('.div_direction').removeClass('div_direction_scroll');
      $('.div_store_links').removeClass('div_store_links_scroll');
      $('.div_store_links_container').removeClass('div_store_links_container_scroll');
      $('.div_central_links a').removeClass('centeral_link_click_scroll');
      $('.div_store_title').removeClass('div_store_title_scoll')
    }
  }

  ngOnInit() {

    //alert(this.storeAddressId)

    $(document).ready(function () {
      $('.div_central_links a').click(function () {

        var borderColor = ConfigModule.defaultCsCustomColor;
        $('.div_central_links a').removeClass('centeral_link_click');
        $(this).addClass('centeral_link_click')
        $(this).css("border-color", borderColor);
        $(this).animate({
          borderWidth: 2,
        }, "2000");
      })
      $('.sidenav a').click(function () {
        $('.sidenav a').removeClass('sidenav_a_click');
        $(this).addClass('sidenav_a_click')
      })

    })

  }

  emitAnimationCart(IsVisible: boolean) {
    this.IsEmitAnimation = !this.IsEmitAnimation;
    this.shoppingCartClick();
  }

  hiddenSmallCart(IsVisible: boolean) {
    //this.IsShowSmallCart = IsVisible;
    this.shoppingCartClick();
  }
  // שינוי עיצוב ללינק הונכחי
  changeDasignLink(id: string) {
    var borderColor = ConfigModule.defaultCsCustomColor;
    $('.div_central_links a').removeClass('centeral_link_click');
    $(`#${id}`).addClass('centeral_link_click')
    $(`#${id}`).css("border-color", borderColor);
    $(`#${id}`).animate({
      borderWidth: 2,
    }, "2000");
  }
  // שינוי עיצוב ללינק במובייל


  addToCartAnimate() {
    //אנימציה לסל בעת הוספת מוצר
    $(".a_shopping_cart").addClass("a_shopping_cart_animate");
    $(".p_amount_products").addClass("circle_red_animate");
    setTimeout(() => {
      $(".a_shopping_cart").removeClass("a_shopping_cart_animate");
      $(".p_amount_products").removeClass("circle_red_animate");
    }, 1500);
  }

  displayNotSignByClickOnCart: boolean = false;
  shoppingCartClick() {
    //בדיקת התחברות
    let storeCustomer: StoreCustomers = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    if (storeCustomer == null) {
      //הצגת הודעת למשתמש שכדי לצפות בסל הקניות צריך להתחבר למערכת
      this.displayNotSignByClickOnCart = !this.displayNotSignByClickOnCart;
      this.IsShowSmallCart = !this.IsShowSmallCart;
    }
    else {
      this.IsShowSmallCart = !this.IsShowSmallCart;
    }
  }


  goToStores() {
    this.storeSer.setIsStorePassVerifiedInService(false);
    //איפוס אוביקט סינון מהסרביס
    this.csProductInStoreSer.setFilterObjInServise(null);
    this.router.navigate(['/shopping-center-links/stors']);
  }

  // הצגה או הסתרה של התפריט הנפתח להתנתקות
  LoginCustomerClick() {
    this.displayDropdownMenue = !this.displayDropdownMenue;
    $(".a_store_login i").toggleClass("i_arrow_click");
  }
  //  האם להציג אפשרות התנתקות בלקוח מחובר לחנות
  displayDropdownMenue = false;
  //האם להציג דיאלוג ללקוח שלא מחובר למערכת הכללית
  displayNotSignIn = false
  //האם להציג דיאלוג ללקוח שהתחבר בהצלחה
  displaySignInSuccess = false
  // הצגת הודעה ללקוח שהרשמה לחנות נעשית רק ע"י המוכר
  displayCustomerRegistrationBySeller: boolean = false;
  //הרשמה/התחברות משתמש לחנות
  storeLogin() {
    //בדיקה אם הלקוח בכלל רשום
    let customer = this.customerSer.getCurrCustomerFromService();
    if (customer == null) {
      //alert('יש להרשם/להתחבר למערכת הכללית')
      //האם ברצונך להרשם למערכת הכללית???
      this.displayNotSignIn = true;
    }
    else {
      let storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId)
      if (storeCustomer != null) {
        alert('אתה כבר מחובר לחנות לחנות')
      }
      else {
        //בדיקה אם המשתמש כבר רשום לחנות
        this.csStoreCustomerSer.checkIfCustomerExistsInStore(customer.CustomerId, this.store.StoreId).subscribe(
          data => {
            if (data == null) {//אם המשתמש לא רשום לחנות על ידו
              //בדיקה אם המוכר לא רשם את הלקוח
              this.csStoreCustomerSer.checkIfStoreCustomerPhoneNumbersExists(this.customer.PhoneNumber, this.customer.AnotherPhoneNumber, this.customer.HousePhoneNumber, this.store.StoreId).subscribe(
                data => {
                  if (data) {//אם הלקוח רשום ע"י המוכר
                    //העברה לדף הרשמה לחנות
                    this.router.navigate(['/store-login'])
                  }
                  else {//אם לקוח לא רשום ע"י המוכר ו-
                    if (this.store.LimitationCustomerNotRegisteredBySeller == true) {// האם מוגדר בחנות שההרשמה נעשית רק ע"י המוכר
                      this.displayCustomerRegistrationBySeller = true;//הצגת הודעה שהרשמה נעשית ע"י המוכר בלבד
                    }
                    else {
                      //העברה לדף הרשמה לחנות
                      this.router.navigate(['/store-login'])
                    }
                  }
                },
                err => {
                  console.log(err)
                })

            }
            else {

              this.csStoreCustomerSer.setCurrStoreCustomerInService(data, this.storeAddressId);
              this.customer = this.customerSer.getCurrCustomerFromService();
              this.storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
              //אתה רשום לחנות
              //alert('התחברת בהצלחה')
              let quantity = this.csCartSer.getTempCartProductsQuantityInService();
              if (quantity == null) {
                //קבלת מספר המוצרים בסל הזמני מהשרת
                this.csCartSer.getTempCartProductsQuantityByStoreCustomerId(this.storeCustomer.StoreCustomerId).subscribe(
                  data => {
                    let q = parseInt(data);
                    //שמירת מספר המוצרים בסשן
                    this.csCartSer.setTempCartProductsQuantityInService(q);
                  },
                  err => { console.log(err.message) }
                )
              }
              //--- Unusual code ---
              //כאן לבדוק אם יש לו סל כלשהוא
              //ואם כן להביא אותו
              // let cart: TempCart = this.csCartSer.getTempCart(this.store.StoreId);

              // this.csCartSer.postCompareTempCarts(data.StoreCustomerId, this.store.StoreId, cart).subscribe(
              //   data => {
              //     //
              //     this.csCartSer.setTempCart(this.store.StoreId, data);

              //     //עדכון מספר המוצרים בסל
              //     //this.csCartSer.QuantityProductInCart=data.ProductInTempCart.length

              //     this.displaySignInSuccess = true
              //   },
              //   err => { console.log(err.message) })
            }
          })
      }
    }
  }

  //מעביר את הלקוח להתחברות כללית למערכת
  goToLoginCustomer() {
    //
    //let url=location.href;
    sessionStorage.setItem('pageLink', 'store-links/' + this.storeAddressId + '/store-products')
    this.router.navigate(['/login'])
  }

  exit() {
    //
    this.csStoreCustomerSer.exitStoreCustomerInService(this.storeCustomer, this.storeAddressId);
    this.storeCustomer = null;
    //--- Unusual code ---
    //מחיקת הסלים אם קיימים
    // this.csCartSer.removeTempCartByStoreIdinService(this.store.StoreId);
  }

  // checkIsStorePassVerified(linkName, url) {
  //   //בדיקה האם אומתה סיסמת לקוח לחנות
  //   if (!this.storeSer.getIsStorePassVerifiedInService()) {
  //     //בדיקה האם קימת סיסמת לקוח לחנות
  //     this.storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
  //       data => {
  //         if (data == true) {//יש סיסמת לקוחות בחנות
  //           //נווט לדף החניות
  //           this.router.navigate(['/shopping-center-links/stors']);
  //         }
  //         else {
  //           this.goByLinkName(linkName, url);
  //         }
  //       },
  //       err => {
  //         console.log(err);
  //       }
  //     )
  //   } else {
  //     this.goByLinkName(linkName, url);
  //   }
  // }


  // goByLinkName(linkName, url) {
  //   switch (linkName) {
  //     case 'login': {
  //       this.displayNotSignByClickOnCart = !this.displayNotSignByClickOnCart;
  //       this.IsShowSmallCart = !this.IsShowSmallCart;
  //       this.storeLogin();
  //       break;
  //     }
  //     case 'storeProducts': {
  //       this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products']);
  //       break;
  //     }
  //     case 'pageInBuild': {
  //       this.router.navigate(['/store-links/' + this.storeAddressId + '/page-in-build']);
  //       break;
  //     }
  //     case 'shipping': {
  //       this.router.navigate(['/store-links/' + this.storeAddressId + '/shipping/']);
  //       break;
  //     }
  //     case 'concatDetails': {
  //       this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'],{fragment: 'contact_details'});
  //       break;
  //     }
  //     default: {
  //       this.goToPage(url);
  //       break;
  //     }
  //   }
  // }

  //עבור בדיקה אם המשתמש רשום לחנות
  displayNotSignInToStore: boolean = false;

  goToPage(url) {
    let storeCustomer: StoreCustomers = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    if (storeCustomer == null) {
      this.displayNotSignInToStore = true;
      //sessionStorage.setItem('pageLink',url)
    }
    else {
      this.router.navigate([url])
    }
  }


  //פתיחת התפריט צד
  openNav() {
    $(".sidenav").addClass('width_sidenav');
  }
  //סגירת התפריט צד
  closeNav() {
    $(".sidenav").removeClass('width_sidenav');
  }

  //פונקציה שבודקת את המשתמש נרשם למערכת הכללית ואז צריך גם לחבר אותו
  //לחנות הנוכחית ואם יש לו סלים אז להביא אותם
  checkIfCustomerLogedIn() {
    //
    //בדיקה אם הלקוח בכלל מחובר
    let customer = this.customerSer.getCurrCustomerFromService();
    if (customer != null)//אכן מחובר למערכת הכללית
    {
      //בדיקה אם כבר מחובר לחנות מכניסות קודמות לחנות
      let storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId)
      //התחברות לקוח לחנות רק במצב שהוא מחובר למערכת
      // הכללית ועדיין לא מחובר לחנות הספציפית
      if (storeCustomer == null) {
        //בדיקה אם המשתמש כבר רשום לחנות
        this.csStoreCustomerSer.checkIfCustomerExistsInStore(customer.CustomerId, this.store.StoreId).subscribe(
          data => {
            if (data != null) {
              //העברה לדף הרשמה לחנות
              //this.router.navigate(['/store-login'])
              // }
              // else
              // {
              //
              this.csStoreCustomerSer.setCurrStoreCustomerInService(data, this.storeAddressId);
              this.customer = this.customerSer.getCurrCustomerFromService();
              this.storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
              //אתה רשום לחנות
              //כאן לבדוק אם יש לו סל כלשהוא
              //ואם כן להביא אותו
              //רק אם הלקוח מאופשר
              if (this.storeCustomer.IsCustomerEnable == true) {

                this.displaySignInSuccess = true;
                setTimeout(() => {
                  this.displaySignInSuccess = false;
                }, 500);
                //קבלת מספר המוצרים בסל הזמני מהסרביס
                let quantity = this.csCartSer.getTempCartProductsQuantityInService();
                if (quantity == null) {
                  //קבלת מספר המוצרים בסל הזמני מהשרת
                  this.csCartSer.getTempCartProductsQuantityByStoreCustomerId(this.storeCustomer.StoreCustomerId).subscribe(
                    data => {
                      let q = parseInt(data);
                      //שמירת מספר המוצרים בסשן
                      this.csCartSer.setTempCartProductsQuantityInService(q);
                    },
                    err => { console.log(err.message) }
                  )
                }

                //--- Unusual code ---
                // let cart: TempCart = this.csCartSer.getTempCart(this.store.StoreId);

                // this.csCartSer.postCompareTempCarts(data.StoreCustomerId, this.store.StoreId, cart).subscribe(
                //   data => {
                //     //שמירה של הסל יתבצע רק במקרה שמספר המוצרים בסל שונה מ0
                //     if (data != null && data.Items > 0) {
                //       this.csCartSer.setTempCart(this.store.StoreId, data);
                //     }
                //     //עדכון מספר המוצרים בסל
                //     //this.csCartSer.QuantityProductInCart=data.ProductInTempCart.length

                //     //this.displaySignInSuccess=true
                //   },
                //   err => { console.log(err.message) })
              }
            }
          })
      }
      else {
        //קבלת מספר המוצרים בסל הזמני מהסרביס
        let quantity = this.csCartSer.getTempCartProductsQuantityInService();
        if (quantity == null) {
          //קבלת מספר המוצרים בסל הזמני מהשרת
          this.csCartSer.getTempCartProductsQuantityByStoreCustomerId(this.storeCustomer.StoreCustomerId).subscribe(
            data => {
              let q = parseInt(data);
              //שמירת מספר המוצרים בסשן
              this.csCartSer.setTempCartProductsQuantityInService(q);
            },
            err => { console.log(err.message) }
          )
        }
      }
    }
  }
}
