import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Buying } from 'src/app/classes/buying';
import { Store } from 'src/app/classes/store';
import { BuyingService } from 'src/app/services/buying.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store-customer-buyings-not-paid',
  templateUrl: './store-customer-buyings-not-paid.component.html',
  styleUrls: ['./store-customer-buyings-not-paid.component.css']
})
export class StoreCustomerBuyingsNotPaidComponent implements OnInit {
  displayBuyingNotPaid: boolean = false;
  storeAddressId: number = null;
  isBuyingDisableForCustomerWithUnPaidBuyings: number = null;
  unPaidBuyings: Buying[] = [];
  pageLink:string=null;
  s:Store=new Store();
  constructor(private storSer: StoreService,
    private customerSer: CustomerService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private csStoreSer: CSStoreService,
    private buyingSer: BuyingService,
    private router: Router) {
    //שליפת מזהה החנות מהסרביס

   this.s = csStoreSer.getCurrStoreFromService();
    this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
    let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    if (sc != null) {
      //שליפת המשתנה האם מוגדר בחנות לאפשר רכישות נוספות, ללקוח שביצע רכישות ללא תשלום
      this.storSer.getStoreByStoreId(this.s.StoreId, this.customerSer.getCurrCustomerFromService().CustomerId).subscribe(
        data => {

          this.isBuyingDisableForCustomerWithUnPaidBuyings = data;
          //שליפת הסטורית קניות שלא שולמו, ללקוח בחנות
          if (this.isBuyingDisableForCustomerWithUnPaidBuyings != null) {
            this.buyingSer.getUnPaidBuyingsByStoreCustomerId(sc.StoreCustomerId, this.s.StoreId).subscribe(
              data => {
                this.unPaidBuyings = data;
                this.pageLink = sessionStorage.getItem('storePageLink');
                if(this.unPaidBuyings.length==0){
               if(this.pageLink==null)
               this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
                else
                  this.router.navigate(['/' + this.pageLink])

                  }
              },
              err => { console.log(err) }
            )
          }

        },
        err => {
          console.log(err);
        }
      );
    } else
      this.displayBuyingNotPaid = false;


  }
  ngOnInit() {
  }

  buyingIdToView: number = 0

  changeBuyingIdToView(id: number) {//להוסיף תנאי אם פתחו שורה אחת לפני שסגרו את הקודמת כי אחרת העיצוב של השורה והחץ נשארים
    if (this.buyingIdToView == id) {
      this.buyingIdToView = 0;
      document.getElementById('i_arrow_' + id).classList.remove('i_arrow_hover');
      document.getElementById('tr_' + id).classList.remove('tr_click');
    }
    else {
      this.buyingIdToView = id
      document.getElementById('i_arrow_' + id).classList.add('i_arrow_hover');
      document.getElementById('tr_' + id).classList.add('tr_click');
    }
  }

  cancel() {
   //עדכון האם להציג הודעה על קניות שלא שולמו
   this.csStoreCustomerSer.setIsCustomerSeeBuyingNotPaidInService(true)
   if(this.pageLink==null)
               this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
                else
                  this.router.navigate(['/' + this.pageLink])
  }
  backToStore() {
    if(this.pageLink==null)
    this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
     else
       this.router.navigate(['/' + this.pageLink])
  }
  goToPayment(buyingId) {
    this.router.navigate(['/store-links/' + this.storeAddressId + '/cu-confirm-temp-cart/' + buyingId])
  }
}
