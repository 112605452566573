import { Category } from "../category";

export class StoreProductsFilter {
    constructor(
        public CategoriesIds?: number[],
        public isFilterByPrice:boolean=false,
        public HighToLowPrice: boolean = false,
        public LowToHighPrice: boolean = false,
        public FromSum?: number,
        public ToSum?: number,
        public LoadNum: number = 1,
        public CountAll: number = 0
    ) {

    }
}