import { Component, OnInit } from '@angular/core';
import { SystemMessageByCustomer } from 'src/app/classes/system-message-by-customer';
import { CustomerService } from 'src/app/services/customer.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  currMessage: SystemMessageByCustomer = new SystemMessageByCustomer();
  textArr: string[] = [
    'הלקוחות שלך נמצאים כאן',
    'המכירה שלכם לפסח מתחילה כאן!',
    'תגלית מערכת המכירות לפסח:כנסו!',
    'מכירה שכונתית מתחילה כאן כנסו!',
    'מכירה זה לא שכונה... הכירו את הפלטפורמה החדשה'
  ]
  textToDisplay: string = '';
  index: number = 0;
  interval;



  constructor(private cusomerSer: CustomerService, private router: Router) {

    this.interval = setInterval(() => {
      if (this.index >= this.textArr.length)
        this.index = 0
      this.textToDisplay = this.textArr[this.index++]
    }, 2000)


  }

  ngOnInit() {
  }
  isFormValid: boolean = true;
  isClicked: boolean = false;
  displaySuccess: boolean = false;

goToSite() {
  window.open('https://yemot-shopping.co.il');
};

  sendDetails(isValid) {

    if (!isValid) {
      this.isFormValid = false;
    } else {
      this.isFormValid = true;
      this.isClicked = true;
      var x = document.getElementById('div_loading_img') as HTMLElement;
      x.classList.remove('hide')
      this.currMessage.Subject = 'פנייה מדף הנחיתה'
      this.cusomerSer.sendLandingPageRequestToSystem(this.currMessage).subscribe(
        data => {

          this.isClicked = false;
          if (data == true) {
            var x = document.getElementById('div_loading_img') as HTMLElement;
            x.classList.add('hide');
            this.displaySuccess = true;

          }
          else {
            var x = document.getElementById('div_loading_img') as HTMLElement;
            x.classList.add('hide');
            alert('בעיה בשליחה')
          }

        },
        err => {
          this.isClicked = false;
          var x = document.getElementById('div_loading_img') as HTMLElement;
          x.classList.add('hide')
          console.log(err.message)
        }
      )



    }
  }

  reloadPage(){
        let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
  }
}
