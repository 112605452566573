import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { UserLogin } from '../classes/user-login';
import { Observable } from 'rxjs';
import { Resailer } from '../classes/resailer';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //צריך לשנות לפי ה nodejs
  constructor(private httpClient: HttpClient) { }

  url = ConfigModule.url + "token";

  //עבור ההתחברות של המנהל
  managerLogin(ul: UserLogin): Observable<any> {
    var data = "username=" + ul.UserName + "&password=" + ul.Password + "&user_code=1&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' })

    return this.httpClient.post<any>(this.url, data, { headers: reqHeader });
  }
  private adminToken: string = null
  setAdminToken(token: string) {
    this.adminToken = token;
    sessionStorage.setItem('adminToken', this.adminToken);
  }
  getAdminToken() {
    if (this.adminToken == null)
      this.adminToken = sessionStorage.getItem('adminToken')

    return this.adminToken;
  }


  //עבור ההתחברות של המשווק
  resailerRepresentLogin(ul: UserLogin): Observable<any> {
    var data = "username=" + ul.UserName + "&password=" + ul.Password + "&user_code=2&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' })

    return this.httpClient.post<any>(this.url, data, { headers: reqHeader });
  }
  private resailerToken: string = null
  setResailerToken(token: string) {
    this.resailerToken = token;
    sessionStorage.setItem('resailerToken', this.resailerToken);
  }

  getResailerToken() {
    if (this.resailerToken == null)
      this.resailerToken = sessionStorage.getItem('resailerToken')

    return this.resailerToken;
  }


  //עבור התחברות של מוכר
  sellerLogin(ul: UserLogin): Observable<any> {
    var data = "username=" + ul.UserName + "&password=" + ul.Password + "&user_code=3&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' })

    return this.httpClient.post<any>(this.url, data, { headers: reqHeader });
  }
  private sellerToken: string = null
  setSellerToken(token: string) {
    this.sellerToken = token;
    sessionStorage.setItem('sellerToken', this.sellerToken);
  }
  getSellerToken() {
    if (this.sellerToken == null)
      this.sellerToken = sessionStorage.getItem('sellerToken')

    return this.sellerToken;
  }

  //עברו התחברות של לקוח
  customerLogin(ul: UserLogin): Observable<any> {
    var data = "username=" + ul.UserName + "&password=" + ul.Password + "&user_code=4&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' })

    return this.httpClient.post<any>(this.url, data, { headers: reqHeader });
  }
  private customerToken: string = null
  setCustomerToken(token: string) {
    this.customerToken = token;
    sessionStorage.setItem('customerToken', this.customerToken);
  }
  getCustomerToken() {
    if (this.customerToken == null)
      this.customerToken = sessionStorage.getItem('customerToken')

    return this.customerToken;
  }

  //nodeJsUrl=ConfigModule.nodeJsUrl;
  //זיהוי של נוד עבור מנהל
  nodeJsAdminLogin(ul: UserLogin): Observable<any> {
    return this.httpClient.post<any>(ConfigModule.url + "api/Admin/adminLogin", ul)
  }
  //כניסה למערכת בנוד עבור נציג של מנהל
  nodeJsRPLogin(ul: UserLogin): Observable<any> {
    return this.httpClient.post<any>(ConfigModule.url + "api/ResailerRepresent/rpLogin", ul)
  }

  //כניסה למערכת בנוד עבור מוכר ל
  nodeJsSellerLogin(ul: UserLogin): Observable<any> {

    return this.httpClient.post<any>(ConfigModule.url + "api/Seller/sellerLogin", ul)
  }

  nodeJsCustomerLogin(ul: UserLogin): Observable<any> {
    //var data = " { 'username': '" + ul.UserName + "' , 'password': '" + ul.Password + "' }";
    //var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' })
    //{ headers: reqHeader, form: data }
    return this.httpClient.post<any>(ConfigModule.url + "api/Customer/customerLogin", ul)
  }
  // nodeJsGetAllResailers():Observable<Resailer[]>
  // {
  //   return this.httpClient.get<Resailer[]>(this.u+"api/Resailer/getAllResailers",{headers:new HttpHeaders({"Authorization":"Bearer "+this.getAdminToken()})})
  // }
}
