import { Component, OnInit, Input, Output, DebugElement } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { CSStoreService } from '../../../services/customersiteservices/csstore.service';
import { ProductInTempCart } from 'src/app/classes/product-in-temp-cart';
import { ConfigModule } from 'src/app/config/config.module';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { Store } from 'src/app/classes/store';
import { TempCart } from 'src/app/classes/temp-cart';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { StoreCustomers } from 'src/app/classes/store-customers';
import { CustomerService } from 'src/app/services/customer.service';
import { BuyingService } from '../../../services/buying.service';
import { StoreLinksComponent } from '../store-links/store-links.component';
import { TempCartService } from 'src/app/services/temp-cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { DiscountInStore } from 'src/app/classes/discount-in-store';
import { DiscountService } from 'src/app/services/discount.service';
import { ProductInStore } from 'src/app/classes/product-in-store';
import * as $ from 'jquery';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {


  @Input() smCart: Boolean;
  @Output() onLongCartClick = new EventEmitter();


  showSmallCart: Boolean = false;
  numberOfItems: number = 0;
  url: string = ""
  storeAddressId;
  store: Store;
  cart: TempCart = null;
  storeCustomer: StoreCustomers = null;
  storeCustomerId: number = null;
  totalPayment: number = 0;
  discountInStore: DiscountInStore[] = [];
  discountSum: { count: number, name: string } = { count: 0, name: '' };
  discountPercentage: { count: number, name: string } = { count: 0, name: '' };

  // --משתנים להצגת הודעות
  displayEmptCart: boolean = false;
  displayInValidCart: boolean = false;
  displayNotSignInToStore: boolean = false;

  constructor(
    private router: Router,
    private csStoreSer: CSStoreService,
    private csCartSer: CSCartService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private customerSer: CustomerService,
    private buyingSer: BuyingService,
    private tempCartSer: TempCartService,
    private authSer: AuthService,
    private discountSer: DiscountService,
    private storeLinksCom: StoreLinksComponent,
    activatedRoute: ActivatedRoute) {

    storeLinksCom.changeDasignLink('shopping_cart');
    this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();
    if (this.storeAddressId == null) {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 2])
      this.storeAddressId = getCurrStoreAddressIdInService
    }
    // קבלת סל הקניות הזמני מהשרת ,ללקוח רשום ומחובר
    this.storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    this.storeCustomerId = null;
    if (this.storeCustomer != null)
      this.storeCustomerId = this.storeCustomer.StoreCustomerId;
    else {
      this.displayNotSignInToStore = true;

    }
    // אם הלקוח רשום, קבלת סל הקניות הזמני של החנות אליה נכנס
    if (this.storeCustomerId != null) {

      // קבלת החנות אליה נכנס המשתמש
      this.store = csStoreSer.getCurrStoreFromService();
      if (this.store == null) {
        csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
          data => {
            csStoreSer.setCurrStoreInService(data);
            this.store = data;
            this.url = ConfigModule.url + "files/Stores/" + this.store.StoreId + "/";
            //שליחה לפונ שמביאה את הסל הזמני מהשרת ואת ההנחות אם יש ללקוח בחנות
            this.getTempCartAndDiscountsByStoreCustomer();
          },
          err => { console.log(err.message) }
        )
      } else {

        //שליחה לפונ שמביאה את הסל הזמני מהשרת ואת ההנחות אם יש ללקוח בחנות
        this.url = ConfigModule.url + "files/Stores/" + this.store.StoreId + "/";
        this.getTempCartAndDiscountsByStoreCustomer();

      }

    }


    //-------- Unusual code
    // קבלת סל הקניות הזמני השמור במחשב לוקלית
    // this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();
    // this.store = csStoreSer.getCurrStoreFromService();
    // if (this.store == null) {
    //   csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
    //     data => {
    //       csStoreSer.setCurrStoreInService(data);
    //       this.store = data;
    //       this.url = ConfigModule.url + "files/Stores/" + this.store.StoreId + "/";
    //       this.csCartSer.cart = csCartSer.getTempCart(this.store.StoreId)
    //       this.csCartSer.cart.ProductInTempCart.forEach(element => {
    //         this.numberOfItems += element.Quantity;
    //       });
    //       this.cart = this.csCartSer.cart;
    //     },
    //     err => { console.log(err.message) }
    //   )
    // }
    // else {
    //   this.url = ConfigModule.url + "Files/Stores/" + this.store.StoreId + "/";
    //   this.csCartSer.cart = csCartSer.getTempCart(this.store.StoreId)
    //   this.csCartSer.cart.ProductInTempCart.forEach(element => {
    //     this.numberOfItems += element.Quantity;
    //   });
    //   this.cart = this.csCartSer.cart;
    // }

  }
  ngOnInit() {
    if (this.smCart != undefined)
      this.showSmallCart = this.smCart;
  }

  //פונקציה ששולפת את הסל הזמני מהשרת ומטפלת בנושא של ההנחות המתאימים ללקוח בחנות
  getTempCartAndDiscountsByStoreCustomer() {

    //קבלת הסל הזמני מהשרת
    this.tempCartSer.getTempCartByStoreCustomerId(this.storeCustomerId, this.authSer.getCustomerToken(), this.storeCustomer.CustomerId).subscribe(
      data => {
        this.cart = data;
        this.cart.ProductInTempCart.forEach(element => {
          this.numberOfItems += element.Quantity;
        });
        this.CalculateCartPriceFunc();
        this.totalPayment += this.cart.SumTempCart;
        this.getDiscountsAndPaymentForPurchase()


      },
      err => { console.log(err.message) }
    )
  }
  getDiscountsAndPaymentForPurchase() {
    //קבלת ההנחות בחנות והתאמה שלהם לסל הנוכחי
    this.totalPayment = this.cart.SumTempCart;
    this.discountSer.getDiscountForStore(this.store.StoreId, this.authSer.getCustomerToken(), 'Customer').subscribe(
      data => {

        this.discountInStore = data;
        this.discountPercentage.count = 0;
        this.discountSum.count = 0

        let currentDate = new Date()
        let startDate;
        let disExpirationDate;

        for (let i = 0; i < this.discountInStore.length; i++) {
          startDate=null;
          disExpirationDate=null;
          if (this.discountInStore[i].StartDate != null)
            startDate = new Date(this.discountInStore[i].StartDate)

          if (this.discountInStore[i].DiscountExpiration != null)
            disExpirationDate = new Date(this.discountInStore[i].DiscountExpiration)
          if (startDate < currentDate && disExpirationDate > currentDate || (startDate < currentDate && disExpirationDate == null) || (startDate == null && disExpirationDate == null) || (startDate == null && disExpirationDate > currentDate)) {
            if (this.discountInStore[i].DiscountType == 'הנחת אחוזים' && this.discountInStore[i].Discount > this.discountPercentage.count) {
              if (this.discountInStore[i].PurchasePriceToReceiveDiscount != null && this.cart.SumTempCart > this.discountInStore[i].PurchasePriceToReceiveDiscount) {
                this.discountPercentage.count = this.discountInStore[i].Discount
                this.discountPercentage.name = `הנחה של ${this.discountInStore[i].Discount}%בקניה מעל ${this.discountInStore[i].PurchasePriceToReceiveDiscount}₪`
              }
              else if (this.discountInStore[i].PurchasePriceToReceiveDiscount == null) {
                this.discountPercentage.count = this.discountInStore[i].Discount
                this.discountPercentage.name = `הנחה של ${this.discountInStore[i].Discount}%`
              }
            }
            else if (this.discountInStore[i].DiscountType == 'הנחת סכום' && this.discountInStore[i].Discount > this.discountSum.count) {
              if (this.discountInStore[i].PurchasePriceToReceiveDiscount != null && this.cart.SumTempCart > this.discountInStore[i].PurchasePriceToReceiveDiscount) {
                this.discountSum.count = this.discountInStore[i].Discount
                this.discountSum.name = `הנחה של ${this.discountInStore[i].Discount}₪בקניה מעל ${this.discountInStore[i].PurchasePriceToReceiveDiscount}₪`
              }
              else if (this.discountInStore[i].PurchasePriceToReceiveDiscount == null) {
                this.discountSum.count = this.discountInStore[i].Discount
                this.discountSum.name = `הנחה של ${this.discountInStore[i].Discount}₪`;
              }

            }

          }
        }

        //חישוב תוספת תשלום אם יש, בסכום לתשלום
        if (this.store.PaymentForPurchase != null && this.store.PaymentForPurchase > 0)
          this.totalPayment += this.store.PaymentForPurchase
        //חישוב  ההנחה בסכום לתשלום
        this.discountPercentage.count = this.cart.SumTempCart * this.discountPercentage.count / 100;
        this.discountPercentage.count = Math.round(this.discountPercentage.count * 100) / 100
        this.totalPayment -= this.discountSum.count;
        this.totalPayment -= this.discountPercentage.count;

      },
      err => {
        console.log(err.message)
      }
    )
  }

  showLongCartClick() {
    this.onLongCartClick.emit('false');
    this.isDispalyFixErrProducts = false;
    this.router.navigate(['/store-links/' + this.storeAddressId + '/cart']);
  }

  //מחיקת מוצר מהסל
  //{האם לא צריך לשאול לפני???}
  //
  productIdToDelete: number = 0;
  deleteProduct(p: ProductInTempCart) {
    //לעשות את הכפתור של המחיקה disabled
    //שלא יוכל ללחוץ שוב
    this.productIdToDelete = p.ProductId;
    //
    p.ProductInStore.IsSelectedInTempCart = false;
    let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

    //הלקוח נכנסל למערכת ויש למחוק את המוצר גם בדטה בייס
    if (sc != null) {
      this.csCartSer.deleteProductInTempCartByProductIdAndStoreCustomerId(sc.StoreCustomerId, p.ProductId).subscribe(
        data => {
          //עדכון פרטי הסל
          this.cart.SumTempCart -= p.ProductInStore.Price * p.Quantity;
          this.cart.Items -= p.Quantity;
          //מחיקה מהסל הזמני
          let ind = this.cart.ProductInTempCart.findIndex(x => x.ProductInStore.ProductId == p.ProductId);
          this.cart.ProductInTempCart.splice(ind, 1);
          this.productIdToDelete = 0;
          //עדכון מספר המוצרים בסל הזמני בסרביס
          let q = this.csCartSer.getTempCartProductsQuantityInService();
          this.csCartSer.setTempCartProductsQuantityInService(q - 1);
          this.getDiscountsAndPaymentForPurchase()
          //-------- Unusual code
          // this.csCartSer.cart = this.csCartSer.deleteProductFromTempCartInService(this.store, p, null);
          // this.cart = this.csCartSer.cart;
        },
        err => { console.log(err.message) }
      )
    }
    //-------- Unusual code
    // else {
    //   this.csCartSer.cart = this.csCartSer.deleteProductFromTempCartInService(this.store, p, null);
    //   this.cart = this.csCartSer.cart;
    //   this.productIdToDelete = 0;
    // }
  }

  displayDeleteShopping:boolean=false;
  displaySuccess:boolean=false;
  //הצגת הודעה למחיקת הסל
  showDeleteShopping() {
    this.displayDeleteShopping = true;;
  }
  // מחיקת הסל
  deleteTempCart() {
    this.displayDeleteShopping = false;
    this.tempCartSer.deleteTempCart(this.cart.StoreCustomerId).subscribe(
      data => {
        if (data == 1 || data == '1') {
          this.numberOfItems = 0;
          this.displaySuccess =true;
             //עדכון מספר המוצרים בסל הזמני בסרביס
             this.csCartSer.setTempCartProductsQuantityInService(0);

          setTimeout(() => {
            this.displaySuccess = false;
            this.backToStore();
          }, 2000)

        }
        else {
          alert('לא הצלחנו למחוק את הסל')
        }
      },
      err => {
      }
    )
  }



  //מחיקת מוצר מהסל
  // deleteProduct(c:ProductInTempCart)
  // {
  //   // בדיקה האם הלקוח רשום לחנות ומחיקה מהמסד נתונים
  //  ;
  //  //מחיקת המוצר מהסל מקומית מהמחשב
  //  this.cart.SumTempCart-= c.ProductInStore.Price*c.Quantity;
  //  this.cart.Items-=c.Quantity;
  //  let ind=this.cart.ProductInTempCart.findIndex(x=>x.ProductInStore.ProductId==c.ProductInStore.ProductId);
  //  this.cart.ProductInTempCart.splice(ind,1);

  //  sessionStorage.setItem('carts',JSON.stringify(this.cart))
  // }

  /*
  הפונקציה חייבת להיות בסרביס של הסל קניות
  minusProd(prod:ProductInTempCart)
  {

  // בדיקה האם הלקוח רשום לחנות ושמירה במסד נתונים
  // שינוי לוקלית על המחשב
  var p=this.cart.ProductInTempCart.find(x=>x.ProductId==prod.ProductId);
  if(p.Quantity>1)
  {
    p.Quantity-=1;
    p.Sum-=Math.round(p.ProductInStore.Price*100)/100;;
    this.cart.Items-=1;
    this.cart.SumTempCart-=Math.round(p.ProductInStore.Price*100)/100;
    sessionStorage.setItem('carts',JSON.stringify(this.cart))
  }
  else{
    alert("האם ברצונך למחוק את המוצר");
  }

  }
  */
  //אישור קניה
  displayPaymentMsg: boolean = false;
  isEmptyCart: boolean = false;
  isTempCartValid: boolean = true;
  errorsInCart: string[] = [];
  displayErrDetails: boolean = true;
  displayErrorsInCart: boolean = false;
  isErrProduct: boolean = false
  startConfirmBuying() {

    // בדיקה האם הלקוח רשום
    let storeCustomer: StoreCustomers = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    if (storeCustomer == null) {
      //... העברה לדף הודעות והרשמה...
      this.router.navigate(['/store-links/' + this.storeAddressId + '/login-messages']);
    }
    else {

       //בדיקה שבחנות אין אפשרות לבצע קניות ללקוח שיש לו קניות שלא שולמו או
        //  ששמוגדר בחנות להציג הודעה על קניות שלא שולמו בלי חסימת קנייה וללקוח וזה פעם ראשונה להצגת ההודעה הזו ללקוח
        if (this.store.IsBuyingDisableForCustomerWithUnPaidBuyings == 1 ||
          (this.store.IsBuyingDisableForCustomerWithUnPaidBuyings == 2 && this.csStoreCustomerSer.getIsCustomerSeeBuyingNotPaidInService() != true)) {
             //שליפת קניות שלא שולמו ללקוח בחנות
          this.buyingSer.getUnPaidBuyingsByStoreCustomerId(storeCustomer.StoreCustomerId, this.store.StoreId).subscribe(
            data => {

              // אם יש קניות שלא שולמו מעבר להודעה על הקניות
              if (data != null && data.length > 0) {
                sessionStorage.setItem('storePageLink', '/store-links/' + this.storeAddressId + '/cart');
                this.router.navigate(['/store-links/' + this.storeAddressId + '/store-customer-buyings-not-paid']);
              } else {
                this.confirmBuying(storeCustomer)
              }
            },
            err => { console.log(err) }
          )
      }
      else
        this.confirmBuying(storeCustomer)



    }

  }
  confirmBuying(storeCustomer){
  //שליחה לבדיקת הסל
      //בדיקה אם הלקוח מאושר

      if (storeCustomer.IsCustomerEnable == true) {
        this.tempCartSer.checkIfTempCartIsValid(this.storeCustomerId, this.store.StoreId, 'Customer', this.storeCustomer.CustomerId).subscribe(
          data => {

            if (data == '2') {//סל ריק
              this.isEmptyCart = true;
              this.isTempCartValid = false;
              //הצגת הודעה
              this.displayEmptCart = true;
            }

            else if (data == '1') {//סל תקין
              //מעבר לעמוד - סיכום קניהל
              this.router.navigate(['/store-links/' + this.storeAddressId + '/cu-confirm-temp-cart/' + 0])
            }
            else {//סל לא תקין

              if (data.includes('isErrProduct')) {
                this.isErrProduct = true
                data = data.replace('isErrProduct', " ")
              }
              this.errorsInCart = data.split("#");
              this.errorsInCart.splice(0, 1);

              this.isTempCartValid = false;
              //הצגת הודעה
              this.displayInValidCart = true;
              this.displayErrorsInCart = true;

              //עדכון של הסל במקרה שהמוכר עדכן הגבלות ולא היה רענון לעמוד
              this.getTempCartAndDiscountsByStoreCustomer()

              //סימון ופירוט בתוך המוצרים עם השגיאות
              setTimeout(() => {
                this.displayInValidCart = false;

                // בדיקת הגבלות על כל מוצר
                this.cart.ProductInTempCart.forEach(p => {
                  if (p.ProductInStore.ToSale == 0) {
                    $(`#${p.ProductInStore.ProductId}`).addClass('div_product_err');
                    $(`#${p.ProductInStore.ProductId} .product_err_text`).append("<p>המוצר לא למכירה</p>");
                    $(`#${p.ProductInStore.ProductId} .product_err_text p`).css({
                      "color": "red", "font-family": "Assistant SemiBold",
                      "text-align": "center", "font-size": "12px", "margin-bottom": "unset"
                    });
                  }
                  if (p.ProductInStore.LimitationByCustomer != null) {

                  }
                  if (p.ProductInStore.LimitationBySale != null && p.Quantity > p.ProductInStore.LimitationBySale) {
                    $(`#${p.ProductInStore.ProductId}`).addClass('div_product_err');
                    $(`#${p.ProductInStore.ProductId} .product_err_text`).append(`<p>    כמות ההזמנות ממוצר זה מוגבלת ל ${p.ProductInStore.LimitationBySale} יחידות בקנייה</p>`);
                    $(`#${p.ProductInStore.ProductId} .product_err_text p`).css({
                      "color": "red", "font-family": "Assistant SemiBold",
                      "text-align": "center", "font-size": "12px", "margin-bottom": "unset"
                    });
                  }
                  if (p.ProductInStore.MinToBuy != null && p.Quantity < p.ProductInStore.MinToBuy) {
                    $(`#${p.ProductInStore.ProductId}`).addClass('div_product_err');
                    $(`#${p.ProductInStore.ProductId} .product_err_text`).append(`<p >  מינימום להזמנה ממוצר זה הוא ${p.ProductInStore.MinToBuy} יחידות</p>`);
                    $(`#${p.ProductInStore.ProductId} .product_err_text p`).css({
                      "color": "red", "font-family": "Assistant SemiBold",
                      "text-align": "center", "font-size": "12px", "margin-bottom": "unset"
                    });

                  }

                  if (p.ProductInStore.QuantityInStock == 0) {
                    $(`#${p.ProductInStore.ProductId}`).addClass('div_product_err');
                    $(`#${p.ProductInStore.ProductId} .product_err_text`).append("<p>המוצר אזל מהמלאי</p>");
                    $(`#${p.ProductInStore.ProductId} .product_err_text p`).css({
                      "color": "red", "font-family": "Assistant SemiBold",
                      "text-align": "center", "font-size": "12px", "margin-bottom": "unset"
                    });
                  }
                });
              }, 2500);

            }

          },
          err => {
            console.log(err.message)
          }
        )
      } else {//להודיע שהלקוח לא מאופשר לקניות!!!!

      }
  }


  fixErrProducts: string[] = [];
  isDispalyFixErrProducts: boolean = false;
  dispalyFixErrProducts() {

    this.tempCartSer.getFixErrorsInTempCart(this.storeCustomerId, this.store.StoreId, 'Customer', this.storeCustomer.CustomerId).subscribe(
      data => {

        if (data == '0' || data == 0) {//הסל ריק
          this.displayEmptCart = true;

        } else if (data == '1' || data == 1) {//אין שגיאות במוצרים בסל
          this.isErrProduct = false;
          this.isDispalyFixErrProducts = true;
          this.fixErrProducts = [];
          this.fixErrProducts[0] = "אין לך שגיאות במוצרים ספציפיים בסל"

        }
        else {
          this.isDispalyFixErrProducts = true;
          this.fixErrProducts = data.split("#");

        }
      },
      err => {

      })
  }
  successFixErr: boolean = false;
  updateFixErrInTempCart() {

    this.tempCartSer.updateFixErrorsInTempCart(this.storeCustomerId, this.store.StoreId, 'Customer', this.storeCustomer.CustomerId).subscribe(
      data => {

        if (data == 'true' || data == true) {

          this.isDispalyFixErrProducts = false;
          this.successFixErr = true;
          setTimeout(() => {
            this.successFixErr = false;
            //רענון העמוד
            let currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });

          }, 3000);
        }

      },
      err => {

      })
  }

  /*
  הפונקציה חייבת להיות בסרביס של הסל קניות
  plusProd(prod:ProductInTempCart)
  {
  //בדיקה האם הלקוח רשום לחנות ושמירה במסד נתונים
  ;
  // שינוי לוקלית על המחשב
    //בדיקה אם אפשר להוסיף את המוצר לסל
    let res=this.csCartSer.checkIfProductCanBeAddedToCart(prod.ProductInStore);
    if(res==true)
    {
      var p=this.cart.ProductInTempCart.find(x=>x.ProductId==prod.ProductId);
      p.Quantity+=1;
      p.Sum+=Math.round(p.ProductInStore.Price*100)/100;;
      this.cart.Items+=1;
      this.cart.SumTempCart+=Math.round(p.ProductInStore.Price*100)/100;
      sessionStorage.setItem('carts',JSON.stringify(this.cart))
    }
    else{
      alert("אין אפשרות להזמין ממוצר זה")
    }

  }
  */

  //הוספת כמות למוצר
  prodIdToAdd: number = 0;

  addQuantityToProd(p: ProductInTempCart) {
    //לחיצה שוב על ה+ לא מאופשרת
    //..
    this.prodIdToAdd = p.ProductId
    let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

    if (sc != null) {
      this.csCartSer.getAddQuantityToProductInTempCart(sc.StoreCustomerId, p.ProductId).subscribe(
        data => {
          if (data == true || data == 'true') {
            let product = this.cart.ProductInTempCart.find(x => x.ProductId == p.ProductId);
            product.Quantity += 1;
            this.cart.Items += 1;
            this.cart.SumTempCart = this.cart.SumTempCart + product.ProductInStore.Price;
            //-------- Unusual code
            // this.cart = this.csCartSer.getAddQuantityToProductInTempCartInService(this.store.StoreId, p.ProductId);

          }
          else {
            alert(data)
          }
          this.prodIdToAdd = 0;
          this.getDiscountsAndPaymentForPurchase()
        },
        err => {
          console.log(err.message)
        }
      )
    }
    //-------- Unusual code
    // else {
    //   this.cart = this.csCartSer.getAddQuantityToProductInTempCartInService(this.store.StoreId, p.ProductId);
    //   this.prodIdToAdd = 0;
    // }
  }
  //הפחתת כמותת ממוצר

  productIdToReduce: number = 0
  reduceQuantityToProd(p: ProductInTempCart) {
    //לחיצה שוב על ה- לא מאופשרת
    //
    this.productIdToReduce = p.ProductId;

    let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

    if (sc != null) {
      if (p.Quantity > 1) {
        this.csCartSer.getReduceQuantityFromProductInTempCart(sc.StoreCustomerId, p.ProductId).subscribe(
          data => {
            if (data == true || data == 'true') {
              if (p.Quantity > 1) {
                p.Quantity -= 1;
                this.cart.Items -= 1;
                this.cart.SumTempCart = this.cart.SumTempCart - p.ProductInStore.Price;

              }
              //-------- Unusual code
              // this.cart = this.csCartSer.getReduceQuantityFromProductInTempCartInService(this.store.StoreId, p.ProductId);
            }
            else {
              alert(data)
            }
            this.productIdToReduce = 0;
            this.getDiscountsAndPaymentForPurchase()
          },
          err => {
            console.log(err.message)
          }
        )
      } else {
        this.productIdToReduce = 0;
      }
    }
    //-------- Unusual code
    // else {
    //   this.cart = this.csCartSer.getReduceQuantityFromProductInTempCartInService(this.store.StoreId, p.ProductId);
    //   this.productIdToReduce = 0;
    // }
  }
  CalculateCartPriceFunc() {
    //חישוב מחיר סל
    this.cart.ProductInTempCart.forEach(x => {
      this.cart.SumTempCart += x.Quantity * x.ProductInStore.Price;
    });
  }

  //חזרה לחנות
  backToStore() {
    this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products']);
  }

  saveInTempLink() {
    let customer = this.customerSer.getCurrCustomerFromService();
    this.buyingSer.getAddProductsInBuyingToDBIncludeDetailesShipment(
      this.storeAddressId, "null",
      "null", customer.UserName, "123456", 1, 0, 1, null,
      null, null, null, null, null, null, null, null).subscribe(
        data => {
          alert('הקניה נשמרה')
        },
        err => {
        }
      )
  }

}
