import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  constructor() {

  }
  sortByColumn(list: any[] | undefined, column: string, subColumn: string, direction = 'desc'): any[] {
    
    let sortedArray = (list || []).sort((a, b) => {
      if (subColumn != null) { //מערך
        if (a[column] != null && b[column] != null) {
          if (a[column][subColumn] > b[column][subColumn]) {
            return (direction === 'desc') ? 1 : -1;
          }
          if (a[column][subColumn] < b[column][subColumn]) {
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        }
        else
          return 0;
      }

      else { //משתנה
        if (a[column] > b[column]) {
          return (direction === 'desc') ? 1 : -1;
        }
        if (a[column] < b[column]) {
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      }
    })
    return sortedArray;
  }



}
