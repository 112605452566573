import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TempCart } from '../../classes/temp-cart';
import { ProductInStore } from '../../classes/product-in-store';
import { ProductInTempCart } from '../../classes/product-in-temp-cart';
import { Store } from '../../classes/store';
import { Observable } from 'rxjs';
import { ConfigModule } from '../../config/config.module';
import { AuthService } from '../auth.service';
import { TempCartService } from '../temp-cart.service';
import { CustomerService } from '../customer.service';
import { SellerService } from '../seller.service';

@Injectable({
  providedIn: 'root'
})
export class CSCartService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private tempCartSer: TempCartService,
    private customerSer: CustomerService,
    private sellerSer: SellerService) {
  }

  url = ConfigModule.url + "api/TempCart/"
  // האם להפעיל את האנימציה
  isRunAnimation: boolean = false;


  //הוספת מוצר לסל הפנימי
  /*
  addProductToTempCart(store:Store,product:ProductInStore,quantity:number,storeCustomerId:number):Boolean
  {

    //בדיקה אם הלקוח רשום ואז לשמור בדטה בייס
    //..
    //..
    if(storeCustomerId!=null)
    {
      //הוספת המוצר לסל בשרת
      this.getAddProductToTempCart(store.StoreId,product.ProductId,storeCustomerId,quantity).subscribe(
        data=>{
          if(data==true)
          {
            //הוספה מקומית
            this.addProductToTempCartInService(store,product,quantity);
            return true;
          }
          else
            return data;
        },
        err=>{
          return err.message;
        })
    }
    else{
      //הוספה מקומית
      var res=this.addProductToTempCartInService(store,product,quantity);
      return res;
    }
  }
  */


  //מחיקת מוצר מהסל בשרת
  deleteProductInTempCartByProductIdAndStoreCustomerId(storeCustomerId, productId): Observable<boolean> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId
    return this.httpClient.delete<boolean>(this.url + "deleteProductInTempCartByProductIdAndStoreCustomerId/" + storeCustomerId + "/" + productId, {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + this.authSer.getCustomerToken()
        , "UserId": userId + ''
      })
    })
  }

  /*
  deleteProductFromTempCart(store:Store,product:ProductInTempCart,storeCustomerId:number):TempCart
  {
    //בדיקה האם הלקוח רשום לחנות ומחיקה מהמסד נתונים
    if(storeCustomerId!=null)
    {
      this.deleteProductInTempCartByProductIdAndStoreCustomerId(storeCustomerId,product.ProductId).subscribe(
        data=>{

          if(data==true)
          {
            return this.deleteProductFromTempCartInService(store,product,storeCustomerId)
          }
        },
        err=>{ console.log(err.message) }
      )
    }
    else{
      //מחיקת המוצר מהסל מקומית מהסשן
      return this.deleteProductFromTempCartInService(store,product,storeCustomerId)
    }


  }
  */

  // שמירת עגלת הקניות בקניות בביצוע
  getAddCartToTempCart(storeCustomerId: number, cart: TempCart): Observable<string> {
    return this.httpClient.post<string>(this.url + 'getAddCartToTempCart/' + storeCustomerId, cart
      , { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getCustomerToken() }) });
  }

  //הוספת מוצר לסל בשרת
  getAddProductToTempCart(storeId: number, productId: number, storeCustomerId: number, quantity: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url + 'getAddProductToTempCart/' + storeId + "/" + productId + "/" + storeCustomerId + "/" + quantity
      ,{
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getCustomerToken(),
          'UserId': this.customerSer.getCurrCustomerFromService().CustomerId + ''
        })
      });
  }


  //הוספת כמות למוצר בסל
  getAddQuantityToProductInTempCart(storeCustomerId, productId): Observable<any> {

    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      }),
      responseType: 'text' as 'json'
    }
    return this.httpClient.get<any>(this.url + "getAddQuantityToProductInTempCart/" + storeCustomerId + "/" + productId, httpOptions)
  }

  //הורדת כמות ממוצר בסל
  getReduceQuantityFromProductInTempCart(storeCustomerId, productId): Observable<any> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      }),
      responseType: 'text' as 'json'
    }
    return this.httpClient.get<any>(this.url + "getReduceQuantityFromProductInTempCart/" + storeCustomerId + "/" + productId, httpOptions)
  }

  //קבלת מספר המוצרים בסל הקניות לפי מזהה לקוח של חנות
  getTempCartProductsQuantityByStoreCustomerId(storeCustomerId): Observable<any> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    return this.httpClient.get<any>(this.url + "getTempCartProductsQuantityByStoreCustomerId/" + storeCustomerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    });
  }


  //בדיקה אם אפשר להוסיף מוצר לסל
  public checkIfProductCanBeAddedToCart(product: ProductInStore): Boolean {
    if (product.QuantityInStock == 0)
      return false;

    if (product.ToSale == false)
      return false;

    //מה עם שאר ההגבלות

    return true;
  }

  tempCartProductsQuantity: number = null;

  //קבלת כמות המוצרים בסל הזמני
  //null הפונקציה מחזירה במקרה שאין סל
  getTempCartProductsQuantityInService() {
    this.tempCartProductsQuantity = JSON.parse(sessionStorage.getItem('tcProdQuantity'))
    return this.tempCartProductsQuantity;
  }

  setTempCartProductsQuantityInService(num) {
    this.tempCartProductsQuantity = num;
    sessionStorage.setItem('tcProdQuantity', JSON.stringify(this.tempCartProductsQuantity));
  }



  //-------- Unusual code ---------
  // Save the temporary carts in the computer's local Storage


  // getTempCartProductsQuantityByStoreIdInService(storeId) {
  //   let q = 0;
  //   if (this.carts == null) {
  //     this.carts = JSON.parse(localStorage.getItem('carts'))
  //   }
  //   // var c=this.carts.find(x=>x.StoreId==storeId)
  //   // var index;
  //   if (this.carts != null) {
  //     var c = this.carts.find(x => x.StoreId == storeId)
  //     if (c != null) {
  //       q = c.ProductInTempCart.length
  //     }
  //   }
  //   return q;
  // }

  // private carts: TempCart[] = null;
  // //QuantityProductInCart:number;

  // //הפונקציה מחזירה סל ריק במקרה שאין
  // getTempCart(storeId) {
  //   if (this.carts == null) {
  //     this.carts = JSON.parse(localStorage.getItem('carts'))
  //   }

  //   let cart = new TempCart(0, 0, 0, '', null, [], 0, 0);
  //   //...יש בעיה...
  //   if (this.carts != null && this.carts.find(x => x.StoreId == storeId))
  //     cart = this.carts.find(x => x.StoreId == storeId);

  //   //this.QuantityProductInCart=cart.ProductInTempCart.length;
  //   return cart;
  // }
  // setTempCart(storeId, tempCart: TempCart) {
  //   if (this.carts == null) {
  //     this.carts = JSON.parse(localStorage.getItem('carts'))
  //   }

  //   //let cart=new TempCart(0,0,0,'',null,[],0,0);
  //   if (this.carts != null) {
  //     var c = this.carts.find(x => x.StoreId == storeId)
  //     var index;
  //     if (c != null) {
  //       index = this.carts.findIndex(x => x.StoreId == storeId);
  //       this.carts[index] = tempCart;
  //     }
  //     else {
  //       this.carts.push(tempCart);
  //     }
  //   }
  //   else {
  //     this.carts = []
  //     this.carts.push(tempCart);
  //   }
  //   localStorage.setItem('carts', JSON.stringify(this.carts));
  // }
  // //מחיקה של כל הסלים שקימים במצב של התנתקות
  // removeAllTempCartByStoreIdinService() {
  //   this.carts = null;
  //   if (localStorage.getItem('carts'))
  //     localStorage.removeItem('carts')

  // }


  // //מחיקת סל של חנות בעת התנתקות
  // removeTempCartByStoreIdinService(storeId) {
  //   //
  //   if (this.carts == null) {
  //     this.carts = JSON.parse(localStorage.getItem('carts'))
  //   }
  //   // var c=this.carts.find(x=>x.StoreId==storeId)
  //   // var index;
  //   if (this.carts != null) {
  //     var c = this.carts.find(x => x.StoreId == storeId)
  //     if (c != null) {
  //       let index = this.carts.findIndex(x => x.StoreId == storeId);
  //       this.carts.splice(index, 1);
  //     }
  //   }
  //   if (this.carts != null)
  //     localStorage.setItem('carts', JSON.stringify(this.carts));
  // }

  // addProductToTempCartInService(store: Store, product: ProductInStore, quantity: number) {
  //   //בדיקה אם אפשר להוסיף את המוצר לסל
  //   let res = this.checkIfProductCanBeAddedToCart(product);
  //   if (res == false)
  //     return false;


  //   //בדיקה אם יש סלים בכלל
  //   this.checkIfSiteHasCarts();


  //   //בדיקה אם יש לחנות הספציפית סל

  //   let cart = this.carts.find(x => x.StoreId == store.StoreId);
  //   if (cart == null)//אם אין עדיין סל
  //   {
  //     cart = new TempCart(0, 0, store.StoreId, store.StoreName, new Date(Date.now()), new Array<ProductInTempCart>())
  //     this.carts.push(cart);
  //     cart = this.carts.find(x => x.StoreId == store.StoreId);
  //     //this.QuantityProductInCart=cart.ProductInTempCart.length;
  //   }

  //   //
  //   //בדיקה אם המוצר כבר קיים בסל
  //   let pInCart = cart.ProductInTempCart.find(x => x.ProductId == product.ProductId);
  //   if (pInCart != null)//הוספה לקיים
  //   {
  //     pInCart.Quantity += quantity;
  //     pInCart.Sum += Math.round(quantity * product.Price * 100) / 100;;
  //     cart.Items += quantity;
  //     cart.SumTempCart += Math.round(quantity * product.Price * 100) / 100;
  //   }
  //   else//הוספת חדש
  //   {
  //     let p = new ProductInTempCart();
  //     p.ProductId = product.ProductId;
  //     p.ProductInStore = product;
  //     p.Quantity = quantity;
  //     p.Sum = Math.round(quantity * product.Price * 100) / 100;
  //     if (cart.ProductInTempCart.length == 0)
  //       p.TempCartOpenDate = cart.TempCartOpenDate;

  //     cart.ProductInTempCart.push(p);

  //     cart.SumTempCart += Math.round(quantity * product.Price * 100) / 100;
  //     cart.Items += quantity;
  //     //this.QuantityProductInCart=cart.ProductInTempCart.length;
  //   }
  //   localStorage.setItem('carts', JSON.stringify(this.carts))
  //   return true;
  // }

  // //בדיקה אם יש סלים בכלל
  // private checkIfSiteHasCarts() {
  //   if (this.carts == null) {
  //     this.carts = JSON.parse(localStorage.getItem('carts'))
  //     if (this.carts == null) {
  //       this.carts = [];
  //       localStorage.setItem('carts', JSON.stringify(this.carts))
  //     }
  //   }
  // }

  // //מחיקה מקומית של מוצר מהסל
  // deleteProductFromTempCartInService(store: Store, product: ProductInTempCart, storeCustomerId: number) {
  //   //מחיקת המוצר מהסל מקומית מהמחשב
  //   let cart = this.getTempCart(store.StoreId);

  //   cart.SumTempCart -= product.ProductInStore.Price * product.Quantity;
  //   cart.Items -= product.Quantity;
  //   let ind = cart.ProductInTempCart.findIndex(x => x.ProductInStore.ProductId == product.ProductInStore.ProductId);
  //   cart.ProductInTempCart.splice(ind, 1);
  //   //this.QuantityProductInCart-=1;
  //   let carts: TempCart[] = JSON.parse(localStorage.getItem('carts'));
  //   let cartIndex = carts.findIndex(x => x.StoreId == store.StoreId);
  //   carts[cartIndex] = cart;

  //   //בדיקה אם כעת אין מוצרים כלל בסל ואז צריך למחוק את הסל בכלל
  //   if (cart.Items == 0) {
  //     this.removeTempCartByStoreIdinService(store.StoreId);
  //   }
  //   else {
  //     localStorage.setItem('carts', JSON.stringify(carts))
  //   }
  //   return cart;
  // }

  // getAddQuantityToProductInTempCartInService(storeId, productId) {
  //   let cart = this.getTempCart(storeId);
  //   let p = cart.ProductInTempCart.find(x => x.ProductId == productId);

  //   p.Quantity += 1;
  //   cart.Items += 1;
  //   cart.SumTempCart = cart.SumTempCart + p.ProductInStore.Price;

  //   this.setTempCart(storeId, cart);
  //   return cart;
  // } 

  // getReduceQuantityFromProductInTempCartInService(storeId, productId) {
  //   //
  //   let cart = this.getTempCart(storeId);
  //   let p = cart.ProductInTempCart.find(x => x.ProductId == productId);
  //   if (p.Quantity > 1) {
  //     p.Quantity -= 1;
  //     cart.Items -= 1;
  //     cart.SumTempCart = cart.SumTempCart - p.ProductInStore.Price;
  //     this.setTempCart(storeId, cart);
  //   }
  //   else {
  //     //
  //     let ind = cart.ProductInTempCart.findIndex(x => x.ProductId == productId);
  //     cart.ProductInTempCart.splice(ind, 1);

  //     cart.Items -= 1;
  //     cart.SumTempCart = cart.SumTempCart - p.ProductInStore.Price;
  //     //בדיקה אם כבר אין סל אז למחוק אותו
  //     //..
  //     if (cart.ProductInTempCart.length == 0) {
  //       this.removeTempCartByStoreIdinService(storeId);
  //       cart = new TempCart(0, 0, 0, "", null, [], 0, 0);
  //     }
  //     else
  //       this.setTempCart(storeId, cart);

  //   }
  //   return cart;
  // }

  //   //השוואה בין סלים
  // //הפונקציה נקראת בכל פעם שללקוח מתחבר לחנות
  // postCompareTempCarts(storeCustomerId, storeId, tempCart: TempCart): Observable<TempCart> {
  //   return this.httpClient.post<TempCart>(this.url + "postCompareTempCarts/" + storeCustomerId + "/" + storeId, tempCart,
  //     {
  //       headers: new HttpHeaders({
  //         'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
  //         , 'UserId': this.customerSer.getCurrCustomerFromService().CustomerId + ''
  //       })
  //     })
  // }



}





