import { Component, OnInit } from '@angular/core';
import  Chart  from 'chart.js';
declare var Chart:any;
@Component({
  selector: 'app-customer-agreement',
  templateUrl: './customer-agreement.component.html',
  styleUrls: ['./customer-agreement.component.css']
})
export class CustomerAgreementComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
