import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TempCart } from 'src/app/classes/temp-cart';
import { ProductInTempCartService } from 'src/app/services/product-in-temp-cart.service';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { Customers } from 'src/app/classes/customers';
import { ConfigModule } from 'src/app/config/config.module';
import { ProductInTempCart } from 'src/app/classes/product-in-temp-cart';
import { TempCartService } from 'src/app/services/temp-cart.service';
import { ShoppingCenterLinksComponent } from '../shopping-center-links/shopping-center-links.component';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { ShoppingCenterBottomLinksComponent } from '../mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';

@Component({
  selector: 'app-shopping-in-progress',
  templateUrl: './shopping-in-progress.component.html',
  styleUrls: ['./shopping-in-progress.component.css']
})

export class ShoppingInProgressComponent implements OnInit {
  displayPageInBuild = false
  displayDeleteProduct: boolean = false;
  displayDeleteShopping: boolean = false;
  displaySuccess: boolean = false;
  isSuccessDeleteProduct: boolean = false;
  CustomerName: string = "";
  currCustomer: Customers;
  allCarts: TempCart[] = []
  carts: TempCart[] = [];
  url: string = ConfigModule.url;
  currTempCart: TempCart = null;
  CalculateCartPrice: number = 0;
  numberOfItems: number = 0;
  id: number = 1;

  constructor(private productInTempCartSer: ProductInTempCartService,
    private CustomerSer: CustomerService,
    private tempCartSer: TempCartService,
    private csCartSer: CSCartService,
    private router: Router,
    private centerLinksCom: ShoppingCenterLinksComponent,
    private csStoreSer: CSStoreService
    //private centerBtmLinksCom: ShoppingCenterBottomLinksComponent
    ) {
    centerLinksCom.changeDasignCenteralLink('shopping-in-progress-link');
    centerLinksCom.changeDasignCenteralMobileLink('sidenav-shProgress-link');
  //  centerBtmLinksCom.changeDasignCenteralMobileBtmLink('mobile-shProgress-link');


    this.currCustomer = CustomerSer.getCurrCustomerFromService();
    if (this.currCustomer != null) {
      this.allCarts = this.productInTempCartSer.getTempCartsOfCustomerFromService();

      if (this.allCarts == null || this.allCarts.length == 0) {
        //לייבא ללקוח את כל הקניות בביצוע שפתח

        tempCartSer.getAllTempCartByCustomer(this.currCustomer.CustomerId).subscribe(
          data => {

            this.allCarts = data;
            this.carts = data;
            //שמירה בסשן את כל הקניות בביצוע ללקוח מסויים
            this.productInTempCartSer.setTempCartsOfCustomerInService(data);
            this.id = 1;
            //חישוב מחיר סל
            this.CalculateCartPriceFunc();
          },
          err => { console.log(err.message) }
        );
      }
      else {
        this.CalculateCartPriceFunc();
      }
    }
    else {
      sessionStorage.setItem('pageLink', JSON.stringify('shopping-in-progress'));
      //לבצע הרשמה לפני הצגת דף קניות בביצוע
      this.router.navigate(['/login'])
    }
  }
  deleteShopping: TempCart;

  //אישור למחיקת קניה
  DeleteShopping(shopping: TempCart) {
    this.displayDeleteShopping = true;;
    this.deleteShopping = shopping;
  }
  deleteProd: ProductInTempCart;
  deleteStoreCustomerId: number;
  ShowDeleteProduct(p: ProductInTempCart, shopping: TempCart) {
    if (shopping.ProductInTempCart.length == 1) {
      this.DeleteShopping(shopping);
    }
    else {
      this.displayDeleteProduct = true;
      this.deleteProd = p;
      this.deleteStoreCustomerId = shopping.StoreCustomerId;
    }
  }
  CalculateCartPriceFunc() {
    //חישוב מחיר סל
    this.allCarts.forEach(element => {
      element.ProductInTempCart.forEach(x => {
        ;
        this.CalculateCartPrice += x.Quantity * x.ProductInStore.Price
      });
      element.SumTempCart = this.CalculateCartPrice;
      this.CalculateCartPrice = 0;
    });
  }

  ngOnInit() {
    //   var $table = $('table'),
    //   $bodyCells = $table.find('tbody tr:nth-of-type(1)').children(),
    //     colWidth;
    //     console.log($bodyCells)

    // // Get the tbody columns width array
    // colWidth = $bodyCells.map(function() {
    //     return $(this).width();
    // }).get();
    // console.log(colWidth)
    // console.log($bodyCells[0].clientWidth)

    // Set the width of thead columns
    // $table.find('thead tr').children().each(function(i, v) {
    //     $(v).width(colWidth[i]);
    // });


  }

  idToView: number = 0
  openDetailesTempCart(id) {
    this.numberOfItems = 0;
    if (this.idToView == id) {
      document.getElementById('i_arrow_' + id).classList.remove('i_arrow_hover');
      this.idToView = 0;
    }
    else {
      this.idToView = id;
      document.getElementById('i_arrow_' + id).classList.add('i_arrow_hover');
    }
    this.currTempCart = this.allCarts.find(x => x.StoreCustomerId == id);
    this.currTempCart.ProductInTempCart.forEach(element => {
      this.numberOfItems += element.Quantity;
    });
  }

  deleteProductInTempCart() {
    this.displayDeleteProduct = false;
    var indexInCart = this.allCarts.findIndex(x => x.StoreCustomerId == this.deleteStoreCustomerId);
    if (this.allCarts[indexInCart].ProductInTempCart.length == 1) {
      //מחיקת כל הסל
      //להציג למשתמש הודעה שכל הסל ימחק
      alert("האם ברצונך למחוק את כל הסל?");
    }
    else {
      // מחיקת המוצר מהשרת
      this.csCartSer.deleteProductInTempCartByProductIdAndStoreCustomerId(this.deleteStoreCustomerId, this.deleteProd.ProductId).subscribe(
        data => {
          // מחיקת המוצר מהסשן
          //לבדוק מקרה שזה המוצר היחיד בסל ויש למחוק את כל הסל...

          let index = this.allCarts[indexInCart].ProductInTempCart.findIndex(x => x.ProductInTempCartId == this.deleteProd.ProductInTempCartId);
          this.allCarts[indexInCart].ProductInTempCart.splice(index, 1);
          this.productInTempCartSer.setTempCartsOfCustomerInService(this.allCarts);
          this.CalculateCartPriceFunc();

          this.isSuccessDeleteProduct = true;
          setTimeout(() => {
            this.isSuccessDeleteProduct = false;
          }, 2500)
          this.numberOfItems -= this.deleteProd.Quantity;
        },
        err => {
        }
      )
    }
  }

  deleteTempCart() {

    this.displayDeleteShopping = false;
    this.tempCartSer.deleteTempCart(this.deleteShopping.StoreCustomerId).subscribe(
      data => {
        if (data == 1 || data == '1') {
          //מחיקת הסל מהסשן
          var indexInCart = this.allCarts.findIndex(x => x.StoreCustomerId == this.deleteShopping.StoreCustomerId);
          this.allCarts.splice(indexInCart, 1);
          this.productInTempCartSer.setTempCartsOfCustomerInService(this.allCarts);
          this.displaySuccess = true;
          setTimeout(() => {
            this.displaySuccess = false;
          }, 2500)
          this.numberOfItems = 0;
        }
        else {
          alert('לא הצלחנו למחוק את הסל')
        }
      },
      err => {
      }
    )
  }



  minusFromProd(p: ProductInTempCart, scId) {
    if (p.Quantity > 1) {
      this.csCartSer.getReduceQuantityFromProductInTempCart(scId, p.ProductId).subscribe(
        data => {
          if (data == 1|| data=='true') {
            // הוספת המוצר בסשן
            p.Quantity -= 1;
            this.productInTempCartSer.setTempCartsOfCustomerInService(this.allCarts);
            this.CalculateCartPriceFunc();
            this.numberOfItems -= 1;
          }
          else {
            alert(data)
          }
        },
        err => {
        }
      )

    }
    else {
      //שאלה האם הור רוצה למחוק מוצר זה מהרשימה?

    }

  }

  plusFromProd(p: ProductInTempCart, scId) {

    this.csCartSer.getAddQuantityToProductInTempCart(scId, p.ProductId).subscribe(
      data => {
        if (data == true || data == 'true') {
          // הוספת המוצר בסשן
          p.Quantity += 1;
          this.productInTempCartSer.setTempCartsOfCustomerInService(this.allCarts);
          this.CalculateCartPriceFunc();
          this.numberOfItems += 1;
        }
        else {
          //הודעה אין אפשרות להוסיף  ממוצר זה פנה למוכר
          alert(data);
        }
      },
      err => {
      }
    )


  }
  goToStore(storeAddressId)//מעבר  לחנות
  {
    this.csStoreSer.setCurrStoreAddressIdInService(storeAddressId)
    this.router.navigateByUrl('/store-links/'+storeAddressId+'/store-products');
  }

}
