import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AboutStoreComponent } from './components/customer/about-store/about-store.component';
import { CustomerProfileSettingsComponent } from './components/customer/customer-profile-settings/customer-profile-settings.component';
import { SendRequestComponent } from './components/customer/send-request/send-request.component';
import { ShoppingCenterHomePageComponent } from './components/customer/shopping-center-home-page/shopping-center-home-page.component';
import { ShoppingCenterLinksComponent } from './components/customer/shopping-center-links/shopping-center-links.component';
import { ShoppingHistoryComponent } from './components/customer/shopping-history/shopping-history.component';
import { ShoppingInProgressComponent } from './components/customer/shopping-in-progress/shopping-in-progress.component';
import { StoreHomePageComponent } from './components/customer/store-home-page/store-home-page.component';
import { StoreLinksComponent } from './components/customer/store-links/store-links.component';
import { StorsComponent } from './components/customer/stors/stors.component';
import { CusomerLoginComponent } from './components/customer/cusomer-login/cusomer-login.component';
import { StoreProductsComponent } from './components/customer/store-products/store-products.component';
import { ShoppingCartComponent } from './components/customer/shopping-cart/shopping-cart.component';
import { StoreCustomerLoginComponent } from './components/customer/store-customer-login/store-customer-login.component';
import { CustomerAgreementComponent } from './components/customer/customer-agreement/customer-agreement.component';
import { ManagerLoginComponent } from './components/admin/manager-login/manager-login.component';
import { ResailerLoginComponent } from './components/resailer/resailer-login/resailer-login.component';
import { SellerLoginComponent } from './components/seller/seller-login/seller-login.component';
import { PageNotFoundComponent } from './components/others/page-not-found/page-not-found.component';
import { PageInBuildComponent } from './components/customer/page-in-build/page-in-build.component';
import { StorsChartComponent } from './components/customer/stors-chart/stors-chart.component';
import { StoreShoppingHistoryComponent } from './components/customer/store-shopping-history/store-shopping-history.component';
import { StoreProductComponent } from './components/customer/store-product/store-product.component';
import { LoginMessagesComponent } from './components/customer/login-messages/login-messages.component';
import { CustomerCreditsComponent } from './components/customer/customer-credits/customer-credits.component';
import { StoreShippingComponent } from './components/customer/store-shipping/store-shipping.component';
import { StoreCustomerSettingComponent } from './components/customer/store-customer-setting/store-customer-setting.component';
import { StoreBottomPageComponent } from './components/customer/store-bottom-page/store-bottom-page.component';
import { ShoppingHistoryPopMobileComponent } from './components/customer/mobile-components/shopping-history-pop-mobile/shopping-history-pop-mobile.component';
import { ShoppingHistoryStorePopComponent } from './components/customer/mobile-components/shopping-history-store-pop/shopping-history-store-pop.component';
import { AddToCartErrorComponent } from './components/customer/add-to-cart-error/add-to-cart-error.component';
import { PaymentsTracksComponent } from './components/seller/payments-tracks/payments-tracks.component';
import { CustomerConfirmTempCartComponent } from './components/customer/customer-confirm-temp-cart/customer-confirm-temp-cart.component';
import { PaymentSuccsesComponent } from './components/customer/payment-succses/payment-succses.component';
import { PaymentFailedComponent } from './components/customer/payment-failed/payment-failed.component';
import { StoreCustomerBuyingsNotPaidComponent } from './components/customer/store-customer-buyings-not-paid/store-customer-buyings-not-paid.component';
import { LandingPageComponent } from './components/others/landing-page/landing-page.component';


const routes: Routes = [
  {
    path: 'a',
    component: AddToCartErrorComponent

  },
  // //Admin
  {
    path: 'admin',
    component: ManagerLoginComponent
  },
  {
    path: 'admin-home-page',
    loadChildren: () => import('src/app/modules/admin-lazy-loading/admin-lazy-loading.module').then(m => m.AdminLazyLoadingModule)
  },
  //Resailer
  {
    path: 'resailer',
    component: ResailerLoginComponent
  },
  {
    path: 'resailer-links',
    loadChildren: () => import('src/app/modules/resailer-lazy-loading/resailer-lazy-loading.module').then(r => r.ResailerLazyLoadingModule)
  },
  //Seller
  {
    path: 'seller',
    component: SellerLoginComponent
  },
  {
    path: 'seller-hp-up-links',
    loadChildren: () => import('src/app/modules/seller-lazy-loading/seller-lazy-loading.module').then(s => s.SellerLazyLoadingModule)
  },
  {
    path: 'customer-agreement',
    component: CustomerAgreementComponent
  },
  {
    path: 'stors-chart',
    component: StorsChartComponent
  },
  //

//דף נחיתה - פרסומת לאתר

{path:'landing-page',component:LandingPageComponent},
  //אתר הלקוחות
  // {

  {
    path: 'shopping-center-links', component: ShoppingCenterLinksComponent, children: [
      { path: 'shopping-center-home-page', component: ShoppingCenterHomePageComponent },
      { path: 'stors', component: StorsComponent },
      { path: 'shopping-in-progress', component: ShoppingInProgressComponent },
      { path: 'shopping-history', component: ShoppingHistoryComponent },
      { path: 'shopping-history-pop-mobile', component: ShoppingHistoryPopMobileComponent },
      { path: 'shopping-history-store-pop', component: ShoppingHistoryStorePopComponent },
      { path: 'customer-profile-settings', component: CustomerProfileSettingsComponent },
      { path: 'send-request', component: SendRequestComponent }
    ]
    ,

  },
  //{path:':storeNameInSite',redirectTo:'store-links/:storeNameInSite/store-home-page',pathMatch:'full'},
  {
    path: 'store-links/:storeAddressId', component: StoreLinksComponent, children: [
      //
      { path: 'login-messages', component: LoginMessagesComponent },
      { path: 'store-home-page', component: StoreHomePageComponent },
      { path: 'about-store', component: AboutStoreComponent },
      { path: 'store-products', component: StoreProductsComponent },
      { path: 'product/:productId/:productSku', component: StoreProductComponent },
      { path: 'store-shopping-history', component: StoreShoppingHistoryComponent },
      { path: 'cart', component: ShoppingCartComponent },
      { path: 'page-in-build', component: PageInBuildComponent },
      { path: 'credits', component: CustomerCreditsComponent },
      { path: 'shipping', component: StoreShippingComponent },
      { path: 'store-customer-setting', component: StoreCustomerSettingComponent },
      { path: 'store-bottom', component: StoreBottomPageComponent },
      { path: 'cu-confirm-temp-cart/:buyingId', component: CustomerConfirmTempCartComponent },
      { path: 'store-customer-buyings-not-paid', component: StoreCustomerBuyingsNotPaidComponent }
    ]
  },
  { path: 'payment-success/:paymentLoginId/:storeCustomerId/:userLogin', component: PaymentSuccsesComponent },
  { path: 'payment-failed/:paymentLoginId/:storeCustomerId/:userLogin', component: PaymentFailedComponent },
  { path: 'payments-tracks', component: PaymentsTracksComponent },
  { path: 'login', component: CusomerLoginComponent },
  { path: 'store-login', component: StoreCustomerLoginComponent },
  { path: '', redirectTo: 'shopping-center-links/shopping-center-home-page', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    //('שיהיה אפשרות ניווט לאלמנט עם מזהה בתוך העמוד(כמו ב'צור קשר
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
