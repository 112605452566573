import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/classes/store';
import { ConfigModule } from 'src/app/config/config.module';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';

@Component({
  selector: 'app-store-bottom-page',
  templateUrl: './store-bottom-page.component.html',
  styleUrls: ['./store-bottom-page.component.css']
})
export class StoreBottomPageComponent implements OnInit {

  storeId
  storeAddressId
  store: Store = new Store()
  //rgb(192, 138, 243)
  customColor: string = ""

  constructor(private csStoreSer: CSStoreService,
    private csProductInStoreSer: CSProductInStoreService,
    private csCart: CSCartService) {
    //החנות הספציפית שאליה נכנס המשתמש
    if (this.csStoreSer.getCurrStoreAddressIdInService() != null) {
      this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
    }
    else {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 2])
      this.storeAddressId = getCurrStoreAddressIdInService
    }

    let s = this.csStoreSer.getCurrStoreFromService();
    if (s == null || s.StoreAddressId != this.storeAddressId) {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          this.storeId = data.StoreId;
          this.storeAddressId = data.StoreAddressId
          this.store = data;
          //צבע בבחירה אישית
          this.customColor = this.store.CustomColor
          if (this.customColor != null && this.customColor != '') {
            ConfigModule.csCustomColor = this.customColor;
          }
          else {
            this.customColor = ConfigModule.defaultCsCustomColor;
          }
        },
        err => {
          console.log(err.message)
        }
      )
    }
    else {
      this.storeId = s.StoreId
      this.store = s;

      this.customColor = this.store.CustomColor
      if (this.customColor != null && this.customColor != '') {
        ConfigModule.csCustomColor = this.customColor;
      }
      else {
        this.customColor = ConfigModule.defaultCsCustomColor;
      }
    }
  }

  ngOnInit() {
  }

}
