import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { BuyingService } from 'src/app/services/buying.service';
import { SavedCartService } from 'src/app/services/saved-cart.service';
import { StoreService } from 'src/app/services/store.service';
import { ProductInTempCartService } from 'src/app/services/product-in-temp-cart.service';
import { StoreCustomers } from 'src/app/classes/store-customers';
import { Buying } from 'src/app/classes/buying';
import { TempCart } from 'src/app/classes/temp-cart';
import { Router } from '@angular/router';
import { ConfigModule } from 'src/app/config/config.module';
import { Customers } from 'src/app/classes/customers';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from '../../../services/auth.service';
import { ShoppingCenterLinksComponent } from '../shopping-center-links/shopping-center-links.component';
import { ShoppingCenterBottomLinksComponent } from '../mobile-components/shopping-center-bottom-links/shopping-center-bottom-links.component';
import { SortService } from 'src/app/services/sort.service';

@Component({
  selector: 'app-shopping-history',
  templateUrl: './shopping-history.component.html',
  styleUrls: ['./shopping-history.component.css']
})
export class ShoppingHistoryComponent implements OnInit {
  currCustomer: Customers;
  customerId: number;
  allCarts: TempCart[] = [];
  allBuying: Buying[] = [];
  sumItems: number = 0;
  numProducts: number = 0;
  storeName: string = "";
  currBuying: Buying = null;
  storeCustomers: StoreCustomers[] = [];
  isLoaded: Boolean = false;
  isShowShoppings: Boolean = false;
  url: string = ConfigModule.url;
  isShowFilter: Boolean = false;
  ShoppingHistoryIdToShow: number = null;

  constructor(
    private buyingSer: BuyingService,
    private customerSer: CustomerService,
    private appComponent: AppComponent,
    private router: Router,
    private sortSer:SortService,
    private authSer: AuthService,
    private centerLinksCom: ShoppingCenterLinksComponent,
    //private centerBtmLinksCom: ShoppingCenterBottomLinksComponent
  ) {

     // centerBtmLinksCom.changeDasignCenteralMobileBtmLink('moile-home-link')
   centerLinksCom.changeDasignCenteralLink('shopping-history-link');
    centerLinksCom.changeDasignCenteralMobileLink( 'sidenav-history-link');

    this.currCustomer = customerSer.getCurrCustomerFromService();
    if (this.currCustomer != null)
      this.customerId = this.currCustomer.CustomerId;
    else {
      // ביצוע הרשמה
      sessionStorage.setItem('pageLink', JSON.stringify('shopping-history'));
      this.router.navigate(['/login'])
    }
    // שליפת הקניות ללקוח מהסרביס
    //this.allBuying = buyingSer.getBuyingsOfCustomerFromService();

    if (this.allBuying == null || this.allBuying.length == 0 || this.allBuying == undefined) {
      buyingSer.getBuyingsByCustomerId(this.customerId).subscribe(
        data => {

          data = data.reverse();

          //מיון הרכישות לפי תאריך
          data = data.sort((a, b) => new Date(b.BuyingDate).getTime() - new Date(a.BuyingDate).getTime());
          //שמירה בסרביס
          let num = 1;
          data.forEach(b => {
            b.BuyingNumber = num;
            num += 1;
          });
          num = 1;
          this.allBuying = data
          this.isLoaded = true;
          this.isShowShoppings = true;
        },
        err => {
          // if((err.message as string).includes(ConfigModule.errMss))
          // appComponent.showDialog();
          console.log(err.message)
        }
      )
    }


  }






  isDownloadClicked: Boolean = false
  buyingIdToDown: number = 0;

  downloadSummary(buyingId: number) {
    var x = document.getElementById('div_loading_img') as HTMLElement;
    x.classList.remove('hide')
    this.isDownloadClicked = true;
    this.buyingIdToDown = buyingId


    let role = "Customer"
    this.buyingSer.downloadBuyingSummary(buyingId, this.authSer.getCustomerToken(), role).subscribe(
      data => {
        this.buyingIdToDown = 0;
        var x = document.getElementById('div_loading_img') as HTMLElement;
        x.classList.add('hide');
        this.isDownloadClicked = false;
        var FileSaver = require('file-saver');
        var blob = new Blob([data],
          { type: "application/pdf" });
        FileSaver.saveAs(blob, buyingId + "_" + Date.now());
      },
      err => {
        var x = document.getElementById('div_loading_img') as HTMLElement;
        x.classList.add('hide')
        if ((err.message as string).includes(ConfigModule.errMss))
          // this.appComponent.showDialog();
          console.log(err.message)
      }
    )
  }

  idToView: number = 0
  openByBuing(id) {

    this.sumItems = 0;
    this.numProducts = 0;
    if (this.idToView == id) {
      document.getElementById('i_arrow_' + id).classList.remove('i_arrow_hover')
      document.getElementById('tr_select_' + id).classList.remove('tr_select')
      document.getElementById('tr_select_' + id).classList.remove('tr_border_btm')

      this.idToView = 0;
    }
    else {
      this.idToView = id;
      document.getElementById('i_arrow_' + id).classList.add('i_arrow_hover')
      document.getElementById('tr_select_' + id).classList.add('tr_select')
      document.getElementById('tr_select_' + id).classList.add('tr_border_btm')
    }

    this.currBuying = this.allBuying.find(x => x.BuyingId == id);

    this.numProducts = this.currBuying.ProductsInBuying.length;
    this.currBuying.ProductsInBuying.forEach(element => {
      this.sumItems += element.Quantity;
    });

  }


  bIdToChangeStatus: number = 0;
  isClicked: Boolean = false;
  changeStatus(buyingId: number, status: number) {
    this.bIdToChangeStatus = buyingId
    this.isClicked = true;
    this.buyingSer.changeBuyingStatus(buyingId, status).subscribe(
      data => {
        let b = this.allBuying.find(x => x.BuyingId == buyingId);
        if (status == 1)
          b.BuyingStatus = "נשמרה";
        else if (status == 2)
          b.BuyingStatus = "שולמה";
        else
          b.BuyingStatus = "סופקה";
        this.isClicked = false;
        this.bIdToChangeStatus = 0;
      },
      err => {
        //if ((err.message as string).includes(ConfigModule.errMss))
        // this.appComponent.showDialog();
        console.log(err.message)
      }
    )
  }
  tempBuyings: Buying[] = null;
  filterBuyingsByDate(fromDate: Date, toDate: Date) {


    this.allBuying = []
    this.isLoaded = false

    this.buyingSer.getBuyingsByCustomerIdAndDates(this.customerId, fromDate, toDate).subscribe(
      data => {
        data = data.reverse();

        //מיון הרכישות לפי תאריך
        data = data.sort((a, b) => new Date(b.BuyingDate).getTime() - new Date(a.BuyingDate).getTime());
        //שמירה בסרביס
        let num = 1;
        data.forEach(b => {
          b.BuyingNumber = num;
          num += 1;
        });
        num = 1;
        this.allBuying = data
        this.isLoaded = true;
        this.isShowShoppings = true;
      },
      err => {
        console.log(err.message)
      }
    )
  }

  ngOnInit() {
  }
  ShowShoppingHistoryPopMobile(buyingId: number) {
    this.ShoppingHistoryIdToShow = buyingId;
  }
  hideShoppingDetails(result) {
    this.ShoppingHistoryIdToShow = null;
  }
  sortOn(column: string, subColumn: string) {  //מיון נתוני הטבלה לפי שם עמודה נבחרת
    this.allBuying = this.sortSer.sortByColumn(this.allBuying, column, subColumn, 'desc');
  }
}
