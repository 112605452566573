import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductInStore } from 'src/app/classes/product-in-store';
import { Store } from 'src/app/classes/store';
import { ConfigModule } from 'src/app/config/config.module';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { ProductService } from 'src/app/services/product.service';
import { StoreLinksComponent } from '../store-links/store-links.component';
import * as $ from 'jquery';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';
import { ProductDataWithFeature } from 'src/app/classes/product-data-with-feature';
import { ProductFeature } from 'src/app/classes/product-feature';
import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { FeatureObject } from 'src/app/classes/feature-object';
import { FeatureValue } from 'src/app/classes/feature-value';
import { StoreService } from 'src/app/services/store.service';
import { timeStamp } from 'console';
import { StoreCustomers } from 'src/app/classes/store-customers';
import { BuyingService } from 'src/app/services/buying.service';

@Component({
  selector: 'app-store-product',
  templateUrl: './store-product.component.html',
  styleUrls: ['./store-product.component.css']
})
export class StoreProductComponent implements OnInit {

  url = ConfigModule.url;

  myThumbnail = ""//"http://localhost:53824/files/Stores/3/food4497.jpg"//"/assets/picture/product.png";

  productId: number;
  productSku: number;
  product: ProductInStore = new ProductInStore();
  store: Store
  storeAddressId;
  sum = 0;
  displayNotSignInToStore: boolean = false;
  displayStorePss: boolean = false;

  // ------ משתנים למוצרים בעלי מאפיינים-----
  //המוצר הנבחר - כלומר כל המוצרים בעלי המקט הנבחר
  pBySku: ProductDataWithFeature = null;



  // myFullresImage="/assets/picture/product.png";
  constructor(private activateRoute: ActivatedRoute,
    private productSer: ProductService,
    private csStoreSer: CSStoreService,
    private csCartSer: CSCartService,
    private csStoreCustomerSer: CSStoreCustomerService,
    private csProductInStoreSer: CSProductInStoreService,
    private productsSer: ProductService,
    private storeLinksCom: StoreLinksComponent,
    activatedRoute: ActivatedRoute,
    private router: Router,
    storeSer: StoreService,
    private buyingSer: BuyingService,) {
    storeLinksCom.changeDasignLink('product_link');

    activateRoute.params.subscribe(
      prm => this.productId = prm['productId']
    )
    activateRoute.params.subscribe(
      prm => this.productSku = prm['productSku']
    )


    this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();

    if (this.storeAddressId == null) {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 4])
      this.storeAddressId = getCurrStoreAddressIdInService
    }

    //בדיקה האם אומתה סיסמת לקוח לחנות
    if (!storeSer.getIsStorePassVerifiedInService()) {
      //בדיקה האם קימת סיסמת לקוח לחנות
      storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
        data => {
          if (data == true) {//יש סיסמת לקוחות בחנות
            //הצגת הודעה של הזנת סיסמא
            this.displayStorePss = true;
          }
          else {
            //קריאה לפונקצית קבלת המוצרים של החנות מהשרת
            this.getCurrStore();
          }
        },
        err => {
          console.log(err);
        }
      )
    } else {
      //קריאה לפונקצית קבלת המוצרים של החנות מהשרת
      this.getCurrStore();

    }

  }

  getCurrStore() {
    //קבלת החנות מהסשן

    this.store = this.csStoreSer.getCurrStoreFromService();
    if (this.store == null) {
      //קבלת החנות מהשרת
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          this.store = data;
          this.getProductInStoreFromServer();

        },
        err => { console.log(err.message) }
      )
    }
    else
      this.getProductInStoreFromServer();
  }
  getProductInStoreFromServer() {//קבלת המוצר מהשרת
    this.csProductInStoreSer.getProductsBySKU(this.store.StoreId, this.productSku).subscribe(
      data => {
        //חישוב סכום המוצר בסך הכל
        this.sum = data[0].Price;
        this.product = data[0];
        //כאשר למוצר זה יש מאפיין
        if (this.product.ProductFeature.length > 0) {
          this.pBySku = new ProductDataWithFeature(this.product.SKU, data);
          this.getProperties();
        }
      },
      err => {
        console.log(err.message);
      }
    );
  }

  ngOnInit() {
  }

  //הוספת כמות למוצר
  addQuantity() {
    let q = parseInt(document.getElementById('quantity').innerHTML)
    q++;
    document.getElementById('quantity').innerHTML = q + ""
    this.sum = q * this.product.Price;
  }

  //הורדת כמות ממוצר
  reduceQuantity() {
    let q = parseInt(document.getElementById('quantity').innerHTML)
    if (q > 1) {
      q--;
      document.getElementById('quantity').innerHTML = q + "";
      this.sum = q * this.product.Price;
    }
  }

  //הוספת המוצר לסל
  isAddToCartClicked = false

  addToCart() {

    if (this.isExistsInStock == 1 && this.isSelectedAll == true) {// כאשר יש למוצר מאפיינים , בדיקה האם בחר מכל תיבות הבחירה ,בחירה תקנית
      this.isAddToCartClicked = true;
      //בדיקת הכמות שהלקוח רצה
      let q = parseInt(document.getElementById('quantity').innerHTML);
      //בדיקה אם הלקוח רשום לחנות
      let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
      if (sc != null) {
        //בדיקה שבחנות אין אפשרות לבצע קניות ללקוח שיש לו קניות שלא שולמו או
        //  ששמוגדר בחנות להציג הודעה על קניות שלא שולמו בלי חסימת קנייה וללקוח וזה פעם ראשונה להצגת ההודעה הזו ללקוח
        if (this.store.IsBuyingDisableForCustomerWithUnPaidBuyings == 1 ||
          (this.store.IsBuyingDisableForCustomerWithUnPaidBuyings == 2 && this.csStoreCustomerSer.getIsCustomerSeeBuyingNotPaidInService() != true)) {
          //שליפת קניות שלא שולמו ללקוח בחנות
          this.buyingSer.getUnPaidBuyingsByStoreCustomerId(sc.StoreCustomerId, this.store.StoreId).subscribe(
            data => {
              // אם יש קניות שלא שולמו מעבר להודעה על הקניות
              if (data != null && data.length > 0) {
                sessionStorage.setItem('storePageLink', 'store-links/' + this.storeAddressId + '/store-products');
                this.router.navigate(['/store-links/' + this.storeAddressId + '/store-customer-buyings-not-paid']);
              } else {
                //הוספה לסל בשרת
                this.csCartSer.getAddProductToTempCart(this.store.StoreId, this.product.ProductId, sc.StoreCustomerId, q).subscribe(
                  data => {
                    //
                    if (data["isSucsess"] == true || data["isSucsess"] == 'true') {
                      //עדכון מספר המוצרים הקיימים בסל בסרביס
                      this.csCartSer.setTempCartProductsQuantityInService(parseInt(data["content"]));
                      //אנימציה של הוספה לסל
                      this.storeLinksCom.addToCartAnimate()
                    }
                    else {
                      //הצגת תוכן השגיאה
                      alert(data["content"]);
                    }
                    this.isAddToCartClicked = false;
                    document.getElementById('quantity').innerHTML = 1 + ""
                    this.sum = this.product.Price;
                  }
                )
              }
            },
            err => { console.log(err) }
          )
        } else {   //הוספה לסל בשרת
          this.csCartSer.getAddProductToTempCart(this.store.StoreId, this.product.ProductId, sc.StoreCustomerId, q).subscribe(
            data => {
              //
              if (data["isSucsess"] == true || data["isSucsess"] == 'true') {
                //עדכון מספר המוצרים הקיימים בסל בסרביס
                this.csCartSer.setTempCartProductsQuantityInService(parseInt(data["content"]));
                // --- Useless code ---
                // הוספה מקומית
                // let res = this.csCartSer.addProductToTempCartInService(this.store, this.product, q);
                // if (res != true) {
                //   alert(res);
                // }

                //אנימציה של הוספה לסל
                this.storeLinksCom.addToCartAnimate()
              }
              else {
                //הצגת תוכן השגיאה
                alert(data["content"]);
              }
              this.isAddToCartClicked = false;
              document.getElementById('quantity').innerHTML = 1 + ""
              this.sum = this.product.Price;
            }
          )
        }
      }
      else {
        //רק הוספה מקומית
        // let res = this.csCartSer.addProductToTempCartInService(this.store, this.product, q);
        // if (res != true) {
        //   alert(res);
        // }
        this.isAddToCartClicked = false;
        // document.getElementById('quantity').innerHTML = 1 + ""
        // this.sum = this.product.Price;
        alert('רק לקוחות רשומים יכולים לרכוש מוצרים')
      }
    }
    else {
      this.isAddToCartClicked = true;
      for (let i = 0; i < this.properties.length; i++) {
        if (this.properties[i].SelectedOption == null)
          document.getElementById('select_setting' + i).classList.add('input_err')
      }
    }
  }




  //עבור תצוגת מובייל
  mobileQuantity = 1
  mobileSumToPay = 0.0

  addToCartMobile() {
    if (this.isExistsInStock == 1 && this.isSelectedAll == true) {// כאשר יש למוצר מאפיינים בדיקה האם בחר מכל תיבות הבחירה ,בחירה תקנית
      this.isAddToCartClicked = true;
      //בדיקת הכמות שהלקוח רצה
      //בדיקה אם הלקוח רשום לחנות
      let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
      if (sc != null) {
        //הוספה לסל בשרת
        this.csCartSer.getAddProductToTempCart(this.store.StoreId, this.product.ProductId, sc.StoreCustomerId, 1).subscribe(
          data => {
            if (data["isSucsess"] == true || data["isSucsess"] == 'true') {
              //עדכון מספר המוצרים הקיימים בסל בסרביס
              this.csCartSer.setTempCartProductsQuantityInService(parseInt(data["content"]));

              // ---  Useless code ---
              // הוספה מקומית
              // let res = this.csCartSer.addProductToTempCartInService(this.store, this.product, 1);
              // if (res != true) {
              //   alert(res);
              // }
              // else {
              $('.add_to_cart_mobile').css('display', 'none');
              $('.payment_details_mobile').css('display', 'flex');
              $('.plus_minus_mobile').css('display', 'flex')
              //אנימציה של הוספה לסל
              this.storeLinksCom.addToCartAnimate()
              this.mobileSumToPay = this.product.Price
              // }
            }
            else {
              alert(data["content"]);
            }
            this.isAddToCartClicked = false;
            document.getElementById('quantity').innerHTML = 1 + ""
            this.sum = this.product.Price;
          }
        )
      }
      else {
        this.isAddToCartClicked = false;
        alert('רק לקוחות רשומים יכולים לרכוש מוצרים')
      }

    }
    else {
      this.isAddToCartClicked = true;
      for (let i = 0; i < this.properties.length; i++) {
        if (this.properties[i].SelectedOption == null)
          document.getElementById('select_setting' + i).classList.add('input_err')
      }
    }
  }
  //הוספת כמות למוצר
  isAddQuantityClicked: boolean = false

  addQuantityMobile() {

    this.isAddQuantityClicked = true
    let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

    if (sc != null) {
      this.csCartSer.getAddQuantityToProductInTempCart(sc.StoreCustomerId, this.product.ProductId).subscribe(
        data => {
          //
          if (data == true || data == 'true') {
            //  --- Useless code ---
            // this.csCartSer.getAddQuantityToProductInTempCartInService(this.store.StoreId, this.product.ProductId);
            //שינוי הכמות הנראית לעין
            //בHTML
            this.mobileQuantity += 1;
            this.mobileSumToPay += this.product.Price
            this.mobileSumToPay = parseFloat(this.mobileSumToPay.toFixed(2))
          }
          else {
            alert(data)
          }
        },
        err => {
          console.log(err.message)
        }
      )
    }

  }

  isReduceQuantityClicked: boolean = false

  //הורדת כמות ממוצר
  reduceQuantityMobile() {

    this.isReduceQuantityClicked = true
    //בדיקה אם הכמות היא יותר מ0 ואז אפשר להוריד וגם להוריד את העיצוב
    if (this.mobileQuantity > 1) {

      //לחיצה שוב על ה- לא מאופשרת
      //
      let sc = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

      if (sc != null) {
        this.csCartSer.getReduceQuantityFromProductInTempCart(sc.StoreCustomerId, this.product.ProductId).subscribe(
          data => {
            if (data == true || data == 'true') {
              //--- Useless code ---
              // this.csCartSer.getReduceQuantityFromProductInTempCartInService(this.store.StoreId, this.product.ProductId);
              //שינוי הכמות בדף HTML
              this.mobileQuantity -= 1;
              this.mobileSumToPay -= this.product.Price
              this.mobileSumToPay = parseFloat(this.mobileSumToPay.toFixed(2))
            }
            else {
              alert(data)
            }
          },
          err => {
            console.log(err.message)
          }
        )
      }

    }
  }


  // ----עבור מוצר עם מאפיינים----

  properties: FeatureObject[] = [];
  property: FeatureObject = new FeatureObject();
  //משתנה של אינדקס המאפיין האחרון שהמשתמש שינה
  lastModifyIndex: number;
  //המאפיינים שהמשתמש בחר בבחירה האחרונה
  lastModifyProperties: any[] = [];
  //האם כל תיבת הבחירה מלאות
  isSelectedAll: boolean = true;
  // אוביקט ערך מאפיין המכיל את הערך ומשתנה האם קיים במלאי
  fValue: FeatureValue = new FeatureValue();

  isExistsInStock: number = 1;

  //קבלת שמות וערכי המאפיינים של המוצר
  getProperties() {
    let prices = [];
    this.product = this.pBySku.Products[0];
    //בדיקה האם קיימים מאפיינים למוצר
    if (this.pBySku.Products[0].ProductFeature.length > 0) {

      //איפוס המשתנה היות ועדיין המשתמש לא בחר את המאפיינים
      this.isSelectedAll = false;
      //עבור הכנסת שמות המאפיינים הקיימים למוצר
      this.pBySku.Products[0].ProductFeature.forEach(pf => {
        this.property.Key = pf.FeatureName;
        this.property.Values = [];
        this.property.SelectedOption = null;
        this.properties.push(this.property);
        this.property = new FeatureObject();
        this.fValue = new FeatureValue();
      });

      //קליטת הערכים הקיימים לכל מאפיין ללא ערכים כפולים
      this.pBySku.Products.forEach(p => {
        for (let i = 0; i < p.ProductFeature.length; i++) {
          if (this.properties[i].Values.find(x => x.Value == p.ProductFeature[i].FeatureValue) == null) {
            this.fValue.Value = p.ProductFeature[i].FeatureValue;
            this.fValue.IsExistsInStock = 1;
            this.properties[i].Values.push(this.fValue);
            this.fValue = new FeatureValue();
          }
        }
      });

      // מעבר על רשימת המוצרים עם מאפיינים ומציאת מחיר מינמלי ומקסימלי
      if (this.product.MaxPrice == null) {// במקרה של טעינה נוספת למנוע את החיפוש שוב
        for (let i = 0; i < this.pBySku.Products.length; i++) {
          prices.push(this.pBySku.Products[i].Price);
        }
        this.product.MinPrice = this.pBySku.Products[0].MinPrice = Math.min(...prices);
        this.product.MaxPrice = this.pBySku.Products[0].MaxPrice = Math.max(...prices);
        prices = [];
      }
    }

  }

  //פונקציה שמשנה את אפשריות בחירת המאפיינים לפי בחירת המשתמש
  onChangeProperty(event, index) {
    let v = event.target.value;
    let selectionsCount = 0;
    let count = 0;
    //מניית מספר המאפיינים שהמשתמש בחר
    for (let i = 0; i < this.properties.length; i++) {
      if (this.properties[i].SelectedOption != null)
        selectionsCount++;
      else { // מקרה קצה
        if (v == 0 && i == index) {//[(ngModel)] לא קלט ערך 0
          this.properties[i].SelectedOption = 0;
          selectionsCount++;
        }
      }
    }

    if (this.isAddToCartClicked == true) {//הסרת מסגרת אדומה לסלקטים
      for (let i = 0; i < this.properties.length; i++) {
        if (this.properties[i].SelectedOption != null) {
          document.getElementById('select_setting' + i).classList.remove('input_err');
        }
      }
    }

    //המשתמש לא סיים לבחור מכל סוגי המאפיינים הקיימים
    if (selectionsCount <= this.properties.length && this.isSelectedAll == false) {
      // סימון ערכי המאפיינים הקיימים לפי בחירת המשתמש
      // סימון כל המאפיינים בסלקטים שעדין לא בחר כלא מאופשרים
      for (let i = 0; i < this.properties.length; i++) {
        if (this.properties[i].SelectedOption == null) {
          this.properties[i].Values.forEach(v => {
            v.IsExistsInStock = 0;
          });
        }
      }

      this.pBySku.Products.forEach(p => {
        for (let i = 0; i < p.ProductFeature.length; i++) {
          // עבור כל מוצר, מנייה של המאפינים השווים למאפיינים שהמשתמש בחר
          if (p.ProductFeature[i].FeatureValue == this.properties[i].SelectedOption)
            count++;
        }
        // במקרה שמספר המאפיינים השווים, שווה למספר הבחירות של המשתמש
        //סימון שאר המאפיינים למוצר זה כמאופשרים לבחירה
        if (count == selectionsCount) {
          for (let i = 0; i < this.properties.length; i++) {
            if (this.properties[i].SelectedOption == null) {
              this.fValue = this.properties[i].Values.find(x => x.Value == p.ProductFeature[i].FeatureValue);
              //אפשור בחירת המאפיין
              this.fValue.IsExistsInStock = 1;
            }
          }
        }
        count = 0;
      });

      // שמירת אינדקס המאפיין האחרון ששינה המשתמש
      this.lastModifyIndex = index;

      if (selectionsCount == this.properties.length) {// המשתמש סיים לבחור מכל הסלקטים
        this.isSelectedAll = true;
        this.getProductBySelectedProperties(v, index);
      }

    } else { //המשתמש שינה את בחירתו אחרי שסיים לבחור מכל סוגי המאפיינים

      //אם השינוי הוא שונה מהבחירה האחרונה
      if (this.lastModifyIndex != index) {

        this.isExistsInStock = 1;
        // איפוס הבחירות - מכל המאפיינים הקיימים מלבד הבחירה הנוכחית
        for (let i = 0; i < this.properties.length; i++) {
          this.lastModifyProperties.push(this.properties[i].SelectedOption);
          if (index != i) {
            this.properties[i].SelectedOption = null;
            this.properties[i].Values.forEach(v => {
              v.IsExistsInStock = 0;
            });
          }
        }
        // כיבוי המשתנה isSelectedAll
        //בגלל האיפוס
        this.isSelectedAll = false;
        //סימון המאפינים למוצר כמאופשרים לבחירה, במידה והערך הנבחר שווה
        this.pBySku.Products.forEach(p => {
          if (p.ProductFeature[index].FeatureValue == v) {
            for (let i = 0; i < p.ProductFeature.length; i++) {
              if (i != index) {
                //מציאת ערך המאפיין ברשימה
                this.fValue = this.properties[i].Values.find(x => x.Value == p.ProductFeature[i].FeatureValue);
                //אפשור המאפיין
                this.fValue.IsExistsInStock = 1;
              }
            }
          }
        });
      }
      else {
        this.getProductBySelectedProperties(v, index);
      }
    }
    if (this.isSelectedAll == true)
      this.isAddToCartClicked = false;
  }


  //חיפוש המוצר אותו בחר המשתמש - לפי המאפיינים הנבחרים
  getProductBySelectedProperties(v: any, index: number) {

    let count = 0;
    let q = parseInt(document.getElementById('quantity').innerHTML);
    //מציאת המאפיין
    this.isExistsInStock = this.properties[index].Values.find(x => x.Value == v).IsExistsInStock;

    if (this.isExistsInStock == 1) {//הבחירות תקינות
      for (let p of this.pBySku.Products) {
        if (p.ProductFeature[index].FeatureValue == v) {
          for (let i = 0; i < p.ProductFeature.length; i++) {
            // עבור כל מוצר מנייה של המאפינים השווים למאפיינים שהמשתמש בחר
            if (p.ProductFeature[i].FeatureValue == this.properties[i].SelectedOption && this.properties[i])
              count++;
          }
          if (count == this.properties.length) {
            this.product = p;
            this.sum = this.product.Price * q;
            this.mobileSumToPay = this.product.Price * this.mobileQuantity;
            return p; //break
          }
          count = 0;
        }
      }
    }
  }
}
// קוד לא שימושי
//יצירת מערך של שמות המאפיינים ולכל מאפיין את רשימת הערכים הקימים
      // let result = this.pBySku.Products.reduce(function (r, a) {
      //   a.ProductFeature.forEach(pf => {
      //     r[pf.FeatureName] = r[pf.FeatureName] || [];
      //     if (r[pf.FeatureName].find(x => x == pf.FeatureValue) == null)
      //       r[pf.FeatureName].push(pf.FeatureValue);
      //   });
      //   return r;
      // }, Object.create(null));
