import { StoreCustomers } from './store-customers';

//מחלקה עבור הרשמה והתחברות של לקוח לחנות
export class StoreCustomerItemByStore {
    constructor(public StoreAddressId:number,
                public StoreCustomer:StoreCustomers)
    {

    }
}
