import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Seller } from '../classes/seller';
import { Resailer } from '../classes/resailer';
import { ConfigModule } from '../config/config.module';
import { UserLogin } from '../classes/user-login';
import { AuthService } from './auth.service';
import { ResailerRepresentService } from './resailer-represent.service';



@Injectable({
  providedIn: 'root'
})
export class ResailerService {

  URL = ConfigModule.url + "api/Resailer/";


  private currResailer: Resailer = null;
  private resailersList: Resailer[] = null;

  constructor(private httpClient: HttpClient,
    private authSer: AuthService
  ) {

  }


  getCurrentResailer(): Resailer {
    //
    //
    if (this.currResailer == null)
      this.currResailer = JSON.parse(sessionStorage.getItem('resailer'))
    return this.currResailer;
  }
  setCurrentResailer(r: Resailer) {
    this.currResailer = r;
    sessionStorage.setItem('resailer', JSON.stringify(r));
  }
  editCurrentResailerDetails(r: Resailer) {
    this.currResailer.Email = r.Email;
    this.currResailer.ResailerImage = r.ResailerImage;
    this.currResailer.PhoneNumber = r.PhoneNumber;
    this.currResailer.ResailerName = r.ResailerName;

    sessionStorage.setItem('resailer', JSON.stringify(this.currResailer));
  }

  getCurrResailersFromService(): Resailer[] {
    if (this.resailersList == null)
      this.resailersList = JSON.parse(sessionStorage.getItem('resailersOfAdmin'));
    return this.resailersList;
  }
  setCurresailersInService(rs: Resailer[]) {
    this.resailersList = rs;
    sessionStorage.setItem('resailersOfAdmin', JSON.stringify(rs));
  }



  addResailerToCurrentResailers(r: Resailer) {
    if (this.resailersList == null)
      this.resailersList = JSON.parse(sessionStorage.getItem('resailersOfAdmin'));

    this.resailersList.push(r);
    sessionStorage.setItem('resailersOfAdmin', JSON.stringify(this.resailersList));
  }
  editResailerToCurrentResailers(r: Resailer) {
    if (this.resailersList == null)
      this.resailersList = JSON.parse(sessionStorage.getItem('resailersOfAdmin'));

    let index = this.resailersList.findIndex(x => x.ResailerId == r.ResailerId);
    this.resailersList[index] = r;

    sessionStorage.setItem('resailersOfAdmin', JSON.stringify(this.resailersList));
  }

  changeResailerStatusInService(id: number) {
    if (this.resailersList == null)
      this.resailersList = JSON.parse(sessionStorage.getItem('resailersOfAdmin'));

    let res = this.resailersList.find(x => x.ResailerId == id);

    if (res.ResailerStatus == 0)
      res.ResailerStatus = 1;
    else
      res.ResailerStatus = 0;

    sessionStorage.setItem('resailersOfAdmin', JSON.stringify(this.resailersList));

  }


  EDITresailer(idResailer: number, updatedResailer: Resailer, token: string, userId: number): Observable<Resailer> {
    return this.httpClient.put<Resailer>(this.URL + "editResailer/" + idResailer, updatedResailer,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token,
          'UserId': userId + ''
        })
      });
  }

  //  checkIfResailerExists(user:UserLogin):Observable<Resailer>
  //  {
  //    return this.httpClient.post<Resailer>(this.URL+"checkIfResailerExists",user);
  //  }

  // getAllResailer(managerId: number): Observable<Resailer[]> {
  //   return this.httpClient.post<Resailer[]>(this.URL + "getAllResailers", managerId,{ headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) });
  // }
  getAllResailer(managerId: number): Observable<Resailer[]> {
    return this.httpClient.get<Resailer[]>(this.URL + "getAllResailers/" + managerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) });
  }

  DeleteResailer(resailer: Resailer): Observable<void> {
    return this.httpClient.delete<void>(this.URL + "DeleteResailer/" + resailer.ResailerId);
  }

  ADDresailer(r: Resailer): Observable<Resailer> {
    var x = { "Resailer": r, "UserLogin": new UserLogin(r.UserName, r.ResailerPassword) }
    return this.httpClient.post<Resailer>(this.URL + "addResailer", x, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) });
  }



  //update image
  // postImage(image: File): Observable<string> {
  //     const formData: FormData = new FormData();
  //     formData.append('Image', image, image.name);
  //     if(this.ResailerNow==undefined)
  //       this.ResailerNow=new Resailer();
  //     return this.httpClient.post<string>(this.URL + "api/Resailer/uploadResailerImage/" + this.ResailerNow.idResailer, formData);
  //   }
  postImage(image: File, token: string, userId: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Image', image, image.name);

    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      }),
      responseType: 'text' as 'json'
    }
    return this.httpClient.post<any>(this.URL + "uploadResailerImage", formData, options);
  }

  //חסימת משווק
  blockingResailer(resailer: Resailer): Observable<void> {
    return this.httpClient.put<void>(this.URL + "blockingResailer/", resailer, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }) })
  }

  /*
  פונקציה שבודקת אם שם המשתמש או אימייל
  שהוזן בעת עריכת או הוספת משווק
  קיים כבר במערכת
  הפונקציה מחזירה :
  1 - שם משתמש קיים
  2 - כתובת אימייל קימת
  0 - הנתונים לא קיימים
  רק בתנאי שהפונקציה החזירה 0 אפשר להמשיק ולהוסיף
  */
  checkIfUserNameResailerOrEmailExists(email, userName, resailerId): Observable<number> {
    return this.httpClient.get<number>(this.URL + "checkIfUserNameResailerOrEmailExists/" + email + "/" + userName + "/" + resailerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }) })
  }

  changeStatus(resailerId): Observable<void> {
    return this.httpClient.get<void>(this.URL + "changeStatus/" + resailerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }

  deletePictures(names: string[], token: string, userId): Observable<any> {

    return this.httpClient.put<any>(this.URL + "deletePicture", names, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  getResailerBySellerId(sellerId: number): Observable<Resailer> {
    return this.httpClient.get<Resailer>(this.URL + "getResailerBySellerId/" + sellerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  // getResailerByUserNameAndPassword(ul:UserLogin):Observable<Resailer>
  // {
  //   return this.httpClient.post<Resailer>(this.URL+"getResailerByUserNameAndPassword",ul,{headers:new HttpHeaders({'Authorization':'Bearer '+sessionStorage.getItem('token')})})
  // }
}
