import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/classes/store';
import { StoreCustomers } from 'src/app/classes/store-customers';
import { CSStoreCustomerService } from 'src/app/services/customersiteservices/csstore-customer.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { StoreLinksComponent } from '../store-links/store-links.component';
import $ from 'jquery'
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { Customers } from 'src/app/classes/customers';
import { CustomerService } from 'src/app/services/customer.service';
@Component({
  selector: 'app-store-customer-setting',
  templateUrl: './store-customer-setting.component.html',
  styleUrls: ['./store-customer-setting.component.css']
})
export class StoreCustomerSettingComponent implements OnInit {

  store: Store = null;
  storeAddressId;
  storeCustomer: StoreCustomers = null;
  isSuccessSavedChanges: boolean = false;
  displayStorePss:boolean=false;
  displayNotSignInToStore:boolean=false;
  customer: Customers = null

  constructor(private csStoreCustomerSer: CSStoreCustomerService,
    private csStoreSer: CSStoreService, private storeLinksCom: StoreLinksComponent,
    private router: Router,
    activatedRoute:ActivatedRoute,
    storeSer:StoreService,
    customerSer:CustomerService) {

    storeLinksCom.changeDasignLink('store_customer_set_link');


    this.storeAddressId = csStoreSer.getCurrStoreAddressIdInService();



    if (this.storeAddressId == null) {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 2])
      this.storeAddressId = getCurrStoreAddressIdInService

    }

    this.storeCustomer = csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);
    if (this.storeCustomer == null) {
      this.displayNotSignInToStore = true;
      $('.settings_container').addClass('hide')
    }


     //בדיקה האם אומתה סיסמת לקוח לחנות
     if (!storeSer.getIsStorePassVerifiedInService()) {

      //בדיקה האם קימת סיסמת לקוח לחנות
      storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
        data => {

          if (data == true) {//יש סיסמת לקוחות בחנות
            //הצגת הודעה של הזנת סיסמא
            this.displayStorePss = true;
            $('.settings_container').addClass('hide')
          }
          else {

          }
        },
        err => {
          console.log(err);
        }
      )
    } else {


    }
    //(document.getElementById('isToEmail') as HTMLInputElement).checked=true
    // this.store = csStoreSer.getCurrStoreFromService();

    // if (this.store == null) {
    //   csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
    //     data => {
    //       csStoreSer.setCurrStoreInService(data);
    //       this.store = data;

    //     },
    //     err => { console.log(err.message) }
    //   )
    // }
    // else {

    // }



  }


  ngOnInit() {

    if(this.storeCustomer!=null&&this.displayStorePss==false)  {
    if (this.storeCustomer.EmailPublications == true)
      (document.getElementById('isToEmail') as HTMLInputElement).checked = true
    if (this.storeCustomer.SmsPublications == true)
      (document.getElementById('isToSMS') as HTMLInputElement).checked = true
    if (this.storeCustomer.PhonePublications == true)
      (document.getElementById('isToPhone') as HTMLInputElement).checked = true
    }
    else {
      $('.settings_container').addClass('hide')
  }

  }

  isSaveClicked: boolean = false
  save() {
    this.isSaveClicked = true;
    this.storeCustomer.EmailPublications = (document.getElementById('isToEmail') as HTMLInputElement).checked;
    this.storeCustomer.SmsPublications = (document.getElementById('isToSMS') as HTMLInputElement).checked;
    this.storeCustomer.PhonePublications = (document.getElementById('isToPhone') as HTMLInputElement).checked;

    
    this.csStoreCustomerSer.editStoreCustomer(this.storeCustomer.StoreCustomerId, this.storeCustomer).subscribe(
      data => {
        if (data == true) {
          this.csStoreCustomerSer.editStoreCustomerInService(this.storeCustomer.StoreCustomerId, this.storeCustomer, this.storeAddressId);
          this.isSaveClicked = false
          this.isSuccessSavedChanges = true
          setTimeout(() => {
            this.isSuccessSavedChanges = false
          }, 1500);

        }
      },
      err => {
        this.isSaveClicked = false
        console.log(err.message)
      }
    )

  }

  cancel() {
    this.storeCustomer = this.csStoreCustomerSer.getCurrStoreCustomerFromService(this.storeAddressId);

    //
    if (this.storeCustomer.EmailPublications == true)
      (document.getElementById('isToEmail') as HTMLInputElement).checked = true
    else
      (document.getElementById('isToEmail') as HTMLInputElement).checked = false

    if (this.storeCustomer.SmsPublications == true)
      (document.getElementById('isToSMS') as HTMLInputElement).checked = true
    else
      (document.getElementById('isToSMS') as HTMLInputElement).checked = false

    if (this.storeCustomer.PhonePublications == true)
      (document.getElementById('isToPhone') as HTMLInputElement).checked = true
    else
      (document.getElementById('isToPhone') as HTMLInputElement).checked = false

       setTimeout(() => {
            this.router.navigate(['/store-links/' + this.storeAddressId + '/store-products'])
          }, 1000);
  }

}
