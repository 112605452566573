import { Injectable } from '@angular/core';
import { ConfigModule } from '../config/config.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Seller } from '../classes/seller';
import { Observable } from 'rxjs';
import { UserLogin } from '../classes/user-login';
import { ResailerService } from './resailer.service';
import { Resailer } from '../classes/resailer';
import { ChangeUserPassword } from '../classes/change-user-password';
import { SystemMessage } from '../classes/system-message';
import { AuthService } from './auth.service';
import { Store } from '../classes/store';
import { ResailerRepresentService } from './resailer-represent.service';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(private httpClient: HttpClient,
    private resailerSer: ResailerService,
    private rpSer: ResailerRepresentService,
    private authSer: AuthService) {

  }
  url = ConfigModule.url + "api/Seller/"
  private currSellersOfResailer: Seller[] = null;
  private seller: Seller = null;
  private allSellers: Seller[] = null;
  private sellerOfresailser: Seller[] = null;
  private attachedFilesToMessage: File[] = [];

  getCurrentSeller(): Seller {
    if (this.seller == null)
      this.seller = JSON.parse(sessionStorage.getItem('seller'))
    return this.seller;
  }
  setCurrentSeller(s: Seller) {
    sessionStorage.setItem('seller', JSON.stringify(s));
    this.seller = s;
  }
  // פונקציות למנהל
  getSellers(): Seller[] {
    if (this.allSellers != null)
      this.allSellers = JSON.parse(sessionStorage.getItem('sellerOfAdmin'))
    return this.allSellers;
  }
  setSellers(sellers: Seller[]) {
    sessionStorage.setItem('sellerOfAdmin', JSON.stringify(sellers));
    this.allSellers = sellers;
  }

  getSellersOfResailerToManager(): Seller[] {
    if (this.currSellersOfResailer == null)
      this.currSellersOfResailer = JSON.parse(sessionStorage.getItem('sellerOfResailerToManager'))

    return this.currSellersOfResailer;
  }
  setSellersOfResailerToManager(sl: Seller[]) {
    this.currSellersOfResailer = sl;
    sessionStorage.setItem('sellerOfResailerToManager', JSON.stringify(sl));
  }


  addPicNameToSeller(name: string) {
    if (this.seller == null)
      this.seller = JSON.parse(sessionStorage.getItem('seller'))

    this.seller.SellerImage = name;
    sessionStorage.setItem('seller', JSON.stringify(this.seller));

  }

  getCurrSellersOfResailer(): Seller[] {
    if (this.currSellersOfResailer == null)
      this.currSellersOfResailer = JSON.parse(sessionStorage.getItem('sellersOfResailer'))

    return this.currSellersOfResailer;
  }
  setCurrSellersOfResailer(sl: Seller[]) {
    this.currSellersOfResailer = sl;
    sessionStorage.setItem('sellersOfResailer', JSON.stringify(sl));

  }

  //שינוי אחוזי הנחת תשלום למשווק עבור מוכר
  changePercentageDiscountBySeller(sellerId: number, percentageDiscount: number, token: string): Observable<void> {
    return this.httpClient.get<void>(this.url + "changePercentageDiscountBySeller/" + sellerId + "/" + percentageDiscount , { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) })
  }


  addSellerToCurrSellersOfResailer(s: Seller) {
    if (this.currSellersOfResailer == null)
      this.currSellersOfResailer = JSON.parse(sessionStorage.getItem('resailer')).Seller
    this.currSellersOfResailer.push(s);

    let r = this.resailerSer.getCurrentResailer();
    r.Seller = this.currSellersOfResailer;
    sessionStorage.setItem('resailer', JSON.stringify(r));
  }
  editSellerInCurrSellersOfResailer(s: Seller) {
    if (this.currSellersOfResailer == null)
      this.currSellersOfResailer = JSON.parse(sessionStorage.getItem('resailer')).Seller

    let index = this.currSellersOfResailer.findIndex(x => x.SellerId == s.SellerId);
    this.currSellersOfResailer[index] = s;

    let r = this.resailerSer.getCurrentResailer();
    r.Seller = this.currSellersOfResailer;
    sessionStorage.setItem('resailer', JSON.stringify(r));
  }

  changeSellerStatusInService(id: number) {
    let res = this.resailerSer.getCurrentResailer().Seller.find(x => x.SellerId == id);
    if (res.SellerStatus == 0)
      res.SellerStatus = 1;
    else
      res.SellerStatus = 0;
  }

  changeSellerStatusOfManagerInService(id: number) {
    let res = this.allSellers.find(x => x.SellerId == id);
    if (res.IsApprovalToAdmin == true)
      res.IsApprovalToAdmin = false;
    else
      res.IsApprovalToAdmin = true;
    sessionStorage.setItem('sellerOfAdmin', JSON.stringify(this.allSellers));
  }
  // שינוי סטטוס מוכר מצד המנהל בסרביס
  changeSellerOfResailerStatusByManagerInService(id: number) {
    this.sellerOfresailser = this.getSellersOfResailerToManager()
    let res = this.sellerOfresailser.find(x => x.SellerId == id);
    if (res.IsApprovalToAdmin == true)
      res.IsApprovalToAdmin = false;
    else
      res.IsApprovalToAdmin = true;
  }




  //שמירת קובץ מצורף של מוכר בפניות למערכת
  // addFileToMessage(fileToUpload:FileList)
  // {
  //    for(var i=0 ; i<fileToUpload.length -1;i++)
  //     this.attachedFilesToMessage.push(fileToUpload[0]);

  //     sessionStorage.setItem('attachedFilesToMessage',JSON.stringify(this.attachedFilesToMessage));
  // }

  //מחיקת קובץ מצורף של מוכר בפניות למערכת
  // deleteFileToMessage(fileToUpload:File)
  // {
  //      let ind=  this.attachedFilesToMessage.indexOf(fileToUpload);
  //      this.attachedFilesToMessage.splice(ind,1);
  //     sessionStorage.setItem('attachedFilesToMessage',JSON.stringify(this.attachedFilesToMessage));
  // }

  //קבלת הקבצים המצורפים להעלאה
  getFilesOfMessageSeller() {
    if (this.attachedFilesToMessage == null)
      this.attachedFilesToMessage = JSON.parse(sessionStorage.getItem('attachedFilesToMessage'))
    return this.attachedFilesToMessage;
  }


  //לטפל בענין
  // שינוי סטטוס מוכר ברשימת מוכרים של משווק בסשן
  // changeSellerStatusOfResailer(id:number)
  // {
  //   let res=this.allSellers.find(x=>x.SellerId==id);
  //   if(res.IsApprovalToAdmin==true)
  //      res.IsApprovalToAdmin=false;
  //   else
  //   res.IsApprovalToAdmin=true;
  // }


  //שינוי המשווק למוכר מסויים בסרביס
  changeReailerToSellerInService(resailer: Resailer, sellerId: number) {
    this.allSellers.find(x => x.SellerId == sellerId).Resailer = resailer;
    this.allSellers.find(x => x.SellerId == sellerId).ResailerId = resailer.ResailerId;

    sessionStorage.setItem('sellerOfAdmin', JSON.stringify(this.allSellers));
  }


  //פונקציות עבור משווק שרוצה לראות פרטים של משווק
  private currSellerIdForResailer: number = null
  //שמירת של קוד מוכר שאותו רוצה לראות בסשן
  setCurrSellerIdForResailerInService(sellerId: number) {
    sessionStorage.setItem('currSellerIdForResailer', sellerId + "")
    this.currSellerIdForResailer = sellerId
  }
  //קבלת קוד מוכר שעליו רוצה לצפות מהסרביס
  getCurrSellerIdForResailerInService() {
    if (this.currSellerIdForResailer == null)
      this.currSellerIdForResailer = parseInt(sessionStorage.getItem('currSellerIdForResailer'));

    return this.currSellerIdForResailer;
  }

  checkIfSellerExists(user: UserLogin): Observable<Seller> {
    return this.httpClient.post<Seller>(this.url + "checkIfSellerExists", user);
  }

  //שליחת פניה של מוכר למערכת
  // sendMessageToSystem(currMessage:SystemMessage,attachedFiles:FileList):Observable<void>
  // {

  //     let formData: FormData = new FormData();
  //     if(attachedFiles!=undefined){
  //       for (var i=0; i < attachedFiles.length ; i++) {

  //         formData.append('some name ' + i, attachedFiles[i], attachedFiles[i].name) // file.name is optional
  //     }
  //     }
  //     return this.httpClient.post<void>(this.url+"sendSellerMesssageToSystem/"+currMessage.Subject +"/"+ currMessage.Content,formData,{headers:new HttpHeaders({'Authorization':'Bearer '+sessionStorage.getItem('token')})})
  // }

  sendMessageToSystem(currMessage: SystemMessage, attachedFiles: FileList): Observable<boolean> {
    let formData: FormData = new FormData();
    formData.append('subject', currMessage.Subject);
    formData.append('content', currMessage.Content);

    formData.append('sender', this.seller.SellerId.toString());
    formData.append('sellerUserName', this.seller.UserName);
    formData.append('sellerEmail', this.seller.Email);
    formData.append('sellerPhoneNumber', this.seller.PhoneNumber)

    if (attachedFiles != undefined) {
      for (var i = 0; i < attachedFiles.length; i++) {
        formData.append('file_' + i, attachedFiles[i], attachedFiles[i].name) // file.name is optional
      }
    }
    // currMessage.FilesContainer=formData;
    return this.httpClient.post<boolean>(this.url + "sendSellerMesssageToSystem", formData, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': this.seller.SellerId + ''
      })
    })
  }



  //קבלת מוכר יחיד
  getSellerById(id, token: string): Observable<Seller> {
    return this.httpClient.get<Seller>(this.url + "getSellerById/" + id, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) })
  }
  //עריכת פרטי מוכר
  editSellerDetails(seller: Seller, token: string, role: string): Observable<Seller> {
    let userId;
    if (role == "Seller")
      userId = this.getCurrentSeller().SellerId;
    else
      userId = this.rpSer.getRPFromService().ResailerRepresentId

    return this.httpClient.put<Seller>(this.url + "editSeller/" + seller.SellerId, seller, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }
  //העלאת תמונה למוכר
  postImage(image: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Image', image, image.name);

    const options = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }),
      responseType: 'text' as 'json'
    }
    return this.httpClient.post<any>(this.url + 'uploadSellerImage/' + this.seller.SellerId, formData, options);
  }



  // checkIfSellerEmailExists(email:string,id):Observable<any>
  // {
  //   return this.httpClient.get<any>(this.url+"checkIfSellerEmailExists/"+email+"/"+id,{headers:new HttpHeaders({'Authorization':'Bearer '+sessionStorage.getItem('token')})})
  // }
  //חסימת מוכר
  // blockingSeller(seller:Seller):Observable<void>
  // {
  //   return this.httpClient.put<void>(this.url+"blockingSeller/",seller)
  // }
  // //אישור מוכר
  // permissionSeller(seller:Seller):Observable<void>
  // {
  //   return this.httpClient.put<void>(this.url+"permissionSeller/",seller)
  // }

  /*
 פונקציה שבודקת אם שם המשתמש או אימייל
 שהוזן בעת עריכת או הוספת משווק
 קיים כבר במערכת
 הפונקציה מחזירה :
 1 - שם משתמש קיים
 2 - כתובת אימייל קימת
 0 - הנתונים לא קיימים
 רק בתנאי שהפונקציה החזירה 0 אפשר להמשיק ולהוסיף
 */
  checkIfUserNameSellerExists(userName, sellerId, token: string, role: string): Observable<number> {


    let userId;

    switch (role) {
      case "ResailerRepresent":
        userId = this.rpSer.getRPFromService().ResailerRepresentId;
        break;
      case "RPAdmin":
        userId = this.rpSer.getRPFromService().ResailerRepresentId;
        break;
      default:
        userId = this.getCurrentSeller().SellerId
        break;
    }
    return this.httpClient.get<number>(this.url + "checkIfUserNameSellerExists/" + userName + "/" + sellerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  //המשווק פונה לפונקציה הזאת
  addSellerToResailer(seller: Seller, userId: number): Observable<Seller> {
    return this.httpClient.post<Seller>(this.url + "addSeller", seller,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getResailerToken()
          , 'UserId': userId + ''
        })
      });
  }

  changeStatus(sellerId: number, token: string, role: string): Observable<void> {

    //
    let userId;

    switch (role) {
      case "ResailerRepresent":
        userId = this.rpSer.getRPFromService().ResailerRepresentId;
        break;
      case "RPAdmin":
        userId = this.rpSer.getRPFromService().ResailerRepresentId;
        break;
      default:
        break;
    }
    return this.httpClient.get<void>(this.url + "changeStatus/" + sellerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  changeSellerStatus(sellerId: number, token: string): Observable<void> {
    return this.httpClient.get<void>(this.url + "changeSellerStatus/" + sellerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) })
  }

  changeResailerToSeller(resailerId: number, sellerId: number, token: string) {
    return this.httpClient.get<void>(this.url + "changeResailerToSeller/" + sellerId + "/" + resailerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) })
  }

  getAllSellersByAdmin(): Observable<Seller[]> {
    return this.httpClient.get<Seller[]>(this.url + "getAllSellersByAdmin", { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) });
  }

  getSellerOfResailerToAdmin(idResailer: number): Observable<Seller[]> {
    return this.httpClient.get<Seller[]>(this.url + "getSellerByResailerToAdmin/" + idResailer, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) });
  }


  getSellerOfResailer(idResailer: number): Observable<Seller[]> {
    return this.httpClient.get<Seller[]>(this.url + "getSellerByResailer/" + idResailer, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getResailerToken() }) });
  }



  initpass(email: string, userName: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.url + "initpass/" + email + "/" + userName)
  }

  getSellerByUserNameAndPassword(ul: UserLogin): Observable<Seller> {
    return this.httpClient.post<Seller>(this.url + "getSellerByUserNameAndPassword", ul, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }) })
  }


  //שינוי סיסמת מוכר
  /*
  הפונקציה מקבלת אוביקט מסוג
  ChangeUserPassword
  ומחזירה
  0- במקרה שהסיסמא הישנה שהזין אינה תואמת את הסיסמא הקיימת
  1- הסיסמא שונתה בהצלחה
  2- הסיסמא החדשה תואמת את הסיסמא הישנה - לא תקין
  */
  changeSellerPassword(cup: ChangeUserPassword): Observable<any> {

    return this.httpClient.post<any>(this.url + "changeSellerPassword", cup, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken(), 'UserId': cup.UserId + '' }) });
  }

  getStoreByStoreAddressId(storeAddressId): Observable<Store> {
    return this.httpClient.get<Store>(this.url + "getStoreByStoreAddressId/" + storeAddressId);
  }
}
