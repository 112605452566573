import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, config } from 'rxjs';
import { ProductInStore } from '../../classes/product-in-store';
import { ConfigModule } from '../../config/config.module';
import { Category } from 'src/app/classes/category';
import { ProductDataWithFeature } from 'src/app/classes/product-data-with-feature';
import { StoreProductsFilter } from 'src/app/classes/filter/store-products-filter';


@Injectable({
  providedIn: 'root'
})
export class CSProductInStoreService {

  url = ConfigModule.url + "api/ProductInStore/";
  urlProductFilter = ConfigModule.url + "api/ProductInStoreFilters/";
  originalProducts: ProductInStore[] = [];
  productsByStore: ProductInStore[] = [];
  productsFeatureByStore: ProductDataWithFeature[] = [];
  constructor(private httpClient: HttpClient) { }


  // //קבלת המוצרים של חנות מהסשן
  // getProductsByStoreFromService(): ProductInStore[] {
  //   if (this.productsByStore == null || this.productsByStore.length == 0)
  //     this.productsByStore = JSON.parse(sessionStorage.getItem('productsByStore'))
  //   return this.productsByStore;
  // }
  // //שמירת המוצרים של חנות בסשן
  // setProductsByStoreInService(pl: ProductInStore[]) {
  //   this.productsByStore = pl;
  //   sessionStorage.setItem('productsByStore', JSON.stringify(pl));
  // }
  // //קבלת רשימת נתוני המוצרים עם מאפיינים מהסשן
  // getProductsDataWithFeatueByStoreFromService(): ProductDataWithFeature[] {
  //   if (this.productsFeatureByStore == null || this.productsFeatureByStore.length == 0)
  //     this.productsFeatureByStore = JSON.parse(sessionStorage.getItem('productsFeatureByStore'))
  //   return this.productsFeatureByStore;
  // }
  // getProductsBySkuFromService(sku): ProductDataWithFeature {
  //   if (this.productsFeatureByStore == null || this.productsFeatureByStore.length == 0)
  //     this.productsFeatureByStore = JSON.parse(sessionStorage.getItem('productsFeatureByStore'));
  //   let productsBySku = this.productsFeatureByStore.find(x => x.SKU == sku);
  //   return productsBySku;
  // }
  //  //שמירת רשימת נתוני המוצרים עם מאפיינים בסשן
  //  setProductsDataWithFeatureByStoreInService(pd: ProductDataWithFeature[]) {
  //   this.productsFeatureByStore = pd;
  //   sessionStorage.setItem('productsFeatureByStore', JSON.stringify(pd));
  // }

  setNumberOfPagesLoaded(numOfLoads: number) {//שמירת מספר הטעינות שהמשתמש טען
    sessionStorage.setItem('numberOfPagesLoaded', JSON.stringify(numOfLoads));
  }
  getNumberOfPagesLoaded(): number {//קבלת מספר הטעינות שהמשתמש טען
    let numOfLoads = JSON.parse(sessionStorage.getItem('numberOfPagesLoaded'));
    if (numOfLoads == null)
      return 1;
    return numOfLoads;
  }


  //שליחה לפונקצייה שמחזירהF עשרים מוצרים מהחנות
  getProductsByStoreAddressIdByloadingNumber(storeAddressId: number, counter: number, numOfProductsPerLoads: number): Observable<ProductInStore[]> {
    return this.httpClient.get<ProductInStore[]>(this.url + "getProductsByStoreAddressIdByloadingNumber/" + storeAddressId + "/" + counter + "/" + numOfProductsPerLoads);
  }
  // שליחה לפונקציה שמחזירה 20 מוצרים לחנות לפי סינון
  getProductsByFilter(storeAddressId: number, counter: number, numOfProductsPerLoads: number, filterObj: StoreProductsFilter): Observable<any> {
    return this.httpClient.post<any>(this.url + "getProductsByFilter/" + storeAddressId + "/" + counter + "/" + numOfProductsPerLoads, filterObj);
  }
  //שליחה לפונקציה שמחזירה את כל המוצרים ממקט מסוים
  getProductsBySKU(storeId, sku): Observable<ProductInStore[]> {
    return this.httpClient.get<ProductInStore[]>(this.url + 'getProductsByCommonSKU/' + storeId + '/' + sku);
  }

  getCountProductsInStore(storeAddressId): Observable<any> {
    return this.httpClient.get<any>(this.url + "getCountProductsInStore/" + storeAddressId);
  }
  // קבלת מספר המוצרים הקיימים מקטגוריות מסויימות
  getCountProductsByCategories(storeAddressId, categories: Category[]): Observable<number> {
    return this.httpClient.post<number>(this.urlProductFilter + "getCountProductsByCategories/" + storeAddressId, categories);
  }


  //שמירת אוביקט הסינון בסרביס
  setFilterObjInServise(filterObj) {
    localStorage.setItem('filterObj', JSON.stringify(filterObj));
    sessionStorage.setItem('filterObj', JSON.stringify(filterObj));
  }
  // קבלת אוביקט הסינון מהסרביס
  getFilterObjFromService() {
    var filterObj = JSON.parse(sessionStorage.getItem('filterObj'))
    return filterObj;
  }

  // //קבלת המוצרים לפי קטגוריות
  // filterProductsByCategories(storeAddressId, categories: Category[]): Observable<ProductInStore[]> {
  //   return this.httpClient.post<ProductInStore[]>(this.urlProductFilter + "filterProductsByCategories/" + storeAddressId, categories);
  // }





}
