import { Component, OnInit } from '@angular/core';
import { StoreLinksComponent } from '../store-links/store-links.component';

@Component({
  selector: 'app-customer-credits',
  templateUrl: './customer-credits.component.html',
  styleUrls: ['./customer-credits.component.css']
})
export class CustomerCreditsComponent implements OnInit {

  constructor(private storeLinksCom:StoreLinksComponent) {
    storeLinksCom.changeDasignLink('credit_link');
  }

  ngOnInit() {
  }

}
