import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/classes/store';
import { ConfigModule } from 'src/app/config/config.module';
import { CSCartService } from 'src/app/services/customersiteservices/cscart.service';
import { CSProductInStoreService } from 'src/app/services/customersiteservices/csproduct-in-store.service';
import { CSStoreService } from 'src/app/services/customersiteservices/csstore.service';
import { StoreService } from 'src/app/services/store.service';
import { StoreLinksComponent } from '../store-links/store-links.component';

@Component({
  selector: 'app-store-shipping',
  templateUrl: './store-shipping.component.html',
  styleUrls: ['./store-shipping.component.css']
})
export class StoreShippingComponent implements OnInit {


  storeId
  //storeNameInSite:string=""
  storeAddressId
  store: Store = new Store()
  url = "";
  displayStorePss: boolean = false;


  constructor(private csStoreSer: CSStoreService,
    private csProductInStoreSer: CSProductInStoreService,
    private csCart: CSCartService,
    private storeLinksCom: StoreLinksComponent,
    private storeSer: StoreService,
    private router: Router) {

    storeLinksCom.changeDasignLink('shipping_link');
    //החנות הספציפית שאליה נכנס המשתמש
    if (this.csStoreSer.getCurrStoreAddressIdInService() != null) {
      this.storeAddressId = this.csStoreSer.getCurrStoreAddressIdInService();
    }
    else {
      let href = window.location.href.split('/')
      let getCurrStoreAddressIdInService = decodeURIComponent(href[href.length - 2])
      this.storeAddressId = getCurrStoreAddressIdInService
    }


    //בדיקה האם אומתה סיסמת לקוח לחנות
    if (!this.storeSer.getIsStorePassVerifiedInService()) {
      //בדיקה האם קימת סיסמת לקוח לחנות
      this.storeSer.checkIsStorePssExists(this.storeAddressId).subscribe(
        data => {
          if (data == true) {//יש סיסמת לקוחות בחנות
            //הצגת הודעה של הזנת סיסמא
            this.displayStorePss = true;
          }
          else {
            //קבלת החנות ופרטיה
            this.getCurrrentStore()
          }
        },
        err => {
          console.log(err);
        }
      )
    } else {
      //קבלת החנות ופרטיה
      this.getCurrrentStore()

      // this.router.navigate()!!!!!!!!!!!!!!!

    }

    // let s = this.csStoreSer.getCurrStoreFromService();

    // if (s == null || s.StoreAddressId != this.storeAddressId) {
    //   this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
    //     data => {
    //       this.csStoreSer.setCurrStoreInService(data);
    //       //this.store=data;
    //       this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
    //       this.storeId = data.StoreId;
    //       this.storeAddressId = data.StoreAddressId
    //       this.store = data;
    //       this.url = ConfigModule.url + "files/Stores/" + this.storeId + "/" + this.store.BackgroundImage
    //     },
    //     err => {
    //       console.log(err.message)
    //     }
    //   )
    // }
    // else {
    //   this.storeId = s.StoreId
    //   this.store = s;
    //   this.url = ConfigModule.url + "files/Stores/" + this.storeId + "/" + this.store.BackgroundImage;
    // }
  }
  ngOnInit() {
  }


  getCurrrentStore() {
    let s = this.csStoreSer.getCurrStoreFromService();
    if (s == null || s.StoreAddressId != this.storeAddressId) {
      this.csStoreSer.getStoreByStoreAddressId(this.storeAddressId).subscribe(
        data => {
          this.csStoreSer.setCurrStoreInService(data);
          //this.store=data;
          this.csStoreSer.setCurrStoreAddressIdInService(data.StoreAddressId);
          this.storeId = data.StoreId;
          this.storeAddressId = data.StoreAddressId
          this.store = data;
          this.url = ConfigModule.url + "files/Stores/" + this.storeId + "/" + this.store.BackgroundImage
        },
        err => {
          console.log(err.message)
        }
      )
    }
    else {
      this.storeId = s.StoreId
      this.store = s;
      this.url = ConfigModule.url + "files/Stores/" + this.storeId + "/" + this.store.BackgroundImage;
    }
  }
}
