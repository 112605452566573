import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customers } from '../classes/customers';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { UserLogin } from '../classes/user-login';
import { ManagerService } from './manager.service';
import { ChangeUserPassword } from '../classes/change-user-password';
import { SystemMessage } from '../classes/system-message';
import { AuthService } from './auth.service';
import { SystemMessageByCustomer } from '../classes/system-message-by-customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService) {
  }
  setCurrCustomerInService(c: Customers) {
    this.currCustomer = c;
    sessionStorage.setItem('customer', JSON.stringify(c));
  }


  getCurrCustomerFromService(): Customers {
    if (this.currCustomer == null)
      this.currCustomer = JSON.parse(sessionStorage.getItem('customer'))
    return this.currCustomer;
  }



  countCustomer: number;
  //שמירה כמה לקוחות מקושרים
  setCountCustomerInService(countCustomer: number) {
    this.countCustomer = countCustomer;
    sessionStorage.setItem('CountConnectedCustoers', JSON.stringify(countCustomer));
  }
  //שליפה כמה לקוחות מקושרים
  getCountCustomerFromService(): number {
    if (this.countCustomer == null)
      this.countCustomer = JSON.parse(sessionStorage.getItem('CountConnectedCustoers'))
    return this.countCustomer;
  }


  // שמירת הלקוח שהמנהל נכנס אליו
  setCurrCustomerByManagerInService(c: Customers) {
    this.currCustomerByManager = c;
    sessionStorage.setItem('customerbyManager', JSON.stringify(c));
  }

  //שליפת הלקוח שהמנהל נכנס אליו
  getCurrCustomerByaManagerFromService(): Customers {
    if (this.currCustomerByManager == null)
      this.currCustomerByManager = JSON.parse(sessionStorage.getItem('customerbyManager'))
    return this.currCustomerByManager;
  }


  currCustomerByManager: Customers = null;
  url = ConfigModule.url + "api/Customer/"
  allCustomers: Customers[] = null;
  currCustomer: Customers = null;
  isResultsSingleaResailer: boolean = false;
  //קבלת כל הלקוחות למוכר מסוים
  getCustomersForSeller(sellerId): Observable<Customers[]> {
    return this.httpClient.get<Customers[]>(this.url + "getCustomersForSeller/" + sellerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }) })
  }

  //קבלת כל הלקוחות למשווק מסוים
  getCustomerForResailer(resailerId: number): Observable<Customers[]> {
    return this.httpClient.get<Customers[]>(this.url + "getCustomerForResailer/" + resailerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getResailerToken() }) })
  }



  //הוספת לקוח למערכת
  //,sellerId:number
  addCustomer(cstmr: Customers): Observable<any> {
    return this.httpClient.post<any>(this.url + "addCustomer", cstmr)
  }

  //עריכת לקוח
  editCustomer(customer: Customers, token: string, role: string): Observable<Customers> {
    let userId;
    if (role == "Customer")
      userId = this.getCurrCustomerFromService().CustomerId;

    return this.httpClient.put<Customers>(this.url + "editCustomer/" + customer.CustomerId, customer, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  //  this.customerSer.getAllCustomers().subscribe(
  //   data=>{
  //     this.customerSer.setCustomersInService(data);

  //קבלת כמות הלקוחות הרשומים במערכת
  getCountCustomers(): Observable<any> {
    return this.httpClient.get<any>(this.url + "getCountCustomers", { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }

  getCountCustomersBySearch(txt: string): Observable<any> {

    return this.httpClient.get<any>(this.url + "getCountCustomersBySearch/" + txt, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }

  //קבלת לקוחות לפי מספר
  getAllCustomersInSiteByCount(num, valueSearch: string): Observable<Customers[]> {
    if (valueSearch == "")
      valueSearch = null;
    return this.httpClient.get<Customers[]>(this.url + "getAllCustomersInSiteByCount/" + num + "/" + valueSearch, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }

  //קבלת כל הלקוחות
  getAllCustomers(): Observable<Customers[]> {
    return this.httpClient.get<Customers[]>(this.url + "getAllCustomersInSite/", { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }

  // שינוי סטטוס לקוח בשרת - מטעם המנהל
  changeCustomerStatusByAdmin(customerId: number): Observable<void> {
    return this.httpClient.get<void>(this.url + "changeCustomerStatusByAdmin/" + customerId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getAdminToken() }) })
  }
  // שמירה בסשן את כל הלקוחות
  setCustomersInService(customers: Customers[]) {
    this.allCustomers = customers;
    sessionStorage.setItem('customersOfAdmin', JSON.stringify(customers));
  }
  // שליפה מהסשן את כל הלקוחות
  getCustomersFromService(): Customers[] {
    if (this.allCustomers == null)
      this.allCustomers = JSON.parse(sessionStorage.getItem('customersOfAdmin'));
    return this.allCustomers;
  }

  // שינוי סטטוס לקוח בשן
  // changeCustomerStatusInService(id:number)
  // {

  //   let res=this.allCustomers.find(x=>x.CustomerId==id);
  //   if(res.IsApprovalToAdmin==false)
  //   res.IsApprovalToAdmin=true;
  //   else
  //   res.IsApprovalToAdmin=false;
  // }
  //עדכון פרטי לקוח בסשן ע"י המנהל
  // editCustomerByManagerInService(c:Customers)
  // {
  // if(this.allCustomers==null)
  // this.allCustomers=JSON.parse(sessionStorage.getItem('customersOfAdmin'));
  // let index=this.allCustomers.findIndex(x=>x.CustomerId==c.CustomerId);
  // this.allCustomers[index]=c;
  //  sessionStorage.setItem('customersOfAdmin',JSON.stringify(this.allCustomers));
  // }

  //--------------------ניסוי---------------------

  //שמירה בסשן האם המנהל נכנס לצפות בכל הלקוחות
  //או רק בלקוחות של משווק מסויים
  setItemsOnlyResailer(is: boolean) {
    this.isResultsSingleaResailer = is;
    sessionStorage.setItem('isSingleResailer', JSON.stringify(this.isResultsSingleaResailer));
  }
  // שליפה מהסשן האם המנהל נכנס לצפות בכל הלקחות
  // או רק בלקוחות של משווק מסוים
  getItemsOnlyResailer() {
    if (this.isResultsSingleaResailer != null)
      this.isResultsSingleaResailer = JSON.parse(sessionStorage.getItem('isSingleResailer'));
    return this.isResultsSingleaResailer;
  }

  //מחיקת לקוח



  //במחיקת לקוח הלקוח


  checkIfCustomerUserNameExists(customerId: number, userName: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.url + "checkIfCustomerUserNameExists/" + customerId + "/" + userName)
  }

  //בדיקה אם לקוח קיים במערכת
  checkIfCustomerExists(user: UserLogin): Observable<Customers> {
    return this.httpClient.post<Customers>(this.url + "checkIfCustomerExists", user)
  }


  getAllCustomersInSite(): Observable<Customers[]> {
    return this.httpClient.get<Customers[]>(this.url + "getAllCustomersInSite");
  }

  checkIfPhoneOrMailExists(housePhoneNumber: string, anotherPhoneNumber: string, mail: string, customerId: number): Observable<any> {
    return this.httpClient.get<any>(this.url + "checkIfPhoneOrMailExists/" + housePhoneNumber + "/" + anotherPhoneNumber + "/" + mail + "/" + customerId);
  }

  checkIfCustomerPhoneNumbersExists(customerId: number, phoneNumber: string, anotherPhoneNumber: string, housePhoneNumber: string): Observable<any> {
    return this.httpClient.get<any>(this.url + "checkIfCustomerPhoneNumbersExists/" + customerId + "/" + phoneNumber + "/" + anotherPhoneNumber + "/" + housePhoneNumber)
  }

  getTempCodeToPhoneVarification(phone: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.url + "getTempCodeToPhoneVarification/" + phone)
  }
  checkIfTempCodeIsCorrect(phone: string, tempCode: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.url + "checkIfTempCodeIsCorrect/" + phone + "/" + tempCode)
  }

  getCustomerByUserNameAndPassword(ul: UserLogin): Observable<Customers> {
    return this.httpClient.post<Customers>(this.url + "getCustomerByUserNameAndPassword", ul, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getCustomerToken() }) })
  }

  getCountConnectedCustomer(): Observable<number> {
    return this.httpClient.get<number>(this.url + "getCountConnectedCustomer")
  }
  initPass(email: string, userName: string): Observable<Boolean> {
    return this.httpClient.get<Boolean>(this.url + "initPass/" + email + "/" + userName);
  }
  //שליחת פנייה
  sendMessageToSystem(currMessage: SystemMessage): Observable<boolean> {
    // let formData: FormData = new FormData();
    // formData.append('subject',currMessage.Subject);
    // formData.append('content',currMessage.Content)

    // currMessage.FilesContainer=formData;
    return this.httpClient.post<boolean>(this.url + "sendCustomerMesssageToSystem", currMessage)//,{headers:new HttpHeaders({'Authorization':'Bearer '+sessionStorage.getItem('token')})})
  }


  //שליחת פנייה מדף הנחיתה
  sendLandingPageRequestToSystem(currMessage: SystemMessageByCustomer) : Observable<boolean> {
    return this.httpClient.post<boolean>(ConfigModule.url+"api/LandingPage/sendLandingPageRequestToSystem", currMessage)


  }

  //1 - טלפון
  //2 - טלפון נוסף
  //3 -טלפון בבית
  //4 - שם משתמש
  //אמייל- 5
  //0 - מאושר
  checkIfCustomerUserNameOrPhoneNumbersOrEmailExists(customer: Customers, token: string, role: string): Observable<number> {
    let userId;
    if (role == "Customer")
      userId = customer.CustomerId;
    return this.httpClient.get<number>(this.url + "checkIfCustomerUserNameOrPhoneNumbersOrEmailExists/" + customer.PhoneNumber + "/" + customer.AnotherPhoneNumber + "/" + customer.HousePhoneNumber + "/" + customer.UserName + "/" + customer.Email + "/" + customer.CustomerId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token
          , 'UserId': userId + ''
        })
      })
  }



  //שינוי סיסמת לקוח
  /*
  הפונקציה מקבלת אוביקט מסוג
  ChangeUserPassword
  ומחזירה
  0- במקרה שהסיסמא הישנה שהזין אינה תואמת את הסיסמא הקיימת
  1- הסיסמא שונתה בהצלחה
  2- הסיסמא החדשה תואמת את הסיסמא הישנה - לא תקין
  */
  changeCustomerPassword(cup: ChangeUserPassword): Observable<any> {
    return this.httpClient.post<any>(this.url + "changeCustomerPassword", cup,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getCustomerToken(),
          'UserId': cup.UserId + ''
        })
      });
  }

  //עבור דף הבית של אתר הלקוחות
  private countCustomers: number = null
  setCountCustomersInServiceForCustomerSit(num: number) {
    this.countCustomers = num
  }
  getCountCustomersFromServiceForCustomerSite() {
    return this.countCustomers;
  }


  //פונקציה לקבלת קוד אימות ללקוח לפי מזהה לקוח
  getVerificationCodeByCustomerId(customerId: number): Observable<string> {
    let userId = this.getCurrCustomerFromService().CustomerId;
    return this.httpClient.get<string>(this.url + "getVerificationCodeByCustomerId/" + customerId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    })
  }

}

