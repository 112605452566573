import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-to-cart-error',
  templateUrl: './add-to-cart-error.component.html',
  styleUrls: ['./add-to-cart-error.component.css']
})
export class AddToCartErrorComponent implements OnInit {

  constructor() {
    console.log('1')
    setTimeout(() => {
      console.log('2')
    }, 2000);
  }

  ngOnInit() {
  }

}
