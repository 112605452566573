export class StoreBranch {
    constructor(public  StoreBranchesId:number=0,
        public  BranchName:string="",
        public  BranchAddress:string="",
        public  StoreId:number=0,
        public  BranchStatus:Boolean=true,
        public  BranchIndex:number=0,
        public  SysRowStatus:Boolean=null,
        public  Email:string="",
        public  IsSendEmailToBranch:Boolean=false)
        {

        }
}
