import { Component, OnInit } from '@angular/core';
import { UserLogin } from 'src/app/classes/user-login';
import { Router } from '@angular/router';
import { SellerService } from 'src/app/services/seller.service';
import { AuthService } from '../../../services/auth.service';
import { ConfigModule } from '../../../config/config.module';
import { ResailerService } from '../../../services/resailer.service';
//import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-seller-login',
  templateUrl: './seller-login.component.html',
  styleUrls: ['./seller-login.component.css']
})
export class SellerLoginComponent implements OnInit {

  name = '';
  showFirstFrm: Boolean = true;
  showSecondFrm: Boolean = false;
  ptrnEscape: string;
  ptrn:string;
  constructor(private router: Router,
    private sellerSer: SellerService,
    private authSer: AuthService,
    private resailerSer: ResailerService) {
    // this.userLogin.UserName="שם משתמש";
    // this.userLogin.Password="סיסמה";
    this.ptrn = ConfigModule.ptrn;
      //עבור הדף שגיאה
      sessionStorage.setItem('status', 'seller')
    this.ngOnInit();

  }

  ngOnInit() {
    //local storage בדיקה אם המשתמש קיים ב
    if (localStorage.getItem('sellerToken')) {
      this.authSer.setSellerToken(localStorage.getItem('sellerToken'))

      this.sellerSer.setCurrentSeller(JSON.parse(localStorage.getItem('seller')))
      //לבדוק פה קודם אם התוקף של התוקן פג

      let tokenExpireTime: Date = new Date(localStorage.getItem('tokenExpireTime'));

      let now = new Date(Date.now());
      if (now > tokenExpireTime) {
        //sessionStorage.clear();
        localStorage.removeItem('seller')
        localStorage.removeItem('sellerToken')
        localStorage.removeItem('tokenExpireTime')

        this.sellerSer.setCurrentSeller(null);
        this.router.navigate(['/seller'])
      }
      else
        this.router.navigate(['/seller-hp-up-links/hp-side-links/seller-hp'])

      // resailerSer.getResailerBySellerId((JSON.parse(localStorage.getItem('seller'))).SellerId).subscribe(
      //   data=>{
      //     router.navigate(['/seller-hp-up-links/hp-side-links/seller-hp'])
      //   },
      //   err=>{
      //     if((err.message as string).includes(ConfigModule.errMss))
      //     {
      //         sessionStorage.clear();
      //         localStorage.clear();
      //         this.router.navigate(['/seller'])
      //     }
      //   }
      // )

    }
  }
  isSellerExists: Boolean = true
  isSellerBlocked: Boolean = false
  userLogin: UserLogin = new UserLogin();
  isClicked: Boolean = false


  login() {
    this.isClicked = true;
    this.isSellerExists = true
    document.getElementById('input_text_user').classList.remove('input_err')
    document.getElementById('input_text_pss').classList.remove('input_err')
    var x = document.getElementById('input_btn');
    x.classList.add('btn_clicked');

    this.authSer.sellerLogin(this.userLogin).subscribe(
      data => {
        x.classList.remove('btn_clicked')
        this.authSer.setSellerToken(data.access_token)

        this.sellerSer.getSellerByUserNameAndPassword(this.userLogin).subscribe(
          data => {
            if (data.SellerStatus == 0) {
              sessionStorage.removeItem('sellerToken')
              this.isSellerBlocked = true;
              this.isClicked = false;
            }
            else {
              this.sellerSer.setCurrentSeller(data);
              //בדיקה אם לחץ על זכור אותי
              let cb = (document.getElementById('cb_remember') as HTMLInputElement).checked;
              if (cb == true) {
                localStorage.setItem('seller', JSON.stringify(data));
                localStorage.setItem('sellerToken', this.authSer.getSellerToken())
                //שמירת התאריך של יצירת התוקן כדי לבדוק אחר כך אם הוא פג
                let expireTime = new Date(Date.now())
                //שיניתי ל7 ימים
                new Date(expireTime.setDate(new Date(expireTime).getDate() + 7))
                //new Date(expireTime.setMinutes(new Date(expireTime).getMinutes()+4))

                //expireTime.setDate(expireTime.getDate()+1)
                localStorage.setItem('tokenExpireTime', expireTime.toString())
              }
              //ניתוב לדף הבית של אפשרויות המוכר
              this.router.navigate([ConfigModule.sellerUrl + '/seller-hp'])
            }
          },
          err => { console.log(err.message) }
        )
      },
      err => {
        this.isSellerExists = false;
        this.isClicked = false;
        // alert('שם משתמש או סיסמא שגויים')
        x.classList.remove('btn_clicked');
        document.getElementById('input_text_user').classList.add('input_err')
        document.getElementById('input_text_pss').classList.add('input_err')
        //הוספת הקלאסים
        console.log(err.message)
      }
    )

  }



  //כניסה למערכת של הנוד
  loginNode() {
    this.isClicked = true;
    this.isSellerExists = true
    document.getElementById('input_text_user').classList.remove('input_err')
    document.getElementById('input_text_pss').classList.remove('input_err')
    var x = document.getElementById('input_btn');
    x.classList.add('btn_clicked');

    this.authSer.nodeJsSellerLogin(this.userLogin).subscribe(
      data => {
        x.classList.remove('btn_clicked')
        if (data.success == 0)//המוכר חסום
        {
          this.isSellerBlocked = true;
          this.isClicked = false;
        }
        else {
          this.authSer.setSellerToken(data.token)

          this.sellerSer.setCurrentSeller(data.seller);
          //בדיקה אם לחץ על זכור אותי
          let cb = (document.getElementById('cb_remember') as HTMLInputElement).checked;
          if (cb == true) {
            localStorage.setItem('seller', JSON.stringify(data.seller));
            localStorage.setItem('sellerToken', this.authSer.getSellerToken())
            //שמירת התאריך של יצירת התוקן כדי לבדוק אחר כך אם הוא פג
            let expireTime = new Date(Date.now())
            //שיניתי ל7 ימים
            new Date(expireTime.setDate(new Date(expireTime).getDate() + 7))
            //new Date(expireTime.setMinutes(new Date(expireTime).getMinutes()+4))

            //expireTime.setDate(expireTime.getDate()+1)
            localStorage.setItem('tokenExpireTime', expireTime.toString())
          }
          //ניתוב לדף הבית של אפשרויות המוכר
          this.router.navigate([ConfigModule.sellerUrl + '/seller-hp'])
        }
      },

      err => {
        this.isSellerExists = false;
        this.isClicked = false;
        // alert('שם משתמש או סיסמא שגויים')
        x.classList.remove('btn_clicked');
        document.getElementById('input_text_user').classList.add('input_err')
        document.getElementById('input_text_pss').classList.add('input_err')
        //הוספת הקלאסים
        console.log(err.message)
      }
    )
  }

  isForgotPss: Boolean = false;//---------
  isEmailExists: Boolean = true;
  isSent: Boolean = false
  isInitpassClicked: Boolean = false
  initpass(email: string, name) {

    this.isInitpassClicked = true;
    this.sellerSer.initpass(email, name).subscribe(
      data => {
        if (data == false) {
          this.isEmailExists = false;
          this.isInitpassClicked = false;
        }
        else {
          this.isSent = true;

          setTimeout(() => {
            this.isSent = false;
            this.isInitpassClicked = false
            this.showSecondFrm = false;
            this.showFirstFrm = true;
            this.isForgotPss = false
          }, 2500);
        }
      },
      err => {
        this.isInitpassClicked = false;
        console.log(err.message)
      }
    )
  }


  nameForgotPss: string = ""
  email: string = ""

  showLoginFrm: Boolean = true
  showForgotPassword() {
    this.isForgotPss = true;
    this.isSellerExists = true;
    this.showLoginFrm = false
  }


  userNameFocus() {
    if (this.userLogin.UserName == "שם משתמש")
      this.userLogin.UserName = ""
  }
  userNameBlur() {
    if (this.userLogin.UserName == "")
      this.userLogin.UserName = "שם משתמש"
  }
  pssFocus() {
    if (this.userLogin.Password == "סיסמה")
      this.userLogin.Password = ""

    var x = document.getElementById('input_text_pss');
    x.removeAttribute('type')
    x.setAttribute('type', 'password')
  }
  pssBlur() {
    if (this.userLogin.Password == "") {
      var x = document.getElementById('input_text_pss');
      x.removeAttribute('type')
      x.setAttribute('type', 'text')
      this.userLogin.Password = "סיסמה"
    }
  }

}
