import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Buying } from '../classes/buying';
import { Observable } from 'rxjs';
import { Charts } from '../classes/charts';
import { ShoppingDataToStore } from '../classes/shopping-data-to-store';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class BuyingService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService,
    private customerSer: CustomerService) { }

  url = ConfigModule.url + "api/Buying/";
  allBuyings: Buying[] = [];
  shoppingsData: ShoppingDataToStore[] = [];

  getBuyingsByStoreId(storeId: number): Observable<Buying[]> {

    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<Buying[]>(this.url + "getBuyingsByStoreId/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }
  getBuyingsByCustomerId(custId: number): Observable<Buying[]> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;

    return this.httpClient.get<Buying[]>(this.url + "getBuyingsByCustomerId/" + custId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    });
  }
  getBuyingsByCustomerIdAndDates(custId: number, from, to): Observable<Buying[]> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;

    return this.httpClient.get<Buying[]>(this.url + "getBuyingsByCustomerIdAndDates/" + custId + "/" + from + "/" + to, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
        , 'UserId': userId + ''
      })
    });
  }
  // שמירה בסשן את כל הקניות של לקוח מסוים
  setBuyingsOfCustomerInService(buyings: Buying[]) {
    this.allBuyings = buyings;
    sessionStorage.setItem('buyingsOfCustomer', JSON.stringify(buyings));
  }
  // שליפה מהסשן את כל הקניות של לקוח מסויים
  getBuyingsOfCustomerFromService(): Buying[] {
    if (this.allBuyings == null)
      this.allBuyings = JSON.parse(sessionStorage.getItem('buyingsOfCustomer'));
    return this.allBuyings;
  }
  getBuyingByBuyingId(id: number, token: string, userId: number): Observable<Buying> {
    return this.httpClient.get<Buying>(this.url + "getBuyingByBuyingId/" + id,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token
          , 'UserId': userId + ''
        })
      });
  }

  //שינוי סטטוס קנייה
  changeBuyingStatus(buyingId: number, status: number): Observable<void> {
    return this.httpClient.get<void>(this.url + "changeBuyingStatus/" + buyingId + "/" + status,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': this.sellerSer.getCurrentSeller().SellerId + ''
        })
      })
  }
  downloadBuyingSummary(buyingId: number, token: string, role: string): Observable<any> {
    let userId;
    if (role == 'Customer')
      userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    else
      userId = this.sellerSer.getCurrentSeller().SellerId
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'UserId': userId + ''
      })
    };
    //let url = ConfigModule.url + "api/Store/savePdf";
    return this.httpClient.get(this.url + "downloadBuyingSummary/" + buyingId, httpOptions);
  }

  downloadRangeSummary(date1, date2, storeId, userId: number): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
        'UserId': userId + ''
      })
    };
    //let url = ConfigModule.url + "api/Store/savePdf";
    return this.httpClient.get(this.url + "downloadRangeSummary/" + date1 + "/" + date2 + "/" + storeId,
      httpOptions);

  }

  downloadRangeSummaryByBranches(date1, date2, storeId, userId: number): Observable<any> {
    // if(date1=="")
    // date1=null;
    // if(date2=="")
    // date2==null;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
        'UserId': userId + ''
      })
    };
    //let url = ConfigModule.url + "api/Store/savePdf";
    return this.httpClient.get(this.url + "downloadRangeSummaryByBranches/" + date1 + "/" + date2 + "/" + storeId,
      httpOptions);
  }
  downloadBuyingDetailsWithProducts(date1, date2, storeId, userId: number): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
        'UserId': userId + ''
      })
    };
    return this.httpClient.get(this.url + "downloadBuyingDetailsWithProducts/" + date1 + "/" + date2 + "/" + storeId,
      httpOptions);
  }


  downloadRangeAllSummariesInPdf(date1, date2, storeId, userId: number): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
        'UserId': userId + ''
      })
    };
    return this.httpClient.get(this.url + "downloadRangeAllSummariesInPdf/" + date1 + "/" + date2 + "/" + storeId,
      httpOptions);
  }

  //שמירה בסשן את סיכומי הרכישות של מוכר
  setCalculationShoppingsDataInService(shoppingData: ShoppingDataToStore[]) {
    this.shoppingsData = shoppingData;
    sessionStorage.setItem('shoppingsData', JSON.stringify(shoppingData));
  }
  //שליפה מהסשן את סיכומי הרכישות של מוכר
  getCalculationShoppingsDataFromService(): ShoppingDataToStore[] {
    if (this.shoppingsData == null || this.shoppingsData.length == 0)
      this.shoppingsData = JSON.parse(sessionStorage.getItem('shoppingsData'));
    return this.shoppingsData;
  }

  //פונקציות עבור אפשרויות סינון
  getFilterBuyingsByAll(storeId: number, custName: string, daysNum: string, status: string, sum: string): Observable<Buying[]> {
    return this.httpClient.get<Buying[]>(this.url + "getFilterBuyingsByAll/" + storeId + "/" + custName + "/" + daysNum + "/" + status + "/" + sum, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }) });
  }

  //עבור סינון לפי טווח תאריכים
  getFilterBuyingsByDates(storeId, custName: string, d1: Date, d2: Date, status: string, sum: string): Observable<Buying[]> {
    return this.httpClient.get<Buying[]>(this.url + "getFilterBuyingsByDates/" + storeId + "/" + custName + "/" + d1 + "/" + d2 + "/" + status + "/" + sum, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }) });
  }
  //סינון לפי טווח סכום
  getFilterBuyingsBySumRange(storeId, custName, daysNum, status, sum1, sum2): Observable<Buying[]> {
    return this.httpClient.get<Buying[]>(this.url + "getFilterBuyingsBySumRange/" + storeId + "/" + custName + "/" + daysNum + "/" + status + "/" + sum1 + "/" + sum2, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authSer.getSellerToken() }) });
  }
  //שליפת סכימת הקניות לפי חודשים בישביל ההצגה בגרף
  getSumBuingAndNumBuingsByMonth(sellersId: number[], sellerId: number): Observable<Charts> {
    return this.httpClient.post<Charts>(this.url + "getSumBuingAndNumBuingsByMonth/" + sellerId, sellersId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': sellerId + ''
        })
      });
  }
  //קבלת מספר הרכישות וסכום הרכישות  בחניות של מוכר
  getBuyingsSumAndBuyingsNumByStores(sellerId: number): Observable<ShoppingDataToStore[]> {
    return this.httpClient.get<ShoppingDataToStore[]>(this.url + 'getBuyingsSumAndBuyingsNumByStores/' + sellerId,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken()
          , 'UserId': sellerId + ''
        })
      });
  }

  getBuyingsByCustomerIdAndStoreId(storeCustomerId, storeId): Observable<Buying[]> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;

    return this.httpClient.get<Buying[]>(this.url + "getBuyingsByCustomerIdAndStoreId/" + storeCustomerId + "/" + storeId, {
      headers:
        new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
          , 'UserId': userId + ''
        })
    });
  }


  getUnPaidBuyingsByStoreCustomerId(storeCustomerId, storeId): Observable<Buying[]> {
    let userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    return this.httpClient.get<Buying[]>(this.url + "getUnPaidBuyingsByStoreCustomerId/" + storeCustomerId + "/" + storeId, {
      headers:
        new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getCustomerToken()
          , 'UserId': userId + ''
        })
    });
  }

  //שמירת הקנייה שהלקוח בתהליך של תשלום עבורה בסרביס
  getBuyingFromService(): Buying {
    let b = JSON.parse(localStorage.getItem('buying'))
    return b;
  }
  // קבלת הקנייה שהלקוח שילם עבורה מהסרביס
  setBuyingInService(b: Buying) {
    localStorage.setItem('buying', JSON.stringify(b));
  }


  //ניסוי לסיום קנייה
  //עבור בדיקת המערכת הטלפונית
  /*
  [Route("getAddProductsInBuyingToDBIncludeDetailesShipment/{storeAddressId}
  /{sellerPassword}/{storePassword}/
  {customerUserName}/{customerPassword}/" +
            "{savingSort}/{isDelivery}/{branchIndex}/" +
            "{ShipmentCity}/" +
            "{ShipmentStreet}/" +
            "{ShipmentNumHouse}/" +
            "{shipmentNumApartment}/" +
            "{shipmentEntrance}/" +
            "{ShipmentPostalCode}/" +
            "{ShipmentContactPerson}/" +
            "{ShipmentPhoneNumber}/" +
            "{ShipmentNotes}")]
  */

  getDetailCustomers(productId, date1, date2): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
      })
    };

    // return this.httpClient.get(this.url+"downloadRangeSummary/"+date1+"/"+date2+"/"+storeId,
    //  httpOptions );
    if (date1 == "")
      date1 = null

    if (date2 == "")
      date2 = null;

    return this.httpClient.get<any>(this.url + "getdownloadBuyingDetailsByProduct/" + productId + "/" + date1 + "/" + date2, httpOptions);
  }

  getBuyingsByFilter(storeId, filterObj, userId: number): Observable<any> {
    return this.httpClient.post<any>(this.url + "getBuyingsByFilter/" + storeId, filterObj,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getSellerToken(),
          'UserId': userId + ''
        })
      })
  }

  getSumBuyingsForAllStoresByDates(date1, date2): Observable<any> {
    return this.httpClient.get<any>(this.url + "getSumBuyingsForAllStoresByDates/" + date1 + '/' + date2,
      {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.authSer.getAdminToken()
        })
      })
  }

  getAddProductsInBuyingToDBIncludeDetailesShipment(storeAddressId, sellerPassword, storePassword, customerUserName, customerPassword,
    savingSort, isDelivery, branchIndex, ShipmentCity, ShipmentStreet, ShipmentNumHouse, shipmentNumApartment,
    shipmentEntrance, ShipmentPostalCode, ShipmentContactPerson, ShipmentPhoneNumber, ShipmentNotes
  ): Observable<any> {
    return this.httpClient.get<any>(ConfigModule.url + "api/ProductsInBuyingIvr/getAddProductsInBuyingToDBIncludeDetailesShipment/" +
      storeAddressId + "/" + sellerPassword + "/" + storePassword + "/" + customerUserName + "/" + customerPassword + "/" +
      savingSort + "/" + isDelivery + "/" + branchIndex + "/" + ShipmentCity + "/" + ShipmentStreet + "/" + ShipmentNumHouse + "/" + shipmentNumApartment + "/" +
      shipmentEntrance + "/" + ShipmentPostalCode + "/" + ShipmentContactPerson + "/" + ShipmentPhoneNumber + "/" + ShipmentNotes)
  }
}
