import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { Observable } from 'rxjs';
import { ProductInTempCart } from '../classes/product-in-temp-cart';
import { TempCart } from '../classes/temp-cart';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';
@Injectable({
  providedIn: 'root'
})
export class ProductInTempCartService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService) { }

  allCartByCustomer: TempCart[] = [];
  url = ConfigModule.url + "api/ProductInTempCart/"

  //שמירה בסשן את הקניות בבצוע של לקוח מסוים
  setTempCartsOfCustomerInService(carts: TempCart[]) {
    this.allCartByCustomer = carts;
    sessionStorage.setItem('tempCartByCustomer', JSON.stringify(carts));
  }

  //שליפה מהסשן קניות בצוע של לקוח
  getTempCartsOfCustomerFromService(): TempCart[] {
    if (this.allCartByCustomer == null || this.allCartByCustomer.length == 0)
      this.allCartByCustomer = JSON.parse(sessionStorage.getItem('tempCartByCustomer'));
    return this.allCartByCustomer;
  }

  // getAllProductsInTempCart(): Observable<ProductInTempCart[]> {
  //   return this.httpClient.get<ProductInTempCart[]>(this.url + "getAllProductsInTempCart")
  // }
  

  //tempCart.service  עברו ל
  
  // getAllTempCartsByStoreId(storeId: number): Observable<TempCart[]> {
  //   let sellerId = this.sellerSer.getCurrentSeller().SellerId

  //   return this.httpClient.get<TempCart[]>(this.url + "getAllTempCartsByStoreId/" + storeId, {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer ' + this.authSer.getSellerToken()
  //       , 'UserId': sellerId + ''
  //     })
  //   })
  // }

  // getTempCartByStoreCustomerId(storeCustomerId): Observable<TempCart> {
  //   let sellerId = this.sellerSer.getCurrentSeller().SellerId

  //   return this.httpClient.get<TempCart>(this.url + "getTempCartByStoreCustomerId/" + storeCustomerId, { headers: new HttpHeaders({
  //     'Authorization': 'Bearer ' + this.authSer.getSellerToken()
  //     , 'UserId': sellerId + ''
  //   })
  // })
  // }

  deleteProductInTempCart(sku, storeCustomerId): Observable<ProductInTempCart[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.delete<ProductInTempCart[]>(this.url + "deleteProductInTempCart/" + storeCustomerId + "/" + sku, { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.authSer.getSellerToken()
      , 'UserId': sellerId + ''
    })
  })
  }
  deleteTempCart(storeId, storeCustomerId): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.delete<any>(this.url + "deleteTempCart/" + storeId + "/" + storeCustomerId, { headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.authSer.getSellerToken()
      , 'UserId': sellerId + ''
    })
  })
  }
  
}
