import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreProfitsTrack } from '../classes/store-profits-track';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigModule } from '../config/config.module';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentsTracksService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService) { }
    url = ConfigModule.url + "api/StoreProfitsTrack/"
  getAllPaymentsTracks():Observable<StoreProfitsTrack[]>
  {
    return this.httpClient.get<StoreProfitsTrack[]>(this.url + "getAllPaymentsTracks")

  }
}

