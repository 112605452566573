import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoreBranch } from '../classes/store-branch';
import { ConfigModule } from '../config/config.module';
import { AuthService } from './auth.service';
import { SellerService } from './seller.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class StoreBranchService {

  constructor(private httpClient: HttpClient,
    private authSer: AuthService,
    private sellerSer: SellerService,
    private customerSer:CustomerService) { }

  url = ConfigModule.url + "api/StoreBranch/";

  getStoreBranchesByStoreId(storeId,token:string,role:string): Observable<StoreBranch[]> {

    let userId;
    if (role == 'Customer')
      userId = this.customerSer.getCurrCustomerFromService().CustomerId;
    else
      userId = this.sellerSer.getCurrentSeller().SellerId
    return this.httpClient.get<StoreBranch[]>(this.url + "getStoreBranchesByStoreId/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
        , 'UserId': userId + ''
      })
    })
  }

  getStoreBranchesByStoreIdForTempCartSaving(storeId): Observable<StoreBranch[]> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<StoreBranch[]>(this.url + "getStoreBranchesByStoreIdForTempCartSaving/" + storeId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  editStoreBranch(sb: StoreBranch): Observable<StoreBranch> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.put<StoreBranch>(this.url + "editStoreBranch/" + sb.StoreBranchesId, sb, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  addStoreBranch(sb: StoreBranch): Observable<StoreBranch> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.post<StoreBranch>(this.url + "addStoreBranch", sb, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  deleteStoreBranch(sbId: number): Observable<any> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.delete<any>(this.url + "deleteStoreBranch/" + sbId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  changeStoreBranchStatus(sbId: number): Observable<void> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<void>(this.url + "changeStoreBranchStatus/" + sbId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }

  getStoreBrancheById(sbId): Observable<StoreBranch> {
    let sellerId = this.sellerSer.getCurrentSeller().SellerId

    return this.httpClient.get<StoreBranch>(this.url + "getStoreBrancheById/" + sbId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authSer.getSellerToken()
        , 'UserId': sellerId + ''
      })
    })
  }
}
